import React, { ComponentType, PropsWithChildren, ReactNode } from 'react';
import { Box, Tooltip, TooltipProps as MuiTooltipProps } from '@mui/material';

export interface TooltipProps {
  tooltip: NonNullable<ReactNode>;
  disableTooltip?: boolean;
  disabled?: boolean;
  disabledTooltip?: string;
  TooltipProps?: Omit<MuiTooltipProps, 'title' | 'children'>;
}

export function withTooltip<P>(Component: ComponentType<PropsWithChildren<P>>) {
  const ComponentWithTooltip = React.forwardRef<HTMLElement, PropsWithChildren<P> & TooltipProps>(
    (props, ref) => {
      const { tooltip, disableTooltip, disabledTooltip, TooltipProps, ...componentProps } = props;
      if (disableTooltip) {
        return <Component {...(componentProps as PropsWithChildren<P>)} ref={ref} />;
      }
      if (componentProps.disabled && disabledTooltip) {
        return (
          <Tooltip title={disabledTooltip} {...TooltipProps}>
            <Box component="span" sx={{ cursor: 'help' }}>
              <Component {...(componentProps as PropsWithChildren<P>)} ref={ref} />
            </Box>
          </Tooltip>
        );
      }
      return (
        <Tooltip title={tooltip} {...TooltipProps}>
          <Component {...(componentProps as PropsWithChildren<P>)} ref={ref} />
        </Tooltip>
      );
    }
  );
  ComponentWithTooltip.displayName = `${Component.displayName || 'Component'}WithTooltip`;
  return ComponentWithTooltip;
}
