import React, { ReactNode, useEffect, useRef } from 'react';
import { DragEndEvent, useDndMonitor, useDroppable } from '@dnd-kit/core';

import { timeId } from '~utils/chore';
import { Coordinate } from '~utils/geometry';

import { FullSizeBox } from '../../../../../full-size-box';
import { ExternalElementDropData } from '../../../../element-board.types';
import { PixelBoardProps } from '../../pixel-board';
import { PixelBoxNode } from '../../pixel-board.types';
import { addNodeToPixelBoard } from '../../pixel-board.utils';

export interface ExternalDroppableLayerProps {
  onClick: React.MouseEventHandler;
  onMouseDown: React.MouseEventHandler;
  onContextMenu: React.MouseEventHandler;
  children: ReactNode;
  editable: PixelBoardProps['editable'];
  onChange: PixelBoardProps['onChange'];
  defaultSize: Exclude<PixelBoardProps['defaultSize'], undefined>;
}

const ExternalDroppableLayerId = 'pixel-board-external-droppable';

export function ExternalDroppableLayer({
  editable,
  onChange,
  defaultSize,
  ...props
}: ExternalDroppableLayerProps) {
  const mousePositionRef = useRef<Coordinate>({
    x: 0,
    y: 0,
  });
  const { setNodeRef } = useDroppable({
    disabled: !editable,
    id: ExternalDroppableLayerId,
  });
  useDndMonitor({
    onDragEnd({ active, over }: DragEndEvent) {
      if (over?.id !== ExternalDroppableLayerId || !over) {
        return;
      }
      const activeData = active?.data?.current as ExternalElementDropData;
      if (activeData.context !== 'board-external') {
        return;
      }
      const newElement: PixelBoxNode = {
        ...defaultSize(activeData),
        id: timeId(),
        data: {
          elementId: activeData.elementId,
        },
        type: 'box',
        top: mousePositionRef.current.y - over.rect.top,
        left: mousePositionRef.current.x - over.rect.left,
      };
      onChange?.(addNodeToPixelBoard(newElement));
    },
  });
  useEffect(() => {
    // TODO: Just a tricky way, should update when have a better way
    const trackMouseHandler = (e: MouseEvent) => {
      mousePositionRef.current = {
        x: e.clientX,
        y: e.clientY,
      };
    };
    window.addEventListener('mousemove', trackMouseHandler);

    return () => window.removeEventListener('mousemove', trackMouseHandler);
  }, []);

  return <FullSizeBox ref={setNodeRef} {...props} />;
}
