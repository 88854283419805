import {
  DialogActions as MUIDialogActions,
  DialogContent as MUIDialogContent,
  Stack,
  styled,
} from '@mui/material';

export const ContainerList = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.grey,
  borderRadius: theme.spacing(0.5),
  height: '100%',
  overflow: 'auto',
  width: '40%',
  paddingTop: theme.spacing(1),
  justifyContent: 'space-between',
  position: 'relative',
}));

export const ContainerDetail = styled(Stack)(({ theme }) => ({
  width: '100%',
  marginLeft: theme.spacing(1),
  overflow: 'auto',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(0.5),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(1.5),
  paddingTight: theme.spacing(1.5),
}));

export const DialogContent = styled(MUIDialogContent)({
  height: '100%',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
});

export const DialogActions = styled(MUIDialogActions)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '50%',
  marginLeft: 'auto',
  marginRight: 'auto',
}));
