import { PropsWithChildren, useCallback, useSyncExternalStore } from 'react';
import { Router } from 'react-router-dom';
import { History } from 'history';

import { HistoryContext } from '../history-provider';

interface DfRouterProps {
  basename?: string;
  history: History;
}

export function DfRouter({ basename, history, children }: PropsWithChildren<DfRouterProps>) {
  const getPathName = useCallback(() => {
    return history.location.pathname;
  }, [history.location.pathname]);

  const pathname = useSyncExternalStore(history.listen, getPathName, getPathName);

  return (
    <HistoryContext.Provider value={history}>
      <Router basename={basename} location={pathname} navigator={history}>
        {children}
      </Router>
    </HistoryContext.Provider>
  );
}
