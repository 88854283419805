import { useEffect, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { useHistory } from '~libs/router';

export interface DocumentUrlConfig {
  pageCode: string;
  seal: string;
  quickSeal: string;
  hideHeader: boolean;
  hidePageBorder: boolean;
  forcePageBgcolor: string;
  forceGraphBgcolor: string;
  forceFaceColor: string;
}

type DocumentUrlConfigCasters = {
  [K in keyof DocumentUrlConfig]: (value: string | null | undefined) => DocumentUrlConfig[K] | null;
};

function castStringOrNull(value: unknown): string | null {
  return value ? String(value) : null;
}

const DOCUMENT_URL_CONFIG_CASTERS: DocumentUrlConfigCasters = {
  pageCode: castStringOrNull,
  seal: castStringOrNull,
  quickSeal: castStringOrNull,
  hideHeader: Boolean,
  hidePageBorder: Boolean,
  forcePageBgcolor: castStringOrNull,
  forceGraphBgcolor: castStringOrNull,
  forceFaceColor: castStringOrNull,
};

const URL_CONFIG_ALIASES: Partial<{ [K in keyof DocumentUrlConfig]: string[] }> = {
  pageCode: ['page', 'page_code'],
  quickSeal: ['quick-seal', 'quick_seal'],
  hideHeader: ['hide-header', 'hide_header'],
  hidePageBorder: ['hide-page-border', 'hide_page_border'],
  forcePageBgcolor: ['force-page-bgcolor', 'force_page_bgcolor'],
  forceGraphBgcolor: ['force-graph-bgcolor', 'force_graph_bgcolor'],
  forceFaceColor: ['force-face-color', 'force_face_color'],
};

function getSearchValue<K extends keyof DocumentUrlConfig>(
  urlSearchParams: URLSearchParams,
  key: K
) {
  const aliases: string[] = URL_CONFIG_ALIASES[key] || [];
  const keys = [key, ...aliases];
  let value: string | null = null;
  let index = 0;
  while (!value && index < keys.length) {
    value = urlSearchParams.get(keys[index]);
    index++;
  }
  return DOCUMENT_URL_CONFIG_CASTERS[key](value) as DocumentUrlConfig[K];
}

export function useDocumentUrlConfig<K extends keyof DocumentUrlConfig>(
  keys: K[]
): Pick<DocumentUrlConfig, K> {
  const history = useHistory();
  const [params, setParams] = useState(() => {
    const urlSearchParams = new URLSearchParams(history.location.search);
    return Object.fromEntries(
      keys.map((key) => [key, getSearchValue(urlSearchParams, key)])
    ) as Pick<DocumentUrlConfig, K>;
  });
  const keysRef = useRef(keys);
  keysRef.current = keys;

  const prevParamsRef = useRef(params);
  prevParamsRef.current = params;

  useEffect(() => {
    return history.listen(({ location }) => {
      const urlSearchParams = new URLSearchParams(location.search);
      const newParams = Object.fromEntries(
        keysRef.current.map((key) => [key, getSearchValue(urlSearchParams, key)])
      ) as Pick<DocumentUrlConfig, K>;
      if (!shallow(newParams, prevParamsRef.current)) {
        setParams(newParams);
      }
    });
  }, [history]);

  return params;
}
