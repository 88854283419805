import { Button, styled } from '@mui/material';

import { withProps } from '~utils/styled';

const BaseButton = styled(
  withProps(Button, {
    fullWidth: true,
    size: 'large',
  })
)(({ theme }) => ({
  ...theme.typography.body1,
  fontWeight: 600,
}));

export const RejectButton = styled(
  withProps(BaseButton, {
    autoFocus: true,
    color: 'inherit',
    variant: 'outlined',
  })
)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export const AcceptButton = styled(
  withProps(BaseButton, {
    color: 'primary',
    variant: 'contained',
  })
)(({}) => ({}));
