import { GenericElementEntity } from '../element.entity';

export type TextElementOverflow = 'hidden' | 'auto' | 'visible';
export const textShapeTypes = ['rect', 'ellipse', 'diamond', 'star', 'triangle'] as const;

export type TextElementShape = (typeof textShapeTypes)[number];

export interface TextElementConfig {
  text: string;
  shape?: TextElementShape;
  editor?: 'quill';
  overflow?: TextElementOverflow;
}

export interface TextElementEntity extends GenericElementEntity<'text', TextElementConfig> {}

export const defaultTextElementConfig = (): Partial<TextElementConfig> => ({
  overflow: 'visible',
});
