import {
  alpha,
  Collapse as MUICollapse,
  styled,
  Table as MUITable,
  TableCell,
  tableCellClasses,
  tableHeadClasses,
  tableRowClasses,
} from '@mui/material';

export const DFStyledTable = styled(MUITable, {
  shouldForwardProp: (propName) => propName != 'transparent' && propName != 'boldHeader',
})(
  ({ theme }) => ({
    [`.${tableHeadClasses.root}`]: {
      borderRadius: theme.shape.borderRadiusSm,
    },

    [`.${tableCellClasses.root}`]: {
      border: 'none',
      borderTop: `1px solid ${theme.palette.divider}`,
      ...theme.typography.body1,
      // Sub table
      [`.${tableCellClasses.root}`]: {
        color: theme.palette.text.secondary,
        padding: theme.spacing(1.75),
      },
    },
    [`.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.lighter,
      borderTop: 'none',
      '&:first-of-type': {
        borderTopLeftRadius: theme.shape.borderRadiusSm,
        borderBottomLeftRadius: theme.shape.borderRadiusSm,
      },
      '&:last-child': {
        borderTopRightRadius: theme.shape.borderRadiusSm,
        borderBottomRightRadius: theme.shape.borderRadiusSm,
      },
    },
    [`.${tableRowClasses.root}`]: {
      '&:first-of-type': {
        [`.${tableCellClasses.root}`]: {
          borderTop: 'none',
        },
      },
    },
    [`.${tableRowClasses.hover}`]: {
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.lighter || '#fff', 0.2),
      },
    },
  }),
  ({ size, theme }) =>
    size === 'small' && {
      [`.${tableCellClasses.root}`]: {
        ...theme.typography.inputValue,
      },
    }
);

export const SubComponentWrapper = styled(TableCell)(() => ({
  /**
   * set this to create a stacking context
   * so sub component will not overlap the parent
   */
  isolation: 'isolate',
  border: 'none',
  borderTop: 'none !important',
}));

export const Collapse = styled(MUICollapse)(({ theme }) => ({
  background: theme.palette.background.subTable,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3),
}));
