import { GenericChartConfig, GenericChartElementEntity } from './chart-element';

export type NodeAlign = 'center' | 'justify' | 'start' | 'end';

export type NodeSort = 'auto' | 'input' | 'ascending' | 'descending';

export type LabelPosition = 'inside' | 'outside';

export type LabelOrientation = 'horizontal' | 'vertical';

export interface SankeyDiagramBaseConfig {}

export interface SankeyDiagramSpecialConfig extends SankeyDiagramBaseConfig {
  valueField?: string;
  sourceField?: string;
  targetField?: string;
  nodeAlign?: NodeAlign;
  nodeSort?: NodeSort;
  nodeThickness?: number;
  nodeSpacing?: number;
  nodeBorderRadius?: number;
  linkContract?: number;
  enableLinkGradient?: boolean;
  dataLabelsPosition?: LabelPosition;
  dataLabelsOrientation?: LabelOrientation;
  dataLabelsPadding?: number;
}

export const NODE_ALIGN_DEFAULT = 'center';
export const NODE_SORT_DEFAULT = 'auto';

export const defaultSankeyDiagramConfig = (): SankeyDiagramSpecialConfig => ({
  nodeAlign: NODE_ALIGN_DEFAULT,
  nodeSort: NODE_SORT_DEFAULT,
  nodeThickness: 18,
  nodeSpacing: 24,
  nodeBorderRadius: 3,
  linkContract: 3,
  enableLinkGradient: true,
  dataLabelsOrientation: 'vertical',
});

export interface SankeyDiagramConfig
  extends GenericChartConfig<'sankey'>,
    SankeyDiagramSpecialConfig {}
export interface SankeyDiagramElementEntity
  extends GenericChartElementEntity<SankeyDiagramConfig> {}
