import { useEffect, useState } from 'react';

import { createZustandHooks } from '~utils/zustand';

import { PixelBoardProps } from '../../pixel-board';

import { createPixelBoardStore } from './pixel-board.store';
import { PixelBoardContext } from './pixel-board-context';

export const [usePixelBoardStore, usePixelBoardSelector] = createZustandHooks(PixelBoardContext);

export const useRegisterPixelBoardStore = ({
  value,
  selectedNodeIds,
}: Pick<PixelBoardProps, 'value' | 'selectedNodeIds'>) => {
  const [store] = useState(() =>
    createPixelBoardStore({
      value,
      selectedNodeIds: selectedNodeIds || [],
    })
  );

  useEffect(() => {
    store.setState({
      value,
      selectedNodeIds: selectedNodeIds || [],
    });
  }, [selectedNodeIds, store, value]);

  return store;
};
