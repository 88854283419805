import { useMemo } from 'react';
import { pick } from 'lodash-es';
import { shallow } from 'zustand/shallow';

import {
  applyTransform,
  calculateScaleTransform,
  Coordinate,
  RECT_POSITION_KEYS,
  RectPosition,
} from '~utils/geometry';

import { useDragTransformData } from '../components/drag-data-context';
import { DraggableTransformData } from '../components/drag-data-context/drag-data-context.store';
import { usePixelBoardSelector } from '../components/pixel-board-context/pixel-board.hook';
import { selectBoundaryRect } from '../components/pixel-board-context/pixel-board.selector';
import { DragAndResizeData, PixelBoxNode } from '../pixel-board.types';

export interface UnknownDraggingData {
  data: DragAndResizeData | undefined;
  boundary: RectPosition;
  node?: PixelBoxNode;
  transformData: DraggableTransformData | null;
  selectedNodeIds: string[];
}

export interface ExpectedDraggingData {
  data: DragAndResizeData;
  boundary: RectPosition;
  node?: PixelBoxNode;
  transformData: DraggableTransformData;
  selectedNodeIds: string[];
}

export function isDraggingNode(params: UnknownDraggingData): params is ExpectedDraggingData {
  const { data, boundary, node, transformData, selectedNodeIds } = params;
  return (
    !!transformData &&
    !!data &&
    !!selectedNodeIds.length &&
    !!boundary &&
    (!node || selectedNodeIds.includes(node?.id))
  );
}

export function calculateTransformedStyle(
  params: Omit<UnknownDraggingData, 'data'>
): TransformData {
  const data = params.transformData?.active.data.current as DragAndResizeData | undefined;
  const paramsWithData = {
    ...params,
    data,
  };

  if (!isDraggingNode(paramsWithData)) {
    const { boundary, node } = paramsWithData;
    return node ?? boundary;
  }
  const { data: nonNullData, boundary, node, transformData } = paramsWithData;

  if (nonNullData.action === 'resize') {
    const transform = calculateScaleTransform(
      node ?? boundary,
      boundary,
      transformData.delta,
      nonNullData.position
    );
    return {
      translate: transform?.translate,
      ...applyTransform(pick(node ?? boundary, RECT_POSITION_KEYS), {
        scale: transform?.scale,
      }),
    };
  }
  if (nonNullData.action === 'drag') {
    return {
      ...(node ?? boundary),
      translate: transformData?.delta,
    };
  }
  // TODO: for multiple resizing (affect to container)
  return node ?? boundary;
}

export type TransformData = RectPosition & { translate?: Coordinate };

export function useDraggableTransformData(node?: PixelBoxNode, ignore?: boolean): TransformData {
  const transformData = useDragTransformData(!!ignore);
  const { selectedNodeIds, boundary } = usePixelBoardSelector((state) => {
    const selectedNodeIds = state.selectedNodeIds;
    const boundary = selectBoundaryRect(state);
    return {
      selectedNodeIds,
      boundary,
    };
  }, shallow);

  return useMemo((): TransformData => {
    const defaultData = {
      top: node?.top ?? 0,
      left: node?.left ?? 0,
      width: node?.width ?? 0,
      height: node?.height ?? 0,
    };
    if (!selectedNodeIds.length || ignore) {
      return defaultData;
    }
    if (!boundary) {
      return defaultData;
    }
    return calculateTransformedStyle({
      node,
      transformData,
      selectedNodeIds,
      boundary,
    });
  }, [boundary, ignore, node, selectedNodeIds, transformData]);
}
