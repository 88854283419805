import { UserRole } from '~services/v1/invitation/invitation.service';

export enum SCOPES {
  canInviteUser,
  canViewCompany,
  canEditCompany,
  canDeleteCompany,
  canEditUserInCompany,
  canDeleteUserInCompany,
  canViewProject,
  canCreateProject,
  canEditProject,
  canDeleteProject,
}

const READ_SCOPES = [SCOPES.canViewCompany, SCOPES.canViewProject];
const WRITE_SCOPES = [...READ_SCOPES];
const ADMIN_SCOPES = [
  ...READ_SCOPES,
  SCOPES.canInviteUser,
  SCOPES.canEditCompany,
  SCOPES.canCreateProject,
  SCOPES.canEditProject,
  SCOPES.canDeleteProject,
  SCOPES.canEditUserInCompany,
  SCOPES.canDeleteUserInCompany,
];
const OWNER_SCOPES = [...ADMIN_SCOPES, SCOPES.canDeleteCompany];

export const PERMISSIONS: Record<UserRole, SCOPES[]> = {
  [UserRole.WRITE]: READ_SCOPES,
  [UserRole.READ]: WRITE_SCOPES,
  [UserRole.MANAGER]: ADMIN_SCOPES,
  [UserRole.ADMIN]: ADMIN_SCOPES,
  [UserRole.ADMINISTRATOR]: ADMIN_SCOPES,
  [UserRole.OWNER]: OWNER_SCOPES,
};
