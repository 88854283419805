import { createQueryHook } from '~utils/react-query';

import { getListChannel, getUserNameTelegram } from './channel.service';

export const useGetListChannelQuery = createQueryHook(['channel', 'getMany'], getListChannel);

export const useTelegramChatList = createQueryHook(
  ['telegram-chat-list', 'getMany'],
  getUserNameTelegram
);
