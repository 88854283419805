import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';

import { DFTimePicker, timeValueFromDate } from '~ui/df-picker/components/df-time-picker';

import { DfCalendar } from '../../../df-calendar';

export interface DFDateTimeRangePickerProps {
  showTimeSelect?: boolean;
  timeInterval?: number;
  value?: [Date, Date];
  onChange?(value: [Date, Date]): unknown;
}

export function DfDatetimeRangePicker({
  showTimeSelect,
  // timeInterval,
  value,
  onChange,
}: DFDateTimeRangePickerProps) {
  const { t } = useTranslation();

  return (
    <Stack gap={1}>
      <DfCalendar
        range
        selectedDateRange={value}
        onChangeSelectedDateRange={onChange}
        sx={{ width: '100%', height: '100%' }}
      />
      {showTimeSelect && (
        <>
          <Stack direction="row" alignItems="center">
            <Typography>{t('COMMON.FROM')}</Typography>
            <Box flexGrow={1} maxWidth={200} ml="auto">
              <DFTimePicker
                value={value && timeValueFromDate(value[0])}
                disabled={!value}
                onChange={(time) => {
                  if (!value) {
                    return;
                  }
                  const [from, to] = value;
                  from.setHours(time.hour);
                  from.setMinutes(time.minute);
                  onChange && onChange([from, to]);
                }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography>{t('COMMON.TO')}</Typography>
            <Box flexGrow={1} maxWidth={200} ml="auto">
              <DFTimePicker
                value={value && timeValueFromDate(value[1])}
                disabled={!value}
                onChange={(time) => {
                  if (!value) {
                    return;
                  }
                  const [from, to] = value;
                  to.setHours(time.hour);
                  to.setMinutes(time.minute);
                  onChange && onChange([from, to]);
                }}
              />
            </Box>
          </Stack>
        </>
      )}
    </Stack>
  );
}
