import { keyBy, max, min, sum } from 'lodash-es';

import {
  GridLayoutValue,
  GridRow,
  NodeData,
  PixelBoxNode,
  PixelLayoutValue,
  PixelNode,
} from '~components/element-board';
import { EntityUuid } from '~services/v1/types';
import { timeId } from '~utils/chore';

import { ElementEntity } from '../element';

interface TmpRow {
  minTop: number;
  maxTop: number;
  elements: {
    data: NodeData;
    width: number;
    left: number;
  }[];
}

function isElementInRow(el: PixelBoxNode, row: TmpRow) {
  // All elements are sorted by top position so we just care about top, no need bottom
  return el.top < row.maxTop;
}

export function calculateGridLayoutFromPixel(pixelLayout: PixelLayoutValue): GridLayoutValue {
  // Line shouldn't exist in grid mode
  return {
    rows: pixelLayout
      .filter((el): el is PixelBoxNode => el.type !== 'line')
      .sort((el1, el2) => el1.top - el2.top)
      .reduce<TmpRow[]>((rows, node) => {
        const lastRow = rows.at(-1);
        if (!lastRow || !isElementInRow(node, lastRow)) {
          return [
            ...rows,
            {
              minTop: node.top,
              maxTop: node.top + node.height,
              elements: [
                {
                  data: node.data,
                  width: node.width,
                  left: node.left,
                },
              ],
            },
          ];
        }
        lastRow.elements.push({
          data: node.data,
          width: node.width,
          left: node.left,
        });
        lastRow.minTop = min([lastRow.minTop, node.top]) ?? 0;
        lastRow.maxTop = max([lastRow.maxTop, node.top + node.height]) ?? 0;
        return rows;
      }, [])
      .map((tmpRow) => ({
        ...tmpRow,
        elements: tmpRow.elements.sort((el1, el2) => el1.left - el2.left),
      }))
      .map((tmpTow, rowIndex): GridRow => {
        const sumWidth = sum(tmpTow.elements.map((el) => el.width));
        return {
          id: timeId(rowIndex),
          height: tmpTow.maxTop - tmpTow.minTop,
          items: tmpTow.elements.map((el, colIndex) => ({
            id: timeId(`${rowIndex}.${colIndex}`),
            data: el.data,
            width: (el.width / sumWidth) * 100,
          })),
        };
      }),
  };
}

export function calculatePixelLayoutFromGrid(
  gridLayout: GridLayoutValue,
  pageWidth: number,
  spacing: number
): PixelLayoutValue {
  let top = 0;
  return gridLayout.rows
    .map(({ height, items }, rowIndex) => {
      let left = 0;
      const widthWithoutSpaces = pageWidth - (items.length - 1) * spacing;
      const els = items.map(({ data, width }, colIndex): PixelNode => {
        const pixelWidth = widthWithoutSpaces * (width / 100);
        const el: PixelNode = {
          id: timeId(`${rowIndex}.${colIndex}`),
          data,
          type: 'box',
          top,
          left,
          width: pixelWidth,
          height,
        };
        left += pixelWidth + spacing;
        return el;
      });
      top += height + spacing;
      return els;
    })
    .flat();
}

export const getAllElementIdsFromGridLayout = (value: GridLayoutValue): EntityUuid[] =>
  value.rows.map((row) => row.items.map((item) => item.data.elementId)).flat();

export const getNodeIdsFromGridLayout = (
  value: GridLayoutValue,
  elements: ElementEntity[] = [],
  elementFilter?: (el: ElementEntity) => boolean
): string[] => {
  const nodes = value.rows.map((row) => row.items).flat();
  const elementMap = keyBy(elements, 'element_id');
  return nodes
    .filter((node) => {
      const element = elementMap[node.data.elementId];
      return !elementFilter || (element && elementFilter(element));
    })
    .map((node) => node.id);
};

export const getAllElementIdsFromPixelLayout = (value: PixelLayoutValue): EntityUuid[] =>
  value.map((item) => item.data.elementId);

export const getNodeIdsFromPixelLayout = (
  value: PixelLayoutValue,
  elements: ElementEntity[],
  elementFilter?: (el: ElementEntity) => boolean
): string[] => {
  const elementMap = keyBy(elements, 'element_id');
  return value
    .filter((node) => {
      const element = elementMap[node.data.elementId];
      return !elementFilter || (element && elementFilter(element));
    })
    .map((node) => node.id);
};
