import { stringify } from 'qs';

import { coreFetcher } from '../../client';

import { AnySearchObject } from './search.entity';

const namespace = '/search';

export interface SearchAllOptions {
  query: string;
  limit?: number;
}

export const searchAllRequest = async ({
  signal,
  ...options
}: SearchAllOptions & { signal?: AbortSignal }) => {
  const res = await coreFetcher.get<AnySearchObject[]>(
    `${namespace}/all${stringify(options, { addQueryPrefix: true })}`,
    (options) => ({
      ...options,
      signal,
    })
  );
  return res.data;
};
