import { keys } from 'lodash-es';
import { stringify } from 'qs';

import { coreFetcher } from '../client';
import { FetchError } from '../fetcher-factory';

import { Entity, EntityId } from './types';

export type FilterValue = string | string[] | number | number[];

export type RequestFilters<T> = Partial<Record<keyof T, FilterValue>>;

export type CollectionResponse<T> = T[];

export type Response<T> = T;

export type GetManyRequest<T extends Entity> = (
  filters?: RequestFilters<T>,
  forEdit?: boolean
) => Promise<T[]>;

export type GetOneRequest<T extends Entity> = (id: EntityId) => Promise<T>;

export type InsertOneRequest<T extends Entity> = (entity: Partial<T>) => Promise<T>;

export type InsertManyRequest<T extends Entity> = (entities: Partial<T>[]) => Promise<T[]>;

export type UpdateOneRequest<T extends Entity> = (id: EntityId, entity: Partial<T>) => Promise<T>;

export type UpdateManyRequest<T extends Entity> = (entity: Partial<T>[]) => Promise<T[]>;

export type DeleteOneRequest = (id: EntityId) => Promise<boolean>;

export type DeleteManyRequest = (ids: EntityId[]) => Promise<boolean>;

export type RestoreOneRequest<T extends Entity> = (id: EntityId) => Promise<T>;

export type RestoreManyRequest<T extends Entity> = (ids: EntityId[]) => Promise<T[]>;

export type ResponseMiddleWare<T> = (data: T) => T | Promise<T>;

export function applyMiddleware<T>(data: T, middlewares: ResponseMiddleWare<T>[]): Promise<T> {
  return middlewares.reduce<Promise<T>>(
    (dataPromise, middleware) => dataPromise.then((data) => Promise.resolve(middleware(data))),
    Promise.resolve(data)
  );
}

export function getManyRequestFactory<T extends Entity>(
  namespace: string,
  middlewares: ResponseMiddleWare<CollectionResponse<T>>[] = []
): GetManyRequest<T> {
  return async (filters = {} as RequestFilters<T>, forEdit = false) => {
    const urlParams = keys(filters).map((key) => {
      const value = filters[key as keyof typeof filters];
      if (Array.isArray(value)) {
        return `filter[${encodeURI(String(key))}]=${encodeURI(value.join(','))}`;
      }
      return `filter[${encodeURI(String(key))}]=${encodeURI(String(value))}`;
    });
    if (forEdit) {
      urlParams.push('for_edit=1');
    }
    const response = await coreFetcher.get<CollectionResponse<T>>(
      `${namespace}?${urlParams.join('&')}`
    );
    return applyMiddleware(response.data || [], middlewares);
  };
}

export function getOneRequestFactory<T extends Entity>(
  namespace: string,
  middlewares: ResponseMiddleWare<T>[] = []
): GetOneRequest<T> {
  return async (id: EntityId) => {
    const response = await coreFetcher.get<Response<T>>(`${namespace}/${id}`);
    return applyMiddleware(response.data, middlewares);
  };
}

export function insertOneRequestFactory<T extends Entity>(
  namespace: string,
  middlewares: ResponseMiddleWare<T>[] = []
): InsertOneRequest<T> {
  return async (entity) => {
    const response = await coreFetcher.post<Response<T>>(`${namespace}`, entity);
    return applyMiddleware(response.data, middlewares);
  };
}

export function updateOneRequestFactory<T extends Entity>(
  namespace: string,
  middlewares: ResponseMiddleWare<T>[] = []
): UpdateOneRequest<T> {
  return async (id, entity) => {
    const response = await coreFetcher.patch<Response<T>>(`${namespace}/${id}`, entity);
    return applyMiddleware(response.data, middlewares);
  };
}

export function updateManyRequestFactory<T extends Entity>(
  namespace: string,
  middlewares: ResponseMiddleWare<T[]>[] = []
): UpdateManyRequest<T> {
  return async (entities) => {
    const response = await coreFetcher.patch<Response<T[]>>(`${namespace}`, {
      entities,
    });
    return applyMiddleware(response.data, middlewares);
  };
}

export function deleteOneRequestFactory(namespace: string): DeleteOneRequest {
  return async (id) => {
    const response = await coreFetcher.delete<boolean>(`${namespace}/${id}`);
    return response.data;
  };
}

export function deleteManyRequestFactory(namespace: string): DeleteManyRequest {
  return async (ids) => {
    const response = await coreFetcher.delete<boolean>(
      `${namespace}${stringify({ ids }, { addQueryPrefix: true })}`
    );
    return response.data;
  };
}

export function restoreOneRequestFactory<T extends Entity>(
  namespace: string,
  middlewares: ResponseMiddleWare<T>[] = []
): RestoreOneRequest<T> {
  return async (id: EntityId) => {
    const response = await coreFetcher.post<Response<T>>(`${namespace}/${id}/restore`, null);
    return applyMiddleware(response.data, middlewares);
  };
}

export function restoreManyRequestFactory<T extends Entity>(
  namespace: string,
  middlewares: ResponseMiddleWare<T[]>[] = []
): RestoreManyRequest<T> {
  return async (ids: EntityId[]) => {
    const response = await coreFetcher.post<T[]>(`${namespace}/restore`, {
      ids,
    });
    return applyMiddleware(response.data, middlewares);
  };
}

interface RequestsFactoryOptions<T, GOT = T, GMT = T, IOT = T, UOT = T, UMT = T, ROT = T, RMT = T> {
  middlewares?: {
    getOneRequest?: ResponseMiddleWare<GOT>[];
    getManyRequest?: ResponseMiddleWare<Array<GMT>>[];
    insertOneRequest?: ResponseMiddleWare<IOT>[];
    updateOneRequest?: ResponseMiddleWare<UOT>[];
    updateManyRequest?: ResponseMiddleWare<Array<UMT>>[];
    restoreOneRequest?: ResponseMiddleWare<ROT>[];
    restoreManyRequest?: ResponseMiddleWare<Array<RMT>>[];
  };
}

export function requestsFactory<
  T extends Entity,
  GOT extends Entity = T,
  GMT extends Entity = T,
  IOT extends Entity = T,
  UOT extends Entity = T,
  UMT extends Entity = T,
  ROT extends Entity = T,
  RMT extends Entity = T,
>(namespace: string, options?: RequestsFactoryOptions<T, GOT, GMT, IOT, UOT, UMT, ROT, RMT>) {
  return {
    getOneRequest: getOneRequestFactory<GOT>(namespace, options?.middlewares?.getOneRequest),
    getManyRequest: getManyRequestFactory<GMT>(namespace, options?.middlewares?.getManyRequest),
    insertOneRequest: insertOneRequestFactory<IOT>(
      namespace,
      options?.middlewares?.insertOneRequest
    ),
    updateOneRequest: updateOneRequestFactory<UOT>(
      namespace,
      options?.middlewares?.updateOneRequest
    ),
    updateManyRequest: updateManyRequestFactory<UMT>(
      namespace,
      options?.middlewares?.updateManyRequest
    ),
    deleteOneRequest: deleteOneRequestFactory(namespace),
    deleteManyRequest: deleteManyRequestFactory(namespace),
    restoreOneRequest: restoreOneRequestFactory<ROT>(
      namespace,
      options?.middlewares?.restoreOneRequest
    ),
    restoreManyRequest: restoreManyRequestFactory<RMT>(
      namespace,
      options?.middlewares?.restoreManyRequest
    ),
  };
}

export function getResponseMessage(error: unknown) {
  return (error instanceof FetchError && (error?.response?.body as any)?.message) || '';
}
