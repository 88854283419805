import { QueryKey } from '@tanstack/react-query';

import { createQueryHook } from '~utils/react-query';
import { removeUndefined } from '~utils/record';

import {
  getDataSourceProgressRequest,
  getDataSourceRequest,
  GetDataSourceRequestOptions,
} from './data-source.service';

export const useDataSourceQuery = createQueryHook(['data-source'], getDataSourceRequest, {
  transformKey: (options?: Partial<GetDataSourceRequestOptions>): QueryKey => [
    'data-source',
    ...(options
      ? [
          removeUndefined({
            connectionStringId: options.connectionStringId,
            queryId: options.queryId,
          }),
          ...(options.bindParams ? [options.bindParams] : []),
        ]
      : []),
  ],
});

export const useDataSourceProgressQuery = createQueryHook(
  ['data-source-progress'],
  getDataSourceProgressRequest
);
