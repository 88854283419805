import { CheckIcon } from '@dataflake/icons';
import { ButtonBase as MUIButtonBase, styled } from '@mui/material';

import { ColorItemProps } from './color-item';

export const ButtonBase = styled(MUIButtonBase, {
  shouldForwardProp: (propName) =>
    propName !== 'color' &&
    propName !== 'size' &&
    propName !== 'checked' &&
    propName !== 'rounded' &&
    propName !== 'backgroundImage',
})<ColorItemProps & { backgroundImage?: string }>(
  ({ color, size = 21, rounded, theme }) => ({
    overflow: 'hidden',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundColor: color,
    width: size,
    height: size,
    display: 'flex',
    borderRadius: rounded ? size : theme.shape.borderRadiusSm,
    borderColor: theme.palette.divider,
    borderStyle: 'solid',
    borderWidth: 'thin',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundClip: 'padding-box',
  }),
  ({ backgroundImage }) =>
    backgroundImage && {
      '::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.3,
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: '50% 0',
        backgroundSize: 'cover',
      },
    }
);

export const Check = styled(CheckIcon)(({ theme }) => ({
  fontSize: 16,
  stroke: theme.palette.common.white,
  color: theme.palette.primary.main,
}));
