import { styled } from '@mui/material';

import { border } from '~utils/styled';

import { FullSizeBox } from '../full-size-box';

export const Wrapper = styled(FullSizeBox, {
  shouldForwardProp: (propName) => propName !== 'withBorder',
})<{ withBorder?: boolean }>(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${theme.spacing(2)}`,
  }),
  ({ withBorder, theme }) => withBorder && border(theme)
);
