import PQueue from 'p-queue';
import { stringify } from 'qs';

import { coreFetcher } from '../../client';
import { EntityUuid } from '../types';

import { DataSourceEntity, DataSourceProgressEntity } from './data-source.entity';

const namespace = '/data-source';

export interface GetDataSourceRequestOptions {
  queryId?: EntityUuid | null;
  connectionStringId?: EntityUuid;
  bindParams?: Record<string, string | undefined | null>;
  seal?: string | null;
  isDebug?: boolean;
  force?: boolean;
  noData?: boolean;
  progressId?: string;
}

export class AsyncQueryError extends Error {
  entity?: DataSourceEntity;
}

const queue = new PQueue({ concurrency: 3 });

export async function getDataSourceRequest(
  {
    queryId,
    connectionStringId,
    bindParams,
    seal,
    isDebug,
    force,
    noData,
    progressId,
  }: GetDataSourceRequestOptions,
  signal?: AbortSignal
): Promise<DataSourceEntity> {
  if (!queryId || !connectionStringId) {
    throw new Error('You should pick a query and connection string');
  }
  const queryStringData = {
    debug: isDebug,
    force,
    bind_params: bindParams,
    seal,
    noData,
    progressId,
  };
  const { data } = await queue.add(
    ({ signal }) =>
      coreFetcher.fetch<DataSourceEntity>(
        `${namespace}/${connectionStringId}/${queryId}${stringify(queryStringData, {
          addQueryPrefix: true,
        })}`,
        {
          signal,
        }
      ),
    {
      throwOnTimeout: true,
      signal,
    }
  );

  if (data.async && data.status === 'pending') {
    const error = new AsyncQueryError();
    error.entity = data;
    throw error;
  }
  return data;
}
export async function getDataSourceProgressRequest(
  {
    queryId,
    connectionStringId,
    bindParams,
    seal,
    noData,
    progressId,
  }: Omit<GetDataSourceRequestOptions, 'isDebug' | 'force'>,
  signal?: AbortSignal
): Promise<DataSourceProgressEntity> {
  if (!queryId || !connectionStringId) {
    throw new Error('You should pick a query and connection string');
  }
  const queryStringData = {
    bind_params: bindParams,
    seal,
    noData,
    progressId,
  };
  const { data } = await coreFetcher.fetch<DataSourceProgressEntity>(
    `${namespace}/${connectionStringId}/${queryId}/progress${stringify(queryStringData, {
      addQueryPrefix: true,
    })}`,
    {
      signal,
    }
  );
  return data;
}
