import React, { useCallback, useMemo, useState } from 'react';
import {
  Autocomplete,
  AutocompleteProps,
  outlinedInputClasses,
  TextField,
  TextFieldProps,
} from '@mui/material';

export interface AutocompleteTagsProps extends AutocompleteProps<string, true, false, true> {
  TextFieldProps?: TextFieldProps;
  defaultOptions?: string[];
  getSuggestion?: (keyword: string) => string[];
  placeholder?: string;
}

export const AutocompleteTags = React.forwardRef<
  unknown,
  Omit<AutocompleteTagsProps, 'options' | 'renderInput'>
>(function AutocompleteTags(
  { defaultOptions = [], getSuggestion = (keyword: string) => [keyword], TextFieldProps, ...props },
  ref
) {
  const [search, setSearch] = useState('');
  const options = useMemo(() => {
    const allowOptions = new Set(props.value || []);
    if (!search) {
      defaultOptions.forEach((option) => allowOptions.add(option));
    } else {
      getSuggestion(search).forEach((option) => allowOptions.add(option));
    }
    return Array.from(allowOptions);
  }, [defaultOptions, getSuggestion, props.value, search]);

  const filterOptions = useCallback(
    (options: string[]) => options.filter((option) => !(props.value || []).includes(option)),
    [props.value]
  );
  return (
    <Autocomplete
      {...props}
      ref={ref}
      multiple
      options={options}
      filterOptions={filterOptions}
      inputValue={search}
      onInputChange={(e, value) => setSearch(value)}
      ChipProps={{ size: 'small' }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...TextFieldProps}
          placeholder={props.placeholder}
          size="small"
          sx={{
            [`.${outlinedInputClasses.root}`]: {
              borderRadius: (theme) => `${theme.shape.borderRadiusSm}px`,
            },
          }}
        />
      )}
    />
  );
});
