import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme as MuiTheme,
} from '@mui/material';

// declare module '@mui/material/Link' {
//   interface LinkPropsVariantOverrides {}
//   interface LinkPropsColorOverrides {}
//   interface LinkClasses {}
// }

interface MuiLinkComponentType {
  styleOverrides?: ComponentsOverrides<MuiTheme>['MuiLink'];
  defaultProps?: ComponentsProps['MuiLink'];
  variants?: ComponentsVariants['MuiLink'];
}

export const MuiLinkComponent: MuiLinkComponentType = {
  variants: [],
  defaultProps: { underline: 'none' },
  styleOverrides: {
    root: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
};
