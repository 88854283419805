import { Stack, styled } from '@mui/material';

export const Wrapper = styled(Stack)(({ theme }) => ({
  height: '100%',
  overflow: 'auto',
  backgroundColor: theme.palette.common.white,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderBottomLeftRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  border: `thin solid ${theme.palette.customDesign.editor.border}`,
}));
