import { alertFetcher } from '~services/client';

import { DispatchEntity, DispatchParams, EndResult } from './dispatch.entity';

const namespace = 'dispatchers';

const getListDispatcher = async () => {
  const { data } = await alertFetcher.get<EndResult>(`config/${namespace}`);
  return data.dispatchers
    .map((dispatcher: DispatchEntity) => ({
      ...dispatcher,
      definition: dispatcher?.definition || [],
    }))
    .sort(
      (curr: DispatchEntity, acc: DispatchEntity) =>
        new Date(curr.added_at).getTime() - new Date(acc.added_at).getTime()
    );
};

const deleteDispatcher = async (dispatcherId: string) => {
  const { data } = await alertFetcher.delete<EndResult>(`config/${namespace}/${dispatcherId}`);
  return data;
};

const updateDispatcher = async (params: DispatchParams) => {
  const { suid, ...formData } = params;
  const { data } = await alertFetcher.put<EndResult>(`config/${namespace}/${suid}`, {
    dispatcher: formData,
  });
  return data;
};

const createDispatcher = async (params: DispatchParams) => {
  const { data } = await alertFetcher.post<EndResult>(`config/${namespace}`, {
    dispatcher: params,
  });
  return data;
};

export { createDispatcher, deleteDispatcher, getListDispatcher, updateDispatcher };
