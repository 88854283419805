import React, { useState } from 'react';

import { INPUT_SELECTOR } from '~utils/dom';

export interface UseKeyPressOptions {
  standalone?: boolean;
}

export function useKeyPress(key: KeyboardEvent['key'], { standalone }: UseKeyPressOptions = {}) {
  const [isMatched, setIsMatched] = useState(false);

  React.useEffect(() => {
    let isPressed: boolean = false;
    let isOtherPressed = new Set();
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.repeat) {
        return;
      }

      if (document.activeElement?.matches?.(INPUT_SELECTOR)) {
        setIsMatched(false);
        return;
      }

      if (e.key === key) {
        isPressed = true;
      } else {
        isOtherPressed.add(e.key);
      }
      setIsMatched(standalone ? isPressed && !isOtherPressed.size : isPressed);
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === key) {
        isPressed = false;
      } else {
        isOtherPressed.delete(e.key);
      }
      setIsMatched(standalone ? isPressed && !isOtherPressed.size : isPressed);
    };

    const resetOnFocus = () => {
      isPressed = false;
      isOtherPressed = new Set();
      setIsMatched(false);
    };

    window.addEventListener('focus', resetOnFocus);

    document.body.addEventListener('keydown', handleKeyDown);
    document.body.addEventListener('keyup', handleKeyUp);

    return () => {
      document.body.removeEventListener('keydown', handleKeyDown);
      document.body.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('focus', resetOnFocus);
    };
  }, [key, standalone]);

  return isMatched;
}
