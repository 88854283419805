import React from 'react';
import { Paper, Stack } from '@mui/material';
import { isSameDay as _isSameDay, isSameHour as _isSameHour } from 'date-fns';

import { DfCalendar } from '../../../df-calendar';
import { DFTimePicker, timeValueFromDate } from '../df-time-picker';

export interface DFDateTimePickerProps {
  value: Date | null;
  onChange: (date: Date) => void;
  showTimeSelect: boolean;
  timeInterval?: number;
  minDate?: Date;
}

export const DFDateTimePicker = React.forwardRef<HTMLDivElement, DFDateTimePickerProps>(
  function DFDateTimePicker(
    { value: _value, onChange, showTimeSelect, timeInterval, minDate },
    ref
  ) {
    const value = _value || minDate;
    const isSameDay = minDate && value ? _isSameDay(minDate, value) : false;
    const isSameHour = minDate && value ? _isSameHour(minDate, value) : false;
    const hour = value?.getHours() || 0;
    const minute = value?.getMinutes() || 0;

    const changeDate = (muiDate: Date | null) => {
      if (muiDate) {
        const date = new Date(muiDate);
        const isSameDay = minDate ? _isSameDay(minDate, muiDate) : false;

        if (isSameDay && minDate) {
          date.setHours(minDate.getHours());
          date.setMinutes(minDate.getMinutes());
        } else {
          date.setHours(hour);
          date.setMinutes(minute);
        }

        onChange(date);
      }
    };
    return (
      <Paper ref={ref}>
        <Stack alignItems="center" p={2}>
          <DfCalendar minDate={minDate} selectedDate={value} onChangeSelectedDate={changeDate} />
          {showTimeSelect && (
            <DFTimePicker
              min={{
                hour: isSameDay ? minDate?.getHours() : undefined,
                minute: isSameDay && isSameHour ? minDate?.getMinutes() : undefined,
              }}
              value={value ? timeValueFromDate(value) : undefined}
              onChange={({ hour, minute }) => {
                if (!value) {
                  return;
                }

                const date = new Date(value);
                date.setHours(hour);
                date.setMinutes(minute);
                onChange(date);
              }}
              timeInterval={timeInterval}
            />
          )}
        </Stack>
      </Paper>
    );
  }
);
