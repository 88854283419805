import { Box, styled } from '@mui/material';

import { Coordinate, isEdgeDirection, ResizeDirection } from '~utils/geometry';

export const StyledResizePoint = styled(Box)<{ coordinate?: Coordinate }>(
  ({ theme }) => ({
    pointerEvents: 'auto',
    position: 'absolute',
    width: theme.spacing(1),
    height: theme.spacing(1),
    zIndex: 1,
    background: theme.palette.primary.main,
  }),
  ({ coordinate, theme }) =>
    coordinate && {
      top: `calc(${coordinate.y}px - ${theme.spacing(1 / 2)})`,
      left: `calc(${coordinate.x}px - ${theme.spacing(1 / 2)})`,
    }
);

export const StyledResizeHandler = styled(StyledResizePoint)<{ direction: ResizeDirection }>(
  ({ direction, theme }) =>
    isEdgeDirection(direction) && {
      [direction]: theme.spacing(-1 / 2),
    },
  ({ direction, theme }) =>
    (direction === 'top' || direction === 'bottom') && {
      left: `calc(50% - ${theme.spacing(1 / 2)})`,
      cursor: 'row-resize',
    },
  ({ direction, theme }) =>
    (direction === 'left' || direction === 'right') && {
      top: `calc(50% - ${theme.spacing(1 / 2)})`,
      cursor: 'col-resize',
    },
  ({ direction, theme }) =>
    !isEdgeDirection(direction) && {
      [direction.split('-')[0]]: theme.spacing(-1 / 2),
      [direction.split('-')[1]]: theme.spacing(-1 / 2),
    },
  ({ direction }) =>
    (direction === 'bottom-left' || direction === 'top-right') && {
      cursor: 'nesw-resize',
    },
  ({ direction }) =>
    (direction === 'bottom-right' || direction === 'top-left') && {
      cursor: 'nwse-resize',
    }
);
