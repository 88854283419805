import { alpha, Box, Stack, styled, Tab as MUITab } from '@mui/material';

export const Layout = styled(Stack)(({ theme }) => ({
  borderRadius: `${theme.shape.borderRadius}px`,
  height: '100%',
  overflow: 'hidden',
  [`.MuiTabs-root`]: {
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    zIndex: 3,
  },
  [`.MuiTabs-flexContainer`]: {
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    borderBottomWidth: 10,
  },
}));

export const Wrapper = styled(Box, {
  shouldForwardProp: (propName) => propName != 'selected',
})<{ selected?: boolean }>(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: `${theme.shape.borderRadius}px`,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  position: 'relative',
  backgroundColor: alpha(
    theme.palette.customDesign.button.darkPastelBlue,
    theme.palette.action.selectedOpacity
  ),
}));

export const ContentWrapper = styled('div')({
  overflowY: 'hidden',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
});

export const Tab = styled(MUITab)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
}));
