import { CSSProperties } from 'react';

import { UnitConfig } from '~utils/unit';

import { ChartConfig, ElementEntity, GenericElementEntity } from '../../element.entity';
import { HorizontalAlign, Position, Shape, VerticalAlign } from '../types';

/**
 * Chart element
 * ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 */

export type LegendSortBy = 'name' | 'value';
export type LegendSortOrder = 'asc' | 'desc';

export type TooltipLegendSort = false | '' | `${'value' | 'label'},${'asc' | 'desc'}`;
export type TooltipType = 'group' | 'single';
export type TooltipPutCurrentLegendOnTop = 'auto' | 'never' | 'always';

export type ToolbarPositionType = 'top' | 'right';

/**
 * Please check when change this interface:
 * app/src/services/v1/element/entities/chart-element/utils.ts
 */
export interface ChartTooltipConfig {
  tooltipEnable?: boolean;
  tooltipLegendSort?: TooltipLegendSort;
  tooltipType?: TooltipType;
  tooltipPutCurrentLegendOnTop?: TooltipPutCurrentLegendOnTop;
}

export const defaultChartTooltipConfig: ChartTooltipConfig = {
  tooltipEnable: true,
  tooltipLegendSort: false,
  tooltipType: 'group',
  tooltipPutCurrentLegendOnTop: 'auto',
};

export const chartTooltipConfigKeys = Object.keys(
  defaultChartTooltipConfig
) as (keyof ChartTooltipConfig)[];

/**
 * Please check when change this interface:
 * app/src/services/v1/element/entities/chart-element/utils.ts
 */
export interface ChartLabelConfig {
  label: string;
  /**
   * @deprecated use theme instead
   */
  labelFontSize?: number;
  /**
   * @deprecated use theme instead
   */
  labelFontFamily?: CSSProperties['fontFamily'];
  /**
   * @deprecated use theme instead
   */
  labelFontStyle?: CSSProperties['fontStyle'];
  /**
   * @deprecated use theme instead
   */
  labelColor?: CSSProperties['color'];
  /**
   * @deprecated use theme instead
   */
  labelFontWeight?: CSSProperties['fontWeight'];
  labelEnable?: boolean;
  labelPosition?: HorizontalAlign;
}

export const defaultChartLabelConfig: ChartLabelConfig = {
  label: '',
  labelEnable: true,
  labelPosition: 'center',
};

export const chartLabelConfigKeys = Object.keys(
  defaultChartLabelConfig
) as (keyof ChartLabelConfig)[];

/**
 * Please check when change this interface:
 * app/src/services/v1/element/entities/chart-element/utils.ts
 */
export interface ChartUnitConfig {
  unit?: UnitConfig;
}

export const defaultChartUnitConfig: ChartUnitConfig = {
  unit: undefined,
};

export const chartUnitConfigKeys = Object.keys(defaultChartUnitConfig) as (keyof ChartUnitConfig)[];

/**
 * Please check when change this interface:
 * app/src/services/v1/element/entities/chart-element/utils.ts
 */
export interface ChartLegendConfig {
  legendPosition?: Position;
  hideLegend?: boolean;
  legendAlignment?: HorizontalAlign;
  legendVerticalAlign?: VerticalAlign;
  legendShape?: Shape;
  legendOffsetX?: number;
  legendOffsetY?: number;
  /**
   * @deprecated use theme instead
   */
  legendFontSize?: number;
  /**
   * @deprecated use theme instead
   */
  legendFontFamily?: CSSProperties['fontFamily'];
  /**
   * @deprecated use theme instead
   */
  legendFontStyle?: CSSProperties['fontStyle'];
  /**
   * @deprecated use theme instead
   */
  legendColor?: CSSProperties['color'];
  /**
   * @deprecated use theme instead
   */
  legendFontWeight?: CSSProperties['fontWeight'];
  legendMaxLine?: number;
  legendEnableSearch?: boolean;
  legendSortBy?: LegendSortBy;
  legendSortOrder?: LegendSortOrder;
}

export const LEGEND_MAX_LINE_DEFAULT = 2;

export const defaultChartLegendConfig: ChartLegendConfig = {
  legendPosition: undefined,
  hideLegend: undefined,
  legendAlignment: undefined,
  legendVerticalAlign: undefined,
  legendShape: undefined,
  legendOffsetX: undefined,
  legendOffsetY: undefined,
  legendMaxLine: LEGEND_MAX_LINE_DEFAULT,
  legendEnableSearch: undefined,
  legendSortBy: 'value',
  legendSortOrder: 'desc',
};

export const chartLegendConfigKeys = Object.keys(
  defaultChartLegendConfig
) as (keyof ChartLegendConfig)[];

/**
 * Please check when change this interface:
 * app/src/services/v1/element/entities/chart-element/utils.ts
 */
export interface ChartDataConfig {
  dataEnable?: boolean;
  /**
   * @deprecated use theme instead
   */
  dataFontSize?: number;
  /**
   * @deprecated use theme instead
   */
  dataFontFamily?: CSSProperties['fontFamily'];
  /**
   * @deprecated use theme instead
   */
  dataFontStyle?: CSSProperties['fontStyle'];
  /**
   * @deprecated use theme instead
   */
  dataColor?: CSSProperties['color'];
  /**
   * @deprecated use theme instead
   */
  dataFontWeight?: CSSProperties['fontWeight'];
}

export const defaultChartDataConfig: ChartDataConfig = {
  dataEnable: undefined,
};

export const chartDataConfigKeys = Object.keys(defaultChartDataConfig) as (keyof ChartDataConfig)[];

/**
 * Please check when change this interface:
 * app/src/services/v1/element/entities/chart-element/utils.ts
 */
export interface ChartToolbarConfig {
  showToolBar?: boolean;
  toolbarPosition?: ToolbarPositionType;
}

export const defaultChartToolbarConfig: ChartToolbarConfig = {
  showToolBar: undefined,
  toolbarPosition: 'right',
};

export const chartToolbarConfigKeys = Object.keys(
  defaultChartToolbarConfig
) as (keyof ChartToolbarConfig)[];

/**
 * Please check when change this interface:
 * app/src/services/v1/element/entities/chart-element/utils.ts
 */
export interface ChartDataLabelConfig {
  dataLabelsEnable?: boolean;

  /**
   * @deprecated use theme instead
   */
  dataLabelsFontSize?: number;
  /**
   * @deprecated use theme instead
   */
  dataLabelsFontFamily?: CSSProperties['fontFamily'];
  /**
   * @deprecated use theme instead
   */
  dataLabelsFontStyle?: CSSProperties['fontStyle'];
  /**
   * @deprecated use theme instead
   */
  dataLabelsColor?: CSSProperties['color'];
  /**
   * @deprecated use theme instead
   */
  dataLabelsFontWeight?: CSSProperties['fontWeight'];
}

export const defaultChartDataLabelConfig: ChartDataLabelConfig = {
  dataLabelsEnable: undefined,
};

export const chartDataLabelConfigKeys = Object.keys(
  defaultChartDataLabelConfig
) as (keyof ChartDataLabelConfig)[];

export interface OtherGeneralChartConfig {
  showAnimations?: boolean;
  labelStyle?: 'label' | 'percentage';
  showMonochrome?: boolean;
}
export const defaultOtherGeneralChartConfig: OtherGeneralChartConfig = {
  showAnimations: undefined,
  labelStyle: undefined,
  showMonochrome: undefined,
};

export const otherGeneralChartConfigKeys = Object.keys(
  defaultOtherGeneralChartConfig
) as (keyof ChartDataLabelConfig)[];

/**
 * Please check when change this interface:
 * app/src/services/v1/element/entities/chart-element/utils.ts
 */
export interface GeneralChartConfig
  extends ChartLegendConfig,
    ChartLabelConfig,
    ChartUnitConfig,
    ChartTooltipConfig,
    ChartDataConfig,
    ChartToolbarConfig,
    ChartDataLabelConfig,
    OtherGeneralChartConfig {}

export interface GenericChartConfig<T extends string> extends GeneralChartConfig {
  chartType: T;
}

export interface GenericChartElementEntity<C extends GenericChartConfig<string>>
  extends GenericElementEntity<'chart', C> {}

export const defaultGeneralConfig = (): GeneralChartConfig => ({
  label: '',
  labelPosition: 'center',
  labelEnable: true,
  labelStyle: 'label',
  hideLegend: false,
  legendPosition: 'bottom',
  legendAlignment: 'center',
  legendVerticalAlign: 'top',
  showToolBar: true,
  legendShape: 'square',
  legendEnableSearch: false,
  legendSortBy: 'value',
  legendSortOrder: 'desc',
  legendMaxLine: LEGEND_MAX_LINE_DEFAULT,
  unit: {
    type: 'other',
    originUnit: '',
  },
});

export const generalChartConfigKeys = [
  ...chartLegendConfigKeys,
  ...chartLabelConfigKeys,
  ...chartUnitConfigKeys,
  ...chartTooltipConfigKeys,
  ...chartDataConfigKeys,
  ...chartToolbarConfigKeys,
  ...chartDataLabelConfigKeys,
  ...otherGeneralChartConfigKeys,
];

export const getChartDimension = (el: ElementEntity) =>
  el.element_type === 'chart' && 'dimension' in el.element_config && el.element_config.dimension;

export function getChartDataColumns(chartConfig: ChartConfig) {
  switch (chartConfig.chartType) {
    case 'pie':
    case 'geomap':
      return [chartConfig.dimension, chartConfig.metric].filter(Boolean);
    case 'bar':
    case 'line':
    case 'horizontalBar':
    case 'scatter':
      return [
        chartConfig.dimension,
        ...(chartConfig.metric || []),
        chartConfig.breakdownDimension,
      ].filter(Boolean);

    case 'mixed':
      return [
        chartConfig.dimension,
        ...(chartConfig.yAxis?.map((item) => item.metrics.map((metric) => metric.field)).flat() ||
          []),
      ].filter(Boolean);
    case 'bubble':
      return [
        chartConfig.dimension,
        ...(chartConfig.metric || []),
        chartConfig.sizeMetric,
        chartConfig.breakdownDimension,
      ].filter(Boolean);
    default:
      return [];
  }
}

export function isChartDataConfigReady(chartConfig: ChartConfig) {
  switch (chartConfig.chartType) {
    case 'pie':
    case 'geomap':
      return !!chartConfig.dimension && !!chartConfig.metric;
    case 'bar':
    case 'line':
    case 'horizontalBar':
    case 'scatter':
      return !!chartConfig.dimension && !!chartConfig.metric?.length;
    case 'mixed':
      return !!chartConfig.dimension && !!chartConfig.yAxis?.length;
    case 'bubble':
      return !!chartConfig.dimension && !!chartConfig.metric && !!chartConfig.sizeMetric;
    default:
      return false;
  }
}
