import { PREDEFINED_DATE_RANGE } from '~services/v1/element/element.service';

export const periodDateRangeOptions = [
  ...PREDEFINED_DATE_RANGE,
  {
    value: 'ago',
    name: 'Time ago (Advance)',
  },
  {
    value: 'fixed',
    name: 'Fixed',
  },
];
