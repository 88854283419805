import { CaretDownIcon } from '@dataflake/icons';
import { styled } from '@mui/material';

export const DefaultSelectIcon = styled(CaretDownIcon, {
  shouldForwardProp: (name) => name !== 'up',
})<{ up?: boolean }>(
  () => ({
    transitionDuration: '300ms',
  }),
  ({ up }) =>
    up && {
      transform: 'rotate(-180deg)',
    }
);

export const ValueWrapper = styled('div')({
  cursor: 'pointer',
});
