import { useTranslation } from 'react-i18next';
import { MenuItem, TextFieldProps } from '@mui/material';
import { range } from 'd3-array';

import { TextFieldWithTooltip } from '~components/with-tooltip';

const fontSizes = range(18).map((value) => Math.floor(value * 1.1 ** value) + 8);

export type FontSizeSelectProps = TextFieldProps;

export function FontSizeSelect(props: FontSizeSelectProps) {
  const { t } = useTranslation();

  return (
    <TextFieldWithTooltip
      fullWidth
      select
      label={t('COMMON.FONT_SIZE')}
      tooltip={t('COMMON.FONT_SIZE')}
      {...props}
    >
      {fontSizes.map((size) => (
        <MenuItem key={size} value={size}>{`${size}px`}</MenuItem>
      ))}
    </TextFieldWithTooltip>
  );
}
