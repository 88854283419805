import { ComponentProps, forwardRef } from 'react';
import { Link } from 'react-router-dom';

import LogoSvg from '~assets/logo.svg';
import LogoDevSvg from '~assets/logo-development.svg';

type DataflakeLogoProps = ComponentProps<'img'>;

export const DataflakeLogo = forwardRef<HTMLImageElement, DataflakeLogoProps>(
  function DataflakeLogo(props, ref) {
    return (
      <Link to="/">
        <img
          alt="LogoSvg"
          src={import.meta.env.VITE_BUILD_ENV === 'development' ? LogoDevSvg : LogoSvg}
          ref={ref}
          {...props}
        />
      </Link>
    );
  }
);
