import { useSyncExternalStore } from 'react';
import { Location, matchPath } from 'react-router-dom';

import { history } from '~libs/router';

export const DASHBOARD_VIEW_ROUTES = [
  'login',
  'register',
  'datacenter-select',
  'onboarding',
  '/:documentId/view',
  '/:shareId/share',
  '/share/:shareId',
  '/s/:shareId',
  '/s/:shareId/customize',
  '/es/:shareId',
  '/element-share/:shareId',
  '/share/:shareId/authentication',
  '/s/:shareId/authentication',
  '/s/password/:token',
  '/explorer',
];

export function checkRouteHasMobileView(location: Location) {
  return DASHBOARD_VIEW_ROUTES.some((path) => matchPath(path, location.pathname));
}

export function getRouteHasMobileView() {
  return checkRouteHasMobileView(history.location);
}

export function useRouteHasMobileView() {
  return useSyncExternalStore(history.listen, getRouteHasMobileView, getRouteHasMobileView);
}
