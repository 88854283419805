import { useSyncExternalStore } from 'react';
import { matchPath } from 'react-router-dom';

import { history } from '~libs/router';

export const PUBLIC_ROUTES = [
  '/login',
  '/datacenter-select',
  '/autologin',
  '/register',
  '/:documentId/view',
  '/:shareId/share',
  '/share/:shareId',
  '/s/:shareId',
  '/es/:shareId',
  '/element-share/:shareId',
  '/share/:shareId/authentication',
  '/s/:shareId/authentication',
  '/s/password/:token',
  '/explorer',
  '/explorer/:userUuid',
  '/explorer/e/:userUuid',
  '/explorer/authentication/:userUuid',
];

export function getIsPublicPage() {
  return PUBLIC_ROUTES.some((path) => matchPath(path, history.location.pathname));
}
export function useIsPublicRoute() {
  return useSyncExternalStore(history.listen, getIsPublicPage, getIsPublicPage);
}
