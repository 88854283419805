import { useCallback, useMemo } from 'react';

import { useStorageStore } from '~providers/storage-provider/storage-store';
import { COMPANY_UUID_KEY, PROJECT_UUID_KEY } from '~services/client';

import { useCurrentCompany } from './use-current-company';
import { useSessionQuery } from './use-session-query';

export function useCurrentProjectUuid() {
  return useStorageStore((store) => store.values[PROJECT_UUID_KEY]);
}
export function useCurrentProject() {
  const currentCompany = useCurrentCompany();
  const currentProjectUuid = useCurrentProjectUuid();
  return useMemo(
    () => currentCompany && currentProjectUuid && currentCompany.projects?.[currentProjectUuid],
    [currentCompany, currentProjectUuid]
  );
}

export function useSetCurrentProjectUuid() {
  const { data } = useSessionQuery();
  const setStorageValues = useStorageStore((store) => store.setValues);
  return useCallback(
    (companyUuid: string, projectUuid?: string) => {
      if (!data) {
        return;
      }
      setStorageValues({
        [COMPANY_UUID_KEY]: companyUuid,
        [PROJECT_UUID_KEY]:
          projectUuid || Object.keys(data.companies[companyUuid]?.projects || {})[0],
      });
    },
    [data, setStorageValues]
  );
}
