import { useCallback, useState } from 'react';
import { Box, BoxProps, Skeleton, SkeletonProps } from '@mui/material';

export interface ImageProps extends BoxProps {
  src: string;
  placeholder?: string;
  alt?: string;
  lazyLoad?: boolean;
  className?: string;
  showSkeleton?: boolean;
  SkeletonProps?: SkeletonProps;
}

export function Image({
  src,
  alt = '',
  className,
  showSkeleton = false,
  SkeletonProps,
  ...rest
}: ImageProps) {
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  const handleOnLoad = useCallback(() => {
    setState((state) => ({ ...state, loaded: true }));
  }, []);

  const handleOnError = useCallback(() => {
    setState((state) => ({ ...state, error: true }));
  }, []);

  return (
    <>
      {showSkeleton && !state.loaded && <Skeleton {...SkeletonProps} />}
      <Box
        {...rest}
        component="img"
        className={className}
        src={src}
        alt={alt}
        onLoad={handleOnLoad}
        onError={handleOnError}
        hidden={!state.loaded}
      />
    </>
  );
}
