import { stringify } from 'qs';

import { coreFetcher } from '../../client';
import { DashboardEntity } from '../dashboard';
import { fillElementNullConfig } from '../element/element.service';
import {
  deleteOneRequestFactory,
  insertOneRequestFactory,
  Response,
  restoreOneRequestFactory,
  updateOneRequestFactory,
} from '../request';
import { EntityUuid } from '../types';

import { PageEntity, PageRelationships } from './page.entity';

const namespace = '/page';

const updateOneRequest = updateOneRequestFactory<PageEntity>(namespace);

export async function getOneRequest({ id, ...opts }: { id: string; withDashboard?: boolean }) {
  const response = await coreFetcher.get<
    Response<PageEntity & PageRelationships & { dashboard?: DashboardEntity }>
  >(`${namespace}/${id}${stringify(opts, { addQueryPrefix: true })}`);
  return {
    ...response.data,
    elements: response.data.elements.map((element) => fillElementNullConfig(element)),
  };
}

const insertOneRequest = insertOneRequestFactory<PageEntity>(namespace);
const deleteOneRequest = deleteOneRequestFactory(namespace);
const restoreOneRequest = restoreOneRequestFactory<PageEntity>(namespace);

const duplicatePage = async (pageId: EntityUuid) => {
  const { data } = await coreFetcher.post<PageEntity>(`${namespace}/${pageId}`, null);
  return data;
};

const updatePagesPosition = async (pagesOrder: EntityUuid[]) => {
  const { data } = await coreFetcher.patch<PageEntity[]>(`${namespace}/position`, {
    pagesOrder,
  });
  return data;
};

export default {
  insertOneRequest,
  getOneRequest,
  updateOneRequest,
  deleteOneRequest,
  restoreOneRequest,
  duplicatePage,
  updatePagesPosition,
};
