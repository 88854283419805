export function removeEmptyString<T extends Record<any, string>>(obj: T) {
  const newObj = { ...obj };

  for (const key in newObj) {
    if (obj[key] === '') {
      delete obj[key];
    }
  }

  return obj;
}

export function removeUndefined<T extends Record<any, any>>(obj: T) {
  const newObj = { ...obj };

  for (const key in newObj) {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  }

  return obj;
}

export function flattenObject(ob: Record<string, unknown>): Record<string, unknown> {
  const toReturn: Record<string, unknown> = {};

  for (const key in ob) {
    if (!ob.hasOwnProperty(key)) continue;

    if (typeof ob[key] == 'object' && ob[key] !== null) {
      const flatObject = flattenObject(ob[key] as Record<string, unknown>);
      for (const nestedKey in flatObject) {
        if (!flatObject.hasOwnProperty(nestedKey)) continue;

        toReturn[key + '.' + nestedKey] = flatObject[nestedKey];
      }
    } else {
      toReturn[key] = ob[key];
    }
  }
  return toReturn;
}
