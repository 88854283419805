import { Button, styled } from '@mui/material';

import { withProps } from '~utils/styled';

export const StyledButton = styled(
  withProps(Button, {
    size: 'small',
  })
)(({ theme }) => ({
  padding: 0,
  borderRadius: 0,
  width: 15,
  height: 15,
  minWidth: 0,
  color: 'inherit',
  ':hover': {
    color: theme.palette.primary.main,
  },
}));
