import { List, ListItem, ListItemButton, styled } from '@mui/material';

export const CompanyList = styled(List)(({ theme }) => ({
  padding: '0',
  backgroundColor: `${theme.palette.background.paper}`,
}));

export const ProjectItem = styled(ListItemButton, {
  shouldForwardProp(propName) {
    return propName !== 'active';
  },
})<{ active?: boolean }>(({ theme }) => ({
  paddingLeft: `${theme.spacing(5)}`,
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const ProjectList = styled(List)(({ theme }) => ({
  padding: '0',
  backgroundColor: `${theme.palette.background.default}`,
}));

export const CompanyItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 700,
  color: `${theme.palette.text.secondary}`,
}));

export const LoadingMask = styled('div')({
  position: 'absolute',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
