export interface Operators {
  number: '>' | '>=' | '<' | '<=' | '==' | '!=';
  date: Operators['number'];
  string:
    | '=='
    | '!='
    | 'empty'
    | '!empty'
    | 'includes'
    | '!includes'
    | 'start_with'
    | 'end_with'
    | 'regex';
}

export type Operator = {
  [T in keyof Operators]: `${T}:${Operators[T]}`;
}[keyof Operators];

export type OperatorArray = {
  [T in keyof Operators]: [T, Operators[T]];
}[keyof Operators];

export const OPERATORS: {
  readonly [K in keyof Operators]: readonly `${K}:${Operators[K]}`[];
} = {
  number: ['number:>', 'number:>=', 'number:<', 'number:<=', 'number:==', 'number:!='],
  date: ['date:>', 'date:>=', 'date:<', 'date:<=', 'date:==', 'date:!='],
  string: [
    'string:==',
    'string:!=',
    'string:!empty',
    'string:empty',
    'string:!includes',
    'string:includes',
    'string:end_with',
    'string:start_with',
    'string:regex',
  ],
};

interface CastTypeFunction {
  (value: any, type: 'date'): Date;
  (value: any, type: 'number'): number;
  (value: any, type: 'string'): string;
}

export const castType = ((value, type) => {
  switch (type) {
    case 'date':
      return new Date(value);
    case 'number':
      return Number(value);
    case 'string':
      return String(value);
  }
}) as CastTypeFunction;

export function compareValue(left: any, operator: Operator, right: any): boolean {
  const [type, op] = operator.split(':') as OperatorArray;
  if (
    ![
      '==',
      '!=',
      '>',
      '>=',
      '<',
      '<=',
      'empty',
      '!empty',
      'includes',
      '!includes',
      'start_with',
      'end_with',
      'regex',
    ].includes(op)
  ) {
    return false;
  }

  let leftValue: string | Date | number;
  let rightValue: string | Date | number;
  try {
    switch (type) {
      case 'number':
        leftValue = castType(left, 'number');
        rightValue = castType(right, 'number');
        // eslint-disable-next-line @typescript-eslint/no-implied-eval
        return Function(
          'left',
          'right',
          `return (left ${op} right)`
        )(leftValue, rightValue) as boolean;
      case 'date':
        leftValue = castType(left, 'date');
        rightValue = castType(right, 'date');
        // eslint-disable-next-line @typescript-eslint/no-implied-eval
        return Function(
          'left',
          'right',
          `return (left ${op} right)`
        )(leftValue, rightValue) as boolean;
      case 'string':
        leftValue = castType(left, 'string');
        rightValue = castType(right, 'string');
        switch (op) {
          case 'empty':
            return !leftValue;
          case '!empty':
            return !leftValue;
          case 'includes':
            return leftValue.includes(rightValue);
          case '!includes':
            return !leftValue.includes(rightValue);
          case 'start_with':
            return leftValue.startsWith(rightValue);
          case 'end_with':
            return !leftValue.endsWith(rightValue);
          case 'regex':
            return !!leftValue.match(new RegExp(rightValue, 'g'));
          default:
            // eslint-disable-next-line @typescript-eslint/no-implied-eval
            return Function(
              'left',
              'right',
              `return (left ${op} right)`
            )(leftValue, rightValue) as boolean;
        }
    }
  } catch (e) {
    console.error(e);
  }
  return false;
}

export const stepFloor = (num: number, step: number) => Math.floor(num / step) * step;
export const stepRound = (num: number, step: number) => Math.round(num / step) * step;
