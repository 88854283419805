import {
  Button,
  ButtonBase,
  Checkbox,
  Chip,
  IconButton,
  Radio,
  Switch,
  Tab,
  TextField,
  Typography,
} from '@mui/material';

import { withTooltip } from './with-tooltip';

export const TextFieldWithTooltip = withTooltip(TextField);

export const IconButtonWithTooltip = withTooltip(IconButton);

export const ButtonWithTooltip = withTooltip(Button);

export const ButtonBaseWithTooltip = withTooltip(ButtonBase);

export const CheckboxWithTooltip = withTooltip(Checkbox);

export const RadioWithTooltip = withTooltip(Radio);

export const SwitchWithTooltip = withTooltip(Switch);

export const TypographyWithTooltip = withTooltip(Typography);

export const TabWithTooltip = withTooltip(Tab);

export const ChipWithTooltip = withTooltip(Chip);
