import React, { useCallback, useMemo } from 'react';
import { useTheme } from '@mui/material';

import {
  ElementProvider,
  selectElementConfig,
  useElementContext,
} from '~providers/element-provider';
import { LineElementEntity } from '~services/v1/element';
// import {
//   useSelectElementAnchors,
//   useSelectElementPosition,
//   useSelectElementTheme,
// } from '~providers/dashboard-provider/hooks/use-select-element';
// import {
//   selectElementConfig,
//   selectElementId,
//   useElementContext,
// } from '~providers/element-provider';
// import { LineElementEntity } from '~services/v1/element';
import { ArrowStyle } from '~services/v1/element/entities/line-element';
import { calculateRadius, calculateRectPosition, rectToSVGViewBox } from '~utils/geometry';
import { getBorderStyle } from '~utils/shape';

import { PixelBoardNodeProps } from '../../../element-board';

// import { usePixelBoardDraggable } from '../../../element-board/components/pixel-board/pixel-board.hooks';
import { LineResizeHandler } from './components/line-resize-handler';
// import { calculateRadius } from '~utils/geometry';
// import { getBorderStyle } from '~utils/shape';
// import { FullSizeBox } from '../../../full-size-box';

export interface LineElementProps extends PixelBoardNodeProps {}

const ELEMENT_PADDING = 20;

function getArrowHead(type: ArrowStyle, props: React.SVGProps<any>) {
  switch (type) {
    case 'circle':
      return <circle {...props} cx={0} cy={0} r={5} />;
    case 'standard':
      return <polygon {...props} points="0,0 3,10 3,3 10,3" />;
    default:
      return null;
  }
}

function _LineElement({
  // editable,
  // boardSize,
  selected,
  node,
  // children,
  // onContextMenu,
  // onChange,
  // activeIds = [],
  // onChangeActiveIds,
  // onDeleteElement,
  // style,
  // onClick,
  setNodesRef,
}: LineElementProps) {
  const elementConfig = useElementContext(selectElementConfig<LineElementEntity>);
  // const elementAnchors = useSelectElementAnchors(elementId);
  // const elementPosition = useSelectElementPosition(elementId);

  const shapeStyle = useTheme().element?.shape;

  const drawing = useMemo(() => {
    if (node.type !== 'line') {
      return null;
    }
    const tailPosition = {
      x: node.x1,
      y: node.y1,
    };
    const headPosition = {
      x: node.x2,
      y: node.y2,
    };

    const strokeWidth = shapeStyle?.borderWidth || 1;
    const props = {
      ...node,
      strokeWidth,
      stroke: shapeStyle?.borderColor,
      fill: shapeStyle?.borderColor,
      strokeDasharray: getBorderStyle(shapeStyle?.borderStyle ?? 'dashed', Number(strokeWidth)),
    } as const;
    const radius = calculateRadius(
      {
        x: node.x1,
        y: node.y1,
      },
      {
        x: node.x2,
        y: node.y2,
      }
    );

    const head = getArrowHead(elementConfig.headStyle, {
      ...props,
      transform: `translate(${headPosition.x}, ${headPosition.y}), rotate(${radius + 135})`,
    });

    const tail = getArrowHead(elementConfig.tailStyle, {
      ...props,
      transform: `translate(${tailPosition.x}, ${tailPosition.y}), rotate(${radius - 45})`,
    });

    return (
      <>
        {head}
        <line {...props} {...props} />
        {tail}
      </>
    );
  }, [
    node,
    shapeStyle?.borderWidth,
    shapeStyle?.borderColor,
    shapeStyle?.borderStyle,
    elementConfig.headStyle,
    elementConfig.tailStyle,
  ]);

  const boxPosition = useMemo(
    () =>
      node.type === 'line' &&
      calculateRectPosition(
        { x: node.x1, y: node.y1 },
        { x: node.x2, y: node.y2 },
        ELEMENT_PADDING
      ),
    [node]
  );

  const setRef = useCallback(
    (el: SVGElement | null) =>
      setNodesRef({
        [node.id]: el,
      }),
    [node.id, setNodesRef]
  );

  if (node.type === 'line' && boxPosition) {
    return (
      <>
        <svg
          ref={setRef}
          // onClick={onClick}
          viewBox={rectToSVGViewBox(boxPosition)}
          style={{
            position: 'absolute',
            ...boxPosition,
          }}
        >
          {drawing}
        </svg>
        {selected && (
          <div>
            <LineResizeHandler
              type="start"
              node={node}
              // onClick={onClick}
            />
            <LineResizeHandler
              type="end"
              node={node}
              // onClick={onClick}
            />
          </div>
        )}
      </>
    );
  }
  return null;
}

_LineElement.displayName = '_LineElement';

export function LineElement(props: LineElementProps) {
  return (
    <ElementProvider elementId={props.node.data.elementId}>
      <_LineElement {...props} />
    </ElementProvider>
  );
}
