import { DateRangeValue } from '~services/v1/element';
import { getDateRangeFromName, getDateRangeFromTimeAgo } from '~utils/datetime';

export function getDateRange(value: DateRangeValue): [Date, Date] {
  switch (value.type) {
    case 'fixed':
      return [new Date(value.value[0]), new Date(value.value[1])];
    case 'ago':
      return getDateRangeFromTimeAgo(value.value);
    case 'predefined':
      return getDateRangeFromName(value.value);
    default:
      return [new Date(), new Date()];
  }
}
