import { styled } from '@mui/material';

import { FullSizeBox } from '../../../../../full-size-box';

export const StyledPixelBoardContainer = styled(FullSizeBox)({
  position: 'relative',
});

export const SelectionFrame = styled('div')(({ theme }) => ({
  position: 'absolute',
  border: `thin dotted ${theme.palette.primary.main}`,
  zIndex: 25,
}));
