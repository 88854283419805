import { AxisChartSpecialConfig, defaultAxisChartConfig } from './axis-chart-element';
import { GenericChartConfig, GenericChartElementEntity } from './chart-element';

export type BubbleChartSpecialConfig = AxisChartSpecialConfig & {
  sizeMetric?: string;
  size?: number;
};

export const defaultBubbleChartConfig = (): BubbleChartSpecialConfig => ({
  ...defaultAxisChartConfig(),
  size: 10,
  showYAxis: true,
  showXAxis: true,
});

export interface BubbleChartConfig extends GenericChartConfig<'bubble'>, BubbleChartSpecialConfig {}

export interface BubbleChartElementEntity extends GenericChartElementEntity<BubbleChartConfig> {}
