import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  TextField,
} from '@mui/material';

import { useForgetPasswordMutation } from '~services/v1/auth/auth.query';
import { AcceptButton } from '~ui/df-confirm-dialog';

import { Alert } from './forgot-password.styled';

export interface ForgotPasswordProps {}

export interface MessageState {
  type?: 'error' | 'success';
  text?: string;
}

export function ForgotPassword({}: ForgotPasswordProps) {
  const { t } = useTranslation();
  const [resetEmail, setResetEmail] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState<MessageState>({});

  const { mutate, isPending } = useForgetPasswordMutation({
    onSuccess: (result) => {
      setMessage({
        type: 'success',
        text: result.msg || t('FORGOT_PASSWORD.SUCCESS_TEXT'),
      });
    },
    onError: (e) => {
      setMessage({
        type: 'error',
        text: (e?.body as any)?.msg || t('ERROR.UNKNOWN'),
      });
    },
  });

  const handleSendMailRetrievePassword = useCallback(() => {
    setMessage({});
    mutate(resetEmail);
  }, [mutate, resetEmail]);

  return (
    <>
      <Link variant="body2" onClick={() => setOpenDialog(true)}>
        {t('FORGOT_PASSWORD.FORGOT_PASSWORD_BUTTON')}
      </Link>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="xs">
        <DialogTitle> {t('FORGOT_PASSWORD.FORGOT_PASSWORD_TITLE')}</DialogTitle>
        <DialogContent>
          {message.type === 'success' && (
            <Box mb={2}>
              <Alert severity="success">{message.text}</Alert>
            </Box>
          )}
          <Box pt={1}>
            <TextField
              label={t('COMMON.EMAIL_ADDRESS')}
              fullWidth
              error={message.type === 'error'}
              helperText={message.type === 'error' ? message.text : undefined}
              name="email"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ pt: 0 }}>
          <AcceptButton
            disabled={isPending}
            disableElevation
            onClick={handleSendMailRetrievePassword}
          >
            {t('COMMON.RESET_PASSWORD')}
          </AcceptButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
