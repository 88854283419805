import { DragMoveEvent } from '@dnd-kit/core';
import { createStore } from 'zustand';

export type DraggableTransformData = Pick<DragMoveEvent, 'delta' | 'active'>;

export interface DragDataState {
  transform: DraggableTransformData | null;
  initTransform: (active: DraggableTransformData) => void;
  updateTransform: (data: DraggableTransformData) => void;
  endTransform: () => void;
}

export const createDragDataStore = () =>
  createStore<DragDataState>((setState) => ({
    transform: null,
    initTransform: (data: DraggableTransformData) => setState({ transform: data }),
    updateTransform: (data) =>
      setState(({ transform }) => (transform ? { transform: data } : { transform: null })),
    endTransform: () => setState({ transform: null }),
  }));
