import { Sensor } from '@dnd-kit/core';

export interface DndKitEvent extends Event {
  dndKit: {
    capturedBy: Sensor<{}>;
  };
}

export function isDndKitEvent(e: Event | DndKitEvent): e is DndKitEvent {
  return 'dndKit' in e && 'capturedBy' in e.dndKit;
}
