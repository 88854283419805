import { CompactMode } from '~utils/unit';

import { GenericChartConfig, GenericChartElementEntity } from './chart-element';

export interface PieChartSpecialConfig {
  metric?: string;
  dimension?: string;
  isDonut?: boolean;
  compactNumber?: CompactMode;
  donutSize?: number;
}

export const DONUT_SIZE_DEFAULT = 0.5;

export const defaultPieChartConfig = (): PieChartSpecialConfig => ({
  compactNumber: 'simplify',
  isDonut: false,
  donutSize: DONUT_SIZE_DEFAULT,
});

export interface PieChartConfig extends GenericChartConfig<'pie'>, PieChartSpecialConfig {}
export interface PieChartElementEntity extends GenericChartElementEntity<PieChartConfig> {}
