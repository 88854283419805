import React from 'react';
import {
  Activators,
  defaultCoordinates,
  Sensor,
  SensorInstance,
  SensorOptions,
  SensorProps,
  Translate,
} from '@dnd-kit/core';

import { findNearestInteractiveParent } from '~utils/dom';
import { ArrowKeyCode, isArrowKeyCode } from '~utils/event';

export interface KeyboardSensorOptions extends SensorOptions {
  step?: number;
}

function updateTranslate(prevTranslate: Translate, keycode: ArrowKeyCode, step: number): Translate {
  switch (keycode) {
    case 'ArrowUp':
      return {
        ...prevTranslate,
        y: prevTranslate.y - step,
      };
    case 'ArrowDown':
      return {
        ...prevTranslate,
        y: prevTranslate.y + step,
      };
    case 'ArrowLeft':
      return {
        ...prevTranslate,
        x: prevTranslate.x - step,
      };
    case 'ArrowRight':
      return {
        ...prevTranslate,
        x: prevTranslate.x + step,
      };
  }
  return prevTranslate;
}

export const DEFAULT_STEP = 1;

// Must also implement Sensor<KeyboardSensorOptions> but we have problem with typescript
export const PixelBoardKeyboardSensor: Sensor<KeyboardSensorOptions> = class PixelBoardKeyboardSensor
  implements SensorInstance
{
  public autoScrollEnabled = false;

  constructor(private props: SensorProps<KeyboardSensorOptions>) {
    const { event, onStart, onMove, onEnd, onCancel, options } = props;
    if (!(event instanceof KeyboardEvent)) {
      onCancel();
      return;
    }

    // Sure this is ArrowKeyCodes because we check it in activators
    const keyCode = event.code as ArrowKeyCode;

    let step = options.step || DEFAULT_STEP;

    let translate = updateTranslate(defaultCoordinates, keyCode, step);

    // speed up after 500ms
    const timeout = window.setInterval(() => {
      step = step * 1.5;
    }, 300);

    function tick() {
      translate = updateTranslate(translate, keyCode, step);
      onMove(translate);
    }

    function keyDownHandler(e: KeyboardEvent) {
      e.preventDefault();
      tick();
    }

    function keyUpHandler() {
      window.removeEventListener('keydown', keyDownHandler);
      window.clearInterval(timeout);
      onEnd();
    }

    onStart(translate);

    window.addEventListener('keydown', keyDownHandler);
    window.addEventListener('keyup', keyUpHandler, {
      once: true,
    });
  }

  static activators: Activators<KeyboardSensorOptions> = [
    {
      eventName: 'onKeyDown' as const,
      handler: (event: React.KeyboardEvent, {}, {}) => {
        const { code, target } = event.nativeEvent;
        if (target instanceof HTMLElement && isArrowKeyCode(code)) {
          if (
            event.target !== event.currentTarget &&
            event.target instanceof Element &&
            findNearestInteractiveParent(event.target) !== event.currentTarget
          ) {
            return false;
          }
          event.preventDefault();
          return true;
        }
        return false;
      },
    },
  ];
};
