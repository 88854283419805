import { BaseBarDatum } from '@dchart/bar';
import { Serie } from '@dchart/line';
import { createStore } from 'zustand';

import { PieData } from '~components/dashboard-element/components/chart-element/components/pie-chart-element/pie-chart-element';
import { ElementEntity } from '~services/v1/element';

export type ChartSeries =
  | {
      type: 'line';
      series: {
        data: Serie[];
        index: string;
      };
    }
  | {
      type: 'bar';
      series: {
        data: BaseBarDatum[];
        index: string;
        keys: string[];
      };
    }
  | {
      type: 'pie';
      series: {
        data: PieData;
      };
    };

export function getLocalParams(element: ElementEntity | null) {
  return element
    ? Object.fromEntries(
        element.element_query_params
          ?.filter((item) => item.valueFrom === 'fixed' || item.valueFrom === 'element')
          ?.map((value) => [value.name, value.default || '']) || []
      )
    : {};
}

export interface ElementStore {
  dataRange: [unknown, unknown] | null;
  element: ElementEntity | null;

  localParams: Record<string, string | undefined | null>;
  enableFetchData: boolean;
  editable: boolean;
  selected: boolean;
  multipleSelected: boolean;
  reverseStack100: boolean;
  /**
   * Use for export feature, should discuss before use for other place
   */
  chartSeries: ChartSeries | null;
  updateElement: (properties: DeepPartial<ElementEntity>) => unknown | Promise<unknown>;
  setLocalParams: (localParams: Record<string, string | undefined | null>) => void;
  setChartSeries: (chartData: ChartSeries) => void;
  setEnableFetchData: (enableFetchData: boolean) => void;
  toggleReverseStack100: () => void;
}

export const createElementStore = ({
  dataRange = null,
  element,
  updateElement,
  editable,
  selected = false,
  multipleSelected = false,
  enableFetchData = false,
}: {
  element: ElementEntity | null;
  updateElement?: (properties: DeepPartial<ElementEntity>) => unknown | Promise<unknown>;
  dataRange?: [unknown, unknown] | null;
  editable?: boolean;
  enableFetchData?: boolean;
  selected?: boolean;
  multipleSelected?: boolean;
}) =>
  createStore<ElementStore>((set) => ({
    selected,
    multipleSelected,
    updateElement: updateElement ?? (() => {}),
    editable: Boolean(editable),
    element,
    dataRange,
    chartSeries: null,
    localParams: getLocalParams(element),
    enableFetchData,
    chartData: null,
    storeChartData: false,
    reverseStack100: false,
    setLocalParams: (localParams) => set({ localParams }),
    setChartSeries: (chartSeries) =>
      set({
        chartSeries,
      }),
    setEnableFetchData: (enableFetchData: boolean) => {
      set({
        enableFetchData,
      });
    },
    toggleReverseStack100: () =>
      set((state) => ({
        reverseStack100: !state.reverseStack100,
      })),
  }));
