import { forwardRef, ReactNode } from 'react';
import { Box, BoxProps, ClickAwayListener } from '@mui/material';

import { useInputPopper } from '~hooks/use-input-popper';

export interface DFPickerInputControlProps extends Omit<BoxProps, 'children'> {
  children: (props: ReturnType<typeof useInputPopper>) => ReactNode;
}

export const DFPickerInputControl = forwardRef(function DFPickerInputControl(
  { children, ...props }: DFPickerInputControlProps,
  ref
) {
  const popperProps = useInputPopper();
  return (
    <ClickAwayListener onClickAway={popperProps.forceClose}>
      <Box {...props} ref={ref}>
        {children(popperProps)}
      </Box>
    </ClickAwayListener>
  );
});
