import { upperFirst } from 'lodash-es';

interface TranslationConfig {
  [key: string]: string | TranslationConfig;
}

const defaultTranslation: TranslationConfig = {
  NETWORK_STATUS: {
    'NO-CONNECTION_MESSAGE':
      'No internet connection. Please check your network. You can download your data for backup.',
    BAD_MESSAGE:
      'Slow internet connection. Please check your network. You can download your data for backup',
    UPDATE_ERROR: 'Error when updating data. You can download your data for backup before reload',
  },
  UNIT_DISPLAY_NAME: {
    'digital-storage': 'Digital Storage ({{unit}})',
    'digital-storage-10-base': 'Digital Storage ({{unit}})',
    'metric-length': 'Length ({{unit}})',
    percentage: 'Percentage',
    time: 'Time ({{unit}})',
    other: '{{unit}}',
    none: 'No unit',
  },
  SHARE: {
    NO_SHARED_PAGE_DESCRIPTION: 'Hint: Ask the creator of this shared to select 1 or more page !',
  },
  ERROR_ELEMENT: {
    STATUS_401:
      'Authentication required. Please provide valid credentials to access this resource.',
    STATUS_403:
      ' Access is denied. You do not have the necessary permissions to access this resource. Please request to access.',
    STATUS_500: 'We have some problems when process data, please reload the page or try again.',
    STATUS_504: 'Server unable to receive timely response. Please try again later.',
    STATUS_OTHER: 'Looks like something went wrong. Please reload the page or try again.',
  },
  EDITOR_PIXEL_LAYOUT: {
    PASTE_ELEMENTS: 'Paste {{count}} element(s)',
    SELECTED_ELEMENTS: '{{count}} element(s) selected',
  },
  CREATE_NEW_QUERY: {
    EXPLORATION_MODE: "Exploration Mode, I'll set the name later",
  },
  AXIS_CHART_EDITOR: {
    BREAKDOWN_WARNING: 'Can’t combine breakdown with multiple metrics',
  },
  FILTER_EDITOR: {
    NUMBER_OF_QUERIES: '{{count}} queries',
    NUMBER_OF_QUERY: '{{count}} query',
  },
  QUERY: {
    CONFIRM_FILL_DATA: 'Auto-update your chart config with new query?',
  },
  QUERY_EDITOR: {
    SAVE_QUERY_CONTENT: 'Do you want to save this query?',
  },
  NUMBER: {
    SIMPLIFY: {
      '': '',
      THOUSAND: 'K',
      MILLION: 'M',
      BILLION: 'B',
      TRILLION: 'T',
      QUADRILLION: 'Q',
      QUINTILLION: 'Q',
      SEXTILLION: 'S',
      SEPTILLION: 'S',
      OCTILLION: 'O',
      NONILLION: 'N',
    },
  },
  UNIT_FORMAT: {
    OTHER: '{{value}} {{unit}}',
    TIME: {
      HOURS: '{{value}} h',
      MINUTES: '{{value}} mins',
      SECONDS: '{{value}} secs',
      MS: '{{value}} ms',
      MCS: '{{value}} mcs',
    },
    PERCENTAGE: {
      '%': '{{value}}%',
    },
    METRIC_LENGTH: {
      MM: '{{value}} mm',
      CM: '{{value}} cm',
      DM: '{{value}} dm',
      M: '{{value}} m',
      DAM: '{{value}} dam',
      HM: '{{value}} hm',
      KM: '{{value}} km',
    },
    DIGITAL_STORAGE_10_BASE: {
      B: '{{value}} B',
      KB: '{{value}} KB',
      MB: '{{value}} MB',
      GB: '{{value}} GB',
      TB: '{{value}} TB',
    },
    DIGITAL_STORAGE: {
      B: '{{value}} B',
      KB: '{{value}} KB',
      MB: '{{value}} MB',
      GB: '{{value}} GB',
      TB: '{{value}} TB',
    },
  },
};

export default function getDefaultTranslation(key: string): string | undefined {
  const parts = key.split('.');
  let value: string | TranslationConfig = defaultTranslation;
  for (const part of parts) {
    value = value[part];
    if (!value || typeof value === 'string') {
      return value;
    }
  }
  return String(value);
}

export function keyToDefaultValue(key: string): string {
  const parts = key.split('.');
  return upperFirst(parts[parts.length - 1].replace(/_/g, ' ').toLowerCase());
}
