import { createContext, PropsWithChildren, useState } from 'react';

import { createDashboardStore, DashboardStore } from './store/dashboard-store';

export interface DashboardProviderProps {}

export const DashboardContext = createContext<DashboardStore | null>(null);

export function DashboardProvider({ children }: PropsWithChildren<DashboardProviderProps>) {
  const [store] = useState(createDashboardStore);

  return <DashboardContext.Provider value={store}>{children}</DashboardContext.Provider>;
}
