import React from 'react';
import { ListItemButtonBaseProps, Tooltip } from '@mui/material';

import { DFCheckbox } from '~ui/df-input/components/df-checkbox';
import { StyledListItem } from '~ui/df-picker/components/df-list-picker/df-list-picker.styled';

export interface ListPickerMultipleOption extends ListItemButtonBaseProps {
  label: React.ReactNode;
  value: string;
  tooltip?: React.ReactNode;
  group?: string;
}

export function renderListItems(
  items: ListPickerMultipleOption[],
  value: string[] = [],
  onChange?: (value: string[]) => void
) {
  function handleChange(newValue: string) {
    if (value.findIndex((value) => value === newValue) > -1) {
      return onChange && onChange(value.filter((value) => value !== newValue));
    }

    return onChange && onChange([...value, newValue]);
  }

  return items.map((item) => {
    const { value: optionValue, label: optionLabel, tooltip, ...props } = item;
    const listItem = (
      <StyledListItem
        {...props}
        key={optionValue}
        onClick={() => onChange && handleChange(optionValue)}
      >
        <DFCheckbox
          checked={value ? value.findIndex((value) => value == optionValue) > -1 : false}
          sx={{ py: 0.5 }}
        />
        {optionLabel}
      </StyledListItem>
    );

    if (tooltip) {
      return (
        <Tooltip key={optionValue} title={tooltip} placement="left">
          {listItem}
        </Tooltip>
      );
    } else {
      return listItem;
    }
  });
}
