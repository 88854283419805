import { createZustandHooks } from '~utils/zustand';

import { DragDataContext } from './drag-data-context';

export const [, /*useDragDataStore*/ useDragDataState] = createZustandHooks(DragDataContext);

export const useDragTransformData = (ignore: boolean) =>
  useDragDataState((state) => (!ignore ? state.transform : null));

// export const useSetDragTransformData = () => useDragDataState((state) => state.setTransform);
