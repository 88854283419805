import { chipClasses, inputBaseClasses, styled } from '@mui/material';

import { DFOutlinedInput } from '../df-outlined-input';

export const OutlinedInput = styled(DFOutlinedInput)(({ theme }) => ({
  [`&.${inputBaseClasses.adornedStart}`]: {
    paddingLeft: theme.spacing(1),
  },
  [`.${chipClasses.root}`]: {
    overflow: 'hidden',
    ':last-child': {
      overflow: 'unset',
    },
  },
}));

export const ChipList = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  width: `calc(100% - ${theme.spacing(2)})`,
}));
