import { useMemo } from 'react';
import useTheme from '@mui/material/styles/useTheme';

import { ElementTheme } from '~services/v1/element';
import { sanitizeColorString } from '~utils/color';

import { useDocumentUrlConfig } from './use-document-url-config';

export function useOverrideElementTheme() {
  const theme = useTheme();
  const { forceGraphBgcolor } = useDocumentUrlConfig(['forceGraphBgcolor']);
  return useMemo((): ElementTheme => {
    if (forceGraphBgcolor) {
      const graphBackgroundColor = sanitizeColorString(forceGraphBgcolor);
      const { contrastText } = theme.palette.augmentColor({
        color: {
          main: graphBackgroundColor,
        },
      });
      return {
        color: {
          elementBackground: graphBackgroundColor,
          text: {
            general: contrastText,
            cardData: contrastText,
            cardTitle: contrastText,
            chartTitle: contrastText,
            dataLabels: contrastText,
            filterInput: contrastText,
            filterTitle: contrastText,
            legend: contrastText,
            tableHeader: contrastText,
            tooltip: contrastText,
          },
        },
      };
    }
    return {};
  }, [forceGraphBgcolor, theme.palette]);
}
