import { Box, Card, styled } from '@mui/material';

export const CustomCard = styled(Card)({
  background: '#ffffff',
  boxShadow: '0px 7.56px 47.88px rgba(0, 0, 0, 0.04)',
  borderRadius: '25px',
  height: '100%',
});

export const BoxWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
}));
