import { useDraggable } from '@dnd-kit/core';

import { GridRow, RowNode } from '../grid-board';
import { MovableData, UseGridDraggableArguments } from '../grid-board.type';

export function useMovable(
  args: { node: RowNode; row: GridRow },
  options: Omit<UseGridDraggableArguments, 'id' | 'data'> = {}
) {
  const data: MovableData = {
    context: 'grid-board',
    action: 'move',
    // If we remove this, component can't be clicked
    activationDistance: 12,
    ...args,
  };
  return useDraggable({
    ...options,
    id: args.node.id,
    data: data,
  });
}
