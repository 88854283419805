import { createContext, PropsWithChildren, useCallback, useMemo } from 'react';

import { useModal } from '~libs/modal-hook';
import { DFConfirmDialog, DFConfirmDialogProps } from '~ui/df-confirm-dialog';

export interface ConfirmAction {
  openConfirm: (props: DFConfirmDialogProps) => unknown;
}

interface ConfirmProviderProps {}

export const ConfirmContext = createContext<ConfirmAction>({
  openConfirm: () => {},
});

export function ConfirmProvider({ children }: PropsWithChildren<ConfirmProviderProps>) {
  const { show, placeholder } = useModal(DFConfirmDialog);

  const openConfirm = useCallback<ConfirmAction['openConfirm']>(
    (props) => {
      return show(props);
    },
    [show]
  );

  const value = useMemo(
    () => ({
      openConfirm,
    }),
    [openConfirm]
  );

  return (
    <ConfirmContext.Provider value={value}>
      {children}
      {placeholder}
    </ConfirmContext.Provider>
  );
}
