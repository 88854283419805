import { useCallback, useEffect, useState } from 'react';

export function useTransformInputState<T>(
  value: T,
  _onChange: (v: T) => void,
  transformValue: (v: string) => T | null,
  inverseValue: (v: T) => string
) {
  const [inputValue, setInputValue] = useState<string>(() => inverseValue(value));
  useEffect(() => {
    setInputValue((prev) => {
      try {
        const transformedValue = transformValue(prev);

        if (transformedValue === null || transformedValue === value) {
          return prev;
        }
        return inverseValue(value);
      } catch (e) {
        return prev;
      }
    });
  }, [inverseValue, transformValue, value]);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
      const _value = typeof e === 'string' ? e : e.currentTarget.value;
      setInputValue(_value);
      try {
        const transformedValue = transformValue(_value);
        if (transformedValue !== null) {
          _onChange(transformedValue);
        }
      } catch (e) {}
    },
    [_onChange, transformValue]
  );

  return [inputValue, onChange] as const;
}
