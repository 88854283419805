import React from 'react';
import { useDraggable } from '@dnd-kit/core';

import { Coordinate } from '~utils/geometry';

import { LineResizeData, PixelLineNode } from '../../../../../element-board';
import { StyledResizePoint } from '../../../../../element-board/components/pixel-board/components/resizer';

export interface LineResizeHandlerProps {
  type: 'start' | 'end';
  node: PixelLineNode;
  onClick?: React.MouseEventHandler;
}

export function LineResizeHandler({ type, node, onClick }: LineResizeHandlerProps) {
  const data: LineResizeData = {
    context: 'pixel-board',
    action: 'resize',
    position: type,
    nodeType: 'line',
    node,
  };
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: `resize-${type}:${node.id}`,
    data,
  });
  const position: Coordinate = {
    x: type === 'start' ? node.x1 : node.x2,
    y: type === 'start' ? node.y1 : node.y2,
  };
  return (
    <StyledResizePoint
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      coordinate={position}
      onClick={onClick}
    />
  );
}
