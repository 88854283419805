import { PropsWithChildren, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, CssBaseline, ScopedCssBaseline } from '@mui/material';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';

import { createTheme, ThemeMode } from '../../theme';

interface ThemeProviderProps {
  mode?: ThemeMode;
  name?: 'experiment';
  scope?: boolean;
}

export function ThemeProvider({
  mode = 'light',
  scope,
  children,
}: PropsWithChildren<ThemeProviderProps>) {
  const theme = useMemo(() => createTheme(mode), [mode]);
  return (
    <MuiThemeProvider theme={theme}>
      {scope ? (
        <ScopedCssBaseline component={Box} sx={{ backgroundColor: 'transparent' }}>
          {children || <Outlet />}
        </ScopedCssBaseline>
      ) : (
        <>
          <CssBaseline />
          {children || <Outlet />}
        </>
      )}
    </MuiThemeProvider>
  );
}
