import React from 'react';
import { EnvelopeIcon } from '@dataflake/icons';
import { InputAdornment, TextFieldProps } from '@mui/material';

import AppTextField from '../app-text-field';

export type EmailInputProps = TextFieldProps;

export const EmailInput = React.forwardRef<HTMLDivElement, EmailInputProps>(
  function EmailInput(props, ref) {
    return (
      <AppTextField
        {...props}
        ref={ref}
        rounded
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EnvelopeIcon />
            </InputAdornment>
          ),
        }}
      />
    );
  }
);
