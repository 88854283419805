import React, { forwardRef, useCallback, useState } from 'react';
import { BoxProps } from '@mui/material';

import { isPrimaryClick } from '~utils/event';
import { calculateRectPosition, Coordinate, getItemsInside, RectPosition } from '~utils/geometry';

import { CopiedNodeData } from '../../../../element-board.types';
import { PixelBoardProps } from '../../pixel-board';
import { PixelBoxNode } from '../../pixel-board.types';
import { copyElementToPixelLayout } from '../../pixel-board.utils';
import { ExternalDroppableLayer } from '../external-dropable-layer';

import { SelectionFrame, StyledPixelBoardContainer } from './pixel-board-container.styled';

export interface PixelBoardContainerProps
  extends Omit<BoxProps, 'onChange' | 'value' | 'onContextMenu'>,
    PickAndTransformOptional<
      PixelBoardProps,
      'value' | 'editable' | 'onChange' | 'onChangeSelectedNodeIds' | 'onContextMenu'
    > {
  defaultSize: Exclude<PixelBoardProps['defaultSize'], undefined>;
}
export interface SelectPoints {
  from: Coordinate;
  to: Coordinate;
  position: RectPosition;
}

export const PixelBoardContainer = forwardRef<HTMLDivElement, PixelBoardContainerProps>(
  function PixelBoardContainer(
    {
      editable,
      value,
      onChange,
      onChangeSelectedNodeIds,
      onContextMenu,
      children,
      defaultSize,
      ...props
    },
    ref
  ) {
    const [selectPoints, setSelectPoints] = useState<SelectPoints>();
    const [selecting, setSelecting] = React.useState(false);

    const handleClickBackground = useCallback(
      (e: React.MouseEvent) => {
        if (isPrimaryClick(e) && e.currentTarget === e.target && !selecting) {
          onChangeSelectedNodeIds?.([]);
        }
      },
      [selecting, onChangeSelectedNodeIds]
    );

    const handleStartSelect = useCallback<React.MouseEventHandler>(
      (e) => {
        if (e.currentTarget !== e.target || !isPrimaryClick(e)) {
          return;
        }
        setSelecting(true);
        const CLICK_DELAY = 0;
        const boundingRect = e.currentTarget.getBoundingClientRect();
        const handleCancel = () => {
          setSelecting(false);
          window.clearTimeout(timeout);
          window.removeEventListener('mouseup', handleCancel);
          window.removeEventListener('mouseleave', handleCancel);
        };
        const timeout = window.setTimeout(() => {
          e.preventDefault();
          const from = {
            x: e.clientX - boundingRect.x,
            y: e.clientY - boundingRect.y,
          };
          const handleMouseMove = (e: MouseEvent) => {
            const to = {
              x: e.clientX - boundingRect.x,
              y: e.clientY - boundingRect.y,
            };
            setSelectPoints({
              from,
              to,
              position: calculateRectPosition(from, to),
            });
          };
          const handleComplete = (e: MouseEvent) => {
            setSelectPoints(undefined);
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleComplete);
            window.removeEventListener('mouseleave', handleComplete);
            const to = {
              x: e.clientX - boundingRect.x,
              y: e.clientY - boundingRect.y,
            };
            onChangeSelectedNodeIds?.(
              getItemsInside(
                from,
                to,
                value
                  .filter((item): item is PixelBoxNode => item.type === 'box')
                  .map((item) => ({
                    position: item,
                    value: item.id,
                  }))
              )
            );
          };
          window.addEventListener('mousemove', handleMouseMove);
          window.addEventListener('mouseup', handleComplete);
          window.addEventListener('mouseleave', handleComplete);

          // Remove cancel events
          window.removeEventListener('mouseup', handleCancel);
          window.removeEventListener('mouseleave', handleCancel);
        }, CLICK_DELAY);
        window.addEventListener('mouseup', handleCancel);
        window.addEventListener('mouseleave', handleCancel);
      },
      [onChangeSelectedNodeIds, value]
    );

    const handleContainerContextMenu = useCallback(
      (e: React.MouseEvent) => {
        if (e.currentTarget !== e.target) {
          return;
        }
        onContextMenu?.(e, {
          type: 'backdrop',
          event: e,
          insertCopyElement(data: CopiedNodeData) {
            onChange?.(copyElementToPixelLayout(data));
          },
        });
      },
      [onChange, onContextMenu]
    );

    return (
      <StyledPixelBoardContainer ref={ref} {...props}>
        <ExternalDroppableLayer
          editable={editable}
          onChange={onChange}
          defaultSize={defaultSize}
          onClick={handleClickBackground}
          onMouseDown={handleStartSelect}
          onContextMenu={handleContainerContextMenu}
        >
          {children}
          {selectPoints && <SelectionFrame style={selectPoints.position} />}
        </ExternalDroppableLayer>
      </StyledPixelBoardContainer>
    );
  }
);
