import { memoize } from 'lodash-es';

import { DashboardState } from '../store/dashboard-store';

export const selectLoadedDashboard = (state: DashboardState) => !!state.dashboard;

export const selectActiveDashboard = (state: DashboardState) => state.dashboard;
export const selectActiveDashboardUuid = (state: DashboardState) => state.dashboard?.document_uuid;
export const selectActiveDashboardName = (state: DashboardState) => state.dashboard?.document_name;

export const selectDashboardSetting = (state: DashboardState) => state?.dashboard?.document_setting;

export const selectHeaderShow = (state: DashboardState) =>
  state.dashboard?.document_header_show || false;

export const selectHeaderHeight = (state: DashboardState) =>
  state.dashboard?.document_header_height || 0;

export const selectHeaderColor = (state: DashboardState) =>
  state.dashboard?.document_header_color || 'transparent';

export const selectFooterShow = (state: DashboardState) =>
  state.dashboard?.document_footer_show || false;

export const selectFooterHeight = (state: DashboardState) =>
  state.dashboard?.document_footer_height || 0;

export const selectFooterColor = (state: DashboardState) =>
  state.dashboard?.document_footer_color || 'transparent';

export const selectHeaderProperties = memoize((state: DashboardState) => ({
  show: selectHeaderShow(state),
  height: selectHeaderHeight(state),
  color: selectHeaderColor(state),
}));

export const selectFooterProperties = memoize((state: DashboardState) => ({
  show: selectFooterShow(state),
  height: selectFooterHeight(state),
  color: selectFooterColor(state),
}));

export const selectDashboardLabels = memoize(
  (state: DashboardState) => state.dashboard?.dashboard_labels || {}
);

export const selectDashboardLabelsList = memoize((state: DashboardState) =>
  Object.entries(state.dashboard?.dashboard_labels || {})
    .map(([key, value]) => ({
      key,
      value,
    }))
    .filter(({ value }) => value !== null && value !== undefined)
);

export const selectDashboardColors = memoize(
  (state: DashboardState) => state.dashboard?.dashboard_colors || {}
);

export const selectDashboardColorsList = memoize((state: DashboardState) =>
  Object.entries(state.dashboard?.dashboard_colors || {})
    .map(([key, value]) => ({
      key,
      value,
    }))
    .filter(({ value }) => value !== null && value !== undefined)
);
