import { GenericElementEntity } from '../element.entity';

export type ParamType =
  | 'List'
  | 'Expression'
  | 'String'
  | 'Float'
  | 'Int'
  | 'Boolean'
  | 'Date'
  | 'Datetime'
  | 'TimeExpression';

export type PredefinedDateRange =
  | 'this-year'
  | 'this-quarter'
  | 'this-month'
  | 'this-week'
  | 'last-year'
  | 'last-quarter'
  | 'last-month'
  | 'last-week'
  | 'this-day'
  | 'last-day'
  | 'today'
  | 'yesterday';

export interface TimeDefine {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  second: number;
}

export type DateRangeValueType = 'ago' | 'fixed' | 'predefined';

export interface DateRangeBaseValue<T extends DateRangeValueType, V> {
  type: T;
  value: V;
}

export type DateRangeValue =
  | DateRangeBaseValue<'ago', TimeDefine>
  | DateRangeBaseValue<'predefined', PredefinedDateRange>
  | DateRangeBaseValue<'fixed', [string, string]>;

export interface RecentTimeRangeValue {
  from: string;
  to: string;
  ref: string;
}

export interface BaseFilterConfig {
  filterType: FilterType;
  label: string;
  hideWhenOnUrl?: boolean;
  twoLine?: boolean;
}

export interface DateRangeFilterConfig extends BaseFilterConfig {
  filterType: 'daterange';
  queryParameter: [string | null, string | null];
  defaultValue: DateRangeValue;
  showTimeSelect?: boolean;
  showTimeSelectIfDaysLessThan?: number;
  availablePeriods?: string[];
}

export interface RecentTimeRangeFilterConfig extends BaseFilterConfig {
  filterType: 'recent-timerange';
  queryParameter: [string | null, string | null];
  defaultValue: RecentTimeRangeValue;
}

export interface TextFilterOptionItem {
  label: string;
  value: string;
}

export interface TextFilterConfig extends BaseFilterConfig {
  filterType: 'text' | 'datetime' | 'date' | 'float' | 'int' | 'bool' | 'list';
  queryParameter: string | null;
  defaultValueCondition?: 'eq' | 'startWith' | 'endWith' | 'regex';
  defaultValue: string;
  defaultAll?: boolean;
  select?: boolean;
  multiple?: boolean;
  optionSource?: 'data_source' | 'manual';
  labelColumn?: string;
  valueColumn?: string;
  options?: TextFilterOptionItem[];
  enableDefaultValueCondition?: boolean;
}

export type FilterElementConfig =
  | TextFilterConfig
  | DateRangeFilterConfig
  | RecentTimeRangeFilterConfig;
export type FilterType = FilterElementConfig['filterType'];

export const isTextFilterConfig = (
  filterConfig: FilterElementConfig
): filterConfig is TextFilterConfig =>
  filterConfig.filterType !== 'daterange' && filterConfig.filterType !== 'recent-timerange';

export const filterTypeMap = {
  bool: 'Boolean',
  int: 'Int',
  float: 'Float',
  text: 'String',
  date: 'Date',
  datetime: 'Datetime',
  daterange: 'Datetime',
  list: 'List',
} as const;

export const parseParamValue = (
  value: string,
  defaultType: ParamType = 'String'
): [type: ParamType, value: string] => {
  const regex = /(Boolean|Int|Float|String|Date|Datetime|List):(.*)/;
  const match = value.match(regex);
  if (match) {
    return [match[1] as ParamType, match[2]];
  }
  return [defaultType, value];
};

export interface TextFilterElementEntity extends GenericElementEntity<'filter', TextFilterConfig> {}
export interface DateRangeFilterElementEntity
  extends GenericElementEntity<'filter', DateRangeFilterConfig> {}

export interface RecentTimeRangeFilterElementEntity
  extends GenericElementEntity<'filter', RecentTimeRangeFilterConfig> {}

export interface FilterElementEntity extends GenericElementEntity<'filter', FilterElementConfig> {}
