import React from 'react';
import { Active, Translate } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { Coordinates } from '@dnd-kit/utilities';
import { min } from 'lodash-es';

import { EntityUuid } from '~services/v1/types';
import { ClipboardElementNode } from '~stores/clipboard.store';
import { wrap } from '~utils/array';
import { timeId } from '~utils/chore';
import { isDndKitEvent } from '~utils/dnd-kit';
import {
  calculateMultipleRectsRelationship,
  filterAndSortNearestRelationships,
  isTriRectRelationship,
  moveRect,
  RectPosition,
  RectSize,
  RelationshipFilter,
  ResizeDirection,
  resizeRect,
} from '~utils/geometry';

import { CopiedNodeData } from '../../element-board.types';
import { PixelBoardKeyboardSensor } from '../../sensors/pixel-board-keyboard.sensor';

import {
  selectBoundaryRect,
  selectUnselectedBoxNodes,
} from './components/pixel-board-context/pixel-board.selector';
import {
  BoxDragData,
  BoxResizeData,
  DragAndResizeData,
  LineHead,
  PixelBoxNode,
  PixelLayoutValue,
  PixelNode,
} from './pixel-board.types';

export const GRID_SNAP_SPACING = 16;

export const DEFAULT_SIZE = () => ({
  width: 400,
  height: 300,
});
export const MIN_SIZE = () => ({
  width: 50,
  height: 50,
});

export const isPixelBoxItem = (item: PixelNode): item is PixelBoxNode => item.type === 'box';

export const createOtherBoxesFilter =
  (currentId: EntityUuid) =>
  (node: PixelNode): node is PixelBoxNode =>
    node.id !== currentId && isPixelBoxItem(node);

export const onClickPixelNode =
  (e: React.MouseEvent, nodeId: string) => (prevSelectedNodeIds: string[]) => {
    const isSelected = prevSelectedNodeIds?.includes(nodeId);

    /**
     * handle activate elements
     */

    if (!isSelected) {
      if (e.metaKey || e.shiftKey) {
        return [...prevSelectedNodeIds, nodeId];
      } else {
        return [nodeId];
      }
    }

    /**
     * handle inactivate elements
     */
    if (e.metaKey || e.shiftKey) {
      return prevSelectedNodeIds.filter((item) => item !== nodeId);
    }

    return prevSelectedNodeIds;
  };

export const addNodeToPixelBoard = (newItem: PixelNode) => (pixelLayoutValue: PixelLayoutValue) => [
  ...pixelLayoutValue,
  newItem,
];

export const getPastedPixelNodeTranslate = (e: React.MouseEvent, data: ClipboardElementNode[]) => {
  const boardRef = e.target as EventTarget & Element;
  const boundingClientRec = boardRef.getBoundingClientRect();

  const positionX = e.clientX - boundingClientRec.x;

  const positionY = e.clientY - boundingClientRec.y;

  const minTop = min(data.map((item) => (item as PixelBoxNode).top || 0)) || 0;
  const minLeft = min(data.map((item) => (item as PixelBoxNode).left || 0)) || 0;

  return {
    x: positionX - minLeft,
    y: positionY - minTop,
  };
};

export const copyElementToPixelLayout =
  ({
    source,
    target: {
      data: { elementId },
      translate,
    },
  }: CopiedNodeData) =>
  (oldValue: PixelLayoutValue) => {
    let newItem = {} as PixelNode;

    const translateX = translate?.x ?? 10;
    const translateY = translate?.y ?? 10;

    if (source.type === 'line') {
      newItem = {
        type: 'line',
        id: timeId(),
        data: { elementId },
        x1: source.x1 + translateX,
        y1: source.y1 + translateY,
        x2: source.x2 + translateX,
        y2: source.y2 + translateY,
      };
    } else {
      const sourceLeft = (source as PixelBoxNode)?.left ?? 0;
      const sourceTop = (source as PixelBoxNode)?.top ?? 0;

      newItem = {
        width: source.width,
        height: source.height,
        left: sourceLeft + translateX,
        top: sourceTop + translateY,
        id: timeId(),
        type: 'box',
        data: { elementId },
      };
    }
    return [...oldValue, newItem];
  };

export const removeNodeFromPixelBoard =
  (nodeIds: EntityUuid[] | EntityUuid) => (pixelLayoutValue: PixelLayoutValue) =>
    pixelLayoutValue.filter((item) => !wrap(nodeIds).includes(item.id));

export const moveBoxNode = (nodeId: string, delta: Translate) => (value: PixelLayoutValue) => {
  return value.map((pixelItem) => {
    if (nodeId === pixelItem.id && pixelItem.type === 'box') {
      return {
        ...pixelItem,
        ...moveRect(pixelItem, delta),
      };
    }
    return pixelItem;
  });
};

export const resizeBoxNode =
  (nodeId: string, direction: ResizeDirection, delta: Translate) => (value: PixelLayoutValue) => {
    return value.map((pixelItem) => {
      if (nodeId === pixelItem.id && pixelItem.type === 'box') {
        return {
          ...pixelItem,
          ...resizeRect(pixelItem, delta, direction),
        };
      }
      return pixelItem;
    });
  };

export const resizeLineNode =
  (nodeId: string, head: LineHead, delta: Translate) => (value: PixelLayoutValue) => {
    return value.map((node) => {
      if (nodeId === node.id && node.type === 'line') {
        return {
          ...node,
          x1: node.x1 + (head === 'start' ? delta.x : 0),
          y1: node.y1 + (head === 'start' ? delta.y : 0),
          x2: node.x2 + (head === 'end' ? delta.x : 0),
          y2: node.y2 + (head === 'end' ? delta.y : 0),
        };
      }
      return node;
    });
  };

export const SNAP_DISTANCE = 12;

export const getGridTransform = (
  data: BoxDragData | BoxResizeData,
  transform: Coordinates,
  originBox: RectPosition
): Coordinates => {
  let offsetX = 0;
  let offsetY = 0;

  if (data.action === 'drag') {
    offsetX = originBox.left;
    offsetY = originBox.top;
  } else if (data.action === 'resize') {
    if (data.position.includes('left')) {
      offsetX = originBox.left;
    }
    if (data.position.includes('top')) {
      offsetY = originBox.top;
    }
    if (data.position.includes('right')) {
      offsetX = originBox.left + originBox.width;
    }
    if (data.position.includes('bottom')) {
      offsetY = originBox.top + originBox.height;
    }
  }

  return {
    ...transform,
    x:
      Math.round(transform.x / GRID_SNAP_SPACING) * GRID_SNAP_SPACING -
      (offsetX % GRID_SNAP_SPACING),
    y:
      Math.round(transform.y / GRID_SNAP_SPACING) * GRID_SNAP_SPACING -
      (offsetY % GRID_SNAP_SPACING),
  };
};

export const getSnapTransform = (
  data: BoxDragData | BoxResizeData,
  transform: Coordinates,
  originBox: RectPosition,
  relatedBoxes: RectPosition[],
  boardSize: RectSize
): Coordinates => {
  let snappedTransform = {
    ...transform,
  };
  let transformedBox = moveRect(originBox, transform);
  if (data.action === 'resize') {
    transformedBox = resizeRect(originBox, transform, data.position);
  }

  let boxRelationships = calculateMultipleRectsRelationship(transformedBox, [
    ...relatedBoxes,
    {
      ...boardSize,
      top: 0,
      left: 0,
    },
  ]);
  boxRelationships = filterAndSortNearestRelationships(boxRelationships, SNAP_DISTANCE);
  const relationshipFilters = getRelationshipActionFilters(data);

  relationshipFilters.forEach((filter) => {
    const nearestRelationship = boxRelationships.find(filter);
    if (!nearestRelationship) {
      return;
    }
    let snapDistance = nearestRelationship.snapDistance;
    if (isTriRectRelationship(nearestRelationship) && data.action === 'drag') {
      snapDistance = snapDistance / 2;
    }
    snappedTransform = {
      ...snappedTransform,
      [nearestRelationship.axis]: transform[nearestRelationship.axis] + snapDistance,
    };
  });

  return snappedTransform;
};

export const createSnapItemModifier =
  ({
    value,
    boardSize,
    enableGrid,
    selectedNodeIds,
  }: {
    value: PixelLayoutValue;
    boardSize: RectSize;
    enableGrid?: boolean;
    selectedNodeIds?: string[];
  }) =>
  ({
    transform,
    active,
    activatorEvent,
  }: {
    transform: Coordinates;
    active: Active;
    activatorEvent: Event | null;
  }) => {
    if (!active) {
      return transform;
    }
    const data = active.data.current as DragAndResizeData;
    if (data.context !== 'pixel-board') {
      return transform;
    }

    // Don't snap when user is using keyboard to move
    if (
      activatorEvent &&
      isDndKitEvent(activatorEvent) &&
      activatorEvent.dndKit.capturedBy === PixelBoardKeyboardSensor &&
      !enableGrid
    ) {
      // Don't snap when user is using keyboard to move
      return transform;
    }

    const originBox = selectBoundaryRect({
      value,
      selectedNodeIds: selectedNodeIds ?? [],
    });

    if (!originBox) {
      return transform;
    }

    // Snap to grid (n x n grid)
    if (enableGrid) {
      return getGridTransform(data, transform, originBox);
    }

    // Snap to nearest item (alignment)
    const relatedBoxes = selectUnselectedBoxNodes({
      value,
      selectedNodeIds: selectedNodeIds ?? [],
    });

    return getSnapTransform(data, transform, originBox, relatedBoxes, boardSize);
  };

export const getRelationshipActionFilters = (data: BoxResizeData | BoxDragData) => {
  const relationshipFilters: RelationshipFilter[] = [];

  if (data.action === 'drag') {
    relationshipFilters.push(
      (rel) => rel.axis === 'x',
      (rel) => rel.axis === 'y'
    );
    return relationshipFilters;
  }

  if (data.position.match(/top/)) {
    relationshipFilters.push(
      (rel) => isTriRectRelationship(rel) || (rel.axis === 'y' && rel.currentPlacement === 'start')
    );
  }
  if (data.position.match(/bottom/)) {
    relationshipFilters.push(
      (rel) => isTriRectRelationship(rel) || (rel.axis === 'y' && rel.currentPlacement === 'end')
    );
  }
  if (data.position.match(/left/)) {
    relationshipFilters.push(
      (rel) => isTriRectRelationship(rel) || (rel.axis === 'x' && rel.currentPlacement === 'start')
    );
  }
  if (data.position.match(/right/)) {
    relationshipFilters.push(
      (rel) => isTriRectRelationship(rel) || (rel.axis === 'x' && rel.currentPlacement === 'end')
    );
  }

  return relationshipFilters;
};

export const makeFullWidth = (item: PixelBoxNode, boardSize: RectSize) => ({
  ...item,
  left: 0,
  width: boardSize.width,
});

export const expandNodeFullWidth =
  (nodeId: EntityUuid | EntityUuid[], boardSize: RectSize) => (value: PixelLayoutValue) => {
    const nodeIds = wrap(nodeId);
    return value.map((node) =>
      nodeIds.includes(node.id) && isPixelBoxItem(node) ? makeFullWidth(node, boardSize) : node
    );
  };

export const bringNodeToFront =
  (nodeId: EntityUuid | EntityUuid[]) => (value: PixelLayoutValue) => {
    const nodeIds = wrap(nodeId);
    return [
      ...value.filter((node) => !nodeIds.includes(node.id)),
      ...value.filter((node) => nodeIds.includes(node.id)),
    ];
  };

export const sendNodeToBack = (nodeId: EntityUuid | EntityUuid[]) => (value: PixelLayoutValue) => {
  const nodeIds = wrap(nodeId);
  return [
    ...value.filter((node) => nodeIds.includes(node.id)),
    ...value.filter((item) => !nodeIds.includes(item.id)),
  ];
};

/**
 * Just temporary, might be wrong
 * @param nodeId
 */
export const sendNodeBackward =
  (nodeId: EntityUuid | EntityUuid[]) => (value: PixelLayoutValue) => {
    const nodeIds = wrap(nodeId);
    const firstMatchedNode = value.find((item) => !nodeIds.includes(item.id));
    if (!firstMatchedNode) {
      return value;
    }
    let otherNodes = value.filter(
      (item) => item === firstMatchedNode || !nodeIds.includes(item.id)
    );
    const otherMatchedNodes = value.filter((item) => !otherNodes.includes(item));
    const firstMatchedNodeIndex = otherNodes.indexOf(firstMatchedNode);
    let newFirstIndex = firstMatchedNodeIndex;
    if (newFirstIndex > 0) {
      newFirstIndex += 1;
      otherNodes = arrayMove(otherNodes, firstMatchedNodeIndex, newFirstIndex);
    }
    newFirstIndex += 1;
    otherMatchedNodes.map((matchedNode) => {
      otherNodes.splice(newFirstIndex, 0, matchedNode);
      newFirstIndex += 1;
    });
    return otherNodes;
  };

/**
 * Just temporary, might be wrong
 * @param nodeId
 */
export const bringNodeForward =
  (nodeId: EntityUuid | EntityUuid[]) => (value: PixelLayoutValue) => {
    const nodeIds = wrap(nodeId);
    const lastMatchedNode = value.findLast((item) => !nodeIds.includes(item.id));
    if (!lastMatchedNode) {
      return value;
    }
    let otherNodes = value.filter((item) => item === lastMatchedNode || !nodeIds.includes(item.id));
    const otherMatchedNodes = value.filter((item) => !otherNodes.includes(item));
    const lastMatchedNodeIndex = otherNodes.indexOf(lastMatchedNode);
    let newLastIndex = lastMatchedNodeIndex;
    if (newLastIndex < otherNodes.length - 1) {
      newLastIndex -= 1;
      otherNodes = arrayMove(otherNodes, lastMatchedNodeIndex, newLastIndex);
    }
    otherMatchedNodes.map((matchedSlot) => {
      otherNodes.splice(newLastIndex, 0, matchedSlot);
      newLastIndex += 1;
    });
    return otherNodes;
  };

// Distribution & Alignments

export type AlignType = 'left' | 'h-center' | 'right' | 'top' | 'v-center' | 'bottom';

export const alignNodes =
  (nodeIds: EntityUuid[], alignType: AlignType) => (value: PixelLayoutValue) => {
    const selectedNodes = value.filter((item) => nodeIds.includes(item.id)).filter(isPixelBoxItem);
    let newNodes: PixelBoxNode[] = [];
    switch (alignType) {
      case 'top':
      case 'left': {
        const min = selectedNodes.reduce<number>(
          (min, pos) => (pos[alignType] < min ? pos[alignType] : min),
          Number.POSITIVE_INFINITY
        );
        newNodes = selectedNodes.map(
          (el): PixelBoxNode => ({
            ...el,
            [alignType]: min,
          })
        );
        break;
      }
      case 'bottom': {
        const bottom = selectedNodes.reduce<number>(
          (max, pos) => (pos.top + pos.height > max ? pos.top + pos.height : max),
          Number.NEGATIVE_INFINITY
        );
        newNodes = selectedNodes.map(
          (el): PixelBoxNode => ({
            ...el,
            top: bottom - el.height,
          })
        );
        break;
      }
      case 'right': {
        const right = selectedNodes.reduce<number>(
          (max, pos) => (pos.left + pos.width > max ? pos.left + pos.width : max),
          Number.NEGATIVE_INFINITY
        );
        newNodes = selectedNodes.map(
          (el): PixelBoxNode => ({
            ...el,
            left: right - el.width,
          })
        );
        break;
      }
      case 'h-center': {
        const { left, width } = selectedNodes.reduce(
          (max, pos) => (pos.width > max.width ? { left: pos.left, width: pos.width } : max),
          { left: Number.NEGATIVE_INFINITY, width: Number.NEGATIVE_INFINITY }
        );
        const centerAxis = left + width / 2;
        newNodes = selectedNodes.map(
          (el): PixelBoxNode => ({
            ...el,
            left: centerAxis - el.width / 2,
          })
        );
        break;
      }
      case 'v-center': {
        const { top, height } = selectedNodes.reduce(
          (max, pos) => (pos.height > max.height ? { top: pos.top, height: pos.height } : max),
          { top: Number.NEGATIVE_INFINITY, height: Number.NEGATIVE_INFINITY }
        );
        const centerAxis = top + height / 2;
        newNodes = selectedNodes.map(
          (el): PixelBoxNode => ({
            ...el,
            top: centerAxis - el.height / 2,
          })
        );
        break;
      }
    }
    const newNodeMap = new Map(newNodes.map((item) => [item.id, item]));
    return value.map((item) => newNodeMap.get(item.id) ?? item);
  };

export type JustifyType = 'h-stretch' | 'v-stretch' | 'h-share' | 'v-share';

export const justifyNodes =
  (nodeIds: EntityUuid[], justifyType: JustifyType) => (value: PixelLayoutValue) => {
    const selectedNodes = value.filter((item) => nodeIds.includes(item.id)).filter(isPixelBoxItem);
    let newNodes: PixelBoxNode[] = [];
    switch (justifyType) {
      case 'v-stretch': {
        const { minTop, maxBottom } = selectedNodes.reduce(
          ({ minTop, maxBottom }, { top, height }) => ({
            minTop: top < minTop ? top : minTop,
            maxBottom: top + height > maxBottom ? top + height : maxBottom,
          }),
          {
            minTop: Number.POSITIVE_INFINITY,
            maxBottom: Number.NEGATIVE_INFINITY,
          }
        );
        newNodes = selectedNodes.map(
          (el): PixelBoxNode => ({
            ...el,
            top: minTop,
            height: maxBottom - minTop,
          })
        );
        break;
      }
      case 'v-share': {
        const { minTop, maxBottom } = selectedNodes.reduce(
          ({ minTop, maxBottom }, { top, height }) => ({
            minTop: top < minTop ? top : minTop,
            maxBottom: top + height > maxBottom ? top + height : maxBottom,
          }),
          {
            minTop: Number.POSITIVE_INFINITY,
            maxBottom: Number.NEGATIVE_INFINITY,
          }
        );
        const height = selectedNodes.length ? (maxBottom - minTop) / selectedNodes.length : 0;
        newNodes = selectedNodes.map(
          (el, index): PixelBoxNode => ({
            ...el,
            top: minTop + height * index,
            height,
          })
        );
        break;
      }
      case 'h-stretch': {
        const { minLeft, maxRight } = selectedNodes.reduce(
          ({ minLeft, maxRight }, { left, width }) => ({
            minLeft: left < minLeft ? left : minLeft,
            maxRight: left + width > maxRight ? left + width : maxRight,
          }),
          {
            minLeft: Number.POSITIVE_INFINITY,
            maxRight: Number.NEGATIVE_INFINITY,
          }
        );
        newNodes = selectedNodes.map(
          (el): PixelBoxNode => ({
            ...el,
            left: minLeft,
            width: maxRight - minLeft,
          })
        );
        break;
      }
      case 'h-share': {
        const { minLeft, maxRight } = selectedNodes.reduce(
          ({ minLeft, maxRight }, { left, width }) => ({
            minLeft: left < minLeft ? left : minLeft,
            maxRight: left + width > maxRight ? left + width : maxRight,
          }),
          {
            minLeft: Number.POSITIVE_INFINITY,
            maxRight: Number.NEGATIVE_INFINITY,
          }
        );
        const width = selectedNodes.length ? (maxRight - minLeft) / selectedNodes.length : 0;
        newNodes = selectedNodes.map(
          (el, index): PixelBoxNode => ({
            ...el,
            left: minLeft + width * index,
            width,
          })
        );
        break;
      }
    }
    const newNodeMap = new Map(newNodes.map((item) => [item.id, item]));
    return value.map((item) => newNodeMap.get(item.id) ?? item);
  };

export type DistributeType = 'v-gaps' | 'h-gaps';

export const distributeNodes =
  (nodeIds: EntityUuid[], distributeType: DistributeType) => (value: PixelLayoutValue) => {
    const selectedNodes = value.filter((item) => nodeIds.includes(item.id)).filter(isPixelBoxItem);
    let newNodes: PixelBoxNode[] = [];
    switch (distributeType) {
      case 'v-gaps': {
        const { minTop, maxBottom, totalHeight } = selectedNodes.reduce(
          ({ minTop, maxBottom, totalHeight }, { top, height }) => ({
            minTop: top < minTop ? top : minTop,
            maxBottom: top + height > maxBottom ? top + height : maxBottom,
            totalHeight: totalHeight + height,
          }),
          {
            minTop: Number.POSITIVE_INFINITY,
            maxBottom: Number.NEGATIVE_INFINITY,
            totalHeight: 0,
          }
        );
        const space = (maxBottom - minTop - totalHeight) / (selectedNodes.length - 1);
        newNodes = selectedNodes;
        newNodes.sort(({ top: top1 }, { top: top2 }) => top1 - top2);
        newNodes.forEach((el, index) => {
          if (index === 0) {
            return;
          }
          const prevNode = newNodes[index - 1];
          newNodes[index] = {
            ...newNodes[index],
            top: prevNode.top + prevNode.height + space,
          };
        });
        break;
      }
      case 'h-gaps': {
        const { minLeft, maxRight, totalWidth } = selectedNodes.reduce(
          ({ minLeft, maxRight, totalWidth }, { left, width }) => ({
            minLeft: left < minLeft ? left : minLeft,
            maxRight: left + width > maxRight ? left + width : maxRight,
            totalWidth: totalWidth + width,
          }),
          {
            minLeft: Number.POSITIVE_INFINITY,
            maxRight: Number.NEGATIVE_INFINITY,
            totalWidth: 0,
          }
        );
        const space = (maxRight - minLeft - totalWidth) / (selectedNodes.length - 1);
        newNodes = selectedNodes;
        newNodes.sort(({ left: left1 }, { left: left2 }) => left1 - left2);
        newNodes.forEach((el, index) => {
          if (index === 0) {
            return;
          }
          const prevNode = newNodes[index - 1];
          newNodes[index] = {
            ...newNodes[index],
            left: prevNode.left + prevNode.width + space,
          };
        });
        break;
      }
    }
    const newNodeMap = new Map(newNodes.map((item) => [item.id, item]));
    return value.map((item) => newNodeMap.get(item.id) ?? item);
  };

export const getPixelNodesByIds = (nodeIds: string[], value: PixelLayoutValue) =>
  value.filter((node) => nodeIds.includes(node.id));

export const getAllNodeIdsFromPixelLayout = (value: PixelLayoutValue): EntityUuid[] =>
  value.map((node) => node.id);
