import { PropsWithChildren } from 'react';
import { Card, styled } from '@mui/material';

import { linearGradient } from '../../../../utils/color';

import { withProps } from '../../../../utils/styled';

export const ActivatorButton = styled(withProps(Card, { variant: 'outlined' }))(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.spacing(1),
  '&:hover': {
    borderColor: `${theme.palette.text.primary}`,
  },
}));

export function ValueDisplayComponent({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return <div className={className}>{children}</div>;
}

export const ValueDisplay = styled(ValueDisplayComponent)<{ colors: string[] }>(({ colors }) => ({
  background: `${linearGradient(colors)}`,
  minHeight: '20px',
  minWidth: '300px',
  height: '100%',
  width: '100%',
}));
