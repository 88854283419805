import { NavLink } from 'react-router-dom';
import { Box, ButtonBase, styled } from '@mui/material';

import { ICON_WIDTH, SIDE_BAR_WIDTH, sideBarClasses } from './side-bar.const';

export const StyledSideBar = styled(Box, {
  shouldForwardProp(propName: PropertyKey) {
    return propName !== 'collapse' && propName !== 'compact';
  },
})<{ compact?: boolean; collapse?: boolean }>(
  ({ collapse, theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    zIndex: theme.zIndex.appBar - 1,
    overflow: 'visible',

    [`.${sideBarClasses.root}`]: {
      lineHeight: 'initial',
      height: '100%',
      overflow: 'auto',
      transition: 'all',
      transitionDuration: '300ms',
      width: theme.spacing(collapse ? 0 : SIDE_BAR_WIDTH.NORMAL),
      boxShadow: theme.shadows[1],
      backgroundColor: theme.palette.background.paper,
    },
    [`.${sideBarClasses.collapseBtnRootOuter}`]: {
      display: 'block',
    },
    [`.${sideBarClasses.collapseBtnRootInsider}`]: {
      display: 'none',
    },
  }),
  ({ compact, collapse }) =>
    compact &&
    collapse && {
      [`.${sideBarClasses.root}`]: {
        width: 0,
      },
    },
  ({ collapse }) =>
    collapse && {
      [`.${sideBarClasses.itemRoot}`]: {
        display: 'none',
      },
      [`.${sideBarClasses.helpRoot}`]: {
        display: 'none',
      },
    },
  ({ collapse, theme }) =>
    !collapse && {
      [`.${sideBarClasses.root}:hover`]: {
        width: theme.spacing(SIDE_BAR_WIDTH.EXPANDED),
        [`.${sideBarClasses.collapseBtnRootOuter}`]: {
          display: 'none',
        },
        [`.${sideBarClasses.collapseBtnRootInsider}`]: {
          display: 'block',
        },
        [`.${sideBarClasses.itemRoot}`]: {
          display: 'flex',
          justifyContent: 'start',
          paddingLeft: theme.spacing(3),
          [`.${sideBarClasses.itemLabel}`]: {
            display: 'block',
          },
        },
        [`.${sideBarClasses.collapseTextRoot}`]: {
          display: 'block',
        },
        [`.${sideBarClasses.helpRoot}`]: {
          display: 'block',
          // TODO: continue
          [`.${sideBarClasses.helpInner}`]: {
            border: `thin solid ${theme.palette.primary.main}`,
            '.helper-text': {
              display: 'block',
            },
            '.help-button-expand': {
              display: 'flex',
            },
            '.help-button-normal': {
              display: 'none',
            },
          },
        },
      },
    },
  ({}) => ({}),
  ({}) => ({})
);

export const SidebarItem = styled(NavLink)(({ theme }) => ({
  height: theme.spacing(5),
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  position: 'relative',
  [`.${sideBarClasses.itemLabel}`]: {
    color: theme.palette.text.primary,
    display: 'none',
  },
  '&:hover': {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    [`.${sideBarClasses.itemLabel}`]: {
      color: theme.palette.primary.contrastText,
    },
  },
  '&.active': {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    [`.${sideBarClasses.itemLabel}`]: {
      color: theme.palette.primary.contrastText,
    },
    //   > .indicator {
    //     display: none;
    //     position: absolute;
    //     right: 0;
    //     top: 50%;
    //     transform: translateY(-50%);
    //   }
    //   &.active > .indicator {
    //     display: block;
    //   }
  },
}));

export const HelperContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(5, 2, 1, 2),
  backgroundColor: theme.palette.background.paper,
  textAlign: 'center',
  [`.${sideBarClasses.helpInner}`]: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(5, 0, 1, 0),
    '.helper-text': {
      display: 'none',
    },
    '.rocket-icon': {
      position: 'absolute',
      top: 0,
      width: theme.spacing(ICON_WIDTH),
      transform: `translateY(${theme.spacing(-3)})`,
    },
    '.help-button': {
      height: theme.spacing(6),
    },
    '.help-button-expand': {
      display: 'none',
      padding: theme.spacing(0, 1),
      borderRadius: theme.shape.borderRadius,
      border: `thin solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

export const CollapseButton = styled(ButtonBase, {
  shouldForwardProp(propName: PropertyKey) {
    return propName !== 'floating';
  },
})<{ floating?: boolean }>(
  ({ theme, floating }) =>
    !floating && {
      textAlign: 'right',
      padding: theme.spacing(0.5),
    },
  ({ theme, floating }) =>
    floating && {
      position: 'absolute',
      left: '100%',
      top: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2.5, 0.5),
      boxShadow: '1px 1px 1px 0px rgba(0,0,0,0.2)',
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    }
);
