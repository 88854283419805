import { styled } from '@mui/material';

export const Wrapper = styled('div', {
  shouldForwardProp: (name) => name !== 'open',
})(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 9999,
  cursor: 'crosshair',
  backgroundColor: 'rgb(0 0 0 / 50%)',
  borderRadius: `${theme.shape.borderRadius}px`,
  transition: 'background-color 200ms',
  '.placeholder-text': {
    color: 'white',
    marginTop: '100px',
    textAlign: 'center',
  },
  '&:hover': {
    backgroundColor: 'transparent',
    '.placeholder-text': {
      display: 'none',
    },
  },
}));
