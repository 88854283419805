import { styled } from '@mui/material';
import { max, min } from 'lodash-es';

import { calculateRectImportantPoints } from '~utils/geometry';

import { AlignmentLineProps } from './alignment-line';

import { StyledLine } from '../../alignment-objects.styled';

export const StyledAlignmentLine = styled(StyledLine, {
  shouldForwardProp(propName: string) {
    return ![
      'axis',
      'refRect',
      'currentRect',
      'refPlacement',
      'currentPlacement',
      'snapDistance',
    ].includes(propName);
  },
})<AlignmentLineProps>(({ axis, refRect, currentRect, refPlacement }) => {
  const refRectPoints = calculateRectImportantPoints(refRect);
  const currentRectPoints = calculateRectImportantPoints(currentRect);
  const positionPropName = axis === 'x' ? 'left' : 'top';
  const positionPropValue = refRectPoints[axis][refPlacement];

  const crossedAxis = axis === 'x' ? 'y' : 'x';
  const startPropName = axis === 'x' ? 'top' : 'left';
  const startPropValue =
    min([refRectPoints[crossedAxis]['start'], currentRectPoints[crossedAxis]['start']]) ?? 0;

  const lengthPropName = axis === 'x' ? 'height' : 'width';
  const lengthPropValue =
    (max([refRectPoints[crossedAxis]['end'], currentRectPoints[crossedAxis]['end']]) ?? 0) -
    startPropValue;

  const withPropName = axis === 'x' ? 'width' : 'height';

  return {
    [startPropName]: startPropValue,
    [lengthPropName]: lengthPropValue,
    [withPropName]: 1,
    [positionPropName]: positionPropValue,
  };
});
