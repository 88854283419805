import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme as MuiTheme,
} from '@mui/material';

interface MuiDialogTitleComponentType {
  styleOverrides?: ComponentsOverrides<MuiTheme>['MuiDialogTitle'];
  defaultProps?: ComponentsProps['MuiDialogTitle'];
  variants?: ComponentsVariants['MuiDialogTitle'];
}

export const MuiDialogTitleComponent: MuiDialogTitleComponentType = {
  variants: [],
  defaultProps: {
    variant: 'h6',
    fontWeight: 'bold',
  },
  styleOverrides: {},
};
