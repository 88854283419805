import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CheckboxProps, TextFieldProps } from '@mui/material';

import { useLoginMutation } from '~hooks/auth';
import { useSearchParams } from '~libs/router';
import { registerRequest } from '~services/v1/auth';

interface RegisterValues {
  name: string;
  email: string;
  password: string;
  checkedPolicy: boolean;
}

interface RegisterInputs {
  name: TextFieldProps;
  email: TextFieldProps;
  password: TextFieldProps;
  checkedPolicy: CheckboxProps & { error: boolean; helperText: string | undefined };
}

export default function useRegisterForm() {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { name, email } = useSearchParams(['name', 'email']);

  const loginMutation = useLoginMutation({
    onSuccess() {
      navigate(
        {
          pathname: '/onboarding',
          search: location.search,
        },
        {
          replace: true,
        }
      );
    },
  });

  const {
    getValues,
    setError,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterValues>({
    defaultValues: {
      name: name ?? '',
      email: email ?? '',
      password: '',
      checkedPolicy: false,
    },
  });

  const onSubmit = useMemo(
    () =>
      handleSubmit((data: { name: string; email: string; password: string }) => {
        setLoading(true);
        const submitData = {
          ...data,
          name: data.name,
        };

        registerRequest(submitData)
          .then((result: any) => {
            // Check if result is an error
            if (result && result.stack && result.message) {
              setError('email', {
                message:
                  result?.response?.data?.msg ||
                  'Internal error! Please try again or contact support!',
              });
            } else {
              return loginMutation.mutateAsync(data);
            }
          })
          .catch((e: any) => {
            setError('email', {
              message:
                e?.response?.data?.msg || 'Internal error! Please try again or contact support!',
            });
          })
          .finally(() => setLoading(false));
      }),
    [handleSubmit, setError, loginMutation]
  );

  const inputs: RegisterInputs = {
    name: {
      ...register('name', {
        required: t('COMMON.FIELD_IS_REQUIRED'),
      }),
      type: 'text',
      size: 'small',
      error: !!errors.name,
      helperText: errors.name?.message,
      fullWidth: true,
      placeholder: t('COMMON.FULL_NAME'),
    },
    email: {
      ...register('email', {
        required: t('COMMON.FIELD_IS_REQUIRED'),
        pattern: { value: /^\S+@\S+$/i, message: t('COMMON.INVALID_EMAIL') },
      }),
      type: 'text',
      size: 'small',
      error: !!errors.email,
      helperText: errors.email?.message,
      fullWidth: true,
      // label: t('COMMON.EMAIL'),
      placeholder: t('COMMON.EMAIL'),
    },
    password: {
      ...register('password', {
        required: t('COMMON.FIELD_IS_REQUIRED'),
        minLength: {
          value: 8,
          message: t('REGISTER.SHORT_PASSWORD'),
        },
      }),
      size: 'small',
      error: !!errors.password,
      helperText: errors.password?.message,
      fullWidth: true,
      type: 'password',
      // label: t('COMMON.PASSWORD'),
      placeholder: t('COMMON.PASSWORD'),
    },
    checkedPolicy: {
      ...register('checkedPolicy', { required: t('COMMON.ACCEPT_POLICY') }),
      color: 'primary',
      error: !!errors.checkedPolicy,
      helperText: errors.checkedPolicy?.message,
    },
  };

  return {
    getValues,
    onSubmit,
    inputs,
    loading,
  };
}
