import { useEffect, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { useHistory } from './use-history';

export function useSearchParams<T extends string>(
  _keys: Array<T | null> | ReadonlyArray<T | null>
): Record<T, string | null> {
  const history = useHistory();
  const keys = _keys.filter((key): key is T => key !== null) as T[];
  const rerializedKeys = JSON.stringify(keys);

  const [state, setState] = useState<Record<T, string | null>>(() => {
    const searchParams = new URLSearchParams(history.location.search);
    return Object.fromEntries(keys.map((key) => [key, searchParams.get(key)])) as Record<
      T,
      string | null
    >;
  });
  const prevState = useRef(state);
  prevState.current = state;

  const keysRef = useRef(keys);
  keysRef.current = keys;

  useEffect(() => {
    return history.listen(({ location }) => {
      const searchParams = new URLSearchParams(location.search);
      const newState = Object.fromEntries(
        keysRef.current.map((key) => [key, searchParams.get(key)])
      ) as Record<T, string | null>;
      if (!shallow(prevState.current, newState)) {
        setState(newState);
      }
    });
  }, [history]);

  useEffect(() => {
    const searchParamsKeys = JSON.parse(rerializedKeys) as string[];
    const searchParams = new URLSearchParams(location.search);
    const newState = Object.fromEntries(
      searchParamsKeys.map((key) => [key, searchParams.get(key)])
    ) as Record<T, string | null>;
    if (!shallow(prevState.current, newState)) {
      setState(newState);
    }
  }, [rerializedKeys]);

  return state;
}
