import React from 'react';

import { SortOption } from '~utils/array';
import { Operator } from '~utils/math';

import { HorizontalAlign, TableColumn, VerticalAlign } from '../types';

import { DECIMAL_PRECISION_DEFAULT, GenericChartConfig, GenericChartElementEntity } from '.';

// Table ==========================
export type AffectArea =
  | {
      type: 'row';
    }
  | {
      type: 'column';
      column: string;
    };

export interface ScalePoint {
  type: 'number' | 'percent';
  value: number;
  color: string;
}

export type ColorScale = ScalePoint[];

export interface TableSingleConditionalFormatRule {
  refField?: string;
  field: string;
  op: Operator;
  value: string;
}

export interface TableColorScaleConditionalFormat {
  id: number;
  type: 'scale';
  field: string;
  affectArea: AffectArea;
  colorScale: ColorScale;
}

export interface TableStyle {
  color: string;
  backgroundColor: string;
}

export interface TableSingleConditionalFormat {
  id: number;
  type: 'single';
  rules: {
    or: TableSingleConditionalFormatRule[];
  }[];
  affectArea: AffectArea;
  style: Partial<TableStyle>;
}

export type TableConditionalFormat =
  | TableColorScaleConditionalFormat
  | TableSingleConditionalFormat;

export interface TableChartSpecialConfig {
  columns?: TableColumn[];
  headerAlign?: HorizontalAlign;
  headerVerticalAlign?: VerticalAlign;
  /**
   * @deprecated consider to remove or keep in the future
   */
  headerStyle?: React.CSSProperties;
  dense?: boolean;
  rowsPerPage?: number;
  lineClamp?: number;
  freezeColumns?: number;
  freezeRows?: number;
  hidePagination?: boolean;
  hideSorting?: boolean;
  showToolBar?: boolean;
  hideHeader?: boolean;
  /**
   * @deprecated consider to remove or keep in the future
   */
  multipleSort?: boolean;
  defaultSort?: SortOption;
  conditionalFormat?: TableConditionalFormat[];
}

export const COLUMN_VALUE_COLOR_DEFAULT = 'red';

export const defaultTableChartConfig = (): TableChartSpecialConfig => ({
  dense: false,
  rowsPerPage: 100,
  lineClamp: 1,
  headerVerticalAlign: 'center',
  headerAlign: 'left',
});

export const isConfigColor = (value?: TableColumn['displayType']) =>
  value !== 'number' && value !== 'string' && value !== 'link';

export const COLUMN_FONT_SIZE_DENSE = 12;

export const defaultColumnConfigInit = (
  displayType = 'string' as TableColumn['displayType']
): Omit<TableColumn, 'id' | 'field'> => ({
  displayType: displayType,
  align: displayType === 'number' ? 'right' : 'left',
  verticalAlign: 'center',
  color: COLUMN_VALUE_COLOR_DEFAULT,
  width: 0.2,
  showNumber: true,
  compactNumber: true,
  decimalPrecision: DECIMAL_PRECISION_DEFAULT,
});

export const defaultColumnStyleConfig = ({ dense }: { dense?: boolean }): React.CSSProperties => ({
  fontFamily: 'Roboto',
  fontSize: dense ? COLUMN_FONT_SIZE_DENSE : 13,
  fontWeight: 'normal',
  fontStyle: 'normal',
  color: '#000',
  backgroundColor: 'rgba(0,0,0,0)',
});

export interface TableChartConfig extends GenericChartConfig<'table'>, TableChartSpecialConfig {}

export interface TableChartElementEntity extends GenericChartElementEntity<TableChartConfig> {}
