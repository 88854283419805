import { Box, styled } from '@mui/material';

import { isTransparent } from '~utils/color';

export const ColorBar = styled(Box)<{ color?: string }>(
  ({ theme }) => ({
    height: theme.spacing(0.5),
    width: theme.spacing(3),
  }),
  ({ color }) =>
    color && {
      backgroundColor: color,
    },
  ({ color, theme }) =>
    color &&
    isTransparent(color) && {
      backgroundSize: '100%',
      backgroundImage: `linear-gradient(to right, 
        ${theme.palette.grey[300]}, ${theme.palette.grey[300]} 20%, 
        ${theme.palette.grey[500]} 20%, ${theme.palette.grey[500]} 40%,
        ${theme.palette.grey[300]} 40%, ${theme.palette.grey[300]} 60%, 
        ${theme.palette.grey[500]} 60%, ${theme.palette.grey[500]} 80%, 
        ${theme.palette.grey[300]} 80%, ${theme.palette.grey[300]})`,
    }
);
