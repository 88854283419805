import { useDraggable } from '@dnd-kit/core';

import { BoxDragData, PixelBoxNode } from '../pixel-board.types';

export interface UsePixelBoardDraggableProps {
  node: PixelBoxNode;
  editable: boolean | undefined | number;
}

export const usePixelBoardDraggable = ({ node, editable }: UsePixelBoardDraggableProps) => {
  const data: BoxDragData = {
    context: 'pixel-board',
    nodeType: 'box',
    action: 'drag',
    node: node,
    // If we remove this, component can't be clicked
    activationDistance: 12,
  };
  return useDraggable({
    id: `drag:${node.id}`,
    disabled: !editable,
    data,
  });
};
