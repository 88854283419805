import { ThemeOptions } from '@mui/material';
// import React from 'react';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    code: React.CSSProperties;
    subcaption: React.CSSProperties;
    inputLabel: React.CSSProperties;
    inputValue: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    code?: React.CSSProperties;
    subcaption?: React.CSSProperties;
    inputLabel?: React.CSSProperties;
    inputValue?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    code: true;
    subcaption: true;
    inputLabel: true;
    inputValue: true;
  }
}

// TODO: implement, remove all comment after done
export const createTypography = (): ThemeOptions['typography'] => {
  const fonts = ['Open Sans'];
  // const headingStyle: React.CSSProperties = {
  //   fontWeight: 600,
  // };
  return {
    fontFamily: fonts.map((font) => `"${font}"`).join(', '),
    button: {
      fontWeight: 400,
      textTransform: 'none',
    },
    // h1: {
    //   ...headingStyle,
    // },
    // h2: {
    //   ...headingStyle,
    //   fontSize: 72,
    // },
    // h3: {
    //   ...headingStyle,
    // },
    // h4: {
    //   ...headingStyle,
    //   fontSize: 44,
    // },
    // h5: {
    //   ...headingStyle,
    //   fontSize: 28,
    // },
    h6: {
      fontSize: '1.15rem',
    },
    // subtitle1: {
    //   fontSize: 16,
    // },
    // subtitle2: {
    //   fontSize: 12,
    // },
    inputLabel: {
      fontSize: 10.5,
      fontWeight: 400,
      lineHeight: '22px',
    },
    inputValue: {
      fontSize: 11,
      fontWeight: 400,
      lineHeight: '22px',
    },
    subcaption: {
      fontSize: 9.5,
      fontWeight: 400,
    },
    code: {
      fontFamily: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
    },
  };
};
