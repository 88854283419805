import { promiseWrap } from './promise';

export class Debounce {
  timeout: {
    id: number;
    callback: () => void;
  } | null = null;

  constructor(
    public delay = 1000,
    private forceUpdateEvents: (keyof (DocumentEventMap & WindowEventHandlersEventMap))[] = [
      'keydown',
      'mouseleave',
      'beforeunload',
      'visibilitychange',
    ]
  ) {}

  start() {
    const stopListen = () => {
      this.forceUpdateEvents.forEach((eventName) =>
        document.removeEventListener(eventName, callback)
      );
    };
    const callback = () => {
      stopListen();
      return this.exec();
    };
    this.forceUpdateEvents.forEach((eventName) => document.addEventListener(eventName, callback));

    return stopListen;
  }

  push(action: () => unknown) {
    this.clearTimeout();
    const callback = () => {
      this.timeout = null;
      return action();
    };
    this.timeout = {
      id: window.setTimeout(callback, this.delay),
      callback,
    };
  }

  clearTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout.id);
    }
  }

  exec() {
    this.clearTimeout();
    return promiseWrap(this.timeout?.callback?.());
  }
}
