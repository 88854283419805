import { useEffect, useState } from 'react';

export function useDebounceValue<T>(value: T, timeout = 1000) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const id = window.setTimeout(() => {
      setDebouncedValue(value);
    }, timeout);
    return () => window.clearTimeout(id);
  }, [value, timeout]);
  return debouncedValue;
}
