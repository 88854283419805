import { ReactNode, useCallback, useMemo, useState } from 'react';
import { DndContext, DragEndEvent, useSensor, useSensors } from '@dnd-kit/core';
import { DragMoveEvent, type DragStartEvent } from '@dnd-kit/core/dist/types';

import { RectSize } from '~utils/geometry';

import { ElementPointerSensor } from '../../../../sensors/element-pointer.sensor';
import { PixelBoardKeyboardSensor } from '../../../../sensors/pixel-board-keyboard.sensor';
import { calculateTransformedBox } from '../../hooks/use-draggable-transformed-box';
import { PixelBoardProps } from '../../pixel-board';
import { PixelLayoutValue } from '../../pixel-board.types';
import { createSnapItemModifier, GRID_SNAP_SPACING } from '../../pixel-board.utils';
import { DragDataContext } from '../drag-data-context';
import { createDragDataStore } from '../drag-data-context/drag-data-context.store';
import { selectBoundaryRect } from '../pixel-board-context/pixel-board.selector';

export interface PixelBoardDndContextProps {
  value: PixelLayoutValue;
  onChange: undefined | ((value: PixelLayoutValue) => void);
  tmpValue: PixelLayoutValue | null;
  onChangeTmpValue: (value: PixelLayoutValue | null) => void;
  children: ReactNode;
  boardSize: RectSize;
  enableGrid?: boolean;
  onMoveStart: PixelBoardProps['onMoveStart'];
  onMoveEnd: PixelBoardProps['onMoveEnd'];
  selectedNodeIds?: string[];
}

export function PixelBoardDndContext({
  value,
  onChange,
  children,
  enableGrid,
  boardSize,
  onMoveStart,
  onMoveEnd,
  selectedNodeIds,
}: PixelBoardDndContextProps) {
  const snapModifier = useMemo(
    () => createSnapItemModifier({ value, boardSize, enableGrid, selectedNodeIds }),
    [boardSize, enableGrid, selectedNodeIds, value]
  );

  const [dataStore] = useState(createDragDataStore);

  // useEffect(() => {
  //   dataStore.subscribe((state) => {
  //     console.trace('state');
  //     console.log('state', state);
  //   });
  // }, [dataStore]);

  const pointerSensor = useSensor(ElementPointerSensor);

  const keyboardSensor = useSensor(PixelBoardKeyboardSensor, {
    step: enableGrid ? GRID_SNAP_SPACING : undefined,
  });

  const sensors = useSensors(pointerSensor, keyboardSensor);

  const handleDragEnd = useCallback(
    ({ delta: transform, active, activatorEvent }: DragEndEvent) => {
      const delta = snapModifier({
        transform,
        active,
        activatorEvent,
      });

      const boundary = selectBoundaryRect({
        value,
        selectedNodeIds: selectedNodeIds || [],
      });
      if (!boundary) {
        return;
      }
      dataStore.getState().endTransform();
      onMoveEnd?.();
      onChange?.(
        value.map((node) => {
          if (node.type === 'line' || !selectedNodeIds?.includes(node.id)) {
            return node;
          }
          return {
            ...node,
            ...calculateTransformedBox({
              boundary,
              node,
              selectedNodeIds,
              transformData: {
                delta,
                active,
              },
            }),
          };
        })
      );
    },
    [dataStore, onChange, onMoveEnd, selectedNodeIds, snapModifier, value]
  );

  const onDragStart = useCallback(
    ({ active }: DragStartEvent) => {
      dataStore.getState().initTransform({
        active,
        delta: {
          x: 0,
          y: 0,
        },
      });
      onMoveStart?.();
    },
    [dataStore, onMoveStart]
  );

  const onDragMove = useCallback(
    ({ delta, active, activatorEvent }: DragMoveEvent) => {
      dataStore.getState().updateTransform({
        delta: snapModifier({
          transform: delta,
          active,
          activatorEvent,
        }),
        active,
      });
    },
    [dataStore, snapModifier]
  );

  const onDragCancel = useCallback(() => {
    dataStore.getState().endTransform();
    onMoveEnd?.();
  }, [dataStore, onMoveEnd]);

  return (
    <DndContext
      onDragMove={onDragMove}
      onDragStart={onDragStart}
      onDragCancel={onDragCancel}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <DragDataContext.Provider value={dataStore}>{children}</DragDataContext.Provider>
    </DndContext>
  );
}
