import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme as MuiTheme,
} from '@mui/material';

export {};

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsSizeOverrides {
    xLarge: true;
    xSmall: true;
  }
  interface SvgIconPropsColorOverrides {}
  interface SvgIconClasses {
    fontSizeXSmall: string;
    fontSizeXLarge: string;
  }
}

interface MuiSvgIconComponentType {
  styleOverrides?: ComponentsOverrides<MuiTheme>['MuiSvgIcon'];
  defaultProps?: ComponentsProps['MuiSvgIcon'];
  variants?: ComponentsVariants['MuiSvgIcon'];
}

export const MuiSvgIconComponent: MuiSvgIconComponentType = {
  // variants: [],
  // defaultProps: {},
  styleOverrides: {
    // fontSizeXSmall: {
    //   fontSize: '1rem',
    // },
    // fontSizeXLarge: {
    //   fontSize: '2.5rem',
    // },
  },
};
