import {
  List as MUIList,
  ListItem as MUIListItem,
  ListItemIcon as MUIListItemIcon,
  ListItemText as MUIListItemText,
  styled,
} from '@mui/material';

import dfAvatar from '~ui/df-avatar';

export const CustomList = styled(MUIList)(({ theme }) => ({
  '& .MuiDivider-root': {
    marginLeft: `${theme.spacing(5)}`,
  },
  '& .MuiListItemIcon-root': {
    minWidth: `${theme.spacing(5)}`,
  },
}));

export const CustomListItem = styled(MUIListItem)(({ theme }) => ({
  padding: '0',
  cursor: 'pointer',
  color: `${theme.palette.text.secondary}`,
  '&:hover': {
    fontWeight: 'bold',
    color: `${theme.palette.text.primary}`,
  },
}));

export const CustomListItemIcon = styled(MUIListItemIcon)({
  color: 'inherit',
});

export const CustomListItemText = styled(MUIListItemText)(({ theme }) => ({
  margin: '0',
  color: `${theme.palette.text.primary}`,
  '& .MuiListItemText-primary': {
    fontWeight: 'inherit',
  },
}));

export const DfAvatar = styled(dfAvatar)(({ theme }) => ({
  color: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
}));
