// eslint-disable-next-line no-restricted-imports
import { create } from 'zustand';

export interface HistoryItem {
  undo: () => Promise<unknown>;
  redo: () => Promise<unknown>;
}

export class HistoryBatch {
  lastUpdated: Date;

  constructor(private items: HistoryItem[]) {
    this.lastUpdated = new Date();
  }

  public clone() {
    return new HistoryBatch(this.items);
  }

  public push(item: HistoryItem) {
    this.items.push(item);
    this.lastUpdated = new Date();
    return this;
  }

  public async undo(): Promise<void> {
    const reversedItems = [...this.items].reverse();
    for (const item of reversedItems) {
      await item.undo();
    }
  }
  public async redo(): Promise<void> {
    for (const item of this.items) {
      await item.redo();
    }
  }
}

export type HistoryState = {
  /**
   * history (old first, new last)
   */
  batches: HistoryBatch[];
  /**
   * Current step
   */
  index: number;

  reset(): void;
  push(item: HistoryItem): void;
  go(step: number): Promise<void>;
};

// Selectors
export const selectCanUndo = (state: HistoryState) => {
  return state.batches.length + state.index > 0;
};

export const selectCanRedo = (state: HistoryState) => state.index < 0;

export const selectHistoryItem = (step: number) => (state: HistoryState) => {
  if (step < 0) {
    return state.batches.at(state.batches.length + state.index + step);
  } else {
    return state.batches.at(state.batches.length + state.index + step - 1);
  }
};

// Store
export const useActionHistoryStore = create<HistoryState>((setState, getState) => ({
  index: 0,
  batches: [],

  reset: () => setState({ index: 0, batches: [] }),
  push: (item, createNewBatch = false) =>
    setState(({ batches: prevBatches, index }) => {
      let batches: HistoryBatch[] = prevBatches;
      if (index < 0) {
        batches = prevBatches.slice(0, index);
      }
      const lastBatch = batches.at(-1);
      if (!lastBatch || createNewBatch || Date.now() - lastBatch.lastUpdated.getTime() >= 1000) {
        const newBatch = new HistoryBatch([item]);
        return {
          index: 0,
          batches: [...batches, newBatch],
        };
      }
      const newBatch = lastBatch.clone().push(item);
      return {
        index: 0,
        batches: [...batches.slice(0, -1), newBatch],
      };
    }),

  /**
   * Only accept -1 or 1 now
   * 1: forward (redo)
   * -1: back (undo)
   * @param step step to go - step = -1 => undo 1, step = +1 => redo 1
   */
  async go(step: -1 | 1) {
    const action = selectHistoryItem(step)(getState());
    setState(({ index }) => ({
      index: index + step,
    }));
    if (!!action) {
      if (step < 0) {
        await action.undo();
      } else {
        await action.redo();
      }
    }
  },
}));

// Helpers
export const resetActionHistoryStore = () => useActionHistoryStore.getState().reset();
export const pushActionHistoryItem = (item: HistoryItem) =>
  useActionHistoryStore.getState().push(item);
export const actionHistoryGo = (step: -1 | 1) => useActionHistoryStore.getState().go(step);

export const useCanUndoRedoAction = () =>
  useActionHistoryStore((state) => [selectCanUndo(state), selectCanRedo(state)]);
