import { Button, styled } from '@mui/material';

export const LabelButton = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.inputLabel.fontSize,
  marginRight: theme.spacing(-1),
  minWidth: 0,
  padding: theme.spacing(0, 1),
  borderRadius: theme.spacing(0.5),
}));

LabelButton.defaultProps = {
  size: 'small',
};
