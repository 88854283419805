import { SetStateAction } from 'react';
import deepmerge, { Options } from 'deepmerge';

import {
  GridBoardDefaultProps,
  GridLayoutValue,
  PixelLayoutValue,
} from '~components/element-board';
import { selectPageSetting } from '~providers/dashboard-provider/selectors/page.selector';
import { useDashboardSearchItemsQuery } from '~services/v1/dashboard/dashboard.query';
import {
  defaultPageSettingsEntity,
  PageEntity,
  PageRenderMode,
  PageSettingEntity,
} from '~services/v1/page';
import {
  calculateGridLayoutFromPixel,
  calculatePixelLayoutFromGrid,
} from '~services/v1/page/page.utils';
import { EntityUuid } from '~services/v1/types';
import { pushActionHistoryItem } from '~stores/action-history.store';
import { replaceItem } from '~utils/array';
import { applyUpdater } from '~utils/chore';
import { Debounce } from '~utils/debounce';
import { arrayReplaceStrategy } from '~utils/deepmerge';

import { queryClient } from '../../../react-query';
import pageService from '../../../services/v1/page/page.service';
import { DashboardStore, dispatchDashboardAction } from '../store/dashboard-store';

const mergeOptions: Options = {
  arrayMerge: arrayReplaceStrategy,
};

export const setPageStateAction = (page: PageEntity) => (store: DashboardStore) =>
  store.setState(({ pages, activePage }) => ({
    pages: replaceItem(pages, (item) => item.page_id === page?.page_id, page),
    activePage: page.page_id === page?.page_id ? { ...activePage, ...page } : activePage,
  }));

export const addPageStateAction = (page: PageEntity) => (store: DashboardStore) =>
  store.setState(({ pages }) => ({ pages: [...pages, page] }));

export const removePageStateAction = (pageId: EntityUuid) => (store: DashboardStore) =>
  store.setState(({ pages }) => ({
    pages: pages.filter((page) => page.page_id !== pageId),
  }));

export const createPageAction =
  (properties: Partial<PageEntity>, saveHistory = true) =>
  async (store: DashboardStore) => {
    const dashboardId = store.getState()?.dashboard?.document_uuid;
    const defaultPageSettings = defaultPageSettingsEntity();
    const page = await pageService.insertOneRequest({
      ...properties,
      page_name: properties.page_name || `Page ${store.getState().pages.length + 1}`,
      page_setting: {
        ...defaultPageSettings,
        ...properties.page_setting,
      } as PageSettingEntity,
      document_uuid: dashboardId,
    });

    queryClient.invalidateQueries({ queryKey: useDashboardSearchItemsQuery.getKey() });

    dispatchDashboardAction(store, addPageStateAction(page));
    {
      if (saveHistory) {
        pushActionHistoryItem({
          redo() {
            return dispatchDashboardAction(store, restorePageAction(page.page_id, false));
          },
          undo() {
            return dispatchDashboardAction(store, removePageAction(page.page_id, false));
          },
        });
      }
      return page;
    }
  };

export const duplicatePageAction =
  (pageId: EntityUuid, saveHistory = true) =>
  async (store: DashboardStore) => {
    const page = await pageService.duplicatePage(pageId);
    queryClient.invalidateQueries({ queryKey: useDashboardSearchItemsQuery.getKey() });
    dispatchDashboardAction(store, addPageStateAction(page));

    if (saveHistory) {
      pushActionHistoryItem({
        redo() {
          return dispatchDashboardAction(store, restorePageAction(page.page_id, false));
        },
        undo() {
          return dispatchDashboardAction(store, removePageAction(page.page_id, false));
        },
      });
    }
    return page;
  };

const pageUpdateDebounce = new Debounce();
pageUpdateDebounce.start();

export const updatePageAction =
  (pageId: EntityUuid, properties: Partial<PageEntity>, saveHistory = true) =>
  async (store: DashboardStore) => {
    const state = store.getState();
    const currentPageId = state.activePage?.page_id;
    const page = state?.pages?.find((page) => page.page_id === pageId);

    if (!page) {
      return;
    }

    if (
      (properties.page_grid_layout || properties.page_pixel_layout) &&
      properties.page_layout_dirty === undefined
    ) {
      properties.page_layout_dirty = true;
    }

    const updatedPage = deepmerge(page, properties, mergeOptions);

    store.setState({
      activePage: currentPageId === pageId ? updatedPage : state.activePage,
    });

    dispatchDashboardAction(store, setPageStateAction(updatedPage));

    if (saveHistory) {
      pushActionHistoryItem({
        redo() {
          return dispatchDashboardAction(store, updatePageAction(pageId, updatedPage, false));
        },
        undo() {
          return dispatchDashboardAction(store, updatePageAction(pageId, page, false));
        },
      });
    }
    if (currentPageId === pageId) {
      pageUpdateDebounce.push(() => {
        pageService
          .updateOneRequest(pageId, properties)
          .then(() => {
            queryClient.invalidateQueries({ queryKey: useDashboardSearchItemsQuery.getKey() });
          })
          .catch(() => {
            store.setState({
              error: true,
            });
          });
      });
    } else {
      await pageService.updateOneRequest(pageId, properties);
    }
    return updatedPage;
  };

export const removePageAction =
  (pageId: EntityUuid, saveHistory = true) =>
  async (store: DashboardStore) => {
    await pageService.deleteOneRequest(pageId);
    queryClient.invalidateQueries({ queryKey: useDashboardSearchItemsQuery.getKey() });
    dispatchDashboardAction(store, removePageStateAction(pageId));

    if (saveHistory) {
      pushActionHistoryItem({
        redo() {
          return dispatchDashboardAction(store, removePageAction(pageId, false));
        },
        undo() {
          return dispatchDashboardAction(store, restorePageAction(pageId, false));
        },
      });
    }
    return true;
  };

export const restorePageAction =
  (pageId: EntityUuid, saveHistory = true) =>
  async (store: DashboardStore) => {
    const page = await pageService.restoreOneRequest(pageId);
    dispatchDashboardAction(store, addPageStateAction(page));

    if (saveHistory) {
      pushActionHistoryItem({
        redo() {
          return dispatchDashboardAction(store, restorePageAction(pageId, false));
        },
        undo() {
          return dispatchDashboardAction(store, removePageAction(pageId, false));
        },
      });
    }
    return page;
  };

export const updatePagesPositionAction =
  (pagesOrder: EntityUuid[]) => async (store: DashboardStore) => {
    store.setState((state) => ({
      pages: state.pages.map((page) => ({
        ...page,
        page_position: pagesOrder.indexOf(page.page_id) + 1,
      })),
    }));
    const pages = await pageService.updatePagesPosition(pagesOrder);
    store.setState({ pages });
    return pages;
  };

export const updateActivePageAction =
  (properties: Partial<PageEntity>) => async (store: DashboardStore) => {
    const pageId = store.getState()?.activePage?.page_id;
    if (pageId) {
      return dispatchDashboardAction(store, updatePageAction(pageId, properties));
    }
    return null;
  };

export const updatePageGridLayoutAction =
  (pageId: string, updater: SetStateAction<GridLayoutValue>) => async (store: DashboardStore) => {
    const page = store.getState()?.pages.find((page) => page.page_id === pageId);
    if (!page) {
      return;
    }
    return dispatchDashboardAction(
      store,
      updatePageAction(pageId, {
        page_grid_layout: applyUpdater(updater, page?.page_grid_layout ?? { rows: [] }),
      })
    );
  };

export const updateActivePageGridLayoutAction =
  (updater: SetStateAction<GridLayoutValue>) => async (store: DashboardStore) => {
    const pageId = store.getState()?.activePage?.page_id;
    return pageId && dispatchDashboardAction(store, updatePageGridLayoutAction(pageId, updater));
  };

export const updatePagePixelLayoutAction =
  (pageId: EntityUuid, updater: SetStateAction<PixelLayoutValue>) =>
  async (store: DashboardStore) => {
    const page = store.getState()?.pages.find((page) => page.page_id === pageId);
    if (!page) {
      return;
    }
    return dispatchDashboardAction(
      store,
      updatePageAction(pageId, {
        page_pixel_layout: applyUpdater(updater, page?.page_pixel_layout ?? []),
      })
    );
  };

export const updateActivePagePixelLayoutAction =
  (updater: SetStateAction<PixelLayoutValue>) => async (store: DashboardStore) => {
    const pageId = store.getState()?.activePage?.page_id;
    return pageId && dispatchDashboardAction(store, updatePagePixelLayoutAction(pageId, updater));
  };

export const updatePageRenderModeAction =
  (pageId: EntityUuid, newRenderMode: PageRenderMode) => async (store: DashboardStore) => {
    const page = store.getState()?.pages.find((page) => page.page_id === pageId);
    if (!page || page.page_render_mode === newRenderMode) {
      return;
    }
    if (page.page_layout_dirty || !page.page_pixel_layout || !page.page_grid_layout) {
      switch (newRenderMode) {
        case 'grid':
          return dispatchDashboardAction(
            store,
            updatePageAction(page.page_id, {
              page_layout_dirty: false,
              page_render_mode: newRenderMode,
              page_grid_layout:
                page.page_pixel_layout && calculateGridLayoutFromPixel(page.page_pixel_layout),
            })
          );
        default:
          const pageSetting = selectPageSetting(store.getState());
          return dispatchDashboardAction(
            store,
            updatePageAction(page.page_id, {
              page_render_mode: newRenderMode,
              page_layout_dirty: false,
              page_pixel_layout:
                page.page_grid_layout &&
                calculatePixelLayoutFromGrid(
                  page.page_grid_layout,
                  pageSetting.width,
                  GridBoardDefaultProps.spacing
                ),
            })
          );
      }
    }
    await dispatchDashboardAction(
      store,
      updatePageAction(page.page_id, {
        page_render_mode: newRenderMode,
        page_layout_dirty: true,
      })
    );
    return null;
  };

export const updateActivePageRenderModeAction =
  (newRenderMode: PageRenderMode) => async (store: DashboardStore) => {
    const pageId = store.getState()?.activePage?.page_id;
    return (
      pageId && dispatchDashboardAction(store, updatePageRenderModeAction(pageId, newRenderMode))
    );
  };
