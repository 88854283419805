import { createContext } from 'react';
import { History } from 'history';
import { StoreApi } from 'zustand';

import { history } from '~libs/router';

import { createEditorContextValue, EditorMemoryState } from './editor-provider.state';

export interface EditorContextValue {
  history: History;
  memoryStore: StoreApi<EditorMemoryState>;
}

export const EditorContext = createContext<EditorContextValue>(createEditorContextValue(history));

export const EditorProvider = EditorContext.Provider;
