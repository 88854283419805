import generateUtilityClasses from '@mui/utils/generateUtilityClasses';

export const SIDE_BAR_WIDTH = {
  COLLAPSED: 2,
  NORMAL: 6,
  EXPANDED: 25,
};

export const ICON_WIDTH = 5;

export const sideBarClasses = generateUtilityClasses('DFSidebar', [
  'root',
  'itemRoot',
  'itemLabel',
  'itemIcon',
  'helpRoot',
  'helpInner',
  'collapseBtnRootOuter',
  'collapseBtnRootInsider',
  'collapseTextRoot',
]);
