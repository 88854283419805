import { Stack, styled } from '@mui/material';

export interface ExternalProps {
  // addable: boolean;
  // visible: boolean;
  placement: 'left' | 'right' | 'bottom';
}

export const Wrapper = styled(Stack, {
  shouldForwardProp: (propName) =>
    propName !== 'placement' && propName !== 'visible' && propName !== 'addable',
})<ExternalProps>(
  ({ theme }) => ({
    color: theme.palette.primary.main,
    padding: theme.spacing(1, 0),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.spacing(1),
    cursor: 'crosshair',
    ':hover': {
      backgroundColor: theme.palette.primary.lighter,
    },
  }),
  ({ theme, placement }) =>
    placement === 'bottom' && {
      width: '100%',
      border: `thin dashed ${theme.palette.primary.main}`,
    },
  ({ theme, placement }) =>
    (placement === 'left' || placement === 'right') && {
      width: 42,
      position: 'absolute',
      ':hover': {
        border: `thin dashed ${theme.palette.primary.main}`,
      },
    },
  ({ placement }) =>
    placement === 'left' && {
      left: -50,
    },
  ({ placement }) =>
    placement === 'right' && {
      right: -50,
    }
  // ({ visible }) =>
  //   !visible && {
  //     opacity: 0,
  //     cursor: 'default',
  //   }
);
