import { max, sumBy } from 'lodash-es';

import { arrayMove, replaceIndex } from '~utils/array';
import { timeId } from '~utils/chore';
import { stepRound } from '~utils/math';

import { BaseNode, CopiedNodeData, NodeData } from '../../element-board.types';

import { GridLayoutValue, GridRow, RowNode } from './grid-board';
import { getRowByNodeId } from './grid-board.getter';
import { ResizableData } from './grid-board.type';

export const removeEmptyRows = (prevValue: GridLayoutValue): GridLayoutValue => ({
  ...prevValue,
  rows: prevValue.rows.filter((row) => row.items.length > 0),
});
export const addNodeToNewRow =
  ({
    id,
    rowId,
    data,
    height,
    rowIndex,
  }: {
    id: string;
    rowId: string;
    data: NodeData;
    height: number;
    rowIndex?: number;
  }) =>
  (prevValue: GridLayoutValue) => {
    const newRow: GridRow = {
      id: rowId,
      height,
      items: [
        {
          id,
          width: 100,
          data,
        },
      ],
    };

    const newRows = [...(prevValue?.rows || [])];
    newRows.splice(rowIndex ?? newRows.length, 0, newRow);

    return {
      rows: newRows,
    };
  };

export function getNewCellWidth(currentCellLength = 0) {
  return 100 / (currentCellLength + 1);
}

export function recalculateAddCell({
  rowIndex,
  pageRow,
}: {
  rowIndex: number;
  pageRow: GridLayoutValue;
}) {
  const currentRow = pageRow?.rows[rowIndex];
  const newCellsWidth = getNewCellWidth(currentRow?.items.length);

  const newOthersCellsWidth = 100 - newCellsWidth;

  return currentRow?.items.map((cell) => ({
    ...cell,
    width: (cell.width / 100) * newOthersCellsWidth,
  }));
}

export const addNodeToExistedRow =
  ({
    id,
    data,
    rowIndex,
    position,
  }: {
    id: string;
    data: NodeData;
    rowIndex: number;
    position: 'right' | 'left' | number;
  }) =>
  (prevValue: GridLayoutValue) => {
    const addableRow = prevValue.rows[rowIndex];
    const recalculateCurrentRowItems = recalculateAddCell({ rowIndex, pageRow: prevValue });
    let cellIndex: number;
    switch (position) {
      case 'left':
        cellIndex = 0;
        break;
      case 'right':
        cellIndex = addableRow.items.length;
        break;
      default:
        cellIndex = position;
        break;
    }
    const newCell: RowNode = {
      id,
      data,
      width: getNewCellWidth(addableRow.items.length),
    };
    recalculateCurrentRowItems.splice(cellIndex, 0, newCell);

    const newRow: GridRow = {
      ...prevValue.rows[rowIndex],
      items: recalculateCurrentRowItems,
    };

    return {
      rows: replaceIndex(prevValue.rows, rowIndex, newRow),
    };
  };

interface MovePositionData {
  rowIdx: number;
  cellIdx: number;
}

export const removeNodeFromRow = ({
  row,
  index,
}: {
  row: GridRow;
  index: number;
}): { removedNode: RowNode | null; row: GridRow } => {
  let rowItems = row.items.slice();
  const [removedNode] = rowItems.splice(index, 1);
  if (!removedNode) {
    return { removedNode, row };
  }
  rowItems = rowItems.map(({ width, ...node }) => ({
    ...node,
    width: width + removedNode.width / rowItems.length,
  }));
  return {
    removedNode,
    row: {
      ...row,
      items: rowItems,
    },
  };
};

export const addNodeToRow = ({
  row,
  node,
  index,
}: {
  row: GridRow;
  node: RowNode;
  index: number;
}): { row: GridRow } => {
  const rowItems = row.items.slice();
  rowItems.splice(index, 0, node);

  return {
    row: {
      ...row,
      items: rowItems.map((item) => ({
        ...item,
        width: 100 / rowItems.length,
      })),
    },
  };
};

export const transferNodeToOtherRow = ({
  sourceRow,
  targetRow,
  sourceCellIdx,
  targetCellIdx,
}: {
  sourceRow: GridRow;
  targetRow: GridRow;
  sourceCellIdx: number;
  targetCellIdx: number;
}) => {
  // Remove index from row
  const { removedNode, row: transferredSourceRow } = removeNodeFromRow({
    row: sourceRow,
    index: sourceCellIdx,
  });

  if (!removedNode) {
    return {
      transferredSourceRow: sourceRow,
      transferredTargetRow: targetRow,
    };
  }

  const { row: transferredTargetRow } = addNodeToRow({
    row: targetRow,
    node: removedNode,
    index: targetCellIdx,
  });

  return {
    transferredSourceRow,
    transferredTargetRow,
  };
};

export const moveNodeToNewRow =
  ({ node, insertRowIdx }: { node: RowNode; insertRowIdx: number }) =>
  (prev: GridLayoutValue) => {
    const rows = prev.rows.slice();
    const { row: sourceRow, nodeIndex: removeIndex } = getRowByNodeId(node.id, {
      rows,
    });
    if (!sourceRow || removeIndex === undefined) {
      return prev;
    }
    const { removedNode, row: transferredSourceRow } = removeNodeFromRow({
      row: sourceRow,
      index: removeIndex,
    });
    if (!removedNode) {
      return prev;
    }
    rows.splice(insertRowIdx, 0, {
      id: timeId(),
      height: sourceRow.height,
      items: [
        {
          ...node,
          width: 100,
        },
      ],
    });
    return removeEmptyRows({
      ...prev,
      rows: rows.map((row) => {
        if (row === sourceRow) {
          return transferredSourceRow;
        }
        return row;
      }),
    });
  };

export const moveGridNode = ({
  node,
  newPosition,
}: {
  node: BaseNode;
  newPosition: MovePositionData;
}) => {
  return (prev: GridLayoutValue): GridLayoutValue => {
    const { row: sourceRow, nodeIndex: sourceCellIdx } = getRowByNodeId(node.id, prev);
    const targetRow = prev.rows.at(newPosition.rowIdx);
    if (!sourceRow || !targetRow || sourceCellIdx === undefined) {
      return prev;
    }
    let transferredSourceRow: GridRow;
    let transferredTargetRow: GridRow;
    if (sourceRow === targetRow) {
      transferredSourceRow = transferredTargetRow = {
        ...sourceRow,
        items: arrayMove(sourceRow.items, sourceCellIdx, newPosition.cellIdx),
      };
    } else {
      ({ transferredSourceRow, transferredTargetRow } = transferNodeToOtherRow({
        sourceRow,
        targetRow,
        sourceCellIdx,
        targetCellIdx: newPosition.cellIdx,
      }));
    }
    const value: GridLayoutValue = {
      ...prev,
      rows: prev.rows.map((row) => {
        switch (row) {
          case sourceRow:
            return transferredSourceRow;
          case targetRow:
            return transferredTargetRow;
          default:
            return row;
        }
      }),
    };
    return removeEmptyRows(value);
  };
};

export interface ResizeColumnArgs extends Pick<ResizableData, 'rowIndex' | 'colIndex'> {
  percentDelta: number;
  percentRoundStep: number;
  minWidth: number;
}

export const resizeColumn =
  ({ rowIndex, colIndex, percentDelta, percentRoundStep, minWidth }: ResizeColumnArgs) =>
  (prev: GridLayoutValue): GridLayoutValue => {
    return {
      rows: prev.rows.map((row, rIndex) => {
        if (rIndex !== rowIndex) {
          return row;
        }
        let deltaBefore = 0;
        const nodeBefore = row.items.at(colIndex - 1);
        const nodeAfter = row.items.at(colIndex);

        if (!nodeBefore || !nodeAfter) {
          return row;
        }

        let newWidthBefore = stepRound(nodeBefore.width + percentDelta, percentRoundStep);
        newWidthBefore = max([newWidthBefore, minWidth]) ?? 0;
        deltaBefore = nodeBefore.width - newWidthBefore;
        let newWidthAfter = nodeAfter.width + deltaBefore;

        if (newWidthAfter < minWidth) {
          newWidthBefore -= minWidth - newWidthAfter;
          newWidthAfter = minWidth;
        }

        if (newWidthBefore < minWidth) {
          return row;
        }

        const items = row.items.map((node) => {
          if (node === nodeBefore) {
            return {
              ...node,
              width: newWidthBefore,
            };
          }
          if (node === nodeAfter) {
            return {
              ...node,
              width: newWidthAfter,
            };
          }
          return node;
        });
        return {
          ...row,
          items,
        };
      }),
    };
  };

export interface ResizeRowArgs extends Pick<ResizableData, 'rowIndex'> {
  pixelDelta: number;
  roundStep: number;
  minHeight: number;
}

export const resizeRow =
  ({ rowIndex, pixelDelta, roundStep, minHeight }: ResizeRowArgs) =>
  (prev: GridLayoutValue): GridLayoutValue => {
    return {
      rows: prev.rows.map((row, rIndex) =>
        rIndex === rowIndex - 1
          ? {
              ...row,
              height: max([stepRound(row.height + pixelDelta, roundStep), minHeight]) ?? 0,
            }
          : row
      ),
    };
  };

export const removeNodeFromGridLayout = (nodeIds: string[]) => (oldValue: GridLayoutValue) => {
  const newRows = oldValue.rows
    .map((row) => ({
      ...row,
      items: row.items.filter((item) => !nodeIds.includes(item.id)),
    }))
    .filter((row) => row.items.length)
    .map((row) => {
      const totalRowWidth = sumBy(row.items, (item) => item.width);

      return {
        ...row,
        items: row.items.map((item) => ({
          ...item,
          width: (item.width / totalRowWidth) * 100,
        })),
      };
    });

  return {
    rows: newRows,
  };
};

/**
 * @Nguyet should implement this
 */
export const copyElementToLayout =
  ({}: CopiedNodeData) =>
  (oldValue: GridLayoutValue): GridLayoutValue =>
    oldValue;

export const onClickNode =
  (e: React.MouseEvent, nodeId: string) => (prevSelectedNodeIds: string[]) => {
    const isSelected = prevSelectedNodeIds?.includes(nodeId);

    /**
     * handle activate elements
     */

    if (!isSelected) {
      if (e.metaKey || e.shiftKey) {
        return [...prevSelectedNodeIds, nodeId];
      } else {
        return [nodeId];
      }
    }

    /**
     * handle inactivate elements
     */
    if (e.metaKey || e.shiftKey) {
      return prevSelectedNodeIds.filter((item) => item !== nodeId);
    }

    return prevSelectedNodeIds;
  };
