import { GenericChartConfig, GenericChartElementEntity } from './chart-element';

export type MapType = 'world' | 'world-2-chars';

export const DEFAULT_GEOMAP_CHART_COLORS = ['#f00', '#00f'];
export const DEFAULT_UNKNOWN_COLOR = '#FFF';
export const DEFAULT_BORDER_COLOR = '#000';
export const DEFAULT_BORDER_WIDTH = 1;

export interface GeomapChartSpecialConfig {
  dimension?: string;
  metric?: string;
  mapType: MapType;
  borderWidth?: number;
  borderColor?: string;
  unknownColor?: string;
}

export const defaultGeomapChartConfig = (): GeomapChartSpecialConfig => ({
  mapType: 'world',
  unknownColor: DEFAULT_UNKNOWN_COLOR,
  borderWidth: DEFAULT_BORDER_WIDTH,
  borderColor: DEFAULT_BORDER_COLOR,
});

export const GEOJSON_SOURCE: Record<MapType, string> = {
  world: `${import.meta.env.BASE_URL || '/'}geojson/countries.geojson?version=1`,
  'world-2-chars': `${import.meta.env.BASE_URL || '/'}geojson/countries-2-chars.geojson?version=1`,
};

export const GEOMAP_TYPES = Object.keys(GEOJSON_SOURCE) as MapType[];

export interface GeomapChartConfig extends GenericChartConfig<'geomap'>, GeomapChartSpecialConfig {}

export interface GeomapChartElementEntity extends GenericChartElementEntity<GeomapChartConfig> {}
