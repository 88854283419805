import { Translate } from '@dnd-kit/core';
import { max, min } from 'lodash-es';

export type Center = 'center';

export type RectXEdge = 'left' | 'right';

export type RectYEdge = 'top' | 'bottom';

export type RectEdge = RectXEdge | RectYEdge;

export type RectCorner = `${RectYEdge}-${RectXEdge}`;

export type ResizeDirection = RectEdge | RectCorner;

export const RECT_LEFT_EDGE = 'left' as const;
export const RECT_RIGHT_EDGE = 'right' as const;
export const RECT_TOP_EDGE = 'top' as const;
export const RECT_COTTOM_EDGE = 'bottom' as const;
export const RECT_X_EDGES = [RECT_LEFT_EDGE, RECT_RIGHT_EDGE] as const;
export const RECT_Y_EDGES = [RECT_TOP_EDGE, RECT_COTTOM_EDGE] as const;

export const RECT_EDGES = [...RECT_X_EDGES, ...RECT_Y_EDGES] as const;

export const isXEdge = (edge: RectEdge): edge is RectXEdge =>
  RECT_X_EDGES.includes(edge as RectXEdge);

export const isYEdge = (edge: RectEdge): edge is RectYEdge =>
  RECT_Y_EDGES.includes(edge as RectYEdge);

// ---------------------------------------------------------------------

export type RectEdgePlacement = 'start' | 'end' | 'middle';

export const RECT_EDGE_PLACEMENTS: RectEdgePlacement[] = ['start', 'end', 'middle'];

export const AXES = ['x', 'y'] as const;

export interface Transform {
  translate?: Coordinate;
  scale?: Coordinate;
}

export interface RectSize {
  width: number;
  height: number;
}

export interface RectPosition extends RectSize {
  left: number;
  top: number;
}

export type RectBoundary = {
  [K in RectEdge]: number;
};

export const RECT_POSITION_KEYS = ['left', 'top', 'width', 'height'] as const;

export interface LinePosition {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

export type Axis = 'x' | 'y';

export interface Coordinate {
  x: number;
  y: number;
}

export const moveRect = (rect: RectPosition, delta: Translate) => ({
  ...rect,
  top: rect.top + delta.y,
  left: rect.left + delta.x,
});

export interface RectImportantPoints {
  x: Record<RectEdgePlacement, number>;
  y: Record<RectEdgePlacement, number>;
}

export const calculateRectImportantPoints = (rect: RectPosition): RectImportantPoints => ({
  x: {
    start: rect.left,
    middle: rect.left + rect.width / 2,
    end: rect.left + rect.width,
  },
  y: {
    start: rect.top,
    middle: rect.top + rect.height / 2,
    end: rect.top + rect.height,
  },
});

export const minimumBoundary = (): RectBoundary => ({
  top: Number.POSITIVE_INFINITY,
  bottom: Number.NEGATIVE_INFINITY,
  left: Number.POSITIVE_INFINITY,
  right: Number.NEGATIVE_INFINITY,
});

export const calculateRectPosition = (
  from: Coordinate,
  to: Coordinate,
  padding = 0
): RectPosition => {
  const orderedPosition = {
    from: {
      x: from.x < to.x ? from.x : to.x,
      y: from.y < to.y ? from.y : to.y,
    },
    to: {
      x: from.x > to.x ? from.x : to.x,
      y: from.y > to.y ? from.y : to.y,
    },
  };
  const top = orderedPosition.from.y;
  const left = orderedPosition.from.x;
  const height = orderedPosition.to.y - orderedPosition.from.y;
  const width = orderedPosition.to.x - orderedPosition.from.x;

  return {
    top: top - padding,
    left: left - padding,
    width: width + padding * 2,
    height: height + padding * 2,
  };
};

export const isNodeIntersecting = (rectA: RectPosition, rectB: RectPosition): boolean => {
  const xOverlap = max([
    0,
    (min([rectA.left + rectA.width, rectB.left + rectB.width]) ?? 0) -
      (max([rectA.left, rectB.left]) ?? 0),
  ]);
  const yOverlap = max([
    0,
    (min([rectA.top + rectA.height, rectB.top + rectB.height]) ?? 0) -
      (max([rectA.top, rectB.top]) ?? 0),
  ]);
  return Boolean(xOverlap && yOverlap);
};

export const getItemsInside = <T>(
  from: Coordinate,
  to: Coordinate,
  items: {
    position: RectPosition;
    value: T;
  }[]
): T[] => {
  const boundaryRectangle = calculateRectPosition(from, to);
  return items
    .filter(({ position }) => isNodeIntersecting(position, boundaryRectangle))
    .map(({ value }) => value);
};

export function calculateRadius(from: Coordinate, to: Coordinate): number {
  const theta = Math.atan2(to.y - from.y, to.x - from.x);
  return theta * (180 / Math.PI);
}

export const getItemsBoundary = (items: RectPosition[]): RectBoundary =>
  items.reduce(
    (boundary, item) => ({
      top: min([item.top, boundary.top]) ?? 0,
      bottom: max([item.top + item.height, boundary.bottom]) ?? 0,
      left: min([item.left, boundary.left]) ?? 0,
      right: max([item.left + item.width, boundary.right]) ?? 0,
    }),
    minimumBoundary()
  );

export const getItemsBoundaryBox = (items: RectPosition[]): RectPosition => {
  const boundary = getItemsBoundary(items);
  return {
    left: boundary.left,
    top: boundary.top,
    width: boundary.right - boundary.left,
    height: boundary.bottom - boundary.top,
  };
};

export const isEdgeDirection = (dir: ResizeDirection): dir is RectEdge =>
  RECT_EDGES.includes(dir as RectEdge);

export function applyTransform(rect: RectPosition, transform: Transform): RectPosition {
  return {
    top: rect.top + (transform.translate?.y ?? 0),
    left: rect.left + (transform.translate?.x ?? 0),
    width: rect.width * (transform.scale?.x ?? 1),
    height: rect.height * (transform.scale?.y ?? 1),
  };
}

export interface ResizeOptions {
  minWidth?: number;
  minHeight?: number;
  movementStep?: number;
}

export const resizeRect = (
  originPosition: RectPosition,
  { x, y }: Coordinate,
  direction: ResizeDirection,
  { minWidth = 0, minHeight = 0, movementStep = 1 }: ResizeOptions = {}
) => {
  x = Math.round(x / movementStep) * movementStep;
  y = Math.round(y / movementStep) * movementStep;
  const newPosition = { ...originPosition };
  const xDir = direction.includes(RECT_LEFT_EDGE) ? -1 : 1;
  const yDir = direction.includes(RECT_TOP_EDGE) ? -1 : 1;

  if (direction.includes(RECT_LEFT_EDGE) || direction.includes(RECT_RIGHT_EDGE)) {
    // eslint-disable-next-line no-restricted-properties
    newPosition.width = Math.max(Math.round(originPosition.width + x * xDir), minWidth);
  }
  if (direction.includes(RECT_TOP_EDGE) || direction.includes(RECT_COTTOM_EDGE)) {
    // eslint-disable-next-line no-restricted-properties
    newPosition.height = Math.max(Math.round(originPosition.height + y * yDir), minHeight);
  }
  if (direction.includes(RECT_LEFT_EDGE)) {
    newPosition.left = originPosition.left + x;
  }
  if (direction.includes(RECT_TOP_EDGE)) {
    newPosition.top = originPosition.top + y;
  }
  return newPosition;
};

/**
 * Calculate transform of child boxes when parent box is scaled
 */
export function calculateScaleTransform(
  childBox: RectPosition,
  parentBox: RectPosition,
  scaleDelta: Coordinate,
  direction: ResizeDirection,
  options: ResizeOptions = {}
): Transform {
  const newParentPosition = resizeRect(parentBox, scaleDelta, direction, options);
  const scale = {
    x: newParentPosition.width / parentBox.width,
    y: newParentPosition.height / parentBox.height,
  };

  const originChildParentDistance = {
    x: childBox.left - parentBox.left,
    y: childBox.top - parentBox.top,
  };

  const newChildPosition = {
    left: newParentPosition.left + originChildParentDistance.x * scale.x,
    top: newParentPosition.top + originChildParentDistance.y * scale.y,
  };

  const translate = {
    y: newChildPosition.top - childBox.top,
    x: newChildPosition.left - childBox.left,
  };

  return {
    translate,
    scale,
  };
}

export const scaleChildBox = (
  childBox: RectPosition,
  parentBox: RectPosition,
  scaleDelta: Coordinate,
  direction: ResizeDirection,
  options: ResizeOptions = {}
): RectPosition => {
  const transform = calculateScaleTransform(childBox, parentBox, scaleDelta, direction, options);
  return applyTransform(childBox, transform);
};

// export function calculateScale(
//   box: RectPosition,
//   container: RectPosition,
//   direction: ResizeDirection,
//   delta: Coordinate
// ): Scale {
//   const boxPonts = calculateRectImportantPoints(box);
//   const containerPoints = calculateRectImportantPoints(container);
//   const origins = {
//     bottom: containerPoints.y.start - boxPonts.y.start,
//     top: box.height + containerPoints.y.end - boxPonts.y.end,
//     right: containerPoints.x.start - boxPonts.x.start,
//     left: box.width + containerPoints.x.end - boxPonts.x.end,
//   };
//   const result: Scale = {
//     originX: box.width / 2,
//     originY: box.height / 2,
//     scaleX: 1,
//     scaleY: 1,
//   };
//   if (direction.includes('left')) {
//     result.originX = origins.left;
//   } else if (direction.includes('right')) {
//     result.originX = origins.right;
//   }
//   if (direction.includes('top')) {
//     result.originY = origins.top;
//   } else if (direction.includes('bottom')) {
//     result.originY = origins.bottom;
//   }
//   const newSize = resizeRect(box, { dir: direction, ...delta });
//   result.scaleX = newSize.width / box.width;
//   result.scaleY = newSize.height / box.height;
//   return result;
// }
//
// export function scaleBox(
//   box: RectPosition,
//   { originX, originY, scaleX, scaleY }: Scale
// ): RectPosition {
//   const { width, height, top, left } = box;
//   const scaledWidth = width * scaleX;
//   const scaledHeight = height * scaleY;
//   const scaledX = top - (scaledWidth - width) * originX;
//   const scaledY = left - (scaledHeight - height) * originY;
//   return {
//     left: scaledX,
//     top: scaledY,
//     width: scaledWidth,
//     height: scaledHeight,
//   };
// }

export type Point = { x: number; y: number };

// Rects relationship

export type RectRelationship = RectBiRelationship | RectTriRelationship;

export interface RectBiRelationship {
  axis: Axis;
  currentRect: RectPosition;
  refRect: RectPosition;
  currentPlacement: RectEdgePlacement;
  refPlacement: RectEdgePlacement;
  snapDistance: number;
}

export interface RectTriRelationship {
  axis: Axis;
  currentRect: RectPosition;
  startRefRect: RectPosition;
  endRefRect: RectPosition;
  currentPlacement: RectEdgePlacement;
  startRefPlacement: RectEdgePlacement;
  endRefPlacement: RectEdgePlacement;
  snapDistance: number;
  startDistance: number;
}

export const calculateBiRectRelationship = (
  currentRect: RectPosition,
  refRect: RectPosition
): RectBiRelationship[] => {
  const refRectPoints = calculateRectImportantPoints(refRect);
  const currentRectPoints = calculateRectImportantPoints(currentRect);
  return RECT_EDGE_PLACEMENTS.map((currentRectEdge) =>
    RECT_EDGE_PLACEMENTS.map((refRectEdge) =>
      AXES.map(
        (axis): RectBiRelationship => ({
          axis,
          currentRect: currentRect,
          refRect: refRect,
          currentPlacement: currentRectEdge,
          refPlacement: refRectEdge,
          snapDistance: refRectPoints[axis][refRectEdge] - currentRectPoints[axis][currentRectEdge],
        })
      )
    )
  ).flat(2);
};

const isRelationshipsInRange = (
  currentRect: RectPosition,
  refRect: RectPosition,
  axes: 'x' | 'y'
): boolean => {
  const currentTop = currentRect.top;
  const currentBottom = currentTop + currentRect.height;
  const currentLeft = currentRect.left;
  const currentRight = currentLeft + currentRect.width;

  const refTop = refRect.top;
  const refBottom = refTop + refRect.height;
  const refLeft = refRect.left;
  const refRight = refLeft + refRect.width;

  const isFromInRangeVertical = refRight >= currentLeft && refLeft <= currentRight;
  const isFromInRangeHorizontal = refBottom >= currentTop && refTop <= currentBottom;

  if (axes === 'x') {
    return isFromInRangeHorizontal && !isFromInRangeVertical;
  }

  return !isFromInRangeHorizontal && isFromInRangeVertical;
};

function isCentered(
  centerRect: RectPosition,
  rects: [RectPosition, RectPosition],
  axes: 'x' | 'y'
): boolean {
  const [startBox, endBox] = rects;

  if (axes === 'x') {
    return (
      startBox.left + startBox.width < centerRect.left &&
      centerRect.left + centerRect.width < endBox.left
    );
  }

  return (
    startBox.top + startBox.height < centerRect.top &&
    centerRect.top + centerRect.height < endBox.top
  );
}

export const validateRectTriRelationship = (
  currentRect: RectPosition,
  rects: [RectPosition, RectPosition],
  axes: 'x' | 'y'
): boolean => {
  const [refRect1, refRect2] = rects;
  const isRect1InRange = isRelationshipsInRange(currentRect, refRect1, axes);
  const isRect2InRange = isRelationshipsInRange(currentRect, refRect2, axes);
  const isCenter = isCentered(currentRect, [refRect1, refRect2], axes);

  return isRect1InRange && isRect2InRange && isCenter;
};

export const calculateTriRectRelationship = (
  currentRect: RectPosition,
  startRefRect: RectPosition,
  endRefRect: RectPosition
) => {
  const startRefRectPoints = calculateRectImportantPoints(startRefRect);
  const endRefRectPoints = calculateRectImportantPoints(endRefRect);
  const currentRectPoints = calculateRectImportantPoints(currentRect);

  let rectTriRelationships: RectTriRelationship[] = [];

  AXES.forEach((axis) => {
    const isValid = validateRectTriRelationship(currentRect, [startRefRect, endRefRect], axis);

    if (!isValid) {
      return;
    }

    const startDistance = Math.abs(
      Math.round(startRefRectPoints[axis]['end'] - currentRectPoints[axis]['start'])
    );
    const endDistance = Math.abs(
      Math.round(endRefRectPoints[axis]['start'] - currentRectPoints[axis]['end'])
    );
    const snapDistance = endDistance - startDistance;

    rectTriRelationships = [
      ...rectTriRelationships,
      {
        axis,
        currentRect,
        startRefRect,
        endRefRect,
        currentPlacement: 'middle',
        startRefPlacement: 'end',
        endRefPlacement: 'start',
        snapDistance,
        startDistance,
      },
    ];
  });

  return rectTriRelationships;
};

export const isTriRectRelationship = (
  relationship: RectRelationship
): relationship is RectTriRelationship => {
  return 'endRefRect' in relationship;
};

export const calculateMultipleRectsRelationship = (
  currentRect: RectPosition,
  refRects: RectPosition[]
): RectRelationship[] => {
  let rectRelationships: RectRelationship[] = [];

  refRects.forEach((refRect) => {
    const biRelationships = calculateBiRectRelationship(currentRect, refRect);
    rectRelationships = [...rectRelationships, ...biRelationships];

    refRects.forEach((refRect2) => {
      const triRelationships = calculateTriRectRelationship(currentRect, refRect, refRect2);
      rectRelationships = [...rectRelationships, ...triRelationships];
    });
  });

  return rectRelationships;
};

export const createNearRelationshipFilter =
  (tolerant: number) => (relationship: RectRelationship) =>
    Math.abs(relationship.snapDistance) <= tolerant;

export const filterAndSortNearestRelationships = (
  relationships: RectRelationship[],
  tolerant = 30
) =>
  relationships
    .filter(createNearRelationshipFilter(tolerant))
    .sort((rel1, rel2) => rel1.snapDistance - rel2.snapDistance);

export type RelationshipFilter = (rel: RectRelationship) => boolean;

export const rectToSVGViewBox = (rect: RectPosition) =>
  `${rect.left} ${rect.top} ${rect.width} ${rect.height}`;

export const pointsDistance = (point1: Point, point2: Point): number => {
  // Pythagorean theorem
  return Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2));
};
export const pointsDelta = (point1: Point, point2: Point): Translate => {
  return {
    x: point2.x - point1.x,
    y: point2.y - point1.y,
  };
};

export const combineTranslates = (
  translate: Coordinate,
  ...translates: (Partial<Coordinate> | undefined | false | null | 0 | '')[]
): Coordinate => {
  return translates.reduce<Coordinate>(
    (result, translate) =>
      translate
        ? {
            x: result.x + (translate?.x ?? 0),
            y: result.y + (translate?.y ?? 0),
          }
        : result,
    translate
  );
};

export function areRectsHorizontalApprox(rects: RectPosition[], tolerance = 0.4) {
  const centerCoords = rects.map((square) => square.top + square.height / 2);
  const minTop = min(rects.map((rect) => rect.top)) ?? 0;
  const maxBottom = max(rects.map((rect) => rect.top + rect.height)) ?? 0;
  const maxDiff = Number(max(centerCoords)) - Number(min(centerCoords));
  return maxDiff <= tolerance * (maxBottom - minTop);
}

export function areRectsVerticalApprox(rects: RectPosition[], tolerance = 0.4) {
  const centerCoords = rects.map((square) => square.left + square.width / 2);
  const minLeft = min(rects.map((rect) => rect.left)) ?? 0;
  const maxRight = max(rects.map((rect) => rect.left + rect.width)) ?? 0;
  const maxDiff = Number(max(centerCoords)) - Number(min(centerCoords));
  return maxDiff <= tolerance * (maxRight - minLeft);
}
