import React from 'react';

import { DashboardThemeConfig } from '../dashboard-theme/dashboard-theme.entity';
import { Entity, EntityUuid } from '../types';

import {
  ChartElementEntity,
  ElementDescription,
  FilterElementEntity,
  HorizontalAlign,
  ImageElementEntity,
  JsonCardElementEntity,
  LineElementEntity,
  LoggingElementEntity,
  ShapeElementEntity,
  TextElementEntity,
  VerticalAlign,
} from './entities';

export * from './entities';

export interface ElementQueryParamOption {
  value: string;
  label: string;
  key?: string;
}

export interface ElementQueryParam {
  name: string;
  valueFrom: 'page' | 'element' | 'fixed';
  default: string;
  displayType?: 'text' | 'tabs' | 'checkbox';
  label?: string;
  options?: ElementQueryParamOption[];
}

export type ElementPlacement = 'header' | 'body' | 'footer';

export interface ElementEntityStyle {
  element_style_text_align?: React.CSSProperties['textAlign'];
  element_style_vertical_align?: VerticalAlign;
  element_style_horizontal_align?: HorizontalAlign;
}

export type ElementTheme = DeepPartial<DashboardThemeConfig>;

export interface BaseElementEntity extends Entity, ElementEntityStyle {
  element_id: EntityUuid;
  group_id?: number;
  element_version?: number;
  document_uuid?: EntityUuid;
  page_id: EntityUuid;
  query_id?: EntityUuid | null;
  connection_string_id?: EntityUuid;
  element_query_params?: ElementQueryParam[];
  element_labels?: Record<string, string | null>;
  element_colors?: Record<string, string | null>;
  element_order?: number;
  element_placement?: ElementPlacement;
  element_description?: ElementDescription;
  element_theme?: ElementTheme;
  element_enable_customization?: boolean;
}

export interface GenericElementEntity<T, C> extends BaseElementEntity {
  element_type: T;
  element_config: C;
}

export type ElementEntity =
  | ChartElementEntity
  | FilterElementEntity
  | ImageElementEntity
  | JsonCardElementEntity
  | LineElementEntity
  | LoggingElementEntity
  | ShapeElementEntity
  | TextElementEntity;

export type ElementType = ElementEntity['element_type'];

export type ElementConfig = ElementEntity['element_config'];

export type ElementEntityByType<
  Type extends ElementType,
  T extends ElementEntity = ElementEntity,
> = T extends {
  element_type: Type;
}
  ? T
  : never;

export const defaultBaseElementEntity = (): Partial<BaseElementEntity> => ({
  element_placement: 'body',
  element_style_horizontal_align: 'center',
  element_style_vertical_align: 'center',
  element_style_text_align: 'left',
});

export type SameElementEntityArray = UnionToArray<ElementEntity>;

export type NewElementData<T extends ElementEntity = ElementEntity> = PartialExcludeKeys<
  UnionOmit<T, 'element_id'> & { image: File },
  'element_type'
>;
export type SanitizedNewElementData<T extends ElementEntity = ElementEntity> = PartialExcludeKeys<
  UnionOmit<T, 'element_id'> & { image: File },
  'element_type' | 'element_config'
>;
