import { useTranslation } from 'react-i18next';
import { Stack, TextFieldProps, Typography } from '@mui/material';

import { RegionSelectItem } from './components/region-select-item';
import { dataCenterDomains, dataCenters } from './data-center-input.const';

export interface DataCenterInputProps extends Pick<TextFieldProps, 'size' | 'fullWidth'> {
  getValues?: () => {
    name?: string;
    email?: string;
  };
}
function switchDataCenter(domain: string, getValues?: DataCenterInputProps['getValues']) {
  localStorage.setItem('LAST_DATACENTER', domain);
  const url = new URL(window.location.href);
  url.hostname = domain;
  if (getValues) {
    const keepValues = getValues();
    if (keepValues.name) {
      url.searchParams.set('name', keepValues.name);
    }
    if (keepValues.email) {
      url.searchParams.set('email', keepValues.email);
    }
  }
  url.searchParams.set('selected_datacenter', '1');
  window.location.href = url.toString();
}

export function DataCenterInput({ getValues }: DataCenterInputProps) {
  const { t } = useTranslation();

  const value = dataCenterDomains.includes(window.location.hostname)
    ? window.location.hostname
    : 'placeholder';

  return (
    <Stack spacing={1} mb={2}>
      <Typography textAlign="center" variant="h6">
        {t('DATACENTER.SELECT_DATACENTER')}
      </Typography>
      {/*<Typography textAlign="center" variant="subtitle1">*/}
      {/*  {t('DATACENTER.SELECT_DATACENTER_SUBTITLE')}*/}
      {/*</Typography>*/}
      <Stack direction="row" justifyContent="space-evenly">
        {dataCenters.map((center) => (
          <RegionSelectItem
            key={center.domain}
            domain={center.domain}
            region={center.text}
            selected={value === center.domain}
            onClick={() => {
              switchDataCenter(center.domain, getValues);
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
}
