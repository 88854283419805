import { stringify } from 'qs';

import { coreFetcher } from '../../client';
import { QueryDialect } from '../query';
import {
  deleteOneRequestFactory,
  getOneRequestFactory,
  insertOneRequestFactory,
  restoreOneRequestFactory,
  updateOneRequestFactory,
} from '../request';
import { EntityUuid } from '../types';

import {
  BaseConnectionStringProperties,
  ConnectionStringEntity,
  ConnectionStringStructure,
  DialectConnectionStringProperties,
  TestConnectionStringResult,
} from './connection-string.entity';

const namespace = '/connection-string';

export type CreateConnectionStringDatas = {
  [T in QueryDialect]: Pick<
    BaseConnectionStringProperties,
    | 'connection_string_user'
    | 'connection_string_key'
    | 'connection_string_name'
    | 'connection_string_information'
  > & {
    connection_string_id?: string;
  } & DialectConnectionStringProperties[T];
};

export type CreateConnectionStringData = CreateConnectionStringDatas[QueryDialect];

async function testConnectionStringRequest(
  data: CreateConnectionStringData
): Promise<TestConnectionStringResult> {
  const response = await coreFetcher.post<TestConnectionStringResult>(`${namespace}/test`, data);
  return response.data;
}

async function getTablesRequest(connectionStringId: EntityUuid) {
  const response = await coreFetcher.get<string[]>(`${namespace}/${connectionStringId}/table`);
  return response.data;
}

export interface ConnectionStringTable {
  table_name: string;
  has_timestamp: boolean | 1 | 0;
}

async function getTablesIncludedDetailRequest(connectionStringId: EntityUuid) {
  const response = await coreFetcher.get<ConnectionStringTable[]>(
    `${namespace}/${connectionStringId}/table/detail`
  );
  return response.data;
}

async function getStructureRequest(connectionStringId: EntityUuid) {
  const response = await coreFetcher.get<ConnectionStringStructure>(
    `${namespace}/${connectionStringId}/structure`
  );

  return response.data;
}

async function getTableDetailRequest(connectionStringId: EntityUuid, $table: string) {
  const response = await coreFetcher.get<{ type: string; name: string }[]>(
    `${namespace}/${connectionStringId}/table/${$table}`
  );
  return response.data;
}

const insertOneRequest = insertOneRequestFactory<ConnectionStringEntity>(namespace);
const updateOneRequest = updateOneRequestFactory<ConnectionStringEntity>(namespace);
const deleteOneRequest = deleteOneRequestFactory(namespace);
const restoreOneRequest = restoreOneRequestFactory(namespace);
const getOneRequest = getOneRequestFactory<ConnectionStringEntity>(namespace);

export type GetManyRequestOptions = {
  documentUuid?: string;
};

const getManyRequest = async (options: GetManyRequestOptions) => {
  return (await coreFetcher.get<ConnectionStringEntity[]>(`${namespace}?${stringify(options)}`))
    .data;
};

export default {
  getOneRequest,
  restoreOneRequest,
  getStructureRequest,
  insertOneRequest,
  updateOneRequest,
  deleteOneRequest,
  testConnectionStringRequest,
  getTablesRequest,
  getTableDetailRequest,
  getManyRequest,
  getTablesIncludedDetailRequest,
};
