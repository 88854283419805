import { Box, styled } from '@mui/material';

import { SpacingData } from '../../../../grid-board.type';

export const StyledResizable = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'disabled',
})<SpacingData & { isDragging: boolean }>(
  ({ theme }) => ({
    position: 'absolute',
    borderRadius: 4,
    '&:hover': {
      background: theme.palette.primary.light,
    },
  }),
  ({ isDragging, theme }) =>
    isDragging && {
      background: theme.palette.primary.light,
    },
  ({ type }) =>
    type === 'row' && {
      cursor: 'row-resize',
    },
  ({ type }) =>
    type === 'cell' && {
      cursor: 'col-resize',
    }
  // ({ disabled, theme }) =>
  //   !disabled && {
  //     cursor: 'ew-resize',
  //     backgroundColor: theme.palette.background.grey,
  //   }
);

export const ResizableIndicator = styled(Box, {
  shouldForwardProp: (propName) =>
    propName !== 'type' &&
    propName !== 'edge' &&
    propName !== 'rowIndex' &&
    propName !== 'colIndex',
})<SpacingData>(
  ({ type }) =>
    type === 'row' && {
      height: '100%',
    },
  ({ type }) =>
    type === 'cell' && {
      width: '100%',
    }
  // ({ active, theme }) =>
  //   active && {
  //     backgroundColor: theme.palette.primary.lighter,
  //   }
);
