import { useMemo } from 'react';
import { ClockIcon } from '@dataflake/icons';
import { InputBaseProps, MenuItem, Stack, Typography } from '@mui/material';
import { range } from 'd3-array';

import { DFListPickerInput } from '~ui/df-input/components/df-list-picker-input';

export interface TimeValue {
  hour: number;
  minute: number;
}

export interface DFTimeSelectProps extends Omit<InputBaseProps, 'value' | 'onChange'> {
  value?: TimeValue;
  onChange?: (time: TimeValue) => void;
  timeInterval?: number;
  min?: Partial<TimeValue>;
}

const defaultValue: TimeValue = {
  hour: 0,
  minute: 0,
};

export function DFTimePicker({
  value = defaultValue,
  onChange,
  timeInterval = 1,
  min,
  ...inputProps
}: DFTimeSelectProps) {
  const hourOptions = useMemo(
    () =>
      range(0, 24)
        .filter((hour) => (min?.hour ? hour >= min.hour : true))
        .map((hour) => ({
          value: String(hour),
          label: String(hour),
        })),
    [min?.hour]
  );

  const minuteOptions = useMemo(
    () =>
      range(0, 60, timeInterval)
        .filter((minute) => (min?.minute ? minute > min?.minute : true))
        .map((minute) => ({
          value: String(minute),
          label: String(minute),
        })),
    [min?.minute, timeInterval]
  );

  const changeHour = (e: string) => {
    onChange &&
      onChange({
        ...value,
        hour: Number(e),
      });
  };
  const changeMinute = (e: string) => {
    onChange &&
      onChange({
        ...value,
        minute: Number(e),
      });
  };

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <ClockIcon />
      <DFListPickerInput
        {...inputProps}
        options={hourOptions}
        onChange={changeHour}
        value={String(value?.hour)}
        fullWidth
        sx={{ textAlign: 'center', flex: 1 }}
      >
        {range(0, 24).map((value: number) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </DFListPickerInput>
      <Typography>:</Typography>
      <DFListPickerInput
        {...inputProps}
        options={minuteOptions}
        onChange={changeMinute}
        value={String(value?.minute)}
        fullWidth
        sx={{ textAlign: 'center', flex: 1 }}
      />
    </Stack>
  );
}
