import { ComponentsOverrides, ComponentsProps, Theme as MuiTheme } from '@mui/material';

interface MuiDialogActionsComponentType {
  styleOverrides?: ComponentsOverrides<MuiTheme>['MuiDialogActions'];
  defaultProps?: ComponentsProps['MuiDialogActions'];
}

export const MuiDialogActionsComponent: MuiDialogActionsComponentType = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => {
      return {
        padding: theme.spacing(1, 3, 3, 3),
        justifyContent: 'center',
      };
    },
  },
};
