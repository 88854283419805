import React, { forwardRef, ReactElement, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  ChartBarIcon,
  DatabaseIcon,
  FileMagnifyingGlassIcon,
  FileTextIcon,
  PresentationChartIcon,
  SquaresFourIcon,
} from '@dataflake/icons';
import {
  Box,
  BoxProps as BoxPropsType,
  ButtonBase,
  Card,
  Divider,
  LinearProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { stringify } from 'qs';

import { useDebounceValue } from '~hooks/use-debounce-value';
import { useSearchParams } from '~libs/router';
import { useSetSearchParams } from '~libs/router/hooks/use-set-search-params';
import { AnySearchObject, SearchObjectType, useSearchAllQuery } from '~services/v1/search';
import { DATE_FORMATS, formatDateBestEffort } from '~utils/datetime';

import { ACTIVE_ELEMENT_PARAM_KEY } from './universal-search.const';

interface UniversalSearchProps {
  term: string;
  BoxProps?: BoxPropsType;
  onSelected?: () => unknown;
}

type TabName = SearchObjectType | 'all';

const tabs: { value: TabName; label: string; icon: React.ReactElement }[] = [
  {
    value: 'all',
    label: 'COMMON.ALL',
    icon: <SquaresFourIcon />,
  },
  {
    value: 'dashboard',
    label: 'COMMON.DASHBOARDS',
    icon: <PresentationChartIcon />,
  },
  {
    value: 'page',
    label: 'COMMON.PAGES',
    icon: <FileTextIcon />,
  },
  {
    value: 'query',
    label: 'COMMON.QUERIES',
    icon: <FileMagnifyingGlassIcon />,
  },
  {
    value: 'chart',
    label: 'COMMON.CHARTS',
    icon: <ChartBarIcon />,
  },
];

const renderSecondaryText = (item: AnySearchObject): ReactNode => {
  switch (item.object_type) {
    case 'dashboard':
      return (
        <>
          <span>{`${item.object_meta.company_name} - ${item.object_meta.project_name}`}</span>
          {' - '}
          <b>{formatDateBestEffort(item.object_created_at, DATE_FORMATS.DATE_ONLY)}</b>
        </>
      );
    case 'page':
      return (
        <>
          <span>{`${item.object_meta.project_name} - ${item.object_meta.dashboard_name}`}</span>
          {' - '}
          <b>{formatDateBestEffort(item.object_created_at, DATE_FORMATS.DATE_ONLY)}</b>
        </>
      );
    case 'chart':
      return (
        <>
          <span>{`${item.object_meta.dashboard_name} - ${item.object_meta.page_name}`}</span>
          {' - '}
          <b>{formatDateBestEffort(item.object_created_at, DATE_FORMATS.DATE_ONLY)}</b>
        </>
      );
    case 'query':
    case 'connection_string':
    default:
      return <b>{formatDateBestEffort(item.object_created_at, DATE_FORMATS.DATE_ONLY)}</b>;
  }
};

const createItemLink = (item: AnySearchObject): string => {
  let path = '';
  let queryString = '';
  switch (item.object_type) {
    case 'dashboard':
      path = generatePath('/:documentId/edit', { documentId: item.object_id });
      break;
    case 'page':
      path = generatePath('/:documentId/edit', { documentId: item.object_meta.dashboard_uuid });
      queryString = stringify({
        page: item.object_meta.page_code,
      });
      break;
    case 'chart':
      path = generatePath('/:documentId/edit', { documentId: item.object_meta.dashboard_uuid });
      queryString = stringify({
        page: item.object_meta.page_code,
        [ACTIVE_ELEMENT_PARAM_KEY]: item.object_id,
      });
      break;
    case 'query':
      path = generatePath('/:documentId/edit', { documentId: item.object_meta.dashboard_uuid });
      queryString = stringify({
        _: `query,edit,${item.object_id}`,
      });
      break;
    case 'connection_string':
      path = generatePath('/:documentId/edit', { documentId: item.object_meta.dashboard_uuid });
      queryString = stringify({
        _: `cs,edit,${item.object_id}`,
      });
      break;
  }
  return `${path}?${queryString}`;
};

function useNavigateSearchItem() {
  const navigate = useNavigate();
  const searchParams = useSearchParams([ACTIVE_ELEMENT_PARAM_KEY]);
  const setSearchParams = useSetSearchParams();

  function navigateSearchItem(searchObject: AnySearchObject) {
    if (searchObject.object_type === 'chart') {
      const activeElementId = searchParams[ACTIVE_ELEMENT_PARAM_KEY];
      if (activeElementId === searchObject.object_id) {
        setSearchParams({
          [ACTIVE_ELEMENT_PARAM_KEY]: null,
        });
      }
    }
    navigate(createItemLink(searchObject));
  }

  return { navigateSearchItem };
}

const icons: Record<SearchObjectType, ReactElement> = {
  page: <FileTextIcon />,
  query: <FileMagnifyingGlassIcon />,
  chart: <ChartBarIcon />,
  connection_string: <DatabaseIcon />,
  dashboard: <PresentationChartIcon />,
};

export const UniversalSearch = forwardRef<HTMLElement, UniversalSearchProps>(
  function UniversalSearch({ term, BoxProps, onSelected }, ref) {
    const debounceTerm = useDebounceValue(term, 500);
    const { navigateSearchItem } = useNavigateSearchItem();
    const {
      data = [],
      isLoading,
      isRefetching,
      error,
    } = useSearchAllQuery(
      {
        query: debounceTerm,
      },
      {
        placeholderData: [],
        enabled: term.length > 0,
      }
    );
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState<TabName>('all');
    const filteredData = useMemo(() => {
      if (activeTab === 'all') {
        return data;
      }
      return data.filter((item) => item.object_type === activeTab);
    }, [activeTab, data]);

    async function handleClickItem(
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      item: AnySearchObject
    ) {
      e.preventDefault();

      await onSelected?.();
      navigateSearchItem(item);
    }

    return (
      <Box ref={ref} component={Card} {...BoxProps}>
        <Tabs
          value={activeTab}
          onChange={(e, value: TabName) => setActiveTab(value)}
          sx={{ minHeight: 'initial' }}
          variant="fullWidth"
        >
          {tabs.map(({ label, value, icon }) => (
            <Tab
              sx={{ minHeight: 'initial', py: 2 }}
              key={label}
              value={value}
              label={t(label)}
              icon={
                <Box width="15px" flexShrink={0}>
                  {icon}
                </Box>
              }
              iconPosition="start"
            />
          ))}
        </Tabs>
        <Box
          sx={(theme) => ({
            height: '1px',
            backgroundColor: theme.palette.primary.main,
            opacity: '20%',
          })}
        />
        <Box maxHeight="50vh" overflow="auto">
          {(isLoading || isRefetching) && <LinearProgress />}
          {error && (
            <Typography color="error" align="center">
              {String(error)}
            </Typography>
          )}
          {filteredData && !filteredData.length && (
            <Box py={2} px={1}>
              <Typography align="center">{t('COMMON.NO_DATA_TO_DISPLAY')}</Typography>
            </Box>
          )}
          <Stack divider={<Divider flexItem />}>
            {filteredData &&
              filteredData.map((item) => {
                const icon = icons[item.object_type];
                return (
                  <ButtonBase
                    key={`${item.object_type}:${item.object_id}`}
                    sx={{ textAlign: 'left' }}
                    href={createItemLink(item)}
                    onClick={(e) => handleClickItem(e, item)}
                  >
                    <Box width="100%" p={2} display="flex">
                      <Box py={0.75} pr={1.5}>
                        {icon}
                      </Box>
                      <Box flex={1}>
                        <Typography variant="body1">{item.object_name}</Typography>
                        <Typography
                          variant="caption"
                          component="div"
                          lineHeight="1.2"
                          color="text.secondary"
                        >
                          {renderSecondaryText(item)}
                        </Typography>
                      </Box>
                    </Box>
                  </ButtonBase>
                );
              })}
          </Stack>
        </Box>
      </Box>
    );
  }
);
