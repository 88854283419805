import { Activator, DataRef, Sensor, SensorInstance, SensorProps } from '@dnd-kit/core';

import { findNearestInteractiveParent } from '~utils/dom';
import { pointsDelta, pointsDistance } from '~utils/geometry';

export interface ElementPointerSensorOptions {
  activationDistance: number;
}

export const ElementPointerSensor: Sensor<{}> = class ElementPointerSensor
  implements SensorInstance
{
  autoScrollEnabled = true;
  constructor(private props: SensorProps<{}>) {
    const { onStart, onMove, onEnd, onCancel, event, activeNode } = props;
    const pointerEvent = event as PointerEvent;
    let started = false;
    let ended = false;

    function stopPropagation(e: Event) {
      e.stopPropagation();
    }

    function mouseMoveHandler(event: PointerEvent) {
      const currentPointerPosition = { x: event.x, y: event.y };
      const delta = pointsDelta(originPointerPosition, currentPointerPosition);
      if (!started) {
        const data = activeNode?.data as DataRef<ElementPointerSensorOptions | undefined>;
        let ready = false;
        if (!data?.current?.activationDistance) {
          ready = true;
        } else {
          const distance = pointsDistance(originPointerPosition, currentPointerPosition);
          if (distance >= data?.current?.activationDistance) {
            ready = true;
          }
        }

        if (ready) {
          onStart({
            x: 0,
            y: 0,
          });
          started = true;
          event.preventDefault();
          // prevent clicking after starting
          window.addEventListener('click', stopPropagation, {
            once: true,
            capture: true,
          });
        }
        // Wait before matching activation conditions
      } else if (!ended) {
        // Start move
        onMove(delta);
      }
    }

    function pointerUpHandler() {
      ended = true;
      window.removeEventListener('pointermove', mouseMoveHandler);

      if (started) {
        onEnd();
      } else {
        onCancel();
      }
    }

    window.addEventListener('pointermove', mouseMoveHandler);
    window.addEventListener('pointerup', pointerUpHandler, {
      once: true,
    });
    const originPointerPosition = { x: pointerEvent.x, y: pointerEvent.y };
  }

  /**
   * Capture events from element and return true if it should start
   * If you want to delay before actual starting, this is not a good place,
   *  just return true and cancel inside constructor
   */
  static activators: Activator<{}>[] = [
    {
      eventName: 'onPointerDown',
      handler: (event: React.PointerEvent) => {
        if (!event.isPrimary || event.button !== 0) {
          return false;
        }
        if (
          event.target !== event.currentTarget &&
          event.target instanceof Element &&
          findNearestInteractiveParent(event.target) !== event.currentTarget
        ) {
          return false;
        }
        return true;
      },
    },
  ];
  /**
   * Called once when the setup context.
   * Might not necessary
   */
  static setup: Sensor<{}>['setup'] = () => {
    return () => {};
  };
};
