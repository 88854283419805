import { forwardRef } from 'react';
import { SwitchProps } from '@mui/base/Switch';

import withTooltip from '~components/with-tooltip';

import { StyledSwitch, StyledSwitchProps } from './df-switch.styled';

export type DFSwitchProps = SwitchProps & StyledSwitchProps;

export const DFSwitch = forwardRef<HTMLSpanElement, DFSwitchProps>(function DFSwitch(
  { ...props },
  ref
) {
  return <StyledSwitch {...props} ref={ref} />;
});

export const DFToolTipSwitch = withTooltip(DFSwitch);
