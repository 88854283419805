import { List, ListItemButton, ListSubheader, styled } from '@mui/material';

export const StyledList = styled(List)(({}) => ({}));
export const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  lineHeight: `22px`,
  fontSize: 11,
  padding: theme.spacing(0, 1.5),
  gap: theme.spacing(1),
}));
export const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  lineHeight: `25px`,
  fontSize: 11,
  fontWeight: 'bold',
  padding: theme.spacing(0.5, 1.5),
}));
