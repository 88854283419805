import { createContext, ReactNode, useEffect, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';

import { DataSourceEntity } from '~services/v1/data-source';

import { createElementDataProviderStore, ElementDataStore } from './element-data-provider.store';

export interface ElementDataProviderProps extends ElementDataStore {
  children: ReactNode;
}

export const ElementDataContext = createContext(
  createElementDataProviderStore({
    invalidate: () => Promise.resolve(),
    dataSourceQueryResult: {} as UseQueryResult<DataSourceEntity>,
    progressQueryResult: null,
  })
);

export function ElementDataProvider({
  children,
  invalidate,
  dataSourceQueryResult,
  progressQueryResult,
}: ElementDataProviderProps) {
  const [store] = useState(() =>
    createElementDataProviderStore({
      invalidate,
      dataSourceQueryResult,
      progressQueryResult,
    })
  );

  useEffect(() => {
    store.setState({
      dataSourceQueryResult,
      progressQueryResult,
    });
  }, [dataSourceQueryResult, store, progressQueryResult]);

  useEffect(() => {
    store.setState({
      invalidate,
    });
  }, [invalidate, store]);

  return <ElementDataContext.Provider value={store}>{children}</ElementDataContext.Provider>;
}
