import { alpha, PaletteOptions } from '@mui/material';

import type { ThemeMode } from './index';

export type Color = 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default';

export interface CustomDesignPalette {
  selectMode: {
    background: string;
    textActive: string;
    textInactive: string;
  };
  text: {
    jet: string;
    oldSilver: string;
  };
  button: {
    darkPastelBlue: string;
    romanSilver: string;
    platinum: string;
    aliceBlue: string;
    gainsboro: string;
    cosmicLatte: string;
    purple: string;
    lightBlue: string;
  };
  editor: {
    border: string;
  };
}

declare module '@mui/material/styles/createPalette' {
  interface TypeAction {}
  interface TypeText {
    formLabel: string;
  }
  interface TypeBackground {
    lightGrey: string;
    grey: string;
    disabled: string;
    surface: string;
    subTable: string;
  }
  interface Palette {
    gradient: {
      primary: string[];
    };
    customDesign: CustomDesignPalette;
  }
  interface PaletteOptions {
    gradient: {
      primary: string[];
    };
    customDesign: CustomDesignPalette;
  }
  interface PaletteColor {
    lighter?: string;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
  }
}

const createSharedOptions = (mode: ThemeMode): PaletteOptions => ({
  mode,
  gradient: {
    primary: ['#9416FF', '#0044DD'],
  },
  primary: {
    main: '#0044DD',
    lighter: '#DFF1FF',
  },
  secondary: {
    main: '#FBA913',
  },
  info: {
    main: '#0288d1',
    lighter: '#F8FCFF',
  },
  error: {
    main: '#d32f2f',
    lighter: '#FFF0EF',
  },
  warning: {
    main: '#FBA913',
  },
  success: {
    main: '#1AD598',
    lighter: '#EFFFFA',
  },
  customDesign: {
    selectMode: {
      background: '#E9F0F5',
      textActive: '#003D82',
      textInactive: alpha('#003D82', 0.6),
    },
    button: {
      darkPastelBlue: '#7B97CE',
      platinum: '#E4E4E4',
      aliceBlue: '#ECF9FF',
      gainsboro: '#D9E1E7',
      cosmicLatte: '#FFF7E9',
      romanSilver: '#808191',
      purple: '#786CE8',
      lightBlue: '#5CC9FF',
    },
    text: {
      jet: '#343434',
      oldSilver: '#828282',
    },
    editor: {
      border: '#E5E5E5',
    },
  },
});

const createModeOptions = (mode: ThemeMode) => {
  const modeOptions: Record<ThemeMode, Partial<PaletteOptions>> = {
    dark: {
      background: {
        // TODO change it
        disabled: '#F9F9F9',
        default: '#384455',
        paper: '#222F42',
        surface: '#222F42',
        subTable: '#222F42',
      },
      text: {
        secondary: '#8A97AA',
        primary: '#FFFFFF',
      },
      action: {
        activatedOpacity: 0.3,
        disabledOpacity: 0.3,
      },
      divider: '#506077',
    },
    light: {
      background: {
        disabled: '#F9F9F9',
        default: '#FFFFFF',
        paper: '#FFFFFF',
        grey: '#F6F6F6',
        surface: '#F7F9FC',
        subTable: '#F5F6FC',
        lightGrey: '#F2f2f2',
      },
      text: {
        primary: '#343434',
        secondary: '#828282',
        formLabel: '#343434',
      },
      action: {
        active: '#050038',
        activatedOpacity: 0.3,
        disabledOpacity: 0.3,
      },
      divider: '#E6E9FA',
    },
  };
  return modeOptions[mode];
};

export const createPalette = (mode: ThemeMode): PaletteOptions => ({
  mode,
  ...createSharedOptions(mode),
  ...createModeOptions(mode),
});
