import { useContext } from 'react';
import { DialogProps } from '@mui/material';
import { StoreApi, useStore } from 'zustand';
import { shallow } from 'zustand/shallow';
import { useStoreWithEqualityFn } from 'zustand/traditional';

import { ModalContext, ModalStore } from '../modal-context';

export function useModalContext<T>() {
  return useStore(useContext(ModalContext) as StoreApi<ModalStore<T>>);
}
export function useModalContextSelector<T, U>(
  selector: (state: ModalStore<T>) => U,
  equalityFn?: (a: U, b: U) => boolean
) {
  return useStoreWithEqualityFn(
    useContext(ModalContext) as StoreApi<ModalStore<T>>,
    selector,
    equalityFn
  );
}

export function useMuiDialogProps(): Pick<DialogProps, 'open' | 'onClose'> {
  const [open, onClose] = useModalContextSelector(
    (state) => [state.visible, state.onClose],
    shallow
  );
  return {
    open,
    onClose,
  };
}

export function useHideModal() {
  return useModalContextSelector((state) => state.hide);
}
export function useRemoveModal() {
  return useModalContextSelector((state) => state.remove);
}

export function useShowModal() {
  return useModalContextSelector((state) => state.show);
}
