import { AxisChartSpecialConfig, defaultAxisChartConfig } from './axis-chart-element';
import { GenericChartConfig, GenericChartElementEntity } from './chart-element';

export type ScatterChartSpecialConfig = AxisChartSpecialConfig & {
  size?: number;
};

export const defaultScatterChartConfig = (): ScatterChartSpecialConfig => ({
  ...defaultAxisChartConfig(),
  showYAxis: true,
  showXAxis: true,
  size: 10,
});

export interface ScatterChartConfig
  extends GenericChartConfig<'scatter'>,
    ScatterChartSpecialConfig {}

export interface ScatterChartElementEntity extends GenericChartElementEntity<ScatterChartConfig> {}
