import { styled } from '@mui/material';

import { card } from '~utils/styled';

export const Alert = styled('div')<{ severity: 'success' }>(({ theme, severity }) => ({
  textAlign: 'center',
  color: theme.palette[severity].contrastText,
  backgroundColor: theme.palette[severity].light,
  padding: theme.spacing(1),
  ...card(theme, 'flat'),
}));
