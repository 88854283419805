import { createContext } from 'react';
import { createStore } from 'zustand';

export interface ModalStore<T> {
  visible: boolean;
  props: T | null;
  setVisible: (value: boolean) => void;
  show: (props?: T) => void;
  hide: () => void;
  remove: () => void;

  /**
   * For quickly use with MuiDialog
   * @param event
   * @param reason
   */
  onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
}

export const createModalStore = <T>(defaultProps: T | null = null) =>
  createStore<ModalStore<T>>((set) => ({
    visible: false,
    props: defaultProps,
    setVisible: (value) =>
      set({
        visible: value,
      }),
    show: (props: T | null = null) => {
      props = props || defaultProps || ({} as T);
      set({
        visible: true,
        props,
      });
    },
    hide: () =>
      set({
        visible: false,
      }),
    remove: () =>
      set({
        visible: false,
        props: null,
      }),
    onClose: () =>
      set(() => ({
        visible: false,
      })),
  }));

export const ModalContext = createContext(createModalStore());
