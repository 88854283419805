import { range } from 'd3-array';

import { GenericElementEntity } from '../element.entity';

export type ArrowStyle = 'none' | 'standard' | 'circle';

export interface LineElementConfig {
  headStyle: ArrowStyle;
  tailStyle: ArrowStyle;
}

export const lineFontWeightList = range(1, 21).map((value) => value);

export interface LineElementEntity extends GenericElementEntity<'line', LineElementConfig> {}
