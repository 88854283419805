import { Badge, badgeClasses, styled } from '@mui/material';

import { AvatarStatus } from './df-avatar';

export const StyledBadge = styled(Badge, {
  shouldForwardProp: (name) => name != 'status',
})<AvatarStatus>(
  ({ theme, status }) =>
    status === 'active' && {
      [`.${badgeClasses.badge}`]: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.main,
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          animation: 'ripple 1.2s infinite ease-in-out',
          border: '1px solid currentColor',
          content: '""',
        },
      },
      '@keyframes ripple': {
        '0%': {
          transform: 'scale(.8)',
          opacity: 1,
        },
        '100%': {
          transform: 'scale(2.4)',
          opacity: 0,
        },
      },
    }
);
