import { omit } from 'lodash-es';
import { stringify } from 'qs';

import { coreFetcher } from '../../client';
import { ConnectionStringEntity } from '../connection-string';
import { ChartElementEntity, ElementEntity, ElementType } from '../element';
import { PageEntity } from '../page';
import { QueryEntity } from '../query';
import { requestsFactory } from '../request';
import { ShareEntity } from '../share';
import { EntityUuid } from '../types';

import {
  DashboardEntity,
  DashboardRelationShips,
  DashboardTemplateEntity,
  UpdateDocumentRelationShips,
} from './dashboard.entity';

export const namespace = '/document';

const requests = requestsFactory<
  DashboardEntity,
  DashboardEntity & DashboardRelationShips,
  DashboardEntity,
  DashboardEntity,
  DashboardEntity & { restore_uuid?: string }
>(namespace);

async function getManyRequest() {
  return (await coreFetcher.get<DashboardEntity[]>(`${namespace}`)).data;
}

async function getOneRequest(
  id: string,
  opts: { with?: (keyof DashboardRelationShips)[] } = {
    with: ['pages'],
  }
) {
  return (
    await coreFetcher.get<DashboardEntity & DashboardRelationShips>(
      `${namespace}/${id}${stringify(opts, { addQueryPrefix: true })}`
    )
  ).data;
}

async function updateOneRequest(
  id: string,
  data: Partial<DashboardEntity & UpdateDocumentRelationShips>
) {
  const response = await coreFetcher.patch<DashboardEntity & Partial<DashboardRelationShips>>(
    `${namespace}/${id}`,
    data
  );
  return response.data;
}

type DuplicateDocumentOptions = {
  documentUuid?: string;
  documentName?: string;
  projectUuid?: string;
  pageCode?: string;
};

async function duplicateDocumentRequest(options: EntityUuid | DuplicateDocumentOptions) {
  if (typeof options === 'string') {
    const { data } = await coreFetcher.post<DashboardEntity>(`${namespace}/${options}`, null);
    return data;
  }
  const { documentUuid } = options;
  const { data } = await coreFetcher.post<DashboardEntity>(
    `${namespace}/${documentUuid}?${stringify(options)}`,
    null
  );
  return data;
}

export interface MoveOptions {
  documentId: EntityUuid;
  projectUuid: EntityUuid;
}

async function moveDocumentRequest({ documentId, projectUuid }: MoveOptions) {
  const { data } = await coreFetcher.patch<DashboardEntity>(`${namespace}/${documentId}/project`, {
    projectUuid,
  });
  return data;
}

async function getQueryParamsRequest(documentId: EntityUuid) {
  const { data } = await coreFetcher.get<string[]>(`${namespace}/${documentId}/query-params`);
  return data;
}

async function updateStarredRequest(documentId: EntityUuid, starred: boolean) {
  const { data } = await coreFetcher.patch<number>(`${namespace}/${documentId}/star`, {
    starred,
  });
  return data;
}

async function getDashboardTemplatesRequest() {
  const response = await coreFetcher.get<DashboardTemplateEntity[]>(`${namespace}/template`);
  return response.data || [];
}

type BaseDashboardRequest = {
  dashboardId?: EntityUuid;
};

async function getDashboardSharesRequest({ dashboardId = '' }: BaseDashboardRequest) {
  const response = await coreFetcher.get<ShareEntity[]>(`${namespace}/${dashboardId}/share`);

  return (
    response.data.sort(
      (share1, share2) => Date.parse(share2.created_at) - Date.parse(share1.created_at)
    ) || []
  );
}

async function getDashboardQueriesRequest({ dashboardId = '' }: BaseDashboardRequest) {
  const response = await coreFetcher.get<QueryEntity[]>(`${namespace}/${dashboardId}/query`);
  return response.data || [];
}

async function getDashboardQueriesElementsCountRequest({ dashboardId = '' }: BaseDashboardRequest) {
  const response = await coreFetcher.get<
    (Pick<QueryEntity, 'query_id'> & { elements_count: number })[]
  >(`${namespace}/${dashboardId}/query-elements-count`);
  return response.data || [];
}

async function getDashboardConnectionStringsRequest({ dashboardId = '' }: BaseDashboardRequest) {
  const response = await coreFetcher.get<ConnectionStringEntity[]>(
    `${namespace}/${dashboardId}/connection-string`
  );
  return response.data || [];
}

async function getDashboardConnectionStringsElementsCountRequest({
  dashboardId = '',
}: BaseDashboardRequest) {
  const response = await coreFetcher.get<
    (Pick<ConnectionStringEntity, 'connection_string_id'> & { elements_count: number })[]
  >(`${namespace}/${dashboardId}/connection-string-elements-count`);
  return response.data || [];
}

export type PasteTemplateIntoDashboardOptions = {
  dashboardId: EntityUuid;
  templateId: EntityUuid;
};

async function pasteTemplateIntoDashboardRequest({
  dashboardId,
  templateId,
}: PasteTemplateIntoDashboardOptions) {
  const response = await coreFetcher.put(`/dashboard/${dashboardId}/template/${templateId}`, null);
  return response.data || [];
}

export type ElementSearchItemType = `element_${ElementType}`;

export type ElementSearchItem = {
  id: string;
  type: ElementSearchItemType;
  label: string;
  data: ElementEntity;
};

export type PageSearchItem = {
  id: string;
  type: 'page';
  label: string;
  data: PageEntity;
};

export type DashboardSearchItem = ElementSearchItem | PageSearchItem;

async function getDashboardSearchItemsRequest({ dashboardId = '' }: BaseDashboardRequest) {
  const response = await coreFetcher.get<DashboardSearchItem[]>(
    `${namespace}/${dashboardId}/search-items`
  );
  return response.data || [];
}

type PagesRelatedQueryOptions = {
  documentId: EntityUuid;
  queryId: EntityUuid;
  connectionStringId: EntityUuid;
};

export type DashboardPagesRelatedQuery = {
  elements: ChartElementEntity[];
} & PageEntity;

async function getDashboardPagesRelatedQueryRequest({
  documentId,
  queryId: query_id,
  connectionStringId: connection_string_id,
}: PagesRelatedQueryOptions) {
  const response = await coreFetcher.get<{ pages: DashboardPagesRelatedQuery[] }>(
    `${namespace}/${documentId}/page-with-elements?${stringify({
      element_type: 'chart',
      query_id,
      connection_string_id,
    })}`
  );
  return response.data.pages || [];
}

export default {
  ...omit(requests, ['getManyRequest', 'updateOneRequest', 'getOneRequest']),
  getOneRequest,
  updateOneRequest,
  getManyRequest,
  getQueryParamsRequest,
  duplicateDocumentRequest,
  moveDocumentRequest,
  updateStarredRequest,
  getDashboardTemplatesRequest,
  getDashboardSharesRequest,
  getDashboardQueriesRequest,
  getDashboardConnectionStringsRequest,
  getDashboardSearchItemsRequest,
  getDashboardPagesRelatedQueryRequest,
  pasteTemplateIntoDashboardRequest,
  getDashboardQueriesElementsCountRequest,
  getDashboardConnectionStringsElementsCountRequest,
};
