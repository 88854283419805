import type {
  QueryFunctionContext,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import { FetchError } from '~services/fetcher-factory';

export interface QueryHook<TData = unknown, TVariables = void, TError = unknown> {
  <TReturn = TData>(
    params: TVariables,
    options?: Omit<UseQueryOptions<TData, TError, TReturn>, 'queryKey'>
  ): UseQueryResult<TReturn, TError>;
  getKey: (params?: TVariables) => QueryKey;
  getFunction: (params: TVariables) => (ctx: QueryFunctionContext) => Promise<TData>;
}

export interface CreateQueryHookOptions<TVariables> {
  transformKey?: (value: TVariables) => readonly unknown[];
}

export function createQueryHook<
  TKey extends QueryKey = QueryKey,
  TData = FetchError,
  TVariables = void,
  TError = FetchError,
>(
  key: TKey,
  queryFn: (variables: TVariables, signal?: AbortSignal) => Promise<TData>,
  { transformKey = (params) => [params] }: CreateQueryHookOptions<TVariables> = {}
) {
  const queryHook: QueryHook<TData, TVariables, TError> = <TReturn = TData>(
    variables: TVariables,
    options?: Omit<UseQueryOptions<TData, TError, TReturn>, 'queryKey' | 'queryFn'>
  ) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery<TData, TError, TReturn>({
      queryKey: queryHook.getKey(variables),
      queryFn: queryHook.getFunction(variables),
      ...options,
    });
  };
  queryHook.getFunction = (params) => (ctx) => queryFn(params, ctx.signal);
  queryHook.getKey = (params) => {
    if (typeof params === 'undefined') {
      return key;
    }
    if (Array.isArray(key)) {
      return [...key, ...transformKey(params)];
    }
    return [key, ...transformKey(params)];
  };
  return queryHook;
}

export function createMutationHook<TData = unknown, TError = FetchError, TVariables = void>(
  mutationFn: (variables: TVariables) => Promise<TData>,
  defaultOptions: Omit<UseMutationOptions<TData, TError, TVariables>, 'mutationFn'> = {}
) {
  return (options?: Omit<UseMutationOptions<TData, TError, TVariables>, 'mutationFn'>) => {
    return useMutation({
      mutationFn,
      ...defaultOptions,
      ...options,
      onSuccess: (...args) => {
        defaultOptions.onSuccess && defaultOptions.onSuccess(...args);
        options?.onSuccess && options.onSuccess(...args);
      },
      onSettled: (...args) => {
        defaultOptions.onSettled && defaultOptions.onSettled(...args);
        options?.onSettled && options.onSettled(...args);
      },
    });
  };
}
