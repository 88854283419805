import React, { useCallback } from 'react';

import { EntityUuid } from '~services/v1/types';
import { ClipboardElementNode, useClipboard } from '~stores/clipboard.store';

import { useConfirmDeleteElement } from '../element-board.hooks';
import { BaseBoardProps, CopiedNodeData } from '../element-board.types';

interface UseShortcutHandlerProps
  extends PickAndTransformOptional<
    BaseBoardProps,
    'onChangeSelectedNodeIds' | 'onDeleteElement' | 'copyItemData'
  > {
  getSelectedNodes: () => ClipboardElementNode[];
  deleteNodesFromLayout: (nodeIds: EntityUuid[]) => void | Promise<void>;
  activateAllNodes: () => void;
  addCopyNodeToLayout: (item: CopiedNodeData) => void;
}

export function useShortcutHandler({
  getSelectedNodes,
  activateAllNodes,
  addCopyNodeToLayout,
  copyItemData,
  deleteNodesFromLayout,
  onChangeSelectedNodeIds,
  onDeleteElement,
}: UseShortcutHandlerProps) {
  const [clipboardValue, setClipboardValue] = useClipboard();

  const confirmDelete = useConfirmDeleteElement();

  return useCallback(
    (e: React.KeyboardEvent) => {
      //ql-editor is class of text element
      const selectedNodes = getSelectedNodes();
      if (
        !selectedNodes ||
        (e.target as HTMLElement).matches('textarea, input, [contenteditable="true"], .ql-editor')
      ) {
        return;
      }

      switch (e.key) {
        case 'a':
          if (e.metaKey || e.ctrlKey) {
            e.preventDefault();
            activateAllNodes();
          }
          break;
        case 'c':
          if (e.metaKey || e.ctrlKey) {
            setClipboardValue({
              type: 'nodes',
              data: selectedNodes,
            });
          }
          break;
        case 'v':
          if (!clipboardValue || !copyItemData) {
            return;
          }
          if ((e.metaKey && !e.ctrlKey) || (!e.metaKey && e.ctrlKey)) {
            for (const node of clipboardValue.data) {
              copyItemData({ elementId: node.data.elementId })?.then((data) =>
                addCopyNodeToLayout({
                  source: node,
                  target: { data },
                })
              );
            }
          }
          break;
        case 'Delete':
        case 'Backspace':
          if (!selectedNodes?.length) {
            return;
          }
          confirmDelete(selectedNodes.length, async () => {
            await deleteNodesFromLayout(selectedNodes.map((slot) => slot.id));
            onChangeSelectedNodeIds?.([]);
            onDeleteElement?.(selectedNodes.map((slot) => slot.data));
          });
          break;
        case 'Escape':
          onChangeSelectedNodeIds?.([]);
          break;

        default:
          break;
      }
    },
    [
      getSelectedNodes,
      clipboardValue,
      copyItemData,
      confirmDelete,
      onChangeSelectedNodeIds,
      activateAllNodes,
      setClipboardValue,
      addCopyNodeToLayout,
      deleteNodesFromLayout,
      onDeleteElement,
    ]
  );
}
