import React from 'react';
import { EyeIcon, EyeSlashIcon, LockIcon } from '@dataflake/icons';
import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';

import AppTextField from '../app-text-field';

export type PasswordInputProps = TextFieldProps;

export const PasswordInput = React.forwardRef<HTMLDivElement, PasswordInputProps>(
  function PasswordInput(props, ref) {
    const [showPassword, setShowPassword] = React.useState(false);

    function toggleShowPassword() {
      setShowPassword(!showPassword);
    }

    return (
      <AppTextField
        {...props}
        ref={ref}
        type={showPassword ? 'text' : 'password'}
        rounded
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={toggleShowPassword}
                onMouseDown={toggleShowPassword}
              >
                {!showPassword ? <EyeIcon /> : <EyeSlashIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
);
