import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function createIcon(name: string, paths: string[] | string, viewBox?: string) {
  const pathArray = Array.isArray(paths) ? paths : [paths];
  function Icon(props: SvgIconProps) {
    return (
      <SvgIcon {...props} viewBox={viewBox}>
        {pathArray.map((path, index) => (
          <path key={index} d={path} />
        ))}
      </SvgIcon>
    );
  }
  Icon.displayName = `${name}Icon`;
  return Icon;
}
