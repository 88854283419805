import { rgb } from 'd3-color';

import { removeNullUndefined } from '~utils/object';

import { ElementTheme } from '../element';

import {
  DashboardThemeColor,
  DashboardThemeText,
  themeTextConfigKeys,
} from './dashboard-theme.entity';

export function fillNullWithGeneralStyleThemeTextConfig(
  textConfig?: DeepPartial<DashboardThemeText>
) {
  if (!textConfig) {
    return textConfig;
  }
  return themeTextConfigKeys.reduce((acc, key) => {
    acc[key] = {
      ...removeNullUndefined(textConfig['general'] || {}),
      ...removeNullUndefined(textConfig[key] || {}),
    };
    return acc;
  }, {} as DeepPartial<DashboardThemeText>);
}

export function fillThemeTextColor(textColors?: Partial<DashboardThemeColor['text']>) {
  if (!textColors) {
    return textColors;
  }

  return themeTextConfigKeys.reduce(
    (acc, key) => {
      switch (key) {
        case 'legend':
          acc[key] =
            textColors[key] ||
            (textColors?.general
              ? rgb(textColors?.general).copy({ opacity: 0.7 }).formatRgb()
              : undefined);
          break;

        case 'tooltip':
          acc[key] = textColors[key];
          break;

        default:
          acc[key] = textColors[key] || textColors.general;
          break;
      }

      return acc;
    },
    {} as Partial<DashboardThemeColor['text']>
  );
}

export function normalizeElementTheme(elementTheme?: ElementTheme): ElementTheme {
  if (!elementTheme) {
    return {};
  }

  return removeNullUndefined({
    ...elementTheme,
    text: fillNullWithGeneralStyleThemeTextConfig(elementTheme?.text),
    color: {
      ...elementTheme?.color,
      text: fillThemeTextColor(elementTheme?.color?.text),
    },
  });
}
