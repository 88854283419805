import { SetStateAction, useCallback, useReducer } from 'react';

import { localStorage } from '../storage';

export const localStorageReducer =
  <S>(key: string) =>
  (prevState: S, action: SetStateAction<S>): S => {
    const newState = action instanceof Function ? action(prevState) : action;
    localStorage.setItem(key, JSON.stringify(newState));
    return newState;
  };

export function useLocalStorageState<T>(key: string, defaultValue: T) {
  const [state, dispatch] = useReducer(
    localStorageReducer<T>(key),
    (localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key) || '') : defaultValue) as T
  );
  return [state, useCallback((value: SetStateAction<T>) => dispatch(value), [])] as const;
}
