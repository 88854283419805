import { styled } from '@mui/material';

export const StyledDot = styled('div')(({ theme }) => ({
  height: 0,
  width: 0,
  ':after': {
    content: '""',
    display: 'block',
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.lighter,
    transform: 'translate(-50%, -50%)',
  },
}));
