export interface CreateFetcherOptions {
  baseURL: string;
  fetcherOptions: (defaultOptions: RequestInit) => RequestInit;
}

export interface QuickFetcherOptions {
  signal?: AbortSignal;
  headers?: Record<string, string | undefined | null>;
}

export type FetchOptions = QuickFetcherOptions | ((options: RequestInit) => RequestInit);

export class FetchError extends Error {
  constructor(
    public response: Response,
    public body: unknown
  ) {
    const message = typeof body === 'string' ? body : (body as any)?.message;
    super(message || response.statusText);
  }
}

export interface FetchResult<T> extends Response {
  data: T;
}

export function getOptions(options: RequestInit, overrideOptions: FetchOptions | undefined) {
  if (!overrideOptions) {
    return options;
  }
  if (typeof overrideOptions === 'function') {
    return overrideOptions(options);
  }
  return {
    ...options,
    ...overrideOptions,
    headers: {
      ...options.headers,
      ...overrideOptions.headers,
    },
  };
}

export class Fetcher {
  constructor(private options: CreateFetcherOptions) {}

  get<T = unknown>(path: string, fetchOptions?: FetchOptions) {
    return this.fetch<T>(path, (options: RequestInit) => {
      return {
        ...getOptions(options, fetchOptions),
        method: 'GET',
      };
    });
  }

  delete<T = unknown>(path: string, fetchOptions?: FetchOptions) {
    return this.fetch<T>(path, (options: RequestInit) => {
      return {
        ...getOptions(options, fetchOptions),
        method: 'DELETE',
      };
    });
  }

  post<T = unknown>(path: string, body: unknown | null, fetchOptions?: FetchOptions) {
    return this.fetchWithBody<T>(path, body, (options: RequestInit) => {
      return {
        ...getOptions(options, fetchOptions),
        method: 'POST',
      };
    });
  }

  put<T = unknown>(path: string, body: unknown | null, fetchOptions?: FetchOptions) {
    return this.fetchWithBody<T>(path, body, (options: RequestInit) => {
      return {
        ...getOptions(options, fetchOptions),
        method: 'PUT',
      };
    });
  }

  patch<T = unknown>(path: string, body: unknown | null, fetchOptions?: FetchOptions) {
    return this.fetchWithBody<T>(path, body, (options: RequestInit) => {
      return {
        ...getOptions(options, fetchOptions),
        method: 'PATCH',
      };
    });
  }

  fetchWithBody<T = unknown>(path: string, body: unknown | null, fetchOptions?: FetchOptions) {
    let useJson = false;
    let safeBody: BodyInit | null = null;
    if (
      body === null ||
      body instanceof ReadableStream ||
      body instanceof Blob ||
      body instanceof ArrayBuffer ||
      ArrayBuffer.isView(body) ||
      body instanceof FormData ||
      body instanceof URLSearchParams ||
      typeof body === 'string'
    ) {
      safeBody = body;
    } else {
      safeBody = JSON.stringify(body);
      useJson = true;
    }
    return this.fetch<T>(path, (_options: RequestInit) => {
      const options = getOptions(_options, fetchOptions);
      return {
        ...options,
        headers: {
          ...options.headers,
          ...(useJson ? { 'Content-Type': 'application/json' } : {}),
        },
        body: safeBody,
      };
    });
  }

  rawFetch(path: string, fetchOptions?: FetchOptions) {
    const options = this.options.fetcherOptions({
      headers: {
        Accept: 'application/json',
      },
    });
    return window.fetch(`${this.options.baseURL}${path}`, getOptions(options, fetchOptions));
  }
  async fetch<T = unknown>(path: string, fetchOptions?: FetchOptions): Promise<FetchResult<T>> {
    const response = await this.rawFetch(path, fetchOptions);
    if (!response.ok) {
      let body: unknown;
      try {
        body = await response.text();
        body = JSON.parse(body as string);
      } catch (e) {
        console.error(e);
      }
      throw new FetchError(response, body);
    }
    const data = (await response.json()) as T;
    return {
      ...response,
      data,
    };
  }
}
