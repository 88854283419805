import { Box, ButtonBase, styled, Typography } from '@mui/material';

export const DfCalendarRoot = styled(Box)(({}) => ({
  width: 36 * 7,
}));

export const DfCalendarHeader = styled(Box)(({}) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const DfCalendarNextPrevButton = styled(ButtonBase)(({}) => ({}));

export const DfCalendarDayGrid = styled(Box)(({}) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(7, 36px)',
}));

export const DfCalendarWeekLabel = styled(Typography)(({}) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  textTransform: 'uppercase',
}));

export const DfCalendarDayButton = styled(ButtonBase)<{ selected?: boolean; today?: boolean }>(
  ({}) => ({
    width: '36px',
    height: '36px',
  }),
  ({ today, theme }) =>
    today && {
      border: `thin solid ${theme.palette.primary.main}`,
    },
  ({ selected, theme }) =>
    selected
      ? {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
        }
      : {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }
);
