import { useEffect, useRef, useState } from 'react';

import { useHistory } from './use-history';

export function useSearch() {
  const history = useHistory();
  const [search, setSearch] = useState(history.location.search);
  const prevState = useRef(search);
  prevState.current = search;

  useEffect(() => {
    return history.listen(({ location }) => {
      if (location.search === prevState.current) {
        return;
      }
      setSearch(location.search);
    });
  }, [history]);
  return search;
}
