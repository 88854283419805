import deepmerge, { all } from 'deepmerge';

import { MIN_SIZE, NodeData } from '~components/element-board';
import {
  ChartElementEntity,
  checkElementType,
  ElementEntity,
  FilterElementEntity,
  getElementMinSize,
  isElementsHasSameType,
  isTextFilterConfig,
} from '~services/v1/element';
import { EntityUuid } from '~services/v1/types';
import { memoizeSelector } from '~utils/zustand';

import { DashboardState } from '../store/dashboard-store';

// import { selectFooterShow, selectHeaderShow } from './dashboard.selector';

export const selectRawElements = (state: DashboardState) => state.elements;

export const selectElements = selectRawElements;
export const selectElementsByIds = (ids: string[]) =>
  memoizeSelector(selectRawElements, (els) => els.filter((el) => ids.includes(el.element_id)));

export const selectChartElements = memoizeSelector(selectRawElements, (els) =>
  els.filter((el): el is ChartElementEntity => el.element_type === 'chart').map((el) => el)
);

export const selectEnableCustomizationChartElements = memoizeSelector(selectElements, (els) =>
  els.filter(
    (el): el is ChartElementEntity =>
      el.element_type === 'chart' && !!el.element_enable_customization
  )
);

/**
 * Shouldn't use it directLy
 */
export const makeSelectRawElement = ((id: EntityUuid) =>
  memoizeSelector(selectElements, (elements) => {
    const element = elements.find((el) => el.element_id === id);
    if (!element) {
      throw new Error(`Element ${id} is not existed`);
    }
    return element;
  })) as <T extends ElementEntity = ElementEntity>(id: EntityUuid) => (state: DashboardState) => T;

/**
 * Should use win shallow function
 */
export const makeSelectElement = makeSelectRawElement;

/**
 * Should use win shallow function
 */
// export const makeSelectElementPosition = memoize((id: EntityUuid) =>
//   createSelector(makeSelectRawElement<AnyElementEntity>(id), (el) => {
//     return el.element_style_position;
//   })
// );

// Commented because these are very dangerous to use
// /**
//  * Should use win shallow function
//  */
// export const makeSelectElementSize = memoize((id: EntityUuid) =>
//   createSelector(makeSelectRawElement<AnyElementEntity>(id), (el) => {
//     return pickProperties(el.element_style_position, ['width', 'height']);
//   })
// );
//
// /**
//  * Should use win shallow function
//  */
// export const makeSelectElementWidth = memoize((id: EntityUuid) =>
//   createSelector(makeSelectRawElement<AnyElementEntity>(id), (el) => {
//     return el.element_style_position.width;
//   })
// );
//
// /**
//  * Should use win shallow function
//  */
// export const makeSelectElementHeight = memoize((id: EntityUuid) =>
//   createSelector(makeSelectRawElement<AnyElementEntity>(id), (el) => {
//     return el.element_style_position.height;
//   })
// );

/**
 * Should use win shallow function
 */
// export const makeSelectElementAnchors = memoize((id: EntityUuid) =>
//   createSelector(makeSelectRawElement<AnyElementEntity>(id), (el) => {
//     return el.element_anchors;
//   })
// );

// export const selectSortedElementsOrderText = createSelector(selectElements, (elements) =>
//   elements
//     .sort((e1, e2) => Number(e1.element_order) - Number(e2.element_order))
//     .map((el) => el.element_id)
//     .join(',')
// );
// // TODO reimplement comments above
// export const selectSortedElementsOrder = createSelector(
//   selectSortedElementsOrderText,
//   (elementIdsString) => {
//     return elementIdsString.split(',');
//   }
// );

// export const selectActiveElementsBoundaryBox = createSelector(selectActiveElements, (elements) => {
//   return getItemsBoundaryBox(elements.map((el) => el.element_style_position));
// });

export const selectParamsInFilterElements = memoizeSelector(
  selectElements,
  (elements: ElementEntity[]) => {
    const params = new Set<string>();
    const filterElements = elements.filter(checkElementType<FilterElementEntity>('filter'));
    filterElements.forEach((el) => {
      if (!isTextFilterConfig(el.element_config)) {
        el.element_config.queryParameter?.forEach((item) => {
          if (item) {
            params.add(item);
          }
        });
      } else if (el.element_config.queryParameter) {
        params.add(el.element_config.queryParameter);
      }
    });
    return params;
  }
);

function customMerge<T>(a: T, b: T) {
  if (Array.isArray(a) || Array.isArray(b)) {
    return [];
  }
  if (a !== null && b !== null && typeof a === 'object' && typeof b === 'object') {
    return deepmerge(a, b, {
      customMerge: () => customMerge,
    });
  }
  if (a !== b) {
    return undefined;
  }
  return a;
}

export const selectMergedElements = (elementIds: string[]) => (state: DashboardState) => {
  const elements = state.elements.filter((el) => elementIds.includes(el.element_id));
  if (elements.length < 2 || !isElementsHasSameType(elements)) {
    return null;
  }
  return all<ElementEntity>(elements, {
    customMerge() {
      return customMerge;
    },
  });
};

export const selectElementMinSizeGetter = memoizeSelector(
  selectElements,
  (elements) =>
    ({ elementId }: NodeData) => {
      const element = elements.find((element) => element.element_id !== elementId);
      return element ? getElementMinSize(element) : MIN_SIZE();
    }
);

export const selectElementsUsingQuery = (queryId: string) =>
  memoizeSelector(selectElements, (elements) =>
    elements.filter((element) => {
      return element.query_id === queryId;
    })
  );
