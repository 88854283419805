import { editor, IDisposable, languages, Position } from 'monaco-editor';
import IStandaloneCodeEditor = editor.IStandaloneCodeEditor;
import EditorOption = editor.EditorOption;
import * as monaco from 'monaco-editor';
import { language as sqlLanguage } from 'monaco-sql-languages/out/esm/sql/sql';

import {
  ConnectionStringStructure,
  IntrospectObject,
  IntrospectObjectType,
} from '~services/v1/connection-string';
import ICodeEditor = editor.ICodeEditor;

export function increaseFontSize(editor: IStandaloneCodeEditor, amount = 1) {
  const fontSize = editor?.getOptions()?.get(EditorOption.fontSize) || 12;
  editor?.updateOptions({
    fontSize: fontSize + amount,
  });
}

export function getSelectedText(editor: ICodeEditor) {
  const model = editor.getModel();
  const selection = editor.getSelection();
  return !!model && !!selection && model.getValueInRange(selection).trim();
}

export function registerSQLTableCompletion(
  connectionStringStructure: ConnectionStringStructure | undefined
) {
  let disposable: IDisposable | null = null;
  // or make sure that it exists by other ways
  disposable = monaco?.languages.registerCompletionItemProvider('sql', {
    provideCompletionItems: (model: editor.ITextModel, position: Position) => {
      const word = model.getWordUntilPosition(position);
      const range = {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: word.startColumn,
        endColumn: word.endColumn,
      };
      const suggestionsFromDefaultKeywords = ((sqlLanguage.keywords ?? []) as string[]).map(
        (kw) => ({
          label: `${kw.toUpperCase()}`,
          kind: monaco.languages.CompletionItemKind.Keyword,
          detail: 'Keyword',
          insertText: `${kw.toUpperCase()} `,
          range: range,
        })
      );

      const extraCompletionItems: languages.CompletionItem[] = [];

      const uniques: Record<IntrospectObjectType, Set<string>> = {
        database: new Set(),
        schema: new Set(),
        table: new Set(),
        view: new Set(),
        column: new Set(),
        function: new Set(),
      };

      function walkThroughTree(item: IntrospectObject) {
        if (!monaco) {
          return;
        }
        const kinds: Record<IntrospectObjectType, languages.CompletionItemKind> = {
          database: monaco.languages.CompletionItemKind.Interface,
          schema: monaco.languages.CompletionItemKind.Interface,
          table: monaco.languages.CompletionItemKind.Class,
          view: monaco.languages.CompletionItemKind.Class,
          column: monaco.languages.CompletionItemKind.Field,
          function: monaco.languages.CompletionItemKind.Function,
        };

        if (!uniques[item.__type].has(item.name)) {
          uniques[item.__type].add(item.name);
          extraCompletionItems.push({
            label: item.name,
            kind: kinds[item.__type] ?? monaco.languages.CompletionItemKind.Text,
            detail: item.__type.slice(0, 1).toUpperCase() + item.__type.slice(1),
            insertText: item.name,
            range: range,
          });
        }

        if (item.children) {
          item.children.forEach(walkThroughTree);
        }
      }

      if (connectionStringStructure) {
        connectionStringStructure.tree.forEach(walkThroughTree);
      }

      return {
        suggestions: [...suggestionsFromDefaultKeywords, ...extraCompletionItems],
      };
    },
  });
  return () => {
    disposable?.dispose();
  };
}

export function appendText(
  editor: monaco.editor.ICodeEditor,
  text: string,
  { newLine = false, keepSelection = false }: { newLine?: boolean; keepSelection?: boolean }
) {
  const model = editor.getModel();
  if (!model) {
    return;
  }
  const line = model.getLineCount();
  const column = model.getLineLength(line) + 1;

  if (newLine && model.getLineLength(line) !== 0) {
    text = `\n${text}`;
  }
  editor.setPosition({
    lineNumber: line,
    column,
  });

  editor.trigger('keyboard', 'type', { text });

  const lineAfter = model.getLineCount();
  const columnAfter = model.getLineLength(lineAfter) + 1;

  if (keepSelection) {
    editor.setSelections([
      {
        selectionStartLineNumber: line,
        selectionStartColumn: column,
        positionLineNumber: lineAfter,
        positionColumn: columnAfter,
      },
    ]);
  }
}
