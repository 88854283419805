import { MagnifyingGlassIcon } from '@dataflake/icons';
import { Input, InputAdornment, InputProps } from '@mui/material';

export interface SearchInputProps extends InputProps {}

export function SearchInput(props: SearchInputProps) {
  return (
    <Input
      fullWidth
      autoFocus
      startAdornment={
        <InputAdornment position="start">
          <MagnifyingGlassIcon sx={{ fontSize: 12 }} />
        </InputAdornment>
      }
      {...props}
    />
  );
}
