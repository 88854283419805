import React from 'react';

import { DotInner, DotOuter, DotsWrapper } from './dots.styled';

export interface DotsProps {
  count: number;
  index: number;
  onDotClick?: (event: React.MouseEvent<HTMLDivElement>, index: number) => void;
}

export function Dots({ count, onDotClick, index, ...rest }: DotsProps) {
  const [internalIndex, setInternalIndex] = React.useState(index);
  const controlledIndex = index ? index : internalIndex;

  function handleDotClick(event: React.MouseEvent<HTMLDivElement>, index: number) {
    setInternalIndex(index);
    onDotClick && onDotClick(event, index);
  }

  return (
    <DotsWrapper {...rest}>
      {[...Array(count).keys()].map((i) => (
        <DotOuter
          key={i}
          style={{
            cursor: onDotClick != null ? 'pointer' : 'inherit',
          }}
          active={controlledIndex === i}
          onClick={(event) => handleDotClick(event, i)}
        >
          <DotInner elevation={0} />
        </DotOuter>
      ))}
    </DotsWrapper>
  );
}
