import { Box, ButtonBase, styled, svgIconClasses } from '@mui/material';

export const ListItemButtonMain = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasChildren',
})<{ hasChildren: boolean }>(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    paddingInline: theme.spacing(0.5),
    marginBlockEnd: theme.spacing(0.75),
    gap: theme.spacing(0.25),
  }),
  ({ hasChildren, theme }) => ({
    paddingInlineStart: hasChildren ? 0 : theme.spacing(3),
  })
);

export const ListItemButtonText = styled(Box)(({ theme }) => ({
  paddingInline: theme.spacing(0.25),
  flexGrow: 1,
  fontSize: theme.typography.body2.fontSize,
  flexShrink: 1,
  overflow: 'hidden',
  display: 'flex',
  '.copy-button': {
    display: 'none',
  },
  '&:hover': {
    '.copy-button': {
      display: 'inline-block',
    },
  },
}));

export const TextActionButton = styled(ButtonBase)(({ theme }) => ({
  ...theme.typography.caption,
  borderRadius: theme.shape.borderRadius / 2,
  paddingInline: theme.spacing(0.5),
  textTransform: 'uppercase',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightBold,
  whiteSpace: 'nowrap',
}));

export const OutlinedActionButton = styled(TextActionButton)(({ theme }) => ({
  border: `thin solid ${theme.palette.primary.light}`,
}));

export const ListItemButtonDropdownIcon = styled('span', {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean | undefined }>(
  ({ theme }) => ({
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      borderRadius: theme.shape.borderRadius,
    },
    [`.${svgIconClasses.root}`]: {
      transition: theme.transitions.create('transform'),
    },
  }),
  ({ open }) => ({
    [`.${svgIconClasses.root}`]: {
      transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
    },
  })
);
