import React from 'react';
import {
  closestCenter,
  DndContext,
  MeasuringConfiguration,
  MeasuringStrategy,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

const measuring: MeasuringConfiguration = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

interface DndProviderProps {}

export function DndProvider({ children }: React.PropsWithChildren<DndProviderProps>) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} measuring={measuring}>
      {children}
    </DndContext>
  );
}
