import { styled } from '@mui/material';

export const List = styled('ul')(({ theme }) => ({
  listStyleType: 'none',
  padding: 0,
  margin: 0,
  ul: {
    paddingLeft: theme.spacing(1.5),
  },
}));
export const ListItem = styled('li')(() => ({}));
