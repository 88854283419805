import { useCallback } from 'react';

import { useConfirmDeleteElement } from '../../../../../element-board.hooks';
import { BaseNode } from '../../../../../element-board.types';
import { GridBoardProps } from '../../../grid-board.type';
import { removeNodeFromGridLayout } from '../../../grid-board.updater';

export function useContextMenuHandler({
  onContextMenu,
  node,
  onDeleteElement,
  onChangeSelectedNodeIds,
  selectedNodes,
  onChange,
}: Pick<
  GridBoardProps,
  'onContextMenu' | 'onDeleteElement' | 'onChangeSelectedNodeIds' | 'onChange'
> & {
  node: BaseNode;
  selectedNodes: BaseNode[];
}) {
  const confirmDelete = useConfirmDeleteElement();
  return useCallback(
    (e: React.MouseEvent) => {
      onContextMenu?.(e, {
        type: 'element',
        elementData: [node],
        remove: () => {
          confirmDelete(selectedNodes.length, async () => {
            await onChange?.(removeNodeFromGridLayout(selectedNodes.map((node) => node.id)));
            onChangeSelectedNodeIds?.([]);
            onDeleteElement?.(selectedNodes.map((node) => node.data));
          });
        },
      });
    },
    [
      selectedNodes,
      confirmDelete,
      node,
      onChange,
      onChangeSelectedNodeIds,
      onContextMenu,
      onDeleteElement,
    ]
  );
}
