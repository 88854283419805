import deepmerge from 'deepmerge';

import { addNodeToNewRow, GridBoardDefaultProps, GridRow } from '~components/element-board';
import { useDashboardSearchItemsQuery } from '~services/v1/dashboard/dashboard.query';
import {
  AXIS_LEGEND_WIDTH,
  ChartElementEntity,
  ElementEntity,
  mergeElement,
  NewElementData,
  NORMAL_MOVING_STEP,
  sanitizeNewElementData,
  TableChartElementEntity,
} from '~services/v1/element';
import elementService, { getElementParamDefaultValues } from '~services/v1/element/element.service';
import { EntityUuid } from '~services/v1/types';
import { pushActionHistoryItem } from '~stores/action-history.store';
import { replaceIndex, replaceItem } from '~utils/array';
import { timeId } from '~utils/chore';
import { Debounce } from '~utils/debounce';
import { arrayReplaceStrategy } from '~utils/deepmerge';
import { ResizeDirection } from '~utils/geometry';

import { queryClient } from '../../../react-query';
import { ELEMENT_THEME_STORAGE_KEY } from '../../element-provider';
import { makeSelectElement, makeSelectRawElement } from '../selectors/element.selector';
import { selectActivePageGridLayout, selectActivePageRenderMode } from '../selectors/page.selector';
import { DashboardStore, dispatchDashboardAction } from '../store/dashboard-store';

import { addElementDefaultProperties } from './action.utils';
import { updateActivePageAction } from './page.action';

const updateElementDebounce = new Debounce();
updateElementDebounce.start();

export function addElementStateAction(element: ElementEntity) {
  return (store: DashboardStore) => {
    store.setState(({ elements, elementsParams }) => ({
      elements: [...elements, element],
      elementsParams: {
        ...elementsParams,
        [element.element_id]: getElementParamDefaultValues(element),
      },
    }));
  };
}

export function setElementLoadedStateAction(elementId: EntityUuid) {
  return (store: DashboardStore) => {
    store.setState(({ elements }) => ({
      elements: replaceItem(
        elements,
        (el) => el.element_id === elementId,
        (el) => ({
          ...el,
          loaded: true,
        })
      ),
    }));
  };
}

export function addElementsStateAction(newElements: ElementEntity[]) {
  return (store: DashboardStore) => {
    store.setState(({ elements, elementsParams }) => {
      const newElementsParams: Record<string, Record<string, string>> = {};
      newElements.forEach((element) => {
        elementsParams[element.element_id] = getElementParamDefaultValues(element);
      });
      return {
        elements: [...elements, ...newElements],
        elementsParams: {
          ...elementsParams,
          ...newElementsParams,
        },
      };
    });
  };
}

export function removeElementsStateAction(elementIds: EntityUuid[]) {
  return (store: DashboardStore) => {
    store.setState(({ elements }) => {
      return {
        elements: elements.filter((el) => !elementIds.includes(el.element_id)),
      };
    });
  };
}

export function updateElementsStateAction(elements: ElementEntity[]) {
  return (store: DashboardStore) => {
    store.setState((prevState) => {
      const elementsParams: Record<string, Record<string, string>> = {};
      elements.forEach((element) => {
        elementsParams[element.element_id] = getElementParamDefaultValues(element);
      });
      return {
        elements: prevState.elements.map((element) => {
          const newElement = elements.find(
            (newElement) => newElement.element_id === element.element_id
          );
          if (newElement) {
            return newElement;
          }
          return element;
        }),
        elementsParams: {
          ...prevState.elementsParams,
          ...elementsParams,
        },
      };
    });
  };
}

export const createElementAction =
  (newElementData: NewElementData, saveHistory = true) =>
  async (store: DashboardStore) => {
    const sanitizeData = sanitizeNewElementData(newElementData);

    const persistTheme = localStorage.getItem(ELEMENT_THEME_STORAGE_KEY);

    const elementTheme = persistTheme
      ? deepmerge(sanitizeData.element_theme || {}, JSON.parse(persistTheme), {
          arrayMerge: arrayReplaceStrategy,
        })
      : sanitizeData.element_theme;

    const newProperties = addElementDefaultProperties(
      {
        ...sanitizeData,
        element_theme: elementTheme,
      },
      store.getState()
    );

    const element = await elementService.insertOneRequest(newProperties);
    const pageRenderMode = selectActivePageRenderMode(store.getState());

    if (element.element_type === 'image') {
      switch (pageRenderMode) {
        case 'grid':
          const gridLayout = selectActivePageGridLayout(store.getState());
          await dispatchDashboardAction(
            store,
            updateActivePageAction({
              page_grid_layout: addNodeToNewRow({
                id: timeId(),
                rowId: timeId('row'),
                data: {
                  elementId: element.element_id,
                },
                height: GridBoardDefaultProps.defaultRowHeight,
              })(gridLayout),
            })
          );
        // default:
        //   const pixelLayout = selectActivePagePixelLayout(getState());
        //   await dispatch(
        //     updateActivePageAction({
        //       page_grid_layout: addNodeToPixelBoard({
        //         data: {
        //           elementId: element.element_id,
        //         },
        //         height: GridBoardDefaultProps.defaultRowHeight,
        //       })(pixelLayout),
        //     })
        //   );
      }
    }
    if (pageRenderMode === 'grid' && element.element_type === 'image') {
    }

    queryClient.invalidateQueries({ queryKey: useDashboardSearchItemsQuery.getKey() });
    dispatchDashboardAction(store, addElementStateAction(element));
    if (saveHistory) {
      pushActionHistoryItem({
        redo() {
          return dispatchDashboardAction(store, restoreElementAction(element.element_id, false));
        },
        undo() {
          return dispatchDashboardAction(store, removeElementAction(element.element_id, false));
        },
      });
    }
    return element;
  };

export const copyElementsAction =
  (elementIds: EntityUuid[], targetPageId?: EntityUuid) => async (store: DashboardStore) => {
    const state = store.getState();
    const activePageId = state?.activePage?.page_id;
    const pageId = targetPageId ?? activePageId;
    let config: {
      adjustX?: number;
      adjustY?: number;
    } = {};
    if (state.elements.some((el) => el.element_id === elementIds[0])) {
      config = {
        adjustX: 0,
        adjustY: 0,
      };
    }
    const elements = await elementService.copyElements(elementIds, {
      pageId: pageId || '',
      ...config,
    });

    const pageRenderMode = selectActivePageRenderMode(state);

    queryClient.invalidateQueries({ queryKey: useDashboardSearchItemsQuery.getKey() });
    dispatchDashboardAction(
      store,
      addElementsStateAction(elements.filter((el) => el.page_id === activePageId))
    );

    if (pageRenderMode === 'grid') {
      const gridLayout = selectActivePageGridLayout(store.getState());

      await dispatchDashboardAction(
        store,
        updateActivePageAction({
          page_grid_layout: {
            rows: [
              ...(gridLayout?.rows || []),
              ...elements.map(
                (el, elIndex): GridRow => ({
                  id: timeId(elIndex),
                  height: GridBoardDefaultProps.defaultRowHeight,
                  items: [
                    {
                      id: timeId(),
                      width: 100,
                      data: {
                        elementId: el.element_id,
                      },
                    },
                  ],
                })
              ),
            ],
          },
        })
      );
    }

    pushActionHistoryItem({
      redo() {
        return dispatchDashboardAction(
          store,
          restoreElementsAction(
            elements.map((el) => el.element_id),
            false
          )
        );
      },
      undo() {
        return dispatchDashboardAction(
          store,
          removeElementsAction(
            elements.map((el) => el.element_id),
            false
          )
        );
      },
    });
    return elements;
  };

export const removeElementAction =
  (elementId: EntityUuid, saveHistory = true) =>
  async (store: DashboardStore) => {
    await elementService.deleteOneRequest(elementId);
    queryClient.invalidateQueries({ queryKey: useDashboardSearchItemsQuery.getKey() });
    const element = store.getState().elements.find((el) => el.element_id === elementId);
    dispatchDashboardAction(store, removeElementsStateAction([elementId]));
    if (saveHistory && element) {
      pushActionHistoryItem({
        redo() {
          return dispatchDashboardAction(store, removeElementAction(elementId, false));
        },
        undo() {
          return dispatchDashboardAction(store, restoreElementAction(elementId, false));
        },
      });
    }
    return true;
  };

export const removeElementsAction =
  (elementIds: EntityUuid[], saveHistory = true) =>
  async (store: DashboardStore) => {
    await elementService.deleteManyRequest(elementIds);
    dispatchDashboardAction(store, removeElementsStateAction(elementIds));
    if (saveHistory) {
      pushActionHistoryItem({
        redo() {
          return dispatchDashboardAction(store, removeElementsAction(elementIds, false));
        },
        undo() {
          return dispatchDashboardAction(store, restoreElementsAction(elementIds, false));
        },
      });
    }
    queryClient.invalidateQueries({ queryKey: useDashboardSearchItemsQuery.getKey() });
    return true;
  };

export const restoreElementAction =
  (elementId: EntityUuid, saveHistory = true) =>
  async (store: DashboardStore) => {
    const [element] = await elementService.restoreManyRequest([elementId]);
    queryClient.invalidateQueries({ queryKey: useDashboardSearchItemsQuery.getKey() });
    dispatchDashboardAction(store, addElementStateAction(element));

    if (saveHistory) {
      pushActionHistoryItem({
        redo: () => dispatchDashboardAction(store, restoreElementAction(elementId, false)),
        undo: () => dispatchDashboardAction(store, removeElementAction(elementId, false)),
      });
    }
    return true;
  };

export const restoreElementsAction =
  (elementIds: EntityUuid[], saveHistory = true) =>
  async (store: DashboardStore) => {
    const elements = await elementService.restoreManyRequest(elementIds);
    queryClient.invalidateQueries({ queryKey: useDashboardSearchItemsQuery.getKey() });
    dispatchDashboardAction(store, addElementsStateAction(elements));

    if (saveHistory) {
      pushActionHistoryItem({
        redo: () => dispatchDashboardAction(store, restoreElementsAction(elementIds, false)),
        undo: () => dispatchDashboardAction(store, removeElementsAction(elementIds, false)),
      });
    }
    return true;
  };

export const updateElementAction =
  <T extends ElementEntity = ElementEntity>(
    elementId: EntityUuid,
    properties: Partial<T>,
    saveHistory = true
  ) =>
  async (store: DashboardStore) => {
    const [updatedElement] = await dispatchDashboardAction(
      store,
      updateElementsAction(
        [
          {
            element_id: elementId,
            ...properties,
          } as Partial<T>,
        ],
        saveHistory
      )
    );
    return updatedElement as T;
  };

export function updateElementsAction<T extends ElementEntity = ElementEntity>(
  partialElements: Partial<T>[],
  saveHistory?: boolean
): (store: DashboardStore) => Promise<T[]>;
export function updateElementsAction<T extends ElementEntity = ElementEntity>(
  partialElements: T[],
  saveHistory: boolean,
  override: true
): (store: DashboardStore) => Promise<T[]>;
export function updateElementsAction<T extends ElementEntity = ElementEntity>(
  partialElements: (Partial<T> | T)[],
  saveHistory = true,
  override?: boolean
) {
  return async (store: DashboardStore) => {
    const elements = store.getState()?.elements || [];
    const newElements = elements?.map((element): ElementEntity => {
      if (override) {
        return (
          (partialElements.find(
            (prop) => prop.element_id === element.element_id
          ) as ElementEntity) || element
        );
      }
      const updatedProperties = partialElements.find(
        (prop) => prop.element_id === element.element_id
      );
      const updatedPropertiesWithDefault = updatedProperties
        ? addElementDefaultProperties(updatedProperties as Partial<ElementEntity>, store.getState())
        : undefined;

      return mergeElement<ElementEntity>({
        element,
        updatedProperties: updatedPropertiesWithDefault,
      });
    });

    dispatchDashboardAction(store, updateElementsStateAction(newElements));

    updateElementDebounce.push(() => {
      elementService
        .updateManyRequest(store.getState()?.elements)
        .finally(() => {
          queryClient.invalidateQueries({ queryKey: useDashboardSearchItemsQuery.getKey() });
        })
        .catch(() => {
          store.setState({
            error: true,
          });
        });
      if (saveHistory) {
        pushActionHistoryItem({
          redo: () => {
            return dispatchDashboardAction(
              store,
              updateElementsAction<ElementEntity>(newElements, false)
            ).catch(() => {
              store.setState({
                error: true,
              });
            });
          },
          undo: () => {
            return dispatchDashboardAction(
              store,
              updateElementsAction<ElementEntity>(elements, false, true)
            ).catch(() => {
              store.setState({
                error: true,
              });
            });
          },
        });
      }
    });
    return newElements as T[];
  };
}

const MIN_AXIS_WIDTH = 30;

export const startResizeYAxisMixedChartElementAction =
  ({
    elementId,
    originEvent,
    dir,
    index,
    minWidth = MIN_AXIS_WIDTH,
  }: {
    elementId: string;
    originEvent: React.MouseEvent;
    dir: Extract<ResizeDirection, 'left' | 'right'>;
    index: number;
    minWidth?: number;
  }) =>
  async (store: DashboardStore) => {
    const element = makeSelectRawElement(elementId)(store.getState());
    if (element.element_type === 'chart' && element.element_config.chartType === 'mixed') {
      const yAxis = element.element_config.yAxis;
      const defaultWidth = yAxis?.[index]?.axisWidth ?? AXIS_LEGEND_WIDTH;

      const handleMouseMove = (e: MouseEvent) => {
        const step =
          Math.round((e.clientX - originEvent.clientX) / NORMAL_MOVING_STEP) *
          NORMAL_MOVING_STEP *
          (dir === 'right' ? -1 : 1);

        const newAxisWidth = defaultWidth + step;

        const resizable = newAxisWidth >= minWidth;

        if (!resizable || !yAxis?.length) {
          return;
        }

        dispatchDashboardAction(
          store,
          updateElementAction(elementId, {
            element_config: {
              yAxis: replaceIndex(yAxis, index, {
                ...yAxis[index],
                axisWidth: newAxisWidth,
              }),
            },
          } as Partial<ChartElementEntity>)
        );
      };

      const handleComplete = () => {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleComplete);
        window.removeEventListener('mouseleave', handleComplete);
      };
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleComplete);
      window.addEventListener('mouseleave', handleComplete);
    }
    return;
  };

export function updateElementConfigAction<T extends ElementEntity>(
  elementId: EntityUuid,
  configs: Partial<T['element_config']>,
  saveHistory = true
) {
  return async (store: DashboardStore) => {
    if ('chartType' in configs) {
      const element = store.getState().elements.find((el) => el.element_id === elementId);
      const updatedElement = await elementService.updateConfigRequest(
        elementId,
        configs as Partial<ElementEntity['element_config']>
      );
      dispatchDashboardAction(store, updateElementsStateAction([updatedElement]));
      if (saveHistory && element) {
        pushActionHistoryItem({
          redo: () =>
            dispatchDashboardAction(
              store,
              updateElementAction<ElementEntity>(elementId, updatedElement, false)
            ),
          undo: () =>
            dispatchDashboardAction(
              store,
              updateElementAction<ElementEntity>(elementId, element, false)
            ),
        });
      }
      queryClient.invalidateQueries({ queryKey: useDashboardSearchItemsQuery.getKey() });
      return updatedElement as unknown as T;
    } else {
      return dispatchDashboardAction(
        store,
        updateElementAction(elementId, {
          element_config: configs,
        })
      );
    }
  };
}

export function updateTableColumnSizeConfigAction(elementId: EntityUuid, sizes: number[]) {
  return async (store: DashboardStore) => {
    const element = makeSelectElement<TableChartElementEntity>(elementId)(store.getState());
    await dispatchDashboardAction(
      store,
      updateElementConfigAction<TableChartElementEntity>(elementId, {
        columns: (element?.element_config?.columns || []).map((column, index) => {
          return {
            ...column,
            width: sizes[index],
          };
        }),
      })
    );
  };
}

export function updateElementsConfigAction<T extends ElementEntity>(
  entities: { id: EntityUuid; config: Partial<T['element_config']> }[],
  saveHistory = true
) {
  return updateElementsAction(
    entities.map(({ id, config }) => ({
      element_id: id,
      element_config: config,
    })),
    saveHistory
  );
}

export const updateImageElementAction =
  (elementId: EntityUuid, image: File) => async (store: DashboardStore) => {
    const updatedElement = await elementService.updateOneRequest(elementId, {
      image,
    });
    dispatchDashboardAction(store, updateElementsStateAction([updatedElement]));
    return updatedElement;
  };

export const moveElementToPageAction =
  (
    elementId: EntityUuid,
    pageId: string,
    config: {
      adjustX?: number;
      adjustY?: number;
    } = {},
    saveHistory = true
  ) =>
  async (store: DashboardStore) => {
    const currentPageId = store.getState().activePage?.page_id;
    const element = await elementService.updateOneRequest(elementId, {
      page_id: pageId,
      ...config,
    });
    queryClient.invalidateQueries({ queryKey: useDashboardSearchItemsQuery.getKey() });
    dispatchDashboardAction(store, removeElementsStateAction([elementId]));
    if (saveHistory && currentPageId) {
      pushActionHistoryItem({
        redo: () =>
          dispatchDashboardAction(store, moveElementToPageAction(elementId, pageId, {}, false)),
        undo: () =>
          dispatchDashboardAction(
            store,
            moveElementToPageAction(elementId, currentPageId, {}, false)
          ),
      });
    }
    return element;
  };
