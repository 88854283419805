import { CheckSquareIcon, SquareIcon } from '@dataflake/icons';
import { Box, Checkbox, CheckboxProps, Tooltip } from '@mui/material';

export interface DFCheckboxProps extends CheckboxProps {
  tooltip?: string;
}

export function DFCheckbox({ disabled, tooltip, ...props }: DFCheckboxProps) {
  return (
    <Tooltip title={tooltip || ''}>
      <Box component={'span'} sx={{ cursor: disabled ? 'not-allowed' : undefined }}>
        <Checkbox
          sx={disabled ? { pointerEvents: 'none' } : undefined}
          checkedIcon={<CheckSquareIcon />}
          icon={<SquareIcon />}
          {...props}
        />
      </Box>
    </Tooltip>
  );
}
