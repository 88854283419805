import { authFetcher } from '~services/client';

import { SharedUserScope, UserRole } from '../invitation/invitation.service';
import { deleteOneRequestFactory } from '../request';
import { EntityUuid } from '../types';

const namespace = '/company';

export type UpdateCompanyPayload = {
  company_uuid: EntityUuid;
  company: Partial<CreateCompanyData>;
};

export const updateOneRequest = async ({ company_uuid, company }: UpdateCompanyPayload) =>
  await authFetcher.put(`${namespace}/${company_uuid}`, { company });

export interface CompanyData {
  uuid: string;
  // slug: string;
  name: string;
}

export interface ProjectData {
  uuid: string;
  // slug: string;
  name: string;
}
export interface UserData {
  uuid: string;
  email: string;
  name: string;
  share_level: UserRole;
  share_scope: SharedUserScope;
  share_projects_uuids?: string[];
  created_at: string;
  last_login_at: string;
  share_uuid?: string;
}

export interface GetManyResponse {
  companies?: CompanyData[];
  status: 'success';
}
export const getCompaniesRequest = async () => {
  const res = await authFetcher.get<GetManyResponse>('/user/company?with_project_data=1');
  return res.data.companies;
};

export interface GetOneResponse {
  company: CompanyData;
  projects: ProjectData[];
  users: UserData[];
  invited_users?: UserData[];
}

export const getCompanyRequest = async (companyUuid: string) => {
  const res = await authFetcher.get<GetOneResponse>(`/company/${companyUuid}`);
  return res.data;
};

export interface CreateCompanyData {
  name: string;
}

export const saveCompanyDataRequest = (
  companyUuid: string,
  data: Partial<CreateCompanyData>
): Promise<any> => authFetcher.put(`/company/${companyUuid}/data`, { data });

export const createCompanyRequest = (company: CreateCompanyData): Promise<any> =>
  authFetcher.post('/company', { company });

export const deleteCompanyRequest = deleteOneRequestFactory(namespace);
