import { useCallback } from 'react';

import { useHistory } from './use-history';

export interface SetSearchParamsOptions {
  replace?: boolean;
  clearState?: boolean;
}

export function useSetSearchParams() {
  const history = useHistory();

  return useCallback(
    (
      params: Record<string, string | null | undefined> | null | undefined,
      options?: SetSearchParamsOptions
    ) => {
      if (!params) {
        return;
      }
      const currentParams = new URLSearchParams(options?.replace ? [] : history.location.search);

      Object.entries(params).forEach(([key, value]) => {
        if (value === null || value === undefined) {
          currentParams.delete(key);
        } else {
          currentParams.set(key, value);
        }
      });

      history.push(
        {
          search: currentParams.toString(),
        },
        options?.clearState ? null : history.location.state
      );
    },
    [history]
  );
}
