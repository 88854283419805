import { forwardRef } from 'react';
import { ButtonBase, IconProps, styled } from '@mui/material';

export type DFIconButtonProps = IconProps<'button'>;

const DFIconButtonStyled = styled(ButtonBase)({
  lineHeight: 1,
});

export const DFIconButton = forwardRef<HTMLButtonElement, DFIconButtonProps>(function DFIconButton(
  { children, ...props },
  ref
) {
  return (
    <DFIconButtonStyled {...props} ref={ref}>
      {children}
    </DFIconButtonStyled>
  );
});
