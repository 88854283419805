import { ThemeOptions } from '@mui/material';

declare module '@mui/system/createTheme/shape' {
  interface Shape {
    borderRadiusSm: number;
    borderRadiusLg: number;
    borderRadiusMd: number;
  }
}

export const createShape = (): ThemeOptions['shape'] => ({
  borderRadius: 8,
  borderRadiusMd: 8,
  borderRadiusSm: 4,
  borderRadiusLg: 16,
});
