import { useMemo } from 'react';
import { rectToClientRect } from '@floating-ui/core';
import { ClientRectObject, VirtualElement } from '@floating-ui/react';

import { getItemsBoundaryBox } from '~utils/geometry';

import { BaseBoardProps } from '../element-board.types';

import { RefsMap } from './use-node-refs';

export interface UseSelectedNodeRefsArgs extends Pick<BaseBoardProps, 'selectedNodeIds'> {
  nodeRefs: RefsMap;
}
export function useSelectedNodesBoundingRef({
  nodeRefs,
  selectedNodeIds,
}: UseSelectedNodeRefsArgs): VirtualElement | null {
  return useMemo(() => {
    if (!selectedNodeIds || !selectedNodeIds.length) {
      return null;
    }
    const refs = selectedNodeIds
      .map((id) => nodeRefs[id])
      .filter((item): item is Exclude<typeof item, undefined | null> => !!item);
    const firstRef = refs.at(0);

    if (refs.length === 1 && firstRef) {
      return {
        getBoundingClientRect() {
          return firstRef.getBoundingClientRect();
        },
      };
    }
    const vir: VirtualElement = {
      getBoundingClientRect(): ClientRectObject {
        const boundary = getItemsBoundaryBox(
          refs.map((item) => {
            const { top, left, width, height } = item.getBoundingClientRect();
            return { top, left, width, height };
          })
        );
        return rectToClientRect({
          ...boundary,
          x: boundary.left,
          y: boundary.top,
        });
      },
    };
    return vir;
  }, [nodeRefs, selectedNodeIds]);
}
