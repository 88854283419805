import { CompactMode, UnitConfig } from '~utils/unit';

import { AxisChartBaseConfig } from './axis-chart-element';
import { GenericChartConfig, GenericChartElementEntity } from './chart-element';

export type MixedChartMetricType = 'line' | 'column' | 'area';
export interface MixedChartMetric {
  field: string;
  breakdown?: string;
  type?: MixedChartMetricType;
}

export interface YNotation {
  color: string;
  value: number;
  width: number;
  label: string;
}

export interface MixedChartYAxis {
  metrics: MixedChartMetric[];
  unit?: UnitConfig;
  yAnnotations?: YNotation[];
  min?: string | null;
  max?: string | null;
  compactNumber?: CompactMode;
  showLine?: boolean;
  lineWeight?: number;
  lineColor?: string;
  showMaxValue?: boolean;
  showMinValue?: boolean;
  showAverageValue?: boolean;
  axisWidth?: number;
  showAxis?: boolean;
}

export interface MixedChartSpecialConfig extends AxisChartBaseConfig {
  yAxis?: MixedChartYAxis[];
}

export const defaultMixedChartConfig = (): MixedChartSpecialConfig => ({
  xScale: 'category',
});

export interface MixedChartConfig extends GenericChartConfig<'mixed'>, MixedChartSpecialConfig {}
export interface MixedChartElementEntity extends GenericChartElementEntity<MixedChartConfig> {}
