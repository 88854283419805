import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';

export function SnackbarProvider() {
  return (
    <NotistackSnackbarProvider
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      autoHideDuration={3000}
    />
  );
}
