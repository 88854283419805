import React from 'react';
import { useDndContext } from '@dnd-kit/core';

import { isDndKitEvent } from '~utils/dnd-kit';
import {
  calculateMultipleRectsRelationship,
  filterAndSortNearestRelationships,
  isTriRectRelationship,
  RectRelationship,
  RectSize,
} from '~utils/geometry';

import { PixelBoardKeyboardSensor } from '../../../../sensors/pixel-board-keyboard.sensor';
import { useDraggableTransformedBox } from '../../hooks/use-draggable-transformed-box';
import { DragAndResizeData, PixelLayoutValue } from '../../pixel-board.types';
import { getRelationshipActionFilters } from '../../pixel-board.utils';
import { usePixelBoardSelector } from '../pixel-board-context/pixel-board.hook';
import { selectUnselectedBoxNodes } from '../pixel-board-context/pixel-board.selector';

import { AlignmentLine } from './components/alignment-line/alignment-line';
import { SpacingLines } from './components/spacing-lines/spacing-lines';

export interface AlignmentObjectsProps {
  value: PixelLayoutValue;
  boardSize: RectSize;
}

const SHOWING_DISTANCE = 12;

export function AlignmentObjects({ boardSize }: AlignmentObjectsProps) {
  const dndContext = useDndContext();
  const relatedBoxes = usePixelBoardSelector(selectUnselectedBoxNodes);
  const transformedBox = useDraggableTransformedBox();

  const relationships = React.useMemo(() => {
    const rectRelationship: RectRelationship[] = [];
    const data = dndContext.active?.data?.current as DragAndResizeData;
    const currentRect = transformedBox;

    if (!data || !currentRect || data.context !== 'pixel-board' || data.nodeType !== 'box') {
      return [];
    }

    if (
      dndContext.activatorEvent &&
      isDndKitEvent(dndContext.activatorEvent) &&
      dndContext.activatorEvent.dndKit.capturedBy === PixelBoardKeyboardSensor
    ) {
      // Don't snap when user move by keyboard
      return [];
    }

    const boxRelationships = filterAndSortNearestRelationships(
      calculateMultipleRectsRelationship(currentRect, [
        ...relatedBoxes,
        {
          ...boardSize,
          top: 0,
          left: 0,
        },
      ]),
      SHOWING_DISTANCE
    );
    const relationshipFilters = getRelationshipActionFilters(data);

    relationshipFilters.forEach((filter) => {
      const nearestRelationship = boxRelationships.find(filter);

      if (!nearestRelationship) {
        return;
      }

      boxRelationships
        .filter((relationship) => {
          const distance = Math.abs(relationship.snapDistance);
          const nearestDistance = Math.abs(nearestRelationship.snapDistance);

          return distance === nearestDistance;
        })
        .forEach((relationship) => {
          rectRelationship.push(relationship);
        });
    });

    return rectRelationship;
  }, [dndContext.active?.data, dndContext.activatorEvent, transformedBox, relatedBoxes, boardSize]);

  return (
    <div>
      {relationships.map((relationship, index) =>
        isTriRectRelationship(relationship) ? (
          <SpacingLines {...relationship} key={index} />
        ) : (
          <AlignmentLine {...relationship} key={index} />
        )
      )}
    </div>
  );
}
