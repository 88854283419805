import React, { FC } from 'react';
import {
  inputAdornmentClasses,
  OutlinedInput,
  outlinedInputClasses,
  OutlinedInputProps,
  styled,
} from '@mui/material';

import withTooltip from '~components/with-tooltip';

export type DFOutlinedInputProps = OutlinedInputProps;

export const StyledOutlinedInput = styled(OutlinedInput as FC<DFOutlinedInputProps>)(
  ({ theme }) => ({
    [`.${outlinedInputClasses.notchedOutline}`]: {
      borderRadius: theme.shape.borderRadiusSm,
    },
  }),
  ({ size, theme }) =>
    size === 'small' && {
      [`.${outlinedInputClasses.input}`]: {
        padding: theme.spacing(1, 1.5),
        fontSize: 11,
        lineHeight: '22px',
        height: 'auto',
      },
    },
  ({ size, theme }) =>
    size === 'xs' && {
      [`&.${outlinedInputClasses.root}`]: {
        padding: theme.spacing(0, 0),
      },
      [`.${inputAdornmentClasses.root}.${inputAdornmentClasses.positionEnd}`]: {
        margin: 0,
        paddingRight: theme.spacing(0.5),
      },
      [`.${inputAdornmentClasses.root}.${inputAdornmentClasses.positionStart}`]: {
        margin: 0,
        paddingLeft: theme.spacing(0.5),
      },
      [`.${outlinedInputClasses.input}`]: {
        minWidth: 50,
        padding: theme.spacing(1, 0.5),
        fontSize: theme.typography.inputLabel.fontSize,
        lineHeight: '12px',
        height: 'auto',
      },
    }
);

export const DFOutlinedInput = React.forwardRef(function DFOutlinedInput(
  { size = 'small', ...props }: DFOutlinedInputProps,
  ref
) {
  return <StyledOutlinedInput {...props} size={size} ref={ref} />;
});

export const DFTooltipOutlinedInput = withTooltip(DFOutlinedInput);
