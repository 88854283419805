import React from 'react';

export const PRIMARY_CLICK = 0;

export function isPrimaryClick(e: MouseEvent | React.MouseEvent) {
  return e.button === PRIMARY_CLICK;
}

export const ARROW_KEY_CODES = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'] as const;
export type ArrowKeyCode = (typeof ARROW_KEY_CODES)[number];

export function isArrowKeyCode(key: string): key is ArrowKeyCode {
  return ARROW_KEY_CODES.includes(key as ArrowKeyCode);
}

export function combineHandlers<T extends unknown[]>(
  ...handlers: (((...e: T) => void) | undefined | null | false | 0)[]
) {
  return (...e: T) => {
    for (const handler of handlers) {
      handler && handler(...e);
    }
  };
}

export const stopPropagation = (e: React.MouseEvent) => {
  e.stopPropagation();
};
