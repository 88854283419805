import { RectTriRelationship } from '~utils/geometry';

import { StyledSpacingLine } from './spacing-lines.styled';

export type AlignmentSpacingProps = RectTriRelationship;

export function SpacingLines(props: AlignmentSpacingProps) {
  return (
    <>
      <StyledSpacingLine
        axis={props.axis}
        currentRect={props.currentRect}
        currentPlacement={props.currentPlacement}
        refRect={props.startRefRect}
        refPlacement={props.startRefPlacement}
        data-distance={props.startDistance}
      />
      <StyledSpacingLine
        axis={props.axis}
        currentRect={props.currentRect}
        currentPlacement={props.currentPlacement}
        refRect={props.endRefRect}
        refPlacement={props.endRefPlacement}
        data-distance={props.startDistance}
      />
    </>
  );
}
