import { createQueryHook } from '~utils/react-query';

import requests from './connection-string.service';

export const useConnectionStringsQuery = createQueryHook(
  ['connectionString', 'getMany'],
  requests.getManyRequest
);

export const useConnectionStringQuery = createQueryHook(
  ['connectionString', 'getOne'],
  requests.getOneRequest
);

export const useConnectionStringTablesIncludedDetailQuery = createQueryHook(
  ['connectionString', 'getTablesIncludedDetail'],
  requests.getTablesIncludedDetailRequest
);

export const useConnectionStructure = createQueryHook(
  ['connectionString', 'getStructure'],
  requests.getStructureRequest
);
