import { Box } from '@mui/material';

import {
  combineTranslates,
  Coordinate,
  isXEdge,
  isYEdge,
  RECT_EDGES,
  RECT_X_EDGES,
  RECT_Y_EDGES,
  RectEdge,
  RectPosition,
} from '~utils/geometry';

import { useDraggableTransformData } from '../../hooks/use-draggable-transform-data';
import { usePixelBoardSelector } from '../pixel-board-context/pixel-board.hook';
import { selectBoundaryRect } from '../pixel-board-context/pixel-board.selector';

import { ResizeHandlerPoint } from './components/resize-handler-point';

import { ResizerFrame } from './resizer.styled';

export interface ResizerProps {}

function computeBoxt(
  originBox: RectPosition,
  scaledBox: RectPosition
): Record<RectEdge, Coordinate> {
  return {
    top: {
      x: 0,
      y: scaledBox.top - originBox.top,
    },
    bottom: {
      x: 0,
      y: scaledBox.top + scaledBox.height - originBox.top - originBox.height,
    },
    left: {
      x: scaledBox.left - originBox.left,
      y: 0,
    },
    right: {
      x: scaledBox.left + scaledBox.width - originBox.left - originBox.width,
      y: 0,
    },
  };
}

export function Resizer({}: ResizerProps) {
  // TODO: Case multiple
  // TODO: Case single
  const { translate, ...scaledBox } = useDraggableTransformData();
  const originBox = usePixelBoardSelector(selectBoundaryRect);

  if (!originBox) {
    return null;
  }

  const boxTranslate = computeBoxt(originBox, scaledBox);

  return (
    <>
      <ResizerFrame
        style={{
          ...scaledBox,
          transform: `translate(${translate?.x ?? 0}px, ${translate?.y ?? 0}px)`,
        }}
      ></ResizerFrame>
      <Box sx={{ position: 'absolute', pointerEvents: 'none' }} style={originBox}>
        {RECT_EDGES.map((edge) => (
          <ResizeHandlerPoint
            key={edge}
            direction={edge}
            transform={combineTranslates(
              boxTranslate[edge],
              {
                x: isYEdge(edge) ? (boxTranslate.right.x - boxTranslate.left.x) / 2 : 0,
                y: isXEdge(edge) ? (boxTranslate.bottom.y - boxTranslate.top.y) / 2 : 0,
              },
              translate
            )}
          />
        ))}
        {RECT_Y_EDGES.map((edge) =>
          RECT_X_EDGES.map((edge2) => (
            <ResizeHandlerPoint
              transform={combineTranslates(boxTranslate[edge], boxTranslate[edge2], translate)}
              key={`${edge}-${edge2}`}
              direction={`${edge}-${edge2}`}
            />
          ))
        )}
      </Box>
    </>
  );
}
