import React from 'react';
import { SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { Button, Paper, Stack } from '@mui/material';
import Popper, { PopperProps } from '@mui/material/Popper';

export type CustomColorPickerProps = {
  anchorEl: SVGElement | HTMLElement | null;
  onChange?: (color: string) => void;
  onClose: () => void;
};

const popperModifiers: PopperProps['modifiers'] = [
  {
    name: 'offset',
    options: {
      offset: [0, 8],
    },
  },
];

export function CustomColorPicker({ anchorEl, onChange, onClose }: CustomColorPickerProps) {
  const { t } = useTranslation();
  const [color, setColor] = React.useState('black');

  return (
    <Popper
      open={!!anchorEl}
      anchorEl={anchorEl}
      placement="right"
      modifiers={popperModifiers}
      sx={{ zIndex: (theme) => theme.zIndex.modal }}
    >
      <Paper sx={{ width: 240, p: 1 }} onClick={(e) => e.stopPropagation()}>
        <SketchPicker
          color={color}
          onChange={(color) => {
            setColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a ?? 1})`);
          }}
          styles={{
            picker: {
              picker: {
                boxShadow: 'none',
              },
            },
          }}
          presetColors={[]}
          // disableAlpha
        />
        <Stack direction="row" pt={1} justifyContent="space-between">
          <Button size="small" onClick={onClose} color="inherit" variant="outlined">
            {t('COMMON.CANCEL')}
          </Button>
          <Button variant="contained" size="small" onClick={() => onChange && onChange(color)}>
            {t('COMMON.SAVE')}
          </Button>
        </Stack>
      </Paper>
    </Popper>
  );
}
