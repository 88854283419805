import { DFListPickerProps } from '../../../df-picker';

import { DFListPickerInputProps } from './df-list-picker-input';

export const defaultRenderValue: Exclude<DFListPickerInputProps['renderValue'], undefined> = (
  options: DFListPickerProps['options'],
  value: string | undefined
) => {
  if (!value) {
    return '';
  }
  return String(options?.find((option) => option.value === value)?.label) || value;
};
