import { forwardRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { XIcon } from '@dataflake/icons';
import { Box, InputAdornment, Popper } from '@mui/material';

import { IconButtonWithTooltip } from '~components/with-tooltip';
import {
  DefaultSelectIcon,
  ValueWrapper,
} from '~ui/df-input/components/df-list-picker-input/df-list-picker-input.styled';
import { DFOutlinedInput, DFOutlinedInputProps } from '~ui/df-input/components/df-outlined-input';
import {
  DFPickerInputControl,
  DFPickerInputControlProps,
} from '~ui/df-input/components/df-picker-input-control';
import { DFListPicker, DFListPickerProps } from '~ui/df-picker/components/df-list-picker';
import { combineHandlers } from '~utils/event';

import { defaultRenderValue } from './df-list-picker-input.const';

export interface DFListPickerInputProps
  extends Omit<DFOutlinedInputProps, 'value' | 'onChange'>,
    DFListPickerProps {
  clearable?: boolean;
  renderValue?: (
    options: DFListPickerProps['options'],
    value: string | undefined
  ) => string | undefined;
  endIcon?: ReactNode;
  children?: ReactNode;
  renderInput?: (value: ReactNode) => ReactNode;
  controlProps?: Omit<DFPickerInputControlProps, 'children'>;
}

export const DFListPickerInput = forwardRef(function DFListPickerInput(
  {
    clearable,
    value,
    onChange,
    disableSearch,
    options,
    maxHeight,
    endIcon,
    scrollToSelectedItem,
    renderValue = defaultRenderValue,
    disabled,
    renderInput,
    sortGroup,
    size,
    searchProp,
    controlProps,
    ...props
  }: DFListPickerInputProps,
  ref
) {
  const { t } = useTranslation();
  return (
    <DFPickerInputControl ref={ref} {...controlProps}>
      {({ open, toggle, forceClose, popperAnchorEl, inputWidth, setInputRef }) => {
        return (
          <>
            {!renderInput ? (
              <DFOutlinedInput
                endAdornment={
                  <InputAdornment
                    position="end"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    {clearable && value && (
                      <IconButtonWithTooltip
                        tooltip={t('COMMON.CLEAR')}
                        sx={{
                          color: (theme) => theme.palette.text.secondary,
                        }}
                        size="small"
                      >
                        <XIcon
                          role="button"
                          fontSize={size === 'xs' ? 'xSmall' : 'small'}
                          onClick={(e) => {
                            e.stopPropagation();
                            onChange?.('');
                          }}
                        />
                      </IconButtonWithTooltip>
                    )}
                    {endIcon || (
                      <DefaultSelectIcon up={open} fontSize={size === 'xs' ? 'xSmall' : 'small'} />
                    )}
                  </InputAdornment>
                }
                disabled={disabled}
                size={size}
                {...props}
                ref={setInputRef}
                onClick={(e) => !disabled && toggle(e.currentTarget)}
                fullWidth
                value={renderValue(options, value)}
                readOnly
              />
            ) : (
              <ValueWrapper onClick={(e) => !disabled && toggle(open ? null : e.currentTarget)}>
                {renderInput(renderValue(options, value))}
              </ValueWrapper>
            )}
            <Popper
              open={open}
              anchorEl={popperAnchorEl}
              sx={{ zIndex: (theme) => theme.zIndex.modal }}
            >
              <Box minWidth={inputWidth}>
                <DFListPicker
                  scrollToSelectedItem={scrollToSelectedItem}
                  disableSearch={disableSearch}
                  options={options}
                  maxHeight={maxHeight}
                  value={value}
                  disabledAll={disabled}
                  onChange={combineHandlers(onChange, forceClose)}
                  sortGroup={sortGroup}
                  searchProp={searchProp}
                />
              </Box>
            </Popper>
          </>
        );
      }}
    </DFPickerInputControl>
  );
});
