import muiZIndex, { ZIndex } from '@mui/material/styles/zIndex';

declare module '@mui/material/styles/zIndex' {
  interface ZIndex {
    backdrop: number;
  }
}

export const zIndex: ZIndex = {
  ...muiZIndex,
  backdrop: 5000,
};
