import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { useConfirm } from '~providers/confirm-provider/hooks/use-confirm';

export function useConfirmDeleteElement() {
  const confirm = useConfirm();
  const { t } = useTranslation();

  return useCallback(
    (lenght: number, onResolve: () => unknown) => {
      confirm({
        variant: 'error',
        title: t('MODAL.REMOVE_TITLE', {
          name: t('COMMON.ELEMENTS'),
        }),
        children: (
          <Typography
            dangerouslySetInnerHTML={{
              __html: t('MODAL.REMOVE_CONTENT', {
                name:
                  lenght <= 1
                    ? t('ELEMENTS.NUMBER_ELEMENT', {
                        count: lenght,
                      })
                    : t('ELEMENTS.NUMBER_ELEMENTS', {
                        count: lenght,
                      }),
              }),
            }}
          />
        ),
        acceptButtonProps: {
          children: t('COMMON.REMOVE'),
        },
        onResolve,
      });
    },
    [confirm, t]
  );
}
