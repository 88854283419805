export type FunctionalUpdater<TOutput, TInput = TOutput> = (input: TInput) => TOutput;
export type Updater<TOutput, TInput = TOutput> = TOutput | FunctionalUpdater<TOutput, TInput>;
export function applyUpdater<TOutput, TInput>(
  updater: Updater<TOutput, TInput>,
  input: TInput
): TOutput {
  return typeof updater === 'function'
    ? (updater as FunctionalUpdater<TOutput, TInput>)(input)
    : updater;
}

export function timeId(context: unknown = '') {
  return String(window.performance.now()) + '.' + String(context);
}
