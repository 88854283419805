import { styled } from '@mui/material';

export const Rotate = styled('div', {
  shouldForwardProp: (name) => name != 'active',
})<{ active: boolean }>(({ theme, active }) => ({
  display: 'flex',
  width: 'fit-content',
  margin: 'auto',
  transform: !active ? 'rotate(180deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
