import createMuiTheme from '@mui/material/styles/createTheme';
import { memoize } from 'lodash-es';

import { ElementTheme } from '~services/v1/element';

import components from './components';
import { createPalette } from './palette';
import { createShape } from './shape';
import { createTypography } from './typography';
import { zIndex } from './z-index';

declare module '@mui/material/styles/createTheme' {
  interface ThemeOptions {
    element?: ElementTheme;
  }
  interface Theme {
    element?: ElementTheme;
  }
}

declare module '@mui/material/styles/zIndex' {
  interface ZIndex {}
}

export type ThemeMode = 'dark' | 'light';

export const createTheme = memoize((mode: ThemeMode = 'light') =>
  createMuiTheme({
    palette: createPalette(mode),
    typography: createTypography(),
    shape: createShape(),
    components,
    zIndex,
  })
);
