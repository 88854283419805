import React, { useContext } from 'react';
import { memoize } from 'lodash-es';
import { StoreApi, useStore } from 'zustand';
import { shallow } from 'zustand/shallow';

import { useSyncStoreWithSelector } from '~hooks/use-sync-store-with-selector';

export function createZustandHooks<T>(
  Context: React.Context<StoreApi<T> | null> | React.Context<StoreApi<T>>
) {
  function useStoreHook() {
    const store = useContext(Context as React.Context<StoreApi<T> | null>);
    if (store === null) {
      throw new Error('useStore must be used within a StoreProvider');
    }
    return store;
  }

  function useSelectorHook<T2>(
    selector: (state: T) => T2,
    equalityFn: (a: T2, b: T2) => boolean = shallow
  ): T2 {
    const store = useStoreHook();
    if (store === null) {
      throw new Error('useStore must be used within a StoreProvider');
    }
    return useSyncStoreWithSelector(store, selector, equalityFn);
  }

  function useStateHook(): T {
    const store = useStoreHook();
    if (store === null) {
      throw new Error('useStore must be used within a StoreProvider');
    }
    return useStore(store);
  }

  return [useStoreHook, useSelectorHook, useStateHook] as const;
}

export function memoizeSelector<T, T2, T3>(
  selector1: (state: T) => T2,
  selector2: (state: T2) => T3
) {
  return memoize((state: T) => selector2(selector1(state)));
}
