import { alertFetcher } from '~services/client';

import {
  ChannelData,
  ChannelEntity,
  ChannelID,
  ChannelRequestReturn,
  ChatList,
} from './chanel.entity';

const namespace = 'config/channels';

const getListChannel = async () => {
  const { data } = await alertFetcher.get<{ status: string; channels: ChannelEntity[] }>(namespace);
  return data?.channels;
};

const addChannel = async ({ type, descriptor }: ChannelData): Promise<ChannelEntity> => {
  const { data } = await alertFetcher.post<ChannelEntity>(namespace, {
    channel: {
      type,
      descriptor,
    },
  });
  return data;
};

const deleteChannel = async (channelId: ChannelID) => {
  const { data } = await alertFetcher.delete<ChannelRequestReturn>(`${namespace}/${channelId}`);
  return data;
};

const getUserNameTelegram = async () => {
  const { data } = await alertFetcher.get<{ status: string; chat_list: ChatList[] }>(
    `${namespace}/telegram/usernames`
  );
  return data?.chat_list;
};

export { addChannel, deleteChannel, getListChannel, getUserNameTelegram };
