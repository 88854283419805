import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserIcon } from '@dataflake/icons';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';

import AppTextField from '~components/input/components/app-text-field';
import { DataCenterInput } from '~components/input/components/data-center-input';
import { EmailInput } from '~components/input/components/email-input';
import { PasswordInput } from '~components/input/components/password-input';
import { GoogleIcon } from '~icons/legacy/google-icon';
import { useSearch } from '~libs/router/hooks/use-search';

import useRegisterForm from './register-page.form';

export interface RegisterProps {}

export function RegisterPage({}: RegisterProps) {
  const { inputs, onSubmit, loading, getValues } = useRegisterForm();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const search = useSearch();

  const { error: policyError, helperText: policyHelperText, ...policyProps } = inputs.checkedPolicy;

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={{
        px: {
          xs: 3,
          sm: 7,
          lg: 15,
        },
      }}
    >
      <Typography align="center" variant="h5" fontWeight="bold" mb={2}>
        {t('COMMON.REGISTRATION')}
      </Typography>
      <Stack gap={3} mb={3}>
        <DataCenterInput getValues={getValues} size="small" fullWidth />
        <AppTextField
          rounded
          {...inputs.name}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <UserIcon />
              </InputAdornment>
            ),
          }}
        />
        <EmailInput {...inputs.email} />
        <PasswordInput {...inputs.password} />
        <FormControl error={policyError}>
          <FormControlLabel control={<Checkbox {...policyProps} />} label={t('REGISTER.POLICY')} />
          <FormHelperText>{policyHelperText}</FormHelperText>
        </FormControl>
      </Stack>
      <Stack spacing={2} mb={4}>
        <Button disabled={loading} type="submit" variant="rounded">
          {t('COMMON.REGISTER')}
        </Button>
        {import.meta.env.VITE_ENABLE_GOOGLE_AUTH && (
          <>
            <Box sx={{ width: '50%', alignSelf: 'center' }}>
              <Divider>
                <Typography color="text.secondary">{t('COMMON.OR')}</Typography>
              </Divider>
            </Box>
            <Stack gap={4}>
              <Button
                href="/api/v1/auth/redirect/google"
                type="button"
                startIcon={<GoogleIcon />}
                variant="round-outlined"
              >
                {t('COMMON.GOOGLE')}
              </Button>
            </Stack>
          </>
        )}
      </Stack>
      <Stack gap={2}>
        <Typography align="center" color="text.secondary">
          {t('COMMON.ALREADY_HAVE_AN_ACCOUNT')}
        </Typography>
        <Button
          type="button"
          variant="round-outlined"
          style={{
            color: theme.palette.primary.main,
          }}
          onClick={() => navigate(`/login${search}`)}
        >
          {t('COMMON.LOG_IN')}
        </Button>
      </Stack>
    </Box>
  );
}
