// TODO: implement, remove all comment after done

import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme as MuiTheme,
} from '@mui/material';

declare module '@mui/material/InputBase' {
  interface InputBasePropsSizeOverrides {
    xs: true;
  }
}
declare module '@mui/material/FormControl' {
  interface FormControlPropsSizeOverrides {
    xs: true;
  }
}

interface MuiInputBaseComponentType {
  styleOverrides?: ComponentsOverrides<MuiTheme>['MuiInputBase'];
  defaultProps?: ComponentsProps['MuiInputBase'];
  variants?: ComponentsVariants['MuiInputBase'];
}

export const MuiInputBaseComponent: MuiInputBaseComponentType = {
  variants: [],
  defaultProps: {
    // variant: 'contained',
  },
  styleOverrides: {
    root: ({ ownerState: { size } }) => {
      return (
        size === 'xs' && {
          fontSize: '0.75rem',
          padding: '0',
        }
      );
    },
    input: ({ ownerState: { size }, theme }) => {
      return (
        size === 'xs' && {
          padding: theme.spacing(0.25, 0),
        }
      );
    },
  },
};
