import { DateInterval, NullValueBehavior } from '~utils/apexchart';
import { CompactMode } from '~utils/unit';

import { AxisChartConfig, ChartConfig } from '../../element.entity';

export type Curve = 'smooth' | 'straight' | 'stepline';

export type LegacyScaleType = 'category' | 'datetime' | 'numeric';
export type OrientationType = 'horizontal' | 'incline' | 'vertical';

export interface AxisChartBaseConfig {
  dimension?: string;
  xScale?: LegacyScaleType;
  orientation?: OrientationType;
  xTooltipFormat?: string;
  strokeWidth?: number;
  strokeDashArray?: string;
  curve?: Curve;
  enableArea?: boolean;
  showXAxis?: boolean;
  showYAxis?: boolean;
  leftAxisWidth?: number;
  reverseX?: boolean;
  reverseY?: boolean;
  enableZoom?: boolean;
  zoomType?: 'x' | 'y' | 'xy';
  autoZoomYaxis?: boolean;
  columnsWidth?: string;
  showXAxisLine?: boolean;
  xAxisLineWeight?: number;
  xAxisLineColor?: string;
  dataLabelsPosition?: 'top' | 'center' | 'bottom';
  dataLabelsOrientation?: 'vertical' | 'horizontal';
}

export interface ValueAnnotation {
  color: string;
  value: number;
  width: number;
  label: string;
}

export interface DataCorrectionConfig {
  enableDataCorrection?: boolean;
  xRange?: DateInterval;
  xInterval?: DateInterval;
  nullDisplay?: boolean;
  nullStartDisplay?: boolean;
  nullEndDisplay?: boolean;
  /**
   * if don't use average, 0 will be used
   */
  useAverageValueForNull?: boolean;
  /**
   * if don't use average, 0 will be used
   */
  useAverageValueForNullStart?: boolean;
  /**
   * if don't use average, 0 will be used
   */
  useAverageValueForNullEnd?: boolean;
}

export interface AxisChartSpecialConfig extends AxisChartBaseConfig, DataCorrectionConfig {
  metric?: string[];
  negativeMetric?: string[];
  breakdownDimension?: string;
  nullValueBehavior?: NullValueBehavior;
  showStacked?: boolean;
  stacked100?: boolean;
  stacked?: 'normal' | '100%' | null;
  yAnnotations?: ValueAnnotation[];
  showTimeSelect?: boolean;
  showTimeSelectIfDaysLessThan?: number;
  yAxisMaxValue?: string | null;
  yAxisMinValue?: string | null;
  compactNumber?: CompactMode;
  showYAxisLine?: boolean;
  yAxisLineWeight?: number;
  yAxisLineColor?: string;
  showYAxisMin?: boolean;
  showYAxisMax?: boolean;
  showYAxisAverage?: boolean;
  yaxis?: {
    tickAmount?: number;
    min?: number;
    max?: number;
  };
  xaxis?: {
    tickAmount?: number;
  };
  hideStacked100Button?: boolean;
}

export const STROKE_WIDTH_DEFAULT = 2;
export const AXIS_LINE_WEIGHT_DEFAULT = 2;
export const AXIS_LINE_COLOR_DEFAULT = '#000';
export const CURVE_DEFAULT = 'smooth';
export const COLUMNS_WIDTH_DEFAULT = '80%';

export const defaultAxisChartConfig = (): AxisChartSpecialConfig => ({
  strokeWidth: STROKE_WIDTH_DEFAULT,
  curve: CURVE_DEFAULT,
  compactNumber: 'simplify',
  columnsWidth: COLUMNS_WIDTH_DEFAULT,
  dataLabelsPosition: 'center',
  dataLabelsOrientation: 'vertical',
  xScale: 'category',
  orientation: 'horizontal',
  yAxisLineWeight: AXIS_LINE_WEIGHT_DEFAULT,
  yAxisLineColor: AXIS_LINE_COLOR_DEFAULT,
  xAxisLineWeight: AXIS_LINE_WEIGHT_DEFAULT,
  xAxisLineColor: AXIS_LINE_COLOR_DEFAULT,
});

export function canInteractableToolbar(config: ChartConfig): config is AxisChartConfig {
  return (
    (config.chartType === 'bar' ||
      config.chartType === 'line' ||
      config.chartType === 'mixed' ||
      config.chartType === 'bubble') &&
    config.xScale === 'datetime'
  );
}

export const XSCALE_VALUES: LegacyScaleType[] = ['datetime', 'category', 'numeric'];

export const NULL_VALUE_BEHAVIOR_VALUES: NullValueBehavior[] = [
  'break-line',
  'cross-over',
  'zero-value',
];
