import { createContext, ReactNode, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { ElementEntity } from '~services/v1/element';

import { updateElementsAction } from '../dashboard-provider/actions/element.action';
import { useDashboardStore } from '../dashboard-provider/hooks';
import { makeSelectElement } from '../dashboard-provider/selectors/element.selector';
import {
  DashboardStore,
  dispatchDashboardAction,
} from '../dashboard-provider/store/dashboard-store';

import { createElementStore, getLocalParams } from './element-provider.store';

export interface ElementProviderProps {
  children: ReactNode;
  editable?: boolean;
  selected?: boolean;
  multipleSelected?: boolean;
  dataRange?: [unknown, unknown] | null;
  element?: ElementEntity | null;
  elementId?: string;
  updateElement?: (properties: DeepPartial<ElementEntity>) => unknown | Promise<unknown>;
  defaultEnableFetchData?: boolean;
}

export const ElementContext = createContext(
  createElementStore({ element: null, updateElement: () => {} })
);
const createUpdateElementFunction =
  (elementId: string, store: DashboardStore) => (properties: DeepPartial<ElementEntity>) =>
    dispatchDashboardAction(
      store,
      updateElementsAction([
        {
          element_id: elementId,
          ...properties,
        } as Partial<ElementEntity>,
      ])
    );

export function ElementProvider({
  children,
  editable,
  dataRange,
  element,
  selected = false,
  multipleSelected = false,
  elementId,
  updateElement,
  defaultEnableFetchData = true,
}: ElementProviderProps) {
  const dashboardStore = useDashboardStore();
  const [store] = useState(() => {
    let _element = element ?? null;
    let _updateElement = updateElement;
    if (elementId) {
      _element = makeSelectElement(elementId)(dashboardStore.getState()) ?? null;
      _updateElement = createUpdateElementFunction(elementId, dashboardStore);
    }
    return createElementStore({
      element: _element,
      updateElement: _updateElement,
      dataRange,
      editable,
      enableFetchData: defaultEnableFetchData,
      selected,
      multipleSelected,
    });
  });

  useEffect(() => {
    if (elementId) {
      store.setState({
        element: makeSelectElement(elementId)(dashboardStore.getState()) ?? null,
      });
      return dashboardStore.subscribe(() => {
        try {
          const element = makeSelectElement(elementId)(dashboardStore.getState());
          store.setState((prevState) => ({
            element,
            ...(!shallow(
              prevState.element?.element_query_params || [],
              element.element_query_params || []
            )
              ? {
                  localParams: getLocalParams(element),
                }
              : {}),
          }));
        } catch (e) {
          store.setState({
            element: null,
          });
        }
      });
    } else {
      store.setState({
        element,
      });
    }
  }, [elementId, element, store, dashboardStore]);

  useEffect(() => {
    if (elementId && !updateElement) {
      store.setState({
        updateElement: createUpdateElementFunction(elementId, dashboardStore),
      });
    } else {
      store.setState({
        updateElement,
      });
    }
  }, [elementId, dashboardStore, store, updateElement]);

  useEffect(() => {
    store.setState({
      dataRange,
    });
  }, [dataRange, store]);

  useEffect(() => {
    store.setState({
      editable,
    });
  }, [editable, store]);

  useEffect(() => {
    store.setState({
      selected,
    });
  }, [selected, store]);

  useEffect(() => {
    store.setState({
      multipleSelected,
    });
  }, [multipleSelected, store]);

  return <ElementContext.Provider value={store}>{children}</ElementContext.Provider>;
}
