import { History } from 'history';
import { createStore } from 'zustand';

import { NewElementData, PositionedStyleArea } from '~services/v1/element';
import { StyleArea } from '~services/v1/element/entities/chart-element/const';
import { EntityUuid } from '~services/v1/types';

import type { EditorContextValue } from './editor-provider';

export type ElementRightPanelTab =
  | 'api'
  | 'filter'
  | 'data'
  | 'style'
  | 'query'
  | 'signal'
  | 'monitor'
  | 'review'
  | 'conditional_format'
  | 'selection';

export function getRightPanelTabs(tabs: ElementRightPanelTab[], multiple?: boolean) {
  if (multiple) {
    return [...tabs, 'selection'] as ElementRightPanelTab[];
  }

  return tabs;
}

export type ToolbarTab = 'style' | 'query' | 'data';

export type EditorRightPanelType =
  | 'alert'
  | 'query'
  | 'cs'
  | 'color'
  | 'labels'
  | 'import'
  | 'theme'
  | 'chart-management'
  | 'visual-settings';

export interface EditorHistoryState {
  selectedNodeIds: string[];
  newElementData: NewElementData | null;
  showUniversalSearch: boolean;
  showToolbar: boolean;
  enableSnapEdges: boolean;
  enableGrid: boolean;
  expandedPageSelectPanel: boolean;
  elementRightPanel: {
    expanded: boolean;
    width: number;
    content: {
      type: ElementRightPanelTab | null;
      stylingArea: StyleArea | PositionedStyleArea | null;
    };
    // TODO: Just + - directly on width
    // expandedWidth: number;
  };
  editorRightPanel: {
    expanded: boolean;
    width: number;
    content: {
      type: EditorRightPanelType | null;
      subType?: 'list' | 'create' | 'edit' | undefined;
      id?: string | undefined;
    };
  };
  searchKeyword: string;
  searchActiveElementId: EntityUuid | null;
}

export interface EditorMemoryState {
  isMovingElement: boolean;
  hoveringStyleArea: StyleArea | PositionedStyleArea | null;
  toggleIsMovingElement: (value?: boolean) => void;
  setHoveringStyleArea: (value: StyleArea | PositionedStyleArea | null) => void;
}

export const createEditorContextValue = (history: History): EditorContextValue => ({
  history,
  memoryStore: createStore<EditorMemoryState>((setState) => ({
    hoveringStyleArea: null,
    isMovingElement: false,
    toggleIsMovingElement: (value) =>
      setState(({ isMovingElement }) => ({ isMovingElement: value ?? !isMovingElement })),
    setHoveringStyleArea: (value) => setState({ hoveringStyleArea: value }),
  })),
});
