import { ComponentType } from 'react';
import {
  Box,
  DialogActions as MUIDialogActions,
  DialogContent as MUIDialogContent,
  Grid as MUIGrid,
  GridProps,
  Stack,
  styled,
} from '@mui/material';

const WIDTH = 82;
const HEIGHT = 82;

export const Grid = styled(MUIGrid)(({ theme }) => ({
  background: theme.palette.primary.main,
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  color: theme.palette.common.white,
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  display: 'flex',
})) as ComponentType<GridProps>;

export const DialogContent = styled(MUIDialogContent)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(8),
  paddingRight: theme.spacing(8),
}));

export const StackCustom = styled(Stack)<{ check: boolean }>(({ check, theme }) => ({
  cursor: 'pointer',
  textAlign: 'center',
  '&.MuiBox-root': {
    color: check ? 'primary' : 'default',
  },
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiTypography-subtitle1': {
    color: check ? theme.palette.text.primary : theme.palette.text.secondary,
  },
}));

export const DialogActions = styled(MUIDialogActions, {
  shouldForwardProp: (prop) => prop !== 'visible',
})<{ visible: boolean }>(({ visible, theme }) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: 'auto',
  width: '50%',
  paddingBottom: theme.spacing(2),
  visibility: visible ? 'visible' : 'hidden',
}));

export const GridContainer = styled(MUIGrid)({
  minHeight: 'calc(95vh - 70px)',
}) as ComponentType<GridProps>;

export const GridItem = styled(MUIGrid)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 'calc(100vh - 120px)',
}) as ComponentType<GridProps>;

export const CircleBox = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'isActive',
})<{ isActive?: boolean }>(({ theme, isActive }) => ({
  backgroundColor: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  filter: !isActive ? 'grayscale(100%)' : 'contrast(100%)',
  width: WIDTH,
  height: HEIGHT,
  border: `2px solid ${!isActive ? theme.palette.divider : theme.palette.primary.main}`,
}));
