import { localStorage } from '../../../storage';
import { authFetcher, coreFetcher, SESSION_UUID_KEY } from '../../client';

import { CurrentSessionEntity, UserInfo } from './auth.entity';

const namespace = '/auth2';

export type ForgetPasswordResponseData =
  | {
      status: 'error';
      msg: string;
    }
  | {
      status: 'success';
      msg: string;
    };

// TODO remove this, don't use any more
export async function forgetPasswordRequest(email: string) {
  const response = await authFetcher.post<ForgetPasswordResponseData>('/forgot-password', {
    email,
  });
  return response.data;
}

export async function getCurrentSessionRequest() {
  return await coreFetcher.get<CurrentSessionEntity>(`${namespace}/session`);
}

export async function updateCurrentUserRequest(data: Partial<CurrentSessionEntity>) {
  return await coreFetcher.patch<CurrentSessionEntity>(`${namespace}/user`, data);
}
export async function updateCurrentUserInfoRequest(data: Partial<UserInfo>) {
  return await coreFetcher.patch<CurrentSessionEntity>(`${namespace}/user`, {
    user_infos: data,
  });
}
export async function updateCurrentUserAvatarRequest(file: File) {
  const formData = new FormData();
  formData.append('avatar', file);
  return await coreFetcher.post<CurrentSessionEntity>(`${namespace}/user/avatar`, formData);
}

export interface LoginData {
  email: string;
  password: string;
}

export interface LoginSuccessResponse {
  redirect_to: string;
  session_uuid: string;
  status: 'success';
  user_email: string;
  user_uuid: string;
  companies: ReadonlyArray<{
    uuid: string;
    slug: string;
    name: string;
  }>;
}

export interface LoginErrorResponse {
  status: 'error';
  msg?: string;
}
export const loginRequest = (login: LoginData) =>
  authFetcher.post<LoginErrorResponse | LoginSuccessResponse>('/login', { login });

export const mfaValidateRequest = (mfaUuid: string, code: string) =>
  authFetcher.post(`/mfa/${mfaUuid}/validate`, { mfa: { code } });

export const registerRequest = (user: { name: string; email: string; password: string }) =>
  authFetcher.post('/user', { user });

export const renewSessionRequest = (sessionUuid = localStorage.getItem(SESSION_UUID_KEY)) =>
  authFetcher.post(`/session/${sessionUuid}/renew`, null);

// Send email to user with email validation link.
export const sendEmailValidationLinkRequest = (
  sessionUuid = localStorage.getItem(SESSION_UUID_KEY)
) => authFetcher.post(`/session/${sessionUuid}/registration-resend-email`, null);

export const getRegistrationInfoRequest = () => authFetcher.get('user/registration/data');
export type ExtraRegistrationData = {
  email: string;
  country: string;
  company: string;
  vertical: string;
  phone: string;
  coupon: string;
  type: 'customer' | 'partner';
};

export const saveExtraRegistrationDataRequest = (
  data: ExtraRegistrationData,
  sessionUuid = localStorage.getItem(SESSION_UUID_KEY)
) => authFetcher.post(`/session/${sessionUuid}/post-registration`, { data });

export const getUserRequest = (
  path: string,
  sessionUuid = localStorage.getItem(SESSION_UUID_KEY)
) => authFetcher.get(`/session/${sessionUuid}/user/${path}`);

export const getUserMetaRequest = (
  path: string,
  sessionUuid = localStorage.getItem(SESSION_UUID_KEY)
) => authFetcher.get(`/session/${sessionUuid}/user-meta/${path}`);

export const setUserMetaRequest = (
  path: string,
  value: unknown = true,
  sessionUuid = localStorage.getItem(SESSION_UUID_KEY)
) => authFetcher.put(`/session/${sessionUuid}/user-meta/${path}`, { meta: { value } });

export const retrievePasswordRequest = ({
  token,
  new_password,
}: {
  token: string;
  new_password: string;
}) => authFetcher.put(`/forgot-password/${token}`, { new_password });

export const checkRetrievePasswordTokenRequest = (token: string) =>
  authFetcher.get(`/forgot-password/${token}/check`);

export type ChangePasswordData = {
  old_password: string;
  new_password: string;
  new_password2: string;
};

export const changePasswordRequest = (data: ChangePasswordData) =>
  authFetcher.put('/user/password', data);

export const deleteSessionRequest = (sessionUuid = localStorage.getItem(SESSION_UUID_KEY)) =>
  authFetcher.delete(`/session/${sessionUuid}`);
