import { memoizeSelector } from '~utils/zustand';

import { DataSourceEntity } from '../../../services/v1/data-source/data-source.entity';
import { DashboardState } from '../store/dashboard-store';

export const selectDataSources = (state: DashboardState) => state.dataSources;

const filterEmptyDataSource = (d: DataSourceEntity | null | undefined): d is DataSourceEntity =>
  !!d;

export const selectMinCacheTime = memoizeSelector(selectDataSources, (dataSources) =>
  Object.entries(dataSources)
    .map((item) => item[1])
    .filter(filterEmptyDataSource)
    .reduce<Date | undefined>((min, item) => {
      const cacheTime = item.cached_time && new Date(item.cached_time);
      if (cacheTime && min && cacheTime < min) {
        return cacheTime;
      }
      return min;
    }, undefined)
);
