import { useState } from 'react';
import { useDndContext } from '@dnd-kit/core';
import {
  autoPlacement,
  autoUpdate,
  FloatingPortal,
  offset,
  shift,
  useFloating,
  useInteractions,
  VirtualElement,
} from '@floating-ui/react';
import { Box } from '@mui/material';

import { useKeyPress } from '~hooks/use-key-press';

import { BaseBoardProps } from '../../element-board.types';
import { RefsMap } from '../../hooks/use-node-refs';
import { useSelectedNodesBoundingRef } from '../../hooks/use-selected-node-refs';
import { usePixelBoardSelector } from '../pixel-board/components/pixel-board-context/pixel-board.hook';
import { selectSelectedBoxNodes } from '../pixel-board/components/pixel-board-context/pixel-board.selector';

export interface ToolbarContainerProps
  extends PickAndTransformOptional<BaseBoardProps, 'selectedNodeIds'>,
    PickAndRemoveOptional<BaseBoardProps, 'ToolbarComponent' | 'hideToolbar'> {
  nodeRefs: RefsMap;
}

const whileElementsMounted = (
  reference: VirtualElement,
  floating: HTMLElement,
  update: () => void
) =>
  autoUpdate(reference, floating, update, {
    ancestorScroll: true,
    ancestorResize: true,
    elementResize: true,
    layoutShift: true,
    animationFrame: true,
  });

export function ToolbarContainer({
  nodeRefs,
  ToolbarComponent,
  selectedNodeIds,
  hideToolbar,
}: ToolbarContainerProps) {
  const selectedNodes = usePixelBoardSelector(selectSelectedBoxNodes);
  const isNodeMoving = !!useDndContext().active;
  const [floatingEl, setFloatingEl] = useState<HTMLElement | null>(null);
  const boundingRef = useSelectedNodesBoundingRef({
    nodeRefs,
    selectedNodeIds,
  });
  useInteractions();

  // const { movingOffset, moveEventHandlers, reset } = useMoving();

  const isPressedShift = useKeyPress('Shift', {
    standalone: true,
  });

  const {
    // strategy,
    refs,
    // x,
    // y,
    floatingStyles,
  } = useFloating({
    strategy: 'fixed',
    elements: {
      floating: floatingEl,
    },
    middleware: [
      offset(8),
      autoPlacement({
        allowedPlacements: ['top', 'bottom'],
        // rootBoundary
      }),
      // {
      //   name: 'middleware',
      //   async fn(state) {
      //     const overflow = await detectOverflow(state);
      //     return {};
      //   },
      // },
      // flip({ fallbackAxisSideDirection: 'end' }),
      shift({
        crossAxis: true,
      }),
    ],
    whileElementsMounted,
  });
  refs.setPositionReference(boundingRef);

  // const totalX = x + movingOffset.translate.x;
  // const totalY = y + movingOffset.translate.y;
  // const transform = `translate(${totalX}px, ${totalY}px)`;

  return !hideToolbar && !!ToolbarComponent && !isNodeMoving && !isPressedShift && !!boundingRef ? (
    <FloatingPortal>
      <Box
        ref={setFloatingEl}
        style={floatingStyles}
        // zIndex={1 /*For Toolbar to overlap AbsoluteLayout*/}
      >
        <ToolbarComponent nodes={selectedNodes} />
      </Box>
    </FloatingPortal>
  ) : null;
}
