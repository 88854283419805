/* eslint-disable @typescript-eslint/ban-types */

export function fillNullWithDefault<T extends {}>(value: T, defaultValue: Partial<T>): T {
  return Object.fromEntries(
    Object.entries({ ...defaultValue, ...value }).map(([key, value]) => [
      key,
      value ?? defaultValue[key as keyof T] ?? null,
    ])
  ) as T;
}

export function removeNullUndefined<T>(input: T): T {
  if (Array.isArray(input)) {
    return input?.map(removeNullUndefined).filter((item) => item != null) as unknown as T;
  }

  if (typeof input === 'object' && input != null) {
    return Object.fromEntries(
      Object.entries(input)
        .map(([key, val]) => [key, removeNullUndefined(val as unknown)])
        .filter(([, v]) => v != null)
    );
  }

  return input;
}
