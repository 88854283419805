import {
  ListSubheader as MUIListSubheader,
  MenuItem as MUIMenuItem,
  menuItemClasses,
  styled,
} from '@mui/material';

export const ListSubheader = styled(MUIListSubheader)`
  font-weight: bold;
  line-height: 2.4rem;
  background-color: inherit;
`;

export const MenuItem = styled(MUIMenuItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.${menuItemClasses.selected} {
    font-weight: bold;
    background-color: inherit;
  }
`;
