import { useEffect } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';

import { SESSION_QUERY_KEY } from '~hooks/auth';
import { useCurrentProjectUuid } from '~hooks/auth/use-current-project';
import { history } from '~libs/router';
import { ConfirmProvider } from '~providers/confirm-provider';
import { DndProvider } from '~providers/dnd-provider';
import SnackbarProvider from '~providers/snackbar-provider';
import { ThemeProvider } from '~providers/theme-provider';

import 'core-js/actual/array/at';
import 'core-js/actual/object/from-entries';

import { DfRouter } from './libs/router';
import { RouterGuardProvider } from './providers/router-guard-provider';
import { queryClient } from './react-query';
import { RollbarUserLayer } from './rollbar';
import Routes from './routes';

function App() {
  const projectUuid = useCurrentProjectUuid();

  useEffect(() => {
    queryClient.resetQueries({
      predicate: (query) => {
        // Reset all query except session query
        const sessionQueries = queryClient.getQueryCache().findAll({
          queryKey: SESSION_QUERY_KEY,
        });
        return !sessionQueries.includes(query);
      },
    });
  }, [projectUuid]);

  return (
    <QueryClientProvider client={queryClient}>
      <RollbarUserLayer>
        <ThemeProvider>
          <ConfirmProvider>
            <DndProvider>
              <DfRouter history={history}>
                <RouterGuardProvider>
                  <Routes />
                </RouterGuardProvider>
              </DfRouter>
            </DndProvider>
            <SnackbarProvider />
          </ConfirmProvider>
        </ThemeProvider>
      </RollbarUserLayer>
    </QueryClientProvider>
  );
}

export default App;
