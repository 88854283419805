export const dataCenters = import.meta.env.VITE_DATA_CENTER.split(',').map((item) => {
  const [key, domain, text] = item.split(':');
  return {
    key,
    domain,
    text,
  };
});

export const dataCenterDomains = dataCenters.map(({ domain }) => domain);
