export interface ApiObject {
  [index: string]: unknown;
}

export interface Options {
  integrations?: { [index: string]: boolean };
}

export type ApiCallback = () => void;

export interface Freshpaint {
  track(eventName: string, properties?: ApiObject, options?: Options, callback?: ApiCallback): void;

  identify(
    uniqueId?: string,
    properties?: ApiObject,
    options?: Options,
    callback?: ApiCallback
  ): void;

  group(
    uniqueId: string | undefined,
    properties?: ApiObject,
    options?: Options,
    callback?: ApiCallback
  ): void;

  alias(newId: string, oldId?: string, options?: Options, callback?: ApiCallback): void;

  page(
    category?: string,
    name?: string,
    properties?: ApiObject,
    options?: Options,
    callback?: ApiCallback
  ): void;

  ready(callback: ApiCallback): void;

  reset(): void;

  addEventProperties(properties: ApiObject): void;

  addPageviewProperties(properties: ApiObject): void;

  addInitialEventProperties(properties: ApiObject): void;

  removeEventProperty(property: string): void;
}

declare global {
  interface Window {
    freshpaint?: Freshpaint;
  }
}

export function freshpaint() {
  if ('freshpaint' in window && !!window.freshpaint) {
    return window.freshpaint;
  }
  return null;
}
