import { useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useStorageStore } from '~providers/storage-provider/storage-store';
import { COMPANY_UUID_KEY, PROJECT_UUID_KEY } from '~services/client';
import { freshpaint } from '~services/freshpaint';
import { getCurrentSessionRequest } from '~services/v1/auth';
import { CurrentSessionEntity } from '~services/v1/auth/auth.entity';

import { useIsPublicRoute } from '../route/use-is-public-route';

export const SESSION_QUERY_KEY = ['auth/session'] as const;

let initPendo = false;

export interface UseSessionQueryOptions {
  forceEnable?: boolean;
}

export function useSessionQuery({ forceEnable }: UseSessionQueryOptions = {}) {
  const isPublicPage = useIsPublicRoute();
  const enabled = forceEnable || !isPublicPage;

  const [setStorageValues, currentCompanyUuid, currentProjectUuid] = useStorageStore((store) => [
    store.setValues,
    store.values[COMPANY_UUID_KEY],
    store.values[PROJECT_UUID_KEY],
  ]);

  const query = useQuery({
    queryKey: SESSION_QUERY_KEY,
    queryFn: async () => {
      const res = await getCurrentSessionRequest();
      return res.data;
    },
    retry: false,
    retryOnMount: false,
    refetchOnMount: false,
    enabled: enabled,
    refetchOnWindowFocus: enabled,
    refetchOnReconnect: enabled,
    refetchInterval: enabled && 1000 * 60 * 3, // refetch every 3 minutes
    staleTime: 0,
  });

  const onSuccess = (data: CurrentSessionEntity) => {
    if (!initPendo) {
      initPendo = true;
      if ('pendo' in window) {
        try {
          (window?.pendo as any)?.initialize?.({
            visitor: {
              id: data.user_uuid, // Required if user is logged in, default creates anonymous ID
              // email:        // Recommended if using Pendo Feedback, or NPS Email
              // full_name:    // Recommended if using Pendo Feedback
              // role:         // Optional
              // You can add any additional visitor level key-values here,
              // as long as it's not one of the above reserved names.
            },
            account: {
              id: data.user_uuid, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
              // name:         // Optional
              // is_paying:    // Recommended if using Pendo Feedback
              // monthly_value:// Recommended if using Pendo Feedback
              // planLevel:    // Optional
              // planPrice:    // Optional
              // creationDate: // Optional
              // You can add any additional account level key-values here,
              // as long as it's not one of the above reserved names.
            },
          });
        } catch (e) {
          console.error(e);
        }
      }
    }
    const fp = freshpaint();
    if (fp) {
      fp.identify(data.user_infos.name, {
        email: data.user_infos.name,
        name: data.user_infos.name,
      });
    }
    if (data.companies) {
      let companyUuid = String(currentCompanyUuid) || null;
      let projectUuid = String(currentProjectUuid) || null;
      if (
        !companyUuid ||
        !data.companies[companyUuid] ||
        !Object.keys(data.companies[companyUuid].projects || {}).length
      ) {
        companyUuid =
          Object.keys(data.companies).find(
            (uuid) => !!Object.keys(data.companies[uuid].projects || {}).length
          ) || null;
      }
      if (companyUuid) {
        if (!projectUuid || !data.companies[companyUuid]?.projects?.[projectUuid]) {
          projectUuid = Object.keys(data.companies[companyUuid]?.projects || {})[0];
        }
        setStorageValues({
          [COMPANY_UUID_KEY]: companyUuid,
          [PROJECT_UUID_KEY]: projectUuid || null,
        });
        return;
      }
    }
    setStorageValues({
      [COMPANY_UUID_KEY]: null,
      [PROJECT_UUID_KEY]: null,
    });
  };
  const onSuccessRef = useRef(onSuccess);
  onSuccessRef.current = onSuccess;

  useEffect(() => {
    if (query.isSuccess) {
      onSuccessRef.current(query.data);
    }
  }, [query.data, query.isSuccess]);

  return query;
}
