import { useEffect, useState } from 'react';

export function useInputPopper() {
  const [inputRef, setInputRef] = useState<Element | null>(null);
  const [popperAnchorEl, setPopperAnchorEl] = useState<Element | null>(null);
  const [inputWidth, setInputWidth] = useState(0);

  const open = !!popperAnchorEl;

  const forceClose = () => setPopperAnchorEl(null);
  const forceOpen = () => setPopperAnchorEl(inputRef);
  const toggle = (ref: Element | null) => setPopperAnchorEl((prevRef) => (prevRef ? null : ref));

  useEffect(() => {
    if (!inputRef) {
      setInputWidth(0);
      return;
    }
    setInputWidth(inputRef.getBoundingClientRect().width);
    const o = new ResizeObserver(() => {
      setInputWidth(inputRef.getBoundingClientRect().width);
    });
    o.observe(inputRef);
    return () => {
      o.disconnect();
    };
  }, [inputRef]);

  return {
    popperAnchorEl,
    setPopperAnchorEl,
    inputWidth,
    setInputRef,
    open,
    forceClose,
    forceOpen,
    toggle,
  };
}
