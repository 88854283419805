import { FC, PropsWithChildren, useMemo } from 'react';
import { ErrorBoundary, Provider, ProviderProps, useRollbarPerson } from '@rollbar/react';

import { useSessionQuery } from '~hooks/auth';

let RollbarUserLayer: FC<PropsWithChildren<{}>>;
let Rollbar: FC<PropsWithChildren<{}>>;

if (import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN) {
  const rollbarConfig: ProviderProps['config'] = {
    accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
    environment: window.location.hostname,
  };
  // eslint-disable-next-line react/display-name
  Rollbar = function ({ children }) {
    return (
      <Provider config={rollbarConfig}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Provider>
    );
  };

  // eslint-disable-next-line react/display-name
  RollbarUserLayer = function ({ children }) {
    const { data } = useSessionQuery();
    useRollbarPerson(
      useMemo(
        () => ({
          email: data?.user_infos.email,
        }),
        [data?.user_infos.email]
      )
    );
    return <>{children}</>;
  };
} else {
  // eslint-disable-next-line react/display-name
  Rollbar = function ({ children }) {
    return <>{children}</>;
  };
  RollbarUserLayer = Rollbar;
}
export { Rollbar, RollbarUserLayer };
