import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextFieldProps } from '@mui/material';

import { useLoginMutation } from '~hooks/auth';
import { useSearchParams } from '~libs/router';
import { FetchError } from '~services/fetcher-factory';
import { EMAIL_VALIDATION_REGEXP } from '~utils/string';

interface LoginFormInputs {
  email: TextFieldProps;
  password: TextFieldProps;
}

export default function useLoginForm() {
  const { t } = useTranslation();
  const { email, ref } = useSearchParams(['email', 'ref']);

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      email: email ?? '',
      password: '',
    },
  });
  const { mutate, isPending } = useLoginMutation({
    onSuccess() {
      window.location.href = ref || '/';
    },
    onError(e: unknown) {
      if (e instanceof FetchError) {
        setError('email', {
          message: (e?.body as any)?.msg || t('ERROR.UNKNOWN'),
        });
      } else if (e instanceof Error) {
        setError('email', {
          message: e.message || t('ERROR.UNKNOWN'),
        });
      } else {
        setError('email', {
          message: t('ERROR.UNKNOWN'),
        });
      }
    },
  });

  const onSubmit = useMemo(
    () =>
      handleSubmit((data: { email: string; password: string }) => {
        mutate(data);
      }),
    [handleSubmit, mutate]
  );

  const inputs: LoginFormInputs = {
    email: {
      ...register('email', {
        required: t('COMMON.FIELD_IS_REQUIRED'),
        pattern: { value: EMAIL_VALIDATION_REGEXP, message: t('COMMON.INVALID_EMAIL') },
      }),
      type: 'text',
      size: 'small',
      error: !!errors.email,
      helperText: errors.email?.message,
      fullWidth: true,
      // label: t('COMMON.EMAIL'),
      placeholder: t('COMMON.EMAIL'),
    },
    password: {
      ...register('password', { required: t('COMMON.FIELD_IS_REQUIRED') }),
      size: 'small',
      error: !!errors.password,
      helperText: errors.password?.message,
      fullWidth: true,
      type: 'password',
      // label: t('COMMON.PASSWORD'),
      placeholder: t('COMMON.PASSWORD'),
    },
  };

  return {
    getValues,
    isPending,
    inputs,
    onSubmit,
  };
}
