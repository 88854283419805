import { ButtonGroup, buttonGroupClasses, styled } from '@mui/material';

export const StyledButtonGroup = styled(ButtonGroup, {
  shouldForwardProp: (propName) => propName !== 'rounded',
})<{ rounded?: boolean }>(
  ({ theme }) => ({
    height: '100%',
    border: `thin solid ${theme.palette.primary.main}`,
    button: {
      [`&.${buttonGroupClasses.grouped}`]: {
        minWidth: 0,
      },
      [`&.${buttonGroupClasses.grouped}:not(:last-of-type)`]: {
        borderRightWidth: 0,
      },
    },
  }),
  ({ theme, rounded }) =>
    rounded && {
      borderRadius: theme.spacing(3),
    },
  ({ theme, size }) =>
    size === 'small' && {
      padding: theme.spacing(0.5, 1),
    }
);
