import { memoizeSelector } from '~utils/zustand';

import { DashboardState } from '../store/dashboard-store';

import { selectActivePage } from './page.selector';

export const selectPages = (state: DashboardState) => state.pages;

export const selectSortedPages = memoizeSelector(selectPages, (pages) =>
  pages.sort((pageA, pageB) => pageA.page_position - pageB.page_position)
);

export const selectSortedPagesExceptCurrentPage = (state: DashboardState) => {
  const pages = selectSortedPages(state);
  const currentPage = selectActivePage(state);
  return pages.filter((item) => item.page_id !== currentPage?.page_id);
};

export const selectPagesCount = memoizeSelector(selectPages, (pages) => pages.length);
