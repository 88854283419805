import { CircularProgress, LinearProgress } from '@mui/material';

import { Wrapper } from './loading-panel.styled';

export interface LoadingPanelProps {
  withBorder?: boolean;
  variant?: 'linear' | 'circular';
}

export function LoadingPanel({ withBorder = false, variant = 'circular' }: LoadingPanelProps) {
  return variant === 'circular' ? (
    <Wrapper withBorder={withBorder}>
      <CircularProgress />
    </Wrapper>
  ) : (
    <LinearProgress />
  );
}
