import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import { useShortcutHandler } from '~components/element-board/hooks/use-shortcut-handler';
import { useForwardedRef } from '~hooks/use-forwarded-ref';
import { EntityUuid } from '~services/v1/types';
import { Updater } from '~utils/chore';
import { observeElementSize } from '~utils/dom';
import { RectSize } from '~utils/geometry';

import { useConfirmDeleteElement } from '../../element-board.hooks';
import { BaseBoardProps, CopiedNodeData, NodeData } from '../../element-board.types';
import { useNodeRefs } from '../../hooks/use-node-refs';
import { ToolbarContainer } from '../toolbar-container';

import { AlignmentObjects } from './components/alignment-objects';
import { DrawingBoard } from './components/drawing-board';
import { PixelBoardContainer } from './components/pixel-board-container';
import { PixelBoardContext } from './components/pixel-board-context';
import { useRegisterPixelBoardStore } from './components/pixel-board-context/pixel-board.hook';
import { selectSelectedBoxNodes } from './components/pixel-board-context/pixel-board.selector';
import { PixelBoardDndContext } from './components/pixel-board-dnd-context';
import { Resizer } from './components/resizer';
import {
  CustomNodeType,
  PixelBoardMouseContext,
  PixelBoardNodeProps,
  PixelLayoutValue,
  PixelNode,
} from './pixel-board.types';
import {
  bringNodeForward,
  bringNodeToFront,
  copyElementToPixelLayout,
  DEFAULT_SIZE,
  expandNodeFullWidth,
  getAllNodeIdsFromPixelLayout,
  MIN_SIZE,
  onClickPixelNode,
  removeNodeFromPixelBoard,
  sendNodeBackward,
  sendNodeToBack,
} from './pixel-board.utils';

import { AbsoluteItemContainer } from './pixel-board.styled';

export interface PixelBoardProps extends BaseBoardProps {
  CustomNodes?: CustomNodeType;
  value: PixelLayoutValue;
  onChange?: (updater: Updater<PixelLayoutValue>) => unknown;

  enableGrid?: boolean;

  newElementType?: PixelNode['type'];
  defaultSize?: (data: NodeData) => RectSize;
  minSize?: (data: NodeData) => RectSize;
  NewElementCursor?: React.ComponentType;

  onContextMenu?: (e: React.MouseEvent, context: PixelBoardMouseContext) => void;
}

export const PixelBoard = forwardRef<HTMLDivElement, PixelBoardProps>(function PixelBoard(
  {
    CustomNodes,
    editable = false,
    enableGrid,
    value,
    NodeComponent,
    selectedNodeIds,
    onChange,
    onChangeSelectedNodeIds,
    onDeleteElement,
    addable,
    defaultSize = DEFAULT_SIZE,
    minSize = MIN_SIZE,
    NewElementCursor,
    createNewItemData,
    newElementType,
    onContextMenu,
    copyItemData,
    onMoveEnd,
    onMoveStart,
    ToolbarComponent,
    hideToolbar = false,
  },
  ref
) {
  const selectedNodeIdsRef = useRef(selectedNodeIds);
  selectedNodeIdsRef.current = selectedNodeIds;

  const pixelBoardStore = useRegisterPixelBoardStore({ value, selectedNodeIds });
  const [nodeRefs, setNodesRef] = useNodeRefs();

  const [boardSize, setBoardSize] = useState<RectSize>({
    width: 0,
    height: 0,
  });
  const innerRef = useForwardedRef(ref);
  const [tmpValue, setTmpValue] = useState<null | PixelLayoutValue>(null);

  useEffect(() => {
    const el = innerRef.current;
    if (!el) {
      return;
    }
    const onBoardSizeChange = () => {
      const rect = el.getBoundingClientRect();
      setBoardSize({
        width: rect.width,
        height: rect.height,
      });
    };
    return observeElementSize(el, onBoardSizeChange);
  }, [innerRef]);

  const activateAllNodes = useCallback(() => {
    onChangeSelectedNodeIds?.(getAllNodeIdsFromPixelLayout(value));
  }, [onChangeSelectedNodeIds, value]);

  const deleteNodesFromLayout = useCallback(
    (nodeIds: EntityUuid[]) => {
      onChange?.(removeNodeFromPixelBoard(nodeIds));
    },
    [onChange]
  );

  const addCopyNodeToLayout = useCallback(
    (params: CopiedNodeData) => onChange?.(copyElementToPixelLayout(params)),
    [onChange]
  );

  const getSelectedNodes = useCallback(() => {
    return selectSelectedBoxNodes(pixelBoardStore.getState());
  }, [pixelBoardStore]);

  const shortcutHandler = useShortcutHandler({
    getSelectedNodes,
    onChangeSelectedNodeIds,
    onDeleteElement,
    activateAllNodes,
    deleteNodesFromLayout,
    addCopyNodeToLayout,
    copyItemData: copyItemData,
  });

  const confirmDeleteNode = useConfirmDeleteElement();

  const onNodeContextMenu = useCallback(
    (e: React.MouseEvent, node: PixelNode) => {
      onContextMenu?.(e, {
        type: 'element',
        elementData: [node],
        remove: () => {
          confirmDeleteNode(selectedNodeIdsRef.current?.length || 0, async () => {
            await onChange?.(removeNodeFromPixelBoard(node.id));
            onChangeSelectedNodeIds?.([]);
            await onDeleteElement?.([node.data]);
          });
        },
        removeFromLayout: (item: PixelNode) => {
          return onChange?.(removeNodeFromPixelBoard(item.id));
        },
        bringToFront: () => {
          onChange?.(bringNodeToFront(selectedNodeIdsRef.current || []));
        },
        sendToBack: () => {
          onChange?.(sendNodeToBack(selectedNodeIdsRef.current || []));
        },
        sendBackward: () => {
          onChange?.(sendNodeBackward(selectedNodeIdsRef.current || []));
        },
        bringForward: () => {
          onChange?.(bringNodeForward(selectedNodeIdsRef.current || []));
        },
        expandFullWidth: () => {
          onChange?.(expandNodeFullWidth(selectedNodeIdsRef.current || [], boardSize));
        },
        duplicate: async () => {
          const newItem = await copyItemData?.(node.data);
          if (newItem) {
            onChange?.(
              copyElementToPixelLayout({
                source: node,
                target: { data: newItem },
              })
            );
          }
        },
        group: () => {
          //TODO: implement later
        },
        ungroup: () => {
          //TODO: implement later
        },
      });
    },
    [
      onContextMenu,
      confirmDeleteNode,
      onChange,
      onChangeSelectedNodeIds,
      onDeleteElement,
      boardSize,
      copyItemData,
    ]
  );

  const onNodePointerDown = useCallback(
    (e: React.MouseEvent, node: PixelNode) => {
      onChangeSelectedNodeIds?.(onClickPixelNode(e, node.id));
    },
    [onChangeSelectedNodeIds]
  );

  return (
    <PixelBoardContext.Provider value={pixelBoardStore}>
      <PixelBoardContainer
        editable={editable}
        ref={innerRef}
        onChangeSelectedNodeIds={onChangeSelectedNodeIds}
        value={value}
        onChange={onChange}
        onContextMenu={onContextMenu}
        defaultSize={defaultSize}
        onKeyDown={shortcutHandler}
        tabIndex={0}
      >
        <PixelBoardDndContext
          onMoveStart={onMoveStart}
          onMoveEnd={onMoveEnd}
          boardSize={boardSize}
          enableGrid={enableGrid}
          value={value}
          onChange={onChange}
          tmpValue={tmpValue}
          onChangeTmpValue={setTmpValue}
          selectedNodeIds={selectedNodeIds}
        >
          {(tmpValue ?? value).map((node) => {
            const Node = CustomNodes && CustomNodes[node.type];
            const selected = !!selectedNodeIds?.includes?.(node.id);
            const props: Omit<PixelBoardNodeProps, 'children'> = {
              editable,
              selected,
              multipleSelected:
                selected && !!selectedNodeIds?.length && selectedNodeIds?.length > 1,
              node,
              onContextMenu: onNodeContextMenu,
              setNodesRef,
            };
            if (Node) {
              return <Node key={node.id} {...props} />;
            }
            return node.type === 'box' ? (
              <AbsoluteItemContainer
                selected={selected}
                key={node.id}
                editable={editable}
                onContextMenu={onNodeContextMenu}
                onPointerDown={onNodePointerDown}
                setNodesRef={setNodesRef}
                node={node}
              >
                <NodeComponent
                  {...node.data}
                  editable={editable}
                  selected={props.selected}
                  multipleSelected={props.multipleSelected}
                />
              </AbsoluteItemContainer>
            ) : (
              <React.Fragment key={node.id} />
            );
          })}
          {!!selectedNodeIds?.length && <Resizer />}
          {!enableGrid && <AlignmentObjects boardSize={boardSize} value={tmpValue ?? value} />}
          {!!ToolbarComponent && (
            <ToolbarContainer
              hideToolbar={hideToolbar}
              ToolbarComponent={ToolbarComponent}
              nodeRefs={nodeRefs}
              selectedNodeIds={selectedNodeIds}
            />
          )}
        </PixelBoardDndContext>

        {addable && (
          <DrawingBoard
            value={value}
            onChange={onChange}
            createNewItemData={createNewItemData}
            minSize={minSize}
            defaultSize={defaultSize}
            NewElementCursor={NewElementCursor}
            newElementType={newElementType}
            onChangeSelectedNodeIds={onChangeSelectedNodeIds}
          />
        )}
        {/*TODO: Droppable here to allow dropping from outside */}
      </PixelBoardContainer>
    </PixelBoardContext.Provider>
  );
});
