import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { UseMutationOptions } from '@tanstack/react-query/src/types';

import { useStorageStore } from '~providers/storage-provider/storage-store';
import { SESSION_UUID_KEY } from '~services/client';
import { FetchError } from '~services/fetcher-factory';
import { LoginData, loginRequest } from '~services/v1/auth';

import { useReloadSession } from './use-reload-session';

export type SessionUuid = string;

export function useLoginMutation(
  options: Omit<
    UseMutationOptions<void, FetchError | Error | unknown, LoginData | SessionUuid, unknown>,
    'mutationFn'
  >
) {
  const setStorageValue = useStorageStore((store) => store.setValues);
  const reloadSession = useReloadSession();
  const { t } = useTranslation();
  return useMutation<void, FetchError | Error | unknown, LoginData | SessionUuid, unknown>({
    mutationFn: async (data) => {
      let sessionUuid: string | null;
      if (typeof data === 'string') {
        sessionUuid = data;
      } else {
        const response = await loginRequest(data);
        const loginResponseData = response.data;
        if (loginResponseData?.status === 'success') {
          sessionUuid = loginResponseData.session_uuid || null;
        } else {
          throw Error(loginResponseData.msg || t('ERROR.UNKNOWN'));
        }
      }
      setStorageValue({
        [SESSION_UUID_KEY]: sessionUuid,
      });
      await reloadSession();
    },
    ...options,
  });
}
