import { Box, styled } from '@mui/material';

export const NodeContainer = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'selected',
})<{
  selected?: boolean;
}>(
  ({}) => ({
    height: '100%',
    flexShrink: 1,
  }),
  ({ selected, theme }) =>
    selected
      ? {
          outline: `thin solid ${theme.palette.primary.main}`,
          outlineOffset: '-1px',
        }
      : {
          ':focus-visible': {
            outline: 'none',
          },
        }
);
