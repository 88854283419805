import { createStore } from 'zustand';

import { PixelLayoutValue } from '../../pixel-board.types';

export interface PixelBoardState {
  value: PixelLayoutValue;
  selectedNodeIds: string[];
}

export const createPixelBoardStore = (
  initState: Pick<PixelBoardState, 'value' | 'selectedNodeIds'> = {
    value: [],
    selectedNodeIds: [],
  }
) =>
  createStore<PixelBoardState>(() => ({
    ...initState,
  }));
