import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { SESSION_QUERY_KEY } from './use-session-query';

export function useReloadSession() {
  const queryClient = useQueryClient();
  return useCallback(
    (silence = false) => {
      if (silence) {
        return queryClient.refetchQueries({
          queryKey: SESSION_QUERY_KEY,
        });
      }
      return queryClient.invalidateQueries({
        queryKey: SESSION_QUERY_KEY,
      });
    },
    [queryClient]
  );
}
