import { Switch, switchClasses } from '@mui/base/Switch';
import { styled } from '@mui/material';

import { Color } from '../../../../theme/palette';

export type StyledSwitchProps = {
  variant?: 'small' | 'medium' | 'large';
  color?: Color;
};

const baseHeight = 15;
const baseThumbPadding = 1;

export const StyledSwitch = styled(Switch, {
  shouldForwardProp(propName: string) {
    return !['variant', 'color', 'ownerState'].includes(propName);
  },
})<StyledSwitchProps>(({ theme, variant = 'small', color = 'primary' }) => {
  const scale = variant === 'large' ? 1.5 : variant === 'medium' ? 1.2 : 1;

  const height = baseHeight * scale;
  const thumbPadding = baseThumbPadding * scale;

  const width = height * 2;
  const thumbRadius = (height - thumbPadding * 2) / 2;

  return `
  font-size: 0;
  position: relative;
  display: inline-block;
  width: ${width}px;
  height: ${height}px;
  cursor: pointer;

  &.${switchClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchClasses.track} {
    background: ${theme.palette.action.disabled};
    border-radius: ${height / 2}px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchClasses.thumb} {
    display: block;
    width: ${thumbRadius * 2}px;
    height: ${thumbRadius * 2}px;
    top: ${thumbPadding}px;
    left: ${thumbPadding}px;
    border-radius: ${thumbRadius}px;
    background-color: ${theme.palette.background.default};
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchClasses.focusVisible} .${switchClasses.thumb} {
    background-color: ${theme.palette.action.disabled};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchClasses.checked} {
    .${switchClasses.thumb} {
      left: ${width - thumbRadius * 2 - thumbPadding}px;
      top: ${thumbPadding}px;
      background-color: ${theme.palette.background.default};
    }

    .${switchClasses.track} {
      background: ${color === 'default' ? theme.palette.primary.main : theme.palette[color].main};
    }
  }

  & .${switchClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
  `;
});
