import { color as d3color, hsl, lab, rgb } from 'd3-color';

import type { ColorScale } from '../services/v1/element';

export const HEX_COLOR_PATTERN = () =>
  /^#(?<r>[0-9a-f]{1,2})(?<g>[0-9a-f]{1,2})(?<b>[0-9a-f]{1,2})$/gi;

export const isDarkColor = (color?: string) => hsl(color || '#fff').l < 0.5;

export const CHART_COLOR_PALETTE = [
  '#008FFB',
  '#00E396',
  '#FEB019',
  '#FF4560',
  '#775DD0',
  '#3F51B5',
  '#03A9F4',
  '#4CAF50',
  '#F9CE1D',
  '#FF9800',
  '#33B2DF',
  '#546E7A',
  '#D4526E',
  '#13D8AA',
  '#A5978B',
  '#4ECDC4',
  '#C7F464',
  '#81D4FA',
  '#546E7A',
  '#FD6A6A',
  '#2B908F',
  '#F9A3A4',
  '#90EE7E',
  '#FA4443',
  '#69D2E7',
  '#449DD1',
  '#F86624',
  '#EA3546',
  '#662E9B',
  '#C5D86D',
  '#D7263D',
  '#1B998B',
  '#2E294E',
  '#F46036',
  '#E2C044',
  '#662E9B',
  '#F86624',
  '#F9C80E',
  '#EA3546',
  '#43BCCD',
  '#5C4742',
  '#A5978B',
  '#8D5B4C',
  '#5A2A27',
  '#C4BBAF',
  '#A300D6',
  '#7D02EB',
  '#5653FE',
  '#2983FF',
  '#00B1F2',
];

export function generateLabelColor(label: string) {
  const fn = generateLabelColor;
  if (fn.colors[label]) {
    return fn.colors[label];
  }
  fn.colors[label] = CHART_COLOR_PALETTE[fn.cursor];
  fn.cursor += 1;
  return fn.colors[label];
}
generateLabelColor.colors = {} as Record<string, string>;
generateLabelColor.cursor = 0;

export function isColor(color: string) {
  return !!d3color(color);
}

export function darken(color: string, ratio: number): string {
  try {
    return rgb(color === 'transparent' ? 'white' : color)
      .darker(ratio)
      .formatHex();
  } catch (e) {
    return color;
  }
}

export const isTransparent = (color: string) =>
  color === 'transparent' || rgb(color).opacity <= 0.1;

export function hexToRGBArray(hexColor: string): [number, number, number] {
  const match = HEX_COLOR_PATTERN().exec(hexColor);
  if (!match || !match.groups) {
    throw TypeError(`${hexColor} is not a hex color value`);
  }
  const { r, g, b } = match.groups;
  return [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)];
}

/**
 * Pick color between 2 colors
 * @param color1 hex color
 * @param color2 hex color
 * @param weight (0 - 1)
 */
export function pickHexBetween(color1: string, color2: string, weight: number) {
  const rbg1 = hexToRGBArray(color1);
  const rbg2 = hexToRGBArray(color2);
  const w1 = weight;
  const w2 = 1 - w1;
  const hex = [
    Math.round(rbg1[0] * w2 + rbg2[0] * w1)
      .toString(16)
      .padStart(2, '0'),
    Math.round(rbg1[1] * w2 + rbg2[1] * w1)
      .toString(16)
      .padStart(2, '0'),
    Math.round(rbg1[2] * w2 + rbg2[2] * w1)
      .toString(16)
      .padStart(2, '0'),
  ].join('');
  return `#${hex}`;
}

export interface ColorGradientPoint {
  percent: number;
  color: string; // hex color #xxxxxx
}

/**
 * Pick color from gradient
 * @param gradient
 * @param weight percentage
 */
export function pickColorFromGradient(gradient: ColorGradientPoint[], weight: number): string {
  if (gradient.length < 2) {
    throw new TypeError(`Gradient is must have 2 items or more: ${JSON.stringify(gradient)}`);
  }
  let sortedGradient = [...gradient];
  sortedGradient.sort((i1, i2) => i1.percent - i2.percent);
  if (!sortedGradient.length) {
    throw new TypeError(`Invalid gradient (Zero length): ${JSON.stringify(gradient)}`);
  }
  if (sortedGradient[0].percent > 0) {
    sortedGradient = [
      {
        color: sortedGradient[0].color,
        percent: 0,
      },
      ...sortedGradient,
    ];
  }
  if (sortedGradient[sortedGradient.length - 1].percent < 100) {
    sortedGradient = [
      ...sortedGradient,
      {
        color: sortedGradient[sortedGradient.length - 1].color,
        percent: 100,
      },
    ];
  }
  if (weight <= 0) {
    return sortedGradient[0].color;
  }
  if (weight >= 100) {
    return sortedGradient[sortedGradient.length - 1].color;
  }
  const nearestAboveIndex = sortedGradient.findIndex((item) => item.percent > weight);
  if (nearestAboveIndex <= 0) {
    throw new TypeError(`Invalid gradient: ${JSON.stringify(gradient)}`);
  }
  const nearestAbovePoint = sortedGradient[nearestAboveIndex];
  const nearestBelowPoint = sortedGradient[nearestAboveIndex - 1];
  const relativeWeight =
    (weight - nearestBelowPoint.percent) / (nearestAbovePoint.percent - nearestBelowPoint.percent);
  return pickHexBetween(nearestBelowPoint.color, nearestAbovePoint.color, relativeWeight);
}

/**
 * https://developer.mozilla.org/en-US/docs/Web/CSS/gradient/linear-gradient()
 * @param colors array of colors <linear-color-stop>
 * @param direction <side-or-corner>
 */
export function linearGradient(colors: string[], direction = 'to right') {
  return `linear-gradient(${direction}, ${colors.join(',')})`;
}

export function sanitizeColorString(value: string): string {
  let color = value;
  if (color.match(/^([a-f0-9]{1,2}){3}$/i)) {
    color = `#${color}`;
  }
  if (!isColor(color)) {
    return '#fff';
  }
  return rgb(color).toString();
}

export function colorScaleToGradient(
  colorScale: ColorScale,
  max: number,
  min: number
): ColorGradientPoint[] {
  const gradient: ColorGradientPoint[] = colorScale.map((point) => {
    if (point.type === 'percent') {
      return {
        color: point.color,
        percent: point.value,
      };
    }
    let percent = ((point.value - min) * 100) / (max - min);
    percent = percent < 0 ? 0 : percent;
    percent = percent > 100 ? 0 : percent;
    return {
      color: point.color,
      percent,
    };
  });
  return gradient.sort((i1, i2) => i1.percent - i2.percent);
}

/**
 * @source: https://github.com/Evercoder/d3-color-difference/blob/master/src/cie94.js
 */
export function differenceCie94(color1: string, color2: string) {
  const KL = 1;
  const K1 = 0.045;
  const K2 = 0.015;

  const LabStd = lab(color1);
  const LabSmp = lab(color2);

  // Extract Lab values, and compute Chroma
  const lStd = LabStd.l;
  const aStd = LabStd.a;
  const bStd = LabStd.b;
  const cStd = Math.sqrt(aStd * aStd + bStd * bStd);

  const lSmp = LabSmp.l;
  const aSmp = LabSmp.a;
  const bSmp = LabSmp.b;
  const cSmp = Math.sqrt(aSmp * aSmp + bSmp * bSmp);

  const dL2 = Math.pow(lStd - lSmp, 2);
  const dC2 = Math.pow(cStd - cSmp, 2);
  const dH2 = Math.pow(aStd - aSmp, 2) + Math.pow(bStd - bSmp, 2) - dC2;

  return Math.sqrt(
    dL2 / Math.pow(KL, 2) + dC2 / Math.pow(1 + K1 * cStd, 2) + dH2 / Math.pow(1 + K2 * cStd, 2)
  );
}

function colorToHex(color: string): string {
  if (color === 'transparent') {
    return '#00000000';
  }
  // If the input is already a hex color, return it as is
  if (color.startsWith('#')) {
    // If the hex value is shorthand, expand it to the full six-digit value
    if (color.length === 4) {
      return `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`.toUpperCase();
    }

    return color.toUpperCase();
  }

  // If the input is an RGB color, convert it to hex
  if (color.startsWith('rgb')) {
    const rgbValues = color.match(/\d+/g)?.map(Number) ?? [];
    const hexValues = rgbValues.map((value) => value.toString(16).padStart(2, '0'));
    return `#${hexValues.join('')}`.toUpperCase();
  }

  // If the input is a color name, create a dummy element to convert it to hex
  const dummyElement = document.createElement('div');
  dummyElement.style.color = color;
  const computedColor = getComputedStyle(dummyElement).color;
  const hexColor = computedColor
    .match(/\d+/g)
    ?.map((value) => Number(value).toString(16).padStart(2, '0'))
    .join('')
    .toUpperCase();

  return `#${hexColor}`;
}

export function colorsAreEqual(color1: string, color2: string) {
  // Convert both colors to hex
  const hexColor1 = colorToHex(color1);
  const hexColor2 = colorToHex(color2);

  // Compare the hex values
  return hexColor1 === hexColor2;
}

export const DEPRESS_OPACITY = 0.2;

export function depressColor(color: string) {
  const rgbObject = rgb(color);
  rgbObject.opacity = rgbObject.opacity * DEPRESS_OPACITY;
  return rgbObject.toString();
}
