import { EntityUuid } from '~services/v1/types';

import { GridLayoutValue, RowNode } from './grid-board';

export const getRowByNodeId = (nodeId: string, value: GridLayoutValue) => {
  const row = value.rows.find((row) => row.items.some((node) => node.id === nodeId));
  return {
    row,
    nodeIndex: row && row.items.findIndex((item) => item.id === nodeId),
  };
};

export type GridNode = RowNode & {
  height: number;
  type: 'box';
};

export const getGridNodesByIds = (nodeIds: string[], value: GridLayoutValue): GridNode[] =>
  value.rows
    .map((row) => row.items.map((item) => ({ ...item, height: row.height, type: 'box' }) as const))
    .flat()
    .filter((node) => nodeIds.includes(node.id));

export const getNodeIdsFromGridLayout = (value: GridLayoutValue): EntityUuid[] =>
  value.rows.map((row) => row.items.map((item) => item.id)).flat();
