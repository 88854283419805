import { styled } from '@mui/material';

export const Wrapper = styled('label')<{ size: number; color: string }>(
  ({ theme, size, color }) => ({
    border: `thin solid ${theme.palette.grey[300]}`,
    width: `${size}px`,
    height: `${size}px`,
    backgroundColor: `${color}`,
    borderRadius: `${Math.ceil(size / 2)}px`,
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all 300ms',
    backgroundClip: 'padding-box',
    input: {
      display: 'none',
    },
    '&:hover': {
      borderRadius: `${Math.ceil(size / 5)}px`,
    },
    '&.dark': {
      '.MuiSvgIcon-root': {
        color: `${theme.palette.primary.contrastText}`,
      },
    },
    '&.transparent': {
      background: '#fff',
      backgroundImage:
        // eslint-disable-next-line max-len
        'linear-gradient(to top left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 0.8px), red 50%, rgba(0, 0, 0, 0) calc(50% + 0.8px), rgba(0, 0, 0, 0) 100%),linear-gradient(to top right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 0.8px), red 50%, rgba(0, 0, 0, 0) calc(50% + 0.8px), rgba(0, 0, 0, 0) 100%)',
      backgroundSize: '12px 12px',
    },
  })
);
