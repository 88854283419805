import { insertAt } from './string';

export function observeElementSize(
  el: Element,
  callback: (size: { width: number; height: number }) => void
) {
  const resizeObserver = new ResizeObserver(() => {
    callback(el.getBoundingClientRect());
  });
  resizeObserver.observe(el);
  callback(el.getBoundingClientRect());
  return () => resizeObserver.disconnect();
}

export const INPUT_SELECTOR = 'input, textarea, [role=textbox], [contenteditable]';

export const INTERACTIVE_SELECTOR = `button, a, [role=button], ${INPUT_SELECTOR}`;

export function findNearestInteractiveParent(_el: Element | null) {
  let el = _el;
  while (!!el) {
    if (el?.matches?.(INTERACTIVE_SELECTOR)) {
      return el;
    }
    el = el.parentElement;
  }
  return null;
}

const isScrollable = (node: Element) => {
  if (!(node instanceof HTMLElement || node instanceof SVGElement)) {
    return false;
  }
  const style = getComputedStyle(node);
  return ['overflow', 'overflow-x', 'overflow-y'].some((propertyName) => {
    const value = style.getPropertyValue(propertyName);
    return value === 'auto' || value === 'scroll';
  });
};

export const findNearestScrollAncestor = (node: Element | null): Element | null => {
  let currentParent = node;
  while (currentParent) {
    if (isScrollable(currentParent)) {
      return currentParent;
    }
    currentParent = currentParent.parentElement;
  }
  return null;
};

export const FLEX_POSITIONS = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
  top: 'flex-start',
  bottom: 'flex-end',
} as const;

interface PreventPatePlainTextOptions {
  value: string | undefined;
  onChange: ((value: string) => void) | undefined;
}

export const preventPatePlainText =
  ({ value, onChange }: PreventPatePlainTextOptions) =>
  (e: React.ClipboardEvent) => {
    e.preventDefault();

    const selection = document.getSelection();
    const text = e.clipboardData?.getData('text/plain');
    if (onChange) {
      onChange(insertAt(value || '', text, selection?.focusOffset || 0));
    }
  };
