import { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { Typography } from '@mui/material';

import { timeId } from '~utils/chore';
import { isPrimaryClick } from '~utils/event';
import { calculateRectPosition, Coordinate, RectPosition } from '~utils/geometry';

import { PixelBoardProps } from '../../pixel-board';
import { addNodeToPixelBoard } from '../../pixel-board.utils';

import LineDraw from './components/line-draw';
import RectangleDraw from './components/rectangle-draw';

import { Wrapper } from './drawing-board.styled';

export interface DrawingValue {
  from: Coordinate;
  to: Coordinate;
  position: RectPosition;
}

interface DrawingBoardProps
  extends PickAndTransformOptional<
      PixelBoardProps,
      | 'value'
      | 'onChange'
      | 'createNewItemData'
      | 'newElementType'
      | 'NewElementCursor'
      | 'onChangeSelectedNodeIds'
    >,
    PickAndRemoveOptional<PixelBoardProps, 'defaultSize' | 'minSize'> {}

export function DrawingBoard({
  onChange,
  NewElementCursor,
  defaultSize,
  minSize,
  createNewItemData,
  newElementType = 'box',
  onChangeSelectedNodeIds,
}: DrawingBoardProps) {
  const [drawingValue, setDrawingValue] = useState<DrawingValue | null>(null);
  const startDraw = useCallback<MouseEventHandler>(
    (e) => {
      if (!isPrimaryClick(e) || !createNewItemData) {
        return;
      }
      e.stopPropagation();
      const boardRef = e.currentTarget;
      const boundingClientRec = boardRef.getBoundingClientRect();
      const from = {
        x: e.clientX - boundingClientRec.x,
        y: e.clientY - boundingClientRec.y,
      };
      const handleMouseMove = (e: MouseEvent) => {
        const to = {
          x: e.clientX - boundingClientRec.x,
          y: e.clientY - boundingClientRec.y,
        };
        setDrawingValue({
          from,
          to,
          position: calculateRectPosition(from, to),
        });
      };
      const handleComplete = async (e: MouseEvent) => {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleComplete);
        window.removeEventListener('mouseleave', handleComplete);

        const to = {
          x: e.clientX - boundingClientRec.x,
          y: e.clientY - boundingClientRec.y,
        };
        const element = await createNewItemData();
        if (element) {
          const { width: minWidth, height: minHeight } = minSize(element);
          const { width: defaultWidth, height: defaultHeight } = defaultSize(element);
          if (Math.abs(from.x - to.x) < minWidth || Math.abs(from.y - to.y) < minHeight) {
            to.x = from.x + defaultWidth * Math.sign(to.x - from.x || 1);
            to.y = from.y + defaultHeight * Math.sign(to.y - from.y || 1);
          }
          const id = timeId();
          if (newElementType === 'line') {
            onChange?.(
              addNodeToPixelBoard({
                id,
                data: element,
                type: 'line',
                x1: from.x,
                y1: from.y,
                x2: to.x,
                y2: to.y,
              })
            );
          } else {
            onChange?.(
              addNodeToPixelBoard({
                ...calculateRectPosition(from, to),
                id,
                data: element,
                type: 'box',
              })
            );
          }
          onChangeSelectedNodeIds?.([id]);
        }
        setDrawingValue(null);
      };
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleComplete, {
        once: true,
      });
      window.addEventListener('mouseleave', handleComplete, {
        once: true,
      });
    },
    [createNewItemData, defaultSize, minSize, newElementType, onChange, onChangeSelectedNodeIds]
  );

  const sketch = useMemo(() => {
    if (!drawingValue) {
      return null;
    }
    if (newElementType === 'line') {
      return <LineDraw {...drawingValue} />;
    }
    return <RectangleDraw {...drawingValue} />;
  }, [drawingValue, newElementType]);
  return (
    <>
      <Wrapper tabIndex={0} onMouseDown={startDraw}>
        <Typography className="placeholder-text">Select element position</Typography>
        {sketch}
      </Wrapper>
      {!drawingValue && !!NewElementCursor && <NewElementCursor />}
    </>
  );
}
