import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme as MuiTheme,
} from '@mui/material';

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    square: true;
  }
  interface ChipPropsColorOverrides {
    square: true;
  }
}

interface MuiChipComponentType {
  styleOverrides?: ComponentsOverrides<MuiTheme>['MuiChip'];
  defaultProps?: ComponentsProps['MuiChip'];
  variants?: ComponentsVariants['MuiChip'];
}

export const MuiChipComponent: MuiChipComponentType = {
  variants: [],
  defaultProps: {},
  styleOverrides: {
    root: ({ ownerState: { variant }, theme }) => {
      return [
        variant === 'square' && {
          borderRadius: theme.shape.borderRadiusSm,
        },
      ];
    },
  },
};
