import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleIcon } from '@dataflake/icons';
import { Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

import { GlobeAsia, GlobeEU } from '../globe';

enum RegionType {
  WESTERN_EUROPE = 'Western Europe',
  SOUTH_EASTERN_ASIA = 'South-Eastern Asia',
}

export interface RegionSelectItemProps {
  domain: string;
  region: string;
  selected?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export function RegionSelectItem({ domain, region, selected, onClick }: RegionSelectItemProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Button
      onClick={onClick}
      color={'inherit'}
      style={{ borderWidth: 2 }}
      sx={(theme) => ({
        p: 1,
        pb: 0,
        borderColor: selected ? theme.palette.primary.light : theme.palette.divider,
      })}
      variant="outlined"
    >
      <Stack height={1} alignItems="center" justifyContent="space-between">
        <Stack flexWrap="wrap" direction="row" textAlign="left" alignItems="center" spacing={1}>
          <CheckCircleIcon
            sx={(theme) => ({
              color: selected ? theme.palette.primary.main : theme.palette.text.primary,
            })}
          />
          {!isMobileScreen && (
            <Typography
              fontWeight="bold"
              sx={(theme) => ({
                color: selected ? theme.palette.primary.main : theme.palette.text.primary,
              })}
              component="span"
            >
              {t(region)}
            </Typography>
          )}
          <img width={20} src={`/data-center-icon/${domain}.svg`} alt="" />

          {isMobileScreen && (
            <Typography
              fontWeight="bold"
              whiteSpace="nowrap"
              sx={(theme) => ({
                color: selected ? theme.palette.primary.main : theme.palette.text.primary,
              })}
              component="span"
            >
              {t(region)}
            </Typography>
          )}
        </Stack>
        {region === RegionType.WESTERN_EUROPE ? (
          <GlobeEU
            active={selected}
            width={isMobileScreen ? 120 : 170}
            height={isMobileScreen ? 90 : 130}
          />
        ) : (
          <GlobeAsia
            active={selected}
            width={isMobileScreen ? 120 : 170}
            height={isMobileScreen ? 90 : 130}
          />
        )}
      </Stack>
    </Button>
  );
}
