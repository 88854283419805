import { moveRect, RectPosition, scaleChildBox } from '~utils/geometry';

import { useDragTransformData } from '../components/drag-data-context';
import { usePixelBoardSelector } from '../components/pixel-board-context/pixel-board.hook';
import { selectBoundaryRect } from '../components/pixel-board-context/pixel-board.selector';
import { DragAndResizeData } from '../pixel-board.types';

import { isDraggingNode, UnknownDraggingData } from './use-draggable-transform-data';

export function calculateTransformedBox(params: Omit<UnknownDraggingData, 'data'>) {
  const data = params.transformData?.active.data.current as DragAndResizeData | undefined;
  const paramsWithData = {
    ...params,
    data,
  };

  if (!isDraggingNode(paramsWithData)) {
    const { boundary, node } = paramsWithData;
    return node ?? boundary;
  }
  const { data: nonNullData, boundary, node, transformData } = paramsWithData;
  if (nonNullData.action === 'resize') {
    return scaleChildBox(node ?? boundary, boundary, transformData.delta, nonNullData.position);
  }
  if (nonNullData.action === 'drag') {
    return moveRect(node ?? boundary, transformData.delta);
  }
  // TODO: for multiple resizing (affect to container)
  return node ?? boundary;
}

const defaultRect: RectPosition = {
  top: 0,
  left: 0,
  width: 0,
  height: 0,
};

export function useDraggableTransformedBox(): RectPosition {
  const ignore = usePixelBoardSelector((state) => !state.selectedNodeIds.length);
  const transformData = useDragTransformData(ignore);
  return usePixelBoardSelector((state) => {
    const selectedNodeIds = state.selectedNodeIds;
    if (!selectedNodeIds) {
      return defaultRect;
    }
    const boundary = selectBoundaryRect(state);
    if (!boundary) {
      return defaultRect;
    }
    return calculateTransformedBox({
      node: undefined,
      transformData,
      selectedNodeIds,
      boundary,
    });
  });
}
