import { useEffect } from 'react';

export interface ExternalNavigateProps {
  to: string;
}

export function ExternalNavigate({ to }: ExternalNavigateProps) {
  useEffect(() => {
    window.location.href = to;
  }, [to]);
  return null;
}
