import { Chip, chipClasses, styled } from '@mui/material';

import { darken } from '~utils/color';

export const DeleteChipStyled = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.error.lighter,
  color: theme.palette.error.main,

  [`& .${chipClasses.icon}`]: {
    color: theme.palette.error.main,
  },

  '&:hover': {
    backgroundColor: darken(
      theme.palette.error.lighter || theme.palette.error.light,
      theme.palette.action.hoverOpacity
    ),
  },

  '&:active': {
    backgroundColor: darken(
      theme.palette.error.lighter || theme.palette.error.light,
      theme.palette.action.activatedOpacity
    ),
  },

  '&:forcus': {
    backgroundColor: darken(
      theme.palette.error.lighter || theme.palette.error.light,
      theme.palette.action.focusOpacity
    ),
  },
}));
