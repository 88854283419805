import { FormLabel, formLabelClasses, styled, Theme } from '@mui/material';

const colorForm = '#0072F4';
const sizeForm = 12;
const sizeRightPanel = 10.5;
const lineHeightForm = 16;
const lineHeightPanel = 22;

export const DFFormLabel = styled(FormLabel)<{ theme?: Theme; variant?: string }>(({
  theme,
  variant = 'right-panel',
}) => {
  const size = variant === 'form' ? sizeForm : sizeRightPanel;
  const color = variant === 'form' ? colorForm : theme.palette.text.formLabel;
  const lineHeight = variant === 'form' ? lineHeightForm : lineHeightPanel;
  const fontWeight = variant === 'form' ? 'bold' : 'normal';

  return {
    [`&.${formLabelClasses.root}`]: {
      textTransform: 'capitalize',
      fontSize: `${size}px`,
      color,
      lineHeight: `${lineHeight}px`,
      fontWeight,
    },
  };
});
