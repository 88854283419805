import React, { memo, RefCallback, useCallback } from 'react';
import { useMergeRefs } from '@floating-ui/react';
import { Box, BoxProps } from '@mui/material';

import { combineHandlers } from '~utils/event';

import { useDraggableTransformData } from '../../hooks/use-draggable-transform-data';
import { usePixelBoardDraggable } from '../../hooks/use-pixel-board-draggable';
import { PixelBoardNodeProps, PixelBoxNode } from '../../pixel-board.types';

interface DraggableProps
  extends Omit<PixelBoardNodeProps, 'node' | 'multipleSelected'>,
    Omit<BoxProps, 'onChange' | 'onContextMenu' | 'onPointerDown' | 'onClick'> {
  node: PixelBoxNode;
}

/**
 * TODO: Rename because we removed resizing
 */
export const PixelBoardNodeContainer = memo(function PixelBoardNodeContainer({
  editable,
  node,
  children,
  onContextMenu,
  onPointerDown,
  setNodesRef,
  selected,
  ...props
}: DraggableProps) {
  const nodeRef = React.useRef<HTMLElement | null>(null);
  const { attributes, listeners, setNodeRef } = usePixelBoardDraggable({
    node,
    editable,
  });
  const { translate, ...scaledBox } = useDraggableTransformData(node, !selected);

  // useHighlightSearchElement({
  //   node,
  //   element: nodeRef.current,
  //   onChangeSelectedNodeIds,
  // });

  const setRef = useMergeRefs([
    setNodeRef,
    useCallback<RefCallback<HTMLElement>>(
      (el) =>
        setNodesRef({
          [node.id]: el,
        }),
      [node.id, setNodesRef]
    ),
    nodeRef,
  ]);

  function handleContextMenu(e: React.MouseEvent) {
    onContextMenu?.(e, node);
  }

  function onPointerDownHandler(e: React.MouseEvent) {
    onPointerDown?.(e, node);
  }

  return (
    <Box
      data-id={node.id}
      ref={setRef}
      onContextMenu={handleContextMenu}
      style={{
        ...scaledBox,
        transform: `translate(${translate?.x ?? 0}px, ${translate?.y ?? 0}px)`,
      }}
      {...listeners}
      {...attributes}
      {...props}
      onPointerDown={combineHandlers(
        onPointerDownHandler,
        listeners?.onPointerDown as React.PointerEventHandler
      )}
    >
      {children}
    </Box>
  );
});
