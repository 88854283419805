import { random, snakeCase, upperFirst } from 'lodash-es';

export type StringType = 'string' | 'float' | 'integer' | 'datetime' | 'time';

export function isJson(value: string) {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
}

export function detectStringType(value: string, strict = false): StringType {
  if (strict && value.trim().match(/^-?\d+$/)) {
    return 'integer';
  }
  if (value.trim().match(/^-?\d+(\.\d+)?$/)) {
    return 'float';
  }
  if (
    value.trim().match(/^\d{4}-[0-1]?\d-[0-3]?\d{2} [0-2]\d:[0-6]\d:[0-6]\d$/) ||
    value.trim().match(/^\d{4}\/[0-1]?\d\/[0-3]?\d{2} [0-2]\d:[0-6]\d:[0-6]\d$/) ||
    value.trim().match(/^\d{4}-[0-1]?\d-[0-3]?\d{2} [0-2]\d:[0-6]\d$/) ||
    value.trim().match(/^\d{4}\/[0-1]?\d\/[0-3]?\d{2} [0-2]\d:[0-6]\d$/)
  ) {
    return 'datetime';
  }
  if (value.trim().match(/^[0-2]\d:[0-6]\d:[0-6]\d$/) || value.trim().match(/^[0-2]\d:[0-6]\d$/)) {
    return 'time';
  }
  return 'string';
}

export function matchStringType(value: string, type: StringType): boolean {
  if (type === 'string') {
    return true;
  }
  const trimedValue = value.trim();
  if (type === 'time') {
    return (
      !!trimedValue.match(/^[0-2]\d:[0-6]\d:[0-6]\d$/) || !!trimedValue.match(/^[0-2]\d:[0-6]\d$/)
    );
  }
  if (type === 'datetime') {
    return (
      !!trimedValue.match(/^\d{4}-[0-1]?\d-[0-3]?\d{2} [0-2]\d:[0-6]\d:[0-6]\d$/) ||
      !!trimedValue.match(/^\d{4}\/[0-1]?\d\/[0-3]?\d{2} [0-2]\d:[0-6]\d:[0-6]\d$/) ||
      !!trimedValue.match(/^\d{4}-[0-1]?\d-[0-3]?\d{2} [0-2]\d:[0-6]\d$/) ||
      !!trimedValue.match(/^\d{4}\/[0-1]?\d\/[0-3]?\d{2} [0-2]\d:[0-6]\d$/)
    );
  }
  if (type === 'integer') {
    return !!trimedValue.match(/^-?\d+$/);
  }
  if (type === 'float') {
    return !!trimedValue.match(/^-?\d+(\.\d+)?$/);
  }
  return false;
}

export const measureTextBoxSizing = (text: string, style?: React.CSSProperties) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    throw new Error('Canvas context is not supported');
  }

  if (style) {
    context.font = `${style.fontStyle || ''} ${style.fontWeight || ''} ${Number(
      style.fontSize || 12
    )}px ${style.fontFamily || ''}`;
  }

  const textMetrics = context.measureText(text);

  return {
    width: textMetrics.width,
    height: textMetrics.fontBoundingBoxAscent + textMetrics.fontBoundingBoxDescent,
  };
};

interface MeasureTextHeightFunction {
  (text: string, style?: Partial<CSSStyleDeclaration>): number;
  div?: HTMLDivElement;
}
export const measureTextHeight: MeasureTextHeightFunction = (
  text,
  style: Partial<CSSStyleDeclaration> = {}
) => {
  if (!measureTextHeight.div) {
    measureTextHeight.div = document.createElement('div');
    measureTextHeight.div.style.position = 'fixed';
    measureTextHeight.div.style.top = '0';
    measureTextHeight.div.style.opacity = '0';
    document.body.append(measureTextHeight.div);
  }
  const { div } = measureTextHeight;
  Object.keys(style).forEach((key: any) => {
    div.style[key] = style[key] as any;
  });
  div.innerHTML = text;
  const { height } = div.getBoundingClientRect();
  return height;
};

export const defaultRandomStringPool =
  'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ09123456789';

export const complexRandomStringPool = `${defaultRandomStringPool}!@#$%^&*()_+-=[]{}|;:,.<>?`;

export const randomString = (length: number, pool = defaultRandomStringPool) => {
  let string = '';
  while (string.length < length) {
    const index = Math.floor(Math.random() * pool.length);
    string += pool[index];
  }
  return string;
};

const defaultWords = [
  'act',
  'apex',
  'bills',
  'build',
  'chores',
  'crest',
  'deal',
  'depot',
  'desk',
  'dream',
  'employ',
  'form',
  'frame',
  'genius',
  'gig',
  'hero',
  'high',
  'hill',
  'jobs',
  'labor',
  'max',
  'prime',
  'ridge',
  'runs',
  'solve',
  'star',
  'task',
  'vertex',
  'whiz',
  'works',
];

export const randomWord = ({
  excludeWord,
  words = defaultWords,
}: {
  words?: string[];
  excludeWord?: string;
}) => {
  let randomWord = '';
  do {
    const randomIndex = Math.floor(Math.random() * words.length);
    randomWord = words[randomIndex];
  } while (randomWord === excludeWord);
  return randomWord;
};

export function randomName() {
  const firstName = randomWord({});
  const secondName = randomWord({ excludeWord: firstName });
  return `${firstName}-${secondName}-${random(1, 100000, false)}`;
}

export function upperSnakeCase(value: string): string {
  return snakeCase(value).toUpperCase();
}

export function normalizeEmail(value: string) {
  return value
    .replace(/^[.-]+|[.-]+$/gi, '')
    .replace(/[^a-z0-9@._+-]+/gi, '_')
    .toLowerCase();
}

export function getNameFromEmail(email: string) {
  return upperFirst(email.split('@')[0]);
}

export function removeMarkdown(md?: string, options?: any) {
  options = options || {};
  options.listUnicodeChar = options.hasOwnProperty('listUnicodeChar')
    ? options.listUnicodeChar
    : false;
  options.stripListLeaders = options.hasOwnProperty('stripListLeaders')
    ? options.stripListLeaders
    : true;
  options.gfm = options.hasOwnProperty('gfm') ? options.gfm : true;
  options.useImgAltText = options.hasOwnProperty('useImgAltText') ? options.useImgAltText : true;
  options.abbr = options.hasOwnProperty('abbr') ? options.abbr : false;
  options.replaceLinksWithURL = options.hasOwnProperty('replaceLinksWithURL')
    ? options.replaceLinksWithURL
    : false;
  options.htmlTagsToSkip = options.hasOwnProperty('htmlTagsToSkip') ? options.htmlTagsToSkip : [];

  let output = md || '';

  // Remove horizontal rules (stripListHeaders conflict with this rule, which is why it has been moved to the top)
  output = output.replace(/^(-\s*?|\*\s*?|_\s*?){3,}\s*/gm, '');

  try {
    if (options.stripListLeaders) {
      if (options.listUnicodeChar)
        output = output.replace(/^([\s\t]*)([*\-+]|\d+\.)\s+/gm, options.listUnicodeChar + ' $1');
      else output = output.replace(/^([\s\t]*)([*\-+]|\d+\.)\s+/gm, '$1');
    }
    if (options.gfm) {
      output = output
        // Header
        .replace(/\n={2,}/g, '\n')
        // Fenced codeblocks
        .replace(/~{3}.*\n/g, '')
        // Strikethrough
        .replace(/~~/g, '')
        // Fenced codeblocks
        .replace(/`{3}.*\n/g, '');
    }
    if (options.abbr) {
      // Remove abbreviations
      output = output.replace(/\*\[.*]:.*\n/, '');
    }
    output = output
      // Remove HTML tags
      .replace(/<[^>]*>/g, '');

    let htmlReplaceRegex = new RegExp('<[^>]*>', 'g');
    if (options.htmlTagsToSkip.length > 0) {
      // Using negative lookahead. Eg. (?!sup|sub) will not match 'sup' and 'sub' tags.
      const joinedHtmlTagsToSkip = '(?!' + options.htmlTagsToSkip.join('|') + ')';

      // Adding the lookahead literal with the default regex for html. Eg./<(?!sup|sub)[^>]*>/ig
      htmlReplaceRegex = new RegExp('<' + joinedHtmlTagsToSkip + '[^>]*>', 'ig');
    }

    output = output
      // Remove HTML tags
      .replace(htmlReplaceRegex, '')
      // Remove setext-style headers
      .replace(/^[=\-]{2,}\s*$/g, '')
      // Remove footnotes?
      .replace(/\[\^.+?](: .*?$)?/g, '')
      .replace(/\s{0,2}\[.*?]: .*?$/g, '')
      // Remove images
      .replace(/!\[(.*?)][\[(].*?[\])]/g, options.useImgAltText ? '$1' : '')
      // Remove inline links
      .replace(/\[([^\]]*?)][\[(].*?[\])]/g, options.replaceLinksWithURL ? '$2' : '$1')
      // Remove blockquotes
      .replace(/^\s{0,3}>\s?/gm, '')
      // .replace(/(^|\n)\s{0,3}>\s?/g, '\n\n')
      // Remove reference-style links?
      .replace(/^\s{1,2}\[(.*?)]: (\S+)( ".*?")?\s*$/g, '')
      // Remove atx-style headers
      .replace(/^(\n)?\s*#{1,6}\s+| *(\n)?\s*#* #*(\n)?\s*$/gm, '$1$2$3')
      // Remove * emphasis
      .replace(/([*]+)(\S)(.*?\S)??\1/g, '$2$3')
      // Remove _ emphasis. Unlike *, _ emphasis gets rendered only if
      //   1. Either there is a whitespace character before opening _ and after closing _.
      //   2. Or _ is at the start/end of the string.
      .replace(/(^|\W)([_]+)(\S)(.*?\S)??\2($|\W)/g, '$1$3$4$5')
      // Remove code blocks
      .replace(/(`{3,})(.*?)\1/gm, '$2')
      // Remove inline code
      .replace(/`(.+?)`/g, '$1')
      // // Replace two or more newlines with exactly two? Not entirely sure this belongs here...
      // .replace(/\n{2,}/g, '\n\n')
      // // Remove newlines in a paragraph
      // .replace(/(\S+)\n\s*(\S+)/g, '$1 $2')
      // Replace strike through
      .replace(/~(.*?)~/g, '$1');
  } catch (e) {
    console.error(e);
    return md;
  }
  return output;
}

export const EMAIL_VALIDATION_REGEXP = /^\S+@\S+$/i;

export interface SearchTextToken {
  type: 'text' | 'keyword';
  text: string;
}

export const searchTextTokenize = (text: string, keyword: string): SearchTextToken[] => {
  const tokens: SearchTextToken[] = [];
  let index = 0;
  while (index < text.length) {
    const keywordIndex = text.toLowerCase().indexOf(keyword.toLowerCase(), index);
    if (keywordIndex === -1) {
      tokens.push({
        type: 'text',
        text: text.slice(index),
      });
      break;
    }
    if (keywordIndex > index) {
      tokens.push({
        type: 'text',
        text: text.slice(index, keywordIndex),
      });
    }
    tokens.push({
      type: 'keyword',
      text: text.slice(keywordIndex, keywordIndex + keyword.length),
    });
    index = keywordIndex + keyword.length;
  }
  return tokens;
};

export function insertAt(text: string, insert: string, index: number) {
  return text.slice(0, index) + insert + text.slice(index);
}
