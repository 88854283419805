import { ButtonBaseProps, useTheme } from '@mui/material';

import transparentSrc from '~assets/transparent.png';
import { isTransparent } from '~utils/color';

import * as Styled from './color-item.styled';

export interface ColorItemProps extends ButtonBaseProps {
  color: string;
  size?: number;
  rounded?: boolean;
  checked?: boolean;
}

export function ColorItem({ checked, ...props }: ColorItemProps) {
  const theme = useTheme();

  return (
    <Styled.ButtonBase
      {...props}
      backgroundImage={isTransparent(props.color) ? transparentSrc : undefined}
    >
      {checked && (
        <Styled.Check
          sx={{
            color: isTransparent(props.color)
              ? theme.palette.text.primary
              : theme.palette.getContrastText(props.color),
          }}
        />
      )}
    </Styled.ButtonBase>
  );
}
