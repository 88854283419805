import React from 'react';
import { useTranslation } from 'react-i18next';
import { EyeIcon, EyeSlashIcon } from '@dataflake/icons';
import { Button, Grid, IconButton, Stack } from '@mui/material';
import { formatDate } from 'date-fns/format';

import { periodDateRangeOptions } from '~components/input';
import { DateRangeValue, PredefinedDateRange } from '~services/v1/element';
import { DFFormItem } from '~ui/df-form/components/df-form-item';
import { DFListPickerInput } from '~ui/df-input/components/df-list-picker-input';
import { DFOutlinedNumberInput } from '~ui/df-input/components/df-outlined-number-input';

import { getDateRange } from '../../utils';

export interface PeriodSelectProps {
  value: DateRangeValue;
  onChange: (value: DateRangeValue) => void;
  availablePeriods?: string[];
  onHidePeriodsToggle?: (
    key: string,
    value: boolean,
    allOptions: typeof periodDateRangeOptions
  ) => void;
}

const AVAILABLE_AGO_UNIT = ['year', 'month', 'day', 'hour', 'minute', 'second'] as const;

export function PeriodSelect({
  value,
  onChange: onChangeValue,
  availablePeriods,
  onHidePeriodsToggle = () => {},
}: PeriodSelectProps) {
  const { t } = useTranslation();

  const [agoValue, setAgoValue] = React.useState(
    (value.type === 'ago' && value.value) || {
      year: 0,
      month: 0,
      day: 0,
      hour: 0,
      minute: 0,
      second: 0,
    }
  );

  const dateRange = getDateRange(value);

  const handleQuickSelectTime = React.useCallback(
    (value: string) => {
      switch (value) {
        case 'ago':
          onChangeValue({
            type: 'ago',
            value: agoValue,
          });
          break;
        case 'fixed':
          onChangeValue({
            type: 'fixed',
            value: [formatDate(dateRange[0], 'yyyy-MM-dd'), formatDate(dateRange[1], 'yyyy-MM-dd')],
          });
          break;
        default:
          onChangeValue({
            type: 'predefined',
            value: value as PredefinedDateRange,
          });
          break;
      }
    },
    [agoValue, dateRange, onChangeValue]
  );

  const handleApplyAgoValue = React.useCallback(() => {
    onChangeValue({
      type: 'ago',
      value: agoValue,
    });
  }, [agoValue, onChangeValue]);

  return (
    <Stack alignItems="center" justifyContent="center">
      <DFListPickerInput
        disableSearch
        value={value.type === 'fixed' || value.type === 'ago' ? value.type : value?.value}
        onChange={handleQuickSelectTime}
        options={periodDateRangeOptions.map(({ name: label, value }) => {
          const show = !availablePeriods || availablePeriods.includes(value);
          return {
            value,
            label,
            endIcon: (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onHidePeriodsToggle(value, !show, periodDateRangeOptions);
                }}
                size="small"
              >
                {show ? (
                  // <Tooltip title={t('PERIOD_SELECT.CLICK_TO_HIDE_ON_VIEW_MODE')}>
                  <EyeIcon fontSize="small" />
                ) : (
                  // </Tooltip>
                  // <Tooltip title={t('PERIOD_SELECT.CLICK_TO_SHOW_ON_VIEW_MODE')}>
                  <EyeSlashIcon fontSize="small" />
                  // </Tooltip>
                )}
              </IconButton>
            ),
          };
        })}
      />
      {value?.type === 'ago' && (
        <Stack direction="column" gap={1}>
          <Grid container spacing={1}>
            {AVAILABLE_AGO_UNIT.map((unit) => (
              <Grid item key={unit} xs={2}>
                <DFFormItem
                  label={unit}
                  input={
                    <DFOutlinedNumberInput
                      minValue={0}
                      value={agoValue[unit] || ''}
                      onChange={(value) => setAgoValue((state) => ({ ...state, [unit]: value }))}
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
          <Button
            variant="contained"
            size="small"
            onClick={handleApplyAgoValue}
            sx={{ ml: 'auto' }}
          >
            {t('COMMON.APPLY')}
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
