import deepmerge, { Options } from 'deepmerge';
import { pick } from 'lodash-es';

import { timeId } from '~utils/chore';
import { arrayReplaceStrategy } from '~utils/deepmerge';
import { RectSize } from '~utils/geometry';

import { DataElementEntityColumn, DataSourceEntity, getDataSourceRequest } from '../data-source';

import { defaultScatterChartConfig } from './entities/chart-element/scatter-chart-element';
import {
  AxisChartConfig,
  BubbleChartConfig,
  ChartConfig,
  ChartConfigByType,
  ChartElementEntity,
  ChartType,
  COLUMN_VALUE_COLOR_DEFAULT,
  DECIMAL_PRECISION_DEFAULT,
  defaultAxisChartConfig,
  defaultBarChartConfig,
  defaultBubbleChartConfig,
  defaultDataCardConfig,
  defaultGeneralConfig,
  defaultGeomapChartConfig,
  defaultMixedChartConfig,
  defaultPieChartConfig,
  defaultSankeyDiagramConfig,
  defaultTableChartConfig,
  ElementEntity,
  type ElementEntityByType,
  ElementType,
  FilterElementConfig,
  FilterElementEntity,
  FilterType,
  GeneralChartConfig,
  generalChartConfigKeys,
  HorizontalBarChartConfig,
  LegacyScaleType,
  MixedChartYAxis,
  NewElementData,
  RealChartElementEntity,
  SameElementEntityArray,
  SanitizedNewElementData,
  TableColumn,
  TableConditionalFormat,
  TableSingleConditionalFormat,
  TextElementConfig,
  TextElementShape,
} from './element.entity';
import { fillElementNullConfig } from './element.service';

export const DEFAULT_SIZES: Record<ElementType, { width: number; height: number }> = {
  line: {
    width: 400,
    height: 60,
  },
  logging: {
    width: 450,
    height: 300,
  },
  image: {
    width: 450,
    height: 300,
  },
  chart: {
    width: 450,
    height: 300,
  },
  text: {
    width: 400,
    height: 60,
  },
  shape: {
    width: 450,
    height: 300,
  },
  filter: {
    width: 400,
    height: 60,
  },
  'json-card': {
    width: 450,
    height: 300,
  },
};

export const CHART_MINIMAL_HEIGHT = 160;
export const CHART_MINIMAL_WIDTH = 160;
export const ELEMENT_MINIMAL_HEIGHT = 35;
export const ELEMENT_MINIMAL_WIDTH = 35;
export const NORMAL_MOVING_STEP = 4;
export const NORMAL_PERCENT_STEP = 0.1;

export function isRealChartElement(el: ElementEntity): el is RealChartElementEntity {
  return el.element_type === 'chart' && el.element_config?.chartType !== 'card';
}

export function checkElementType<T extends ElementEntity>(...types: T['element_type'][]) {
  return (el: ElementEntity): el is T => (types as string[]).includes(el.element_type);
}

export function getElementMinSize(element: ElementEntity): RectSize {
  return {
    height: isRealChartElement(element) ? CHART_MINIMAL_HEIGHT : ELEMENT_MINIMAL_HEIGHT,
    width: isRealChartElement(element) ? CHART_MINIMAL_WIDTH : ELEMENT_MINIMAL_WIDTH,
  };
}

export const isTableSingleColorFormat = (
  format: TableConditionalFormat
): format is TableSingleConditionalFormat => format.type === 'single';

export function getShowStackedConfig(showStacked: boolean) {
  return {
    showStacked,
    ...(!showStacked && {
      stacked100: false,
    }),
  };
}

export function getXScaleConfig(xScale: LegacyScaleType) {
  return {
    xScale,
    reverseX: false,
  };
}

export function canReverseX(xScale?: LegacyScaleType) {
  return xScale === 'category';
}

export const isElementsHasSameType = (
  elements: ElementEntity[]
): elements is SameElementEntityArray => {
  if (elements.length < 2) {
    return false;
  }
  const [firstElement, ...otherElements] = elements;
  switch (firstElement.element_type) {
    case 'chart':
      return otherElements.every((element) => {
        return (
          element.element_type === firstElement.element_type &&
          element.element_config.chartType === firstElement.element_config.chartType
        );
      });
    case 'filter':
      return otherElements.every((element) => {
        return (
          element.element_type === firstElement.element_type &&
          element.element_config.filterType === firstElement.element_config.filterType
        );
      });
    case 'shape':
      return otherElements.every((element) => {
        return (
          element.element_type === firstElement.element_type &&
          element.element_config.type === firstElement.element_config.type
        );
      });
    default:
      return otherElements.every((element) => {
        return element.element_type === firstElement.element_type;
      });
  }
};

export function getElementLabel(el: ElementEntity) {
  switch (el.element_type) {
    case 'chart':
      return el.element_config.label || 'Untitled chart';
    case 'filter':
      return el.element_config.label || 'Untitled filter';
    default:
      return el.element_id;
  }
}

export function pickGeneralChartConfig(config: ChartConfig): GeneralChartConfig {
  return pick(config, generalChartConfigKeys);
}

export interface ColumTypes {
  datetime: string[];
  numeric: string[];
  category: string[];
}

export function getDimension(columnTypes: ColumTypes) {
  let dimension: string | undefined = undefined;
  let xScale: LegacyScaleType = 'category';
  if (columnTypes.datetime.length) {
    [dimension] = columnTypes.datetime.splice(0, 1);
    xScale = 'datetime';
  } else if (columnTypes.category.length) {
    [dimension] = columnTypes.category.splice(0, 1);
    xScale = 'category';
  } else if (columnTypes.numeric.length) {
    [dimension] = columnTypes.numeric.splice(0, 1);
    xScale = 'numeric';
  }
  return {
    dimension,
    xScale,
  };
}

export function getMetrics(columnTypes: ColumTypes) {
  if (columnTypes.numeric.length) {
    const metrics = [...columnTypes.numeric];
    columnTypes.numeric = [];
    return metrics;
  }
  return [];
}

export function getBreakdownDimension(columnTypes: ColumTypes) {
  let breakdownDimension: string | undefined = undefined;
  if (columnTypes.category.length) {
    [breakdownDimension] = columnTypes.category.splice(0, 1);
  }
  return breakdownDimension;
}

export function mapColumnsByTypes(columns: DataElementEntityColumn[]) {
  return columns.reduce(
    (acc, column) => {
      if (column.type === 'datetime' || column.name.match(/(dt|date|time)/)) {
        acc.datetime.push(column.name);
      } else if (column.type === 'number') {
        acc.numeric.push(column.name);
      } else {
        acc.category.push(column.name);
      }
      return acc;
    },
    {
      category: [] as string[],
      numeric: [] as string[],
      datetime: [] as string[],
    }
  );
}

export function fillChartDataConfig<C extends ChartConfig>(
  chartConfig: C,
  dataSource: DataSourceEntity
): C {
  const columnTypes = mapColumnsByTypes(dataSource.columns || []);

  switch (chartConfig.chartType) {
    case 'bubble':
    case 'scatter':
    case 'bar':
    case 'horizontalBar':
    case 'line': {
      const { xScale, dimension } = getDimension(columnTypes);
      const metric = getMetrics(columnTypes);

      return {
        ...chartConfig,
        metric: metric,
        dimension,
        xScale,
      };
    }
    case 'card': {
      const [column] = getMetrics(columnTypes);
      return {
        ...chartConfig,
        function: 'first',
        column,
      };
    }
    case 'pie':
    case 'geomap': {
      const { dimension } = getDimension(columnTypes);
      const [metric] = getMetrics(columnTypes);

      return {
        ...chartConfig,
        metric: metric,
        dimension,
      };
    }
    case 'sankey': {
      const { dimension: sourceField } = getDimension(columnTypes);
      const { dimension: targetField } = getDimension(columnTypes);
      const [valueField] = getMetrics(columnTypes);

      return {
        ...chartConfig,
        sourceField,
        targetField,
        valueField,
      };
    }
    case 'mixed': {
      const { dimension, xScale } = getDimension(columnTypes);
      const metrics = getMetrics(columnTypes);

      return {
        ...chartConfig,
        xScale,
        dimension,
        yAxis: [
          {
            metrics: metrics.map((field) => ({
              type: 'line',
              field,
              breakdown: undefined,
            })),
          },
        ],
      };
    }

    case 'table': {
      const { dimension } = getDimension(columnTypes);
      const metrics = getMetrics(columnTypes);
      const columns: TableColumn[] = [];
      if (dimension !== undefined) {
        columns.push({
          id: timeId(),
          name: dimension,
          field: dimension,
          displayType: 'string' as const,
          color: COLUMN_VALUE_COLOR_DEFAULT,
          width: 0.2,
          showNumber: true,
          compactNumber: true,
          decimalPrecision: DECIMAL_PRECISION_DEFAULT,
          style: {
            fontFamily: 'Roboto',
          },
        });
      }
      columns.push(
        ...metrics.map((metric, index) => ({
          id: timeId(`${index}`),
          name: metric,
          field: metric,
          displayType: 'heatmap' as const,
          color: COLUMN_VALUE_COLOR_DEFAULT,
          width: 0.2,
          showNumber: true,
          compactNumber: true,
          decimalPrecision: DECIMAL_PRECISION_DEFAULT,
          style: {
            fontFamily: 'Roboto',
          },
        }))
      );

      return {
        ...chartConfig,
        columns: columns,
      };
    }
    default:
      throw new Error(
        `Strange chart type: ${(chartConfig as C).chartType}, You might forget to add logic when add chart type it`
      );
  }
}

export function recommendChartConfig(dataSource: DataSourceEntity): ChartConfig {
  const columnTypes = mapColumnsByTypes(dataSource.columns || []);
  if (!!columnTypes.numeric.length && !!columnTypes.datetime.length) {
    return {
      label: '',
      chartType: 'line',
      metric: columnTypes.numeric,
      dimension: columnTypes.datetime[0],
      xScale: 'datetime',
    };
  }
  if (!!columnTypes.category.length) {
    const category = columnTypes.category[0];
    const uniqueValues = new Set(dataSource.rows?.map((row) => row[category]));
    if (uniqueValues.size <= 10) {
      return {
        label: '',
        chartType: 'pie',
        metric: columnTypes.numeric[0],
        dimension: category,
      };
    } else if (uniqueValues.size <= 20) {
      return {
        label: '',
        chartType: 'horizontalBar',
        metric: columnTypes.numeric,
        dimension: category,
      };
    }
  }
  return {
    label: '',
    chartType: 'bar',
    metric: columnTypes.numeric,
    dimension: columnTypes.category.at(0),
  };
}

export async function getFillChartDataConfig({
  queryId,
  connectionStringId,
  elementConfig,
}: {
  queryId: string;
  connectionStringId: string;
  elementConfig: ChartConfig;
}) {
  const data = await getDataSourceRequest({
    queryId,
    connectionStringId,
  });

  return fillChartDataConfig(elementConfig, data);
}

export function checkChartElementDataConfig(config: ChartConfig) {
  switch (config.chartType) {
    case 'bar':
    case 'line':
    case 'pie':
    case 'scatter':
    case 'bubble':
    case 'geomap':
    case 'horizontalBar':
      return config.metric?.length && config.dimension;
    case 'card':
      return !!config.column && !!config.function;
    case 'table':
      return !!config.columns?.length;
    case 'mixed':
      return (
        !!config.dimension?.length &&
        config.yAxis &&
        config.yAxis.some((axis) => !!axis.metrics.length)
      );
    case 'sankey':
      return !!config.valueField && !!config.sourceField && !!config.targetField;
  }
  return false;
}

export const AXIS_LEGEND_WIDTH = 90;

export function sanitizeNewElementData<T extends ElementEntity>(
  data: NewElementData<T>
): SanitizedNewElementData<T> {
  const defaultConfig: {
    [K in ElementType]: Partial<ElementEntityByType<K>['element_config']>;
  } = {
    chart: {
      chartType: 'bar',
      label: '',
      xScale: 'category',
      toolbarPosition: 'right',
      showAnimations: false,
      tooltipPutCurrentLegendOnTop: 'auto',
    },
    'json-card': {
      apiUrl: '',
    },
    text: {
      text: '',
    },
    image: {
      src: '',
      size: 'fill',
    },
    logging: {
      timestampColumn: '',
      severityColumn: '',
      histogramPeriod: '1d',
      query: '',
      columns: [],
    },
    shape: {
      type: 'rectangle',
    },
    filter: {
      label: '',
      filterType: 'daterange',
      defaultValue: {
        type: 'predefined',
        value: 'today',
      },
      queryParameter: [null, null],
    },
    line: {
      headStyle: 'standard',
      tailStyle: 'none',
    },
  };

  return {
    ...data,
    element_config: {
      ...defaultConfig[data.element_type],
      ...data.element_config,
    },
  } as SanitizedNewElementData<T>;
}

export function getDefaultSize(newElementData: NewElementData) {
  return DEFAULT_SIZES[newElementData.element_type];
}

export const isFilterElement = (el: ElementEntity): el is FilterElementEntity =>
  el.element_type === 'filter';

export function getXScale(type: DataElementEntityColumn['type']): LegacyScaleType {
  if (type === 'string') {
    return 'category';
  }
  if (type === 'number') {
    return 'numeric';
  }
  if (type === 'datetime') {
    return 'datetime';
  }
  return 'category';
}

export function getUpdatedMetricsMixedChart({
  columns,
  updatedIdx,
  yAxis,
}: {
  yAxis?: MixedChartYAxis[];
  columns: string[];
  updatedIdx: number;
}) {
  return (yAxis || []).map((yAxis, yAxisIdx) =>
    yAxisIdx !== updatedIdx
      ? yAxis
      : {
          ...yAxis,
          metrics: [
            ...yAxis.metrics.filter((metric) => columns.includes(metric.field)),
            ...columns
              .filter((column) => yAxis.metrics.every((metric) => metric.field !== column))
              .map((column) => ({
                field: column,
                breakdown: '',
              })),
          ],
        }
  );
}

export function isValidMetricBubbleChart(
  elementConfig: Partial<BubbleChartConfig>,
  value: string,
  metrics = [] as string[]
) {
  return ![
    elementConfig.dimension,
    elementConfig.breakdownDimension,
    elementConfig.sizeMetric,
    ...metrics,
  ].includes(value);
}

const mergeOptions: Options = {
  arrayMerge: arrayReplaceStrategy,
};

export function isValidMetricAxisChart(
  elementConfig: Partial<AxisChartConfig | HorizontalBarChartConfig>,
  value: string,
  includeMetric = true
) {
  return ![
    elementConfig.dimension,
    elementConfig.breakdownDimension,
    ...(includeMetric ? elementConfig.metric || [] : []),
  ].includes(value);
}

interface MergeElementProps<T> {
  element: T;
  updatedProperties?: DeepPartial<T>;
}

export function mergeElement<T extends ElementEntity>({
  element,
  updatedProperties,
}: MergeElementProps<T>): T {
  if (updatedProperties) {
    if (
      element.element_config &&
      updatedProperties.element_config &&
      element.element_type === 'chart' &&
      'chartType' in updatedProperties.element_config &&
      'chartType' in element.element_config &&
      updatedProperties.element_config.chartType &&
      updatedProperties.element_config.chartType !== element.element_config.chartType
    ) {
      let newElement: T = {
        ...element,
      };

      const generalChartConfig = pickGeneralChartConfig(newElement.element_config as ChartConfig);

      const newConfig = updatedProperties.element_config as ChartElementEntity['element_config'];

      if (newConfig) {
        newElement = {
          ...newElement,
          element_config: newConfig,
        } as T;
      } else {
        newElement = fillElementNullConfig({
          ...newElement,
          ...sanitizeNewElementData({
            element_type: 'chart',
            element_config: {
              ...updatedProperties.element_config,
              ...getDefaultChartConfig(updatedProperties.element_config.chartType),
            } as ChartConfig,
          }),
        } as ChartElementEntity) as unknown as T;
      }

      newElement.element_config = {
        ...newElement.element_config,
        ...generalChartConfig,
      };

      // TODO: Tyler should move and make clear which case we will update this
      // if (dataSource) {
      //   newElement.element_config = fillChartDataConfig(newElement.element_config, dataSource);
      // }

      return newElement as T;
    }

    return deepmerge(element, updatedProperties, mergeOptions);
  }
  return element;
}

export function getDefaultFilterConfig(filterType: FilterType) {
  let elementConfig: FilterElementConfig;
  const defaultValue = {
    label: '',
  };
  switch (filterType) {
    case 'daterange':
      elementConfig = {
        filterType,
        defaultValue: {
          type: 'predefined',
          value: 'today',
        },
        queryParameter: [null, null],
        ...defaultValue,
      };
      break;
    case 'recent-timerange':
      elementConfig = {
        filterType,
        defaultValue: {
          from: '',
          to: '',
          ref: '',
        },
        queryParameter: [null, null],
        ...defaultValue,
      };
      break;
    default:
      elementConfig = {
        filterType,
        defaultValue: '',
        queryParameter: null,
        ...defaultValue,
      };
      break;
  }

  return elementConfig;
}

export function getDefaultChartConfig<T extends ChartType>(chartType: T): ChartConfigByType<T> {
  const defaultGeneralValue = defaultGeneralConfig();

  switch (chartType) {
    case 'pie':
      return {
        chartType,
        ...defaultGeneralValue,
        ...defaultPieChartConfig(),
      } as ChartConfigByType<T>;
    case 'bar':
      return {
        chartType,
        ...defaultGeneralValue,
        ...defaultBarChartConfig(),
      } as ChartConfigByType<T>;
    case 'line':
      return {
        chartType,
        ...defaultGeneralValue,
        ...defaultAxisChartConfig(),
      } as ChartConfigByType<T>;
    case 'mixed':
      return {
        chartType,
        ...defaultGeneralValue,
        ...defaultMixedChartConfig(),
      } as ChartConfigByType<T>;
    case 'card':
      return {
        chartType,
        ...defaultGeneralValue,
        ...defaultDataCardConfig(),
      } as ChartConfigByType<T>;
    case 'table':
      return {
        chartType,
        ...defaultGeneralValue,
        ...defaultTableChartConfig(),
      } as ChartConfigByType<T>;
    case 'scatter':
      return {
        chartType,
        ...defaultGeneralValue,
        ...defaultScatterChartConfig(),
      } as ChartConfigByType<T>;
    case 'bubble':
      return {
        chartType,
        ...defaultGeneralValue,
        ...defaultBubbleChartConfig(),
      } as ChartConfigByType<T>;
    case 'horizontalBar':
      return {
        chartType,
        ...defaultGeneralValue,
        ...defaultBarChartConfig(),
      } as ChartConfigByType<T>;
    case 'sankey':
      return {
        chartType,
        ...defaultGeneralValue,
        ...defaultSankeyDiagramConfig(),
      } as ChartConfigByType<T>;
    case 'geomap':
      return {
        chartType,
        ...defaultGeneralValue,
        ...defaultGeomapChartConfig(),
      } as ChartConfigByType<T>;
    default:
      throw new Error(`Unknown chart type: "${chartType}"`);
  }
}

export function getDefaultTextConfig(shape?: TextElementShape): TextElementConfig {
  return {
    text: '',
    shape,
  };
}
