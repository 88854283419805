// eslint-disable-next-line no-restricted-imports
import { create } from 'zustand';

import { GridNode, PixelNode } from '~components/element-board';

interface ClipboardValue<T extends string, D> {
  type: T;
  data: D;
}

export type ClipboardElementNode = PixelNode | GridNode;

export type ClipboardElementIdValue = ClipboardValue<'nodes', ClipboardElementNode[]>;

export type AnyClipboardValue = ClipboardElementIdValue;
export interface ClipboardState {
  clipboardValue: AnyClipboardValue | null;
  setClipboardValue: (value: AnyClipboardValue | null) => void;
}

export const useClipboardStore = create<ClipboardState>((set) => ({
  clipboardValue: null,
  setClipboardValue: (clipboardValue) =>
    set({
      clipboardValue,
    }),
}));

// Hooks
export const useClipboardValue = () => useClipboardStore((state) => state.clipboardValue);
export const useSetClipboardValue = () => useClipboardStore((state) => state.setClipboardValue);

export const useClipboard = () =>
  useClipboardStore((state) => [state.clipboardValue, state.setClipboardValue] as const);
