import { styled, TextField as MUITextField } from '@mui/material';

type ButtonProps = {
  rounded?: boolean;
};

export const AppTextField = styled(MUITextField, {
  shouldForwardProp: (prop) => prop !== 'rounded',
})<ButtonProps>(({ theme, rounded }) => ({
  '--autofill-shadow-color': 'white',

  '.MuiOutlinedInput-root': {
    borderRadius: rounded ? Number(theme.shape.borderRadius) * 4.5 : undefined,
  },
}));
