import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme as MuiTheme,
} from '@mui/material';

// declare module '@mui/material/Link' {
//   interface LinkPropsVariantOverrides {}
//   interface LinkPropsColorOverrides {}
//   interface LinkClasses {}
// }

interface MuiTabsComponentType {
  styleOverrides?: ComponentsOverrides<MuiTheme>['MuiTabs'];
  defaultProps?: ComponentsProps['MuiTabs'];
  variants?: ComponentsVariants['MuiTabs'];
}

export const MuiTabsComponent: MuiTabsComponentType = {
  // variants: [],
  // defaultProps: {},
  styleOverrides: {
    indicator: {
      borderRadius: '3px',
      height: '3px',
    },
  },
};
