import { styled } from '@mui/material';
import { max, min } from 'lodash-es';

import {
  Axis,
  calculateRectImportantPoints,
  RectEdgePlacement,
  RectPosition,
} from '~utils/geometry';

import { StyledLine } from '../../alignment-objects.styled';

type AlignmentSpacingProps = {
  axis: Axis;
  currentRect: RectPosition;
  currentPlacement: RectEdgePlacement;
  refRect: RectPosition;
  refPlacement: RectEdgePlacement;
};

export const StyledSpacingLine = styled(StyledLine, {
  shouldForwardProp(propName: string) {
    return !['axis', 'currentRect', 'currentPlacement', 'refRect', 'refPlacement'].includes(
      propName
    );
  },
})<AlignmentSpacingProps>(
  ({ axis, currentRect, currentPlacement, refRect, refPlacement }) => {
    const refRectPoints = calculateRectImportantPoints(refRect);
    const currentRectPoints = calculateRectImportantPoints(currentRect);

    const currentPlacementPropName = refPlacement === 'end' ? 'start' : 'end';

    const positionPropName = axis === 'x' ? 'top' : 'left';
    const crossedAxis = axis === 'x' ? 'y' : 'x';
    const positionPropValue = refRectPoints[crossedAxis][currentPlacement];

    const startPropName = axis === 'x' ? 'left' : 'top';
    const startPropValue =
      min([refRectPoints[axis][refPlacement], currentRectPoints[axis][currentPlacementPropName]]) ??
      0;

    const lengthPropName = axis === 'x' ? 'width' : 'height';
    const lengthPropValue =
      (max([
        refRectPoints[axis][refPlacement],
        currentRectPoints[axis][currentPlacementPropName],
      ]) ?? 0) - startPropValue;

    const withPropName = axis === 'x' ? 'height' : 'width';

    return {
      [startPropName]: startPropValue,
      [lengthPropName]: lengthPropValue,
      [withPropName]: 1,
      [positionPropName]: positionPropValue,
    };
  },
  ({ theme }) => ({
    '::after': {
      content: 'attr(data-distance)',
      position: 'absolute',
      top: theme.spacing(2),
      left: theme.spacing(1),
      right: 0,
      bottom: 0,
      margin: 'auto',
      width: 'fit-content',
      height: 'fit-content',
      padding: theme.spacing(0, 0.5),
      zIndex: theme.zIndex.modal,
      color: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius / 2,
      backgroundColor: theme.palette.warning.main,
      fontSize: theme.typography.subtitle2.fontSize,
    },
  })
);
