/* eslint-disable object-curly-newline */
import { StringValue } from '@dchart/scales/src/types';
import { range } from 'd3-array';
import { NumberValue } from 'd3-scale';
import { differenceInDays } from 'date-fns/differenceInDays';
import { endOfDay } from 'date-fns/endOfDay';
import { endOfMonth } from 'date-fns/endOfMonth';
import { endOfQuarter } from 'date-fns/endOfQuarter';
import { endOfWeek } from 'date-fns/endOfWeek';
import { endOfYear } from 'date-fns/endOfYear';
import { format as dateFormat } from 'date-fns/format';
import { startOfDay } from 'date-fns/startOfDay';
import { startOfMonth } from 'date-fns/startOfMonth';
import { startOfQuarter } from 'date-fns/startOfQuarter';
import { startOfWeek } from 'date-fns/startOfWeek';
import { startOfYear } from 'date-fns/startOfYear';
import { subDays } from 'date-fns/subDays';
import { subHours } from 'date-fns/subHours';
import { subMinutes } from 'date-fns/subMinutes';
import { subMonths } from 'date-fns/subMonths';
import { subQuarters } from 'date-fns/subQuarters';
import { subSeconds } from 'date-fns/subSeconds';
import { subWeeks } from 'date-fns/subWeeks';
import { subYears } from 'date-fns/subYears';
import { Duration } from 'date-fns/types';

import { DateRangeValue, PredefinedDateRange, TimeDefine } from '~services/v1/element';

export const SECS_PER_MINUTE = 60;
export const MSECS_PER_MINUTE = SECS_PER_MINUTE * 1000;
export const SECS_PER_HOUR = SECS_PER_MINUTE * 60;
export const MSECS_PER_HOUR = SECS_PER_HOUR * 1000;
export const SECS_PER_DAY = 24 * SECS_PER_HOUR;
export const MSECS_PER_DAY = SECS_PER_DAY * 1000;
export const SECS_PER_WEEK = 7 * SECS_PER_DAY;

export const DATE_FORMATS = {
  VERBOSE: 'do MMM y, HH:mm:ss',
  DATE_ONLY: 'dd/MM/yyy',
};

export function formatVerbose(value: string | number | Date) {
  return formatDateBestEffort(value, DATE_FORMATS.VERBOSE);
}

export const defaultTimeFormat = 'dd-MM-yyyy HH:mm:ss';

export type TimeUnit = 'day' | 'month' | 'year' | 'week' | 'quarter';
export type TimeUnitPrefix = 'this' | 'last';

// eslint-disable-next-line max-len
export const commonDateTimePattern: [
  RegExp,
  Record<'year' | 'month' | 'day' | 'hour' | 'minute' | 'second', number>,
][] = [
  [
    /(\d{4})-([0-1]?[0-9])-([0-3]?[0-9])T([0-2]?[0-9]):(?<minute>[0-6]?[0-9]):(?<second>[0-6]?[0-9])/i,
    { year: 1, month: 2, day: 3, hour: 4, minute: 5, second: 6 },
  ],
  [
    /(\d{4})-([0-1]?[0-9])-([0-3]?[0-9]) ([0-2]?[0-9]):([0-6]?[0-9]):([0-6]?[0-9])/i,
    { year: 1, month: 2, day: 3, hour: 4, minute: 5, second: 6 },
  ],
  [
    /(\d{4})\/([0-1]?[0-9])\/([0-3]?[0-9]) ([0-2]?[0-9]):([0-6]?[0-9]):([0-6]?[0-9])/i,
    { year: 1, month: 2, day: 3, hour: 4, minute: 5, second: 6 },
  ],
  [
    /(\d{4})-([0-1]?[0-9])-([0-3]?[0-9]) ([0-2]?[0-9]):([0-6]?[0-9])/i,
    { year: 1, month: 2, day: 3, hour: 4, minute: 5, second: 6 },
  ],
  [
    /(\d{4})\/([0-1]?[0-9])\/([0-3]?[0-9]) ([0-2]?[0-9]):([0-6]?[0-9])/i,
    { year: 1, month: 2, day: 3, hour: 4, minute: 5, second: 6 },
  ],
  [
    /(?<year>\d{4})-(?<month>[0-1]?[0-9])/i,
    { year: 1, month: 2, day: 3, hour: 4, minute: 5, second: 6 },
  ],
  [
    /(?<year>\d{4})\/(?<month>[0-1]?[0-9])/i,
    { year: 1, month: 2, day: 3, hour: 4, minute: 5, second: 6 },
  ],
  [
    /(?<month>[0-1]?[0-9])-(?<year>\d{4})/i,
    { year: 2, month: 1, day: 3, hour: 4, minute: 5, second: 6 },
  ],
  [
    /(?<month>[0-1]?[0-9])\/(?<year>\d{4})/i,
    { year: 2, month: 1, day: 3, hour: 4, minute: 5, second: 6 },
  ],
];

export const commonDatePattern: [
  RegExp,
  Record<'year' | 'month' | 'day' | 'hour' | 'minute' | 'second', number>,
][] = [
  [
    /(?<year>\d{4})-(?<month>[0-1]?[0-9])-(?<day>[0-3]?[0-9])/i,
    { year: 1, month: 2, day: 3, hour: 4, minute: 5, second: 6 },
  ],
  [
    /(?<year>\d{4})\/(?<month>[0-1]?[0-9])\/(?<day>[0-3]?[0-9])/i,
    { year: 1, month: 2, day: 3, hour: 4, minute: 5, second: 6 },
  ],
  [
    /(?<month>[0-1]?[0-9])-(?<day>[0-3]?[0-9])-(?<year>\d{4})/i,
    { year: 1, month: 2, day: 3, hour: 4, minute: 5, second: 6 },
  ],
  [
    /(?<month>[0-1]?[0-9]})\/(?<day>[0-3]?[0-9])\/(?<year>\d{4})/i,
    { year: 1, month: 2, day: 3, hour: 4, minute: 5, second: 6 },
  ],
  [
    /(?<day>[0-3]?[0-9])-(?<month>[0-1]?[0-9])-(?<year>\d{4})/i,
    { year: 1, month: 2, day: 3, hour: 4, minute: 5, second: 6 },
  ],
  [
    /(?<day>[0-3]?[0-9])\/(?<month>[0-1]?[0-9])\/(?<year>\d{4})/i,
    { year: 1, month: 2, day: 3, hour: 4, minute: 5, second: 6 },
  ],
];

export function getDateRangeFromName(name: PredefinedDateRange): [Date, Date] {
  let compareTime = new Date();
  let from = new Date();
  let to = new Date();
  if (name === 'today') {
    name = 'this-day';
  }
  if (name === 'yesterday') {
    name = 'last-day';
  }
  const [prefix, unit] = name.split('-') as [TimeUnitPrefix, TimeUnit];
  switch (unit) {
    case 'day':
      if (prefix === 'last') {
        compareTime = subDays(compareTime, 1);
      }
      from = startOfDay(compareTime);
      to = endOfDay(compareTime);
      break;
    case 'month':
      if (prefix === 'last') {
        compareTime = subMonths(compareTime, 1);
      }
      from = startOfMonth(compareTime);
      to = endOfMonth(compareTime);
      break;
    case 'year':
      if (prefix === 'last') {
        compareTime = subYears(compareTime, 1);
      }
      from = startOfYear(compareTime);
      to = endOfYear(compareTime);
      break;
    case 'week':
      if (prefix === 'last') {
        compareTime = subWeeks(compareTime, 1);
      }
      from = startOfWeek(compareTime);
      to = endOfWeek(compareTime);
      break;
    case 'quarter':
      if (prefix === 'last') {
        compareTime = subQuarters(compareTime, 1);
      }
      from = startOfQuarter(compareTime);
      to = endOfQuarter(compareTime);
      break;
  }
  return [from, to];
}

export function getDateRangeFromTimeAgo(time: TimeDefine, date = new Date()): [Date, Date] {
  let fromTime = date;
  if (time.year) {
    fromTime = subYears(fromTime, time.year);
  }
  if (time.month) {
    fromTime = subMonths(fromTime, time.month);
  }
  if (time.day) {
    fromTime = subDays(fromTime, time.day);
  }
  if (time.hour) {
    fromTime = subHours(fromTime, time.hour);
  }
  if (time.minute) {
    fromTime = subMinutes(fromTime, time.minute);
  }
  if (time.second) {
    fromTime = subSeconds(fromTime, time.second);
  }
  return [fromTime, date];
}

export function getDateRangeFromDateRangeValue(
  value: DateRangeValue,
  {
    withTime = false,
    withTimeIfDaysLessThan,
  }: {
    withTime?: boolean;
    withTimeIfDaysLessThan?: number;
  } = {}
): [string, string] {
  let fromDate: Date;
  let toDate: Date;
  switch (value.type) {
    case 'fixed':
      const [from, to] = value.value;
      return [from, to];
    case 'ago':
      [fromDate, toDate] = getDateRangeFromTimeAgo(value.value);
      break;
    case 'predefined':
      [fromDate, toDate] = getDateRangeFromName(value.value);
      break;
  }
  const shouldUseTimeFormat =
    withTime || (withTimeIfDaysLessThan && Math.abs(differenceInDays(fromDate, toDate)) < 1);
  const format = shouldUseTimeFormat ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd';
  return [dateFormat(fromDate, format), dateFormat(toDate, format)];
}

export function isValidDate(dateString: any) {
  const timestamp = Date.parse(dateString);
  return !Number.isNaN(timestamp);
}

export function dateOrNull(value: NumberValue | StringValue | string | number | Date) {
  try {
    return new Date(value as string | number | Date);
  } catch (e) {
    return null;
  }
}

export function parseDateBestEffort(
  value: NumberValue | StringValue | string | number | Date
): Date {
  let dateValue: Date | null = dateOrNull(value);
  if (!dateValue || Number.isNaN(dateValue.getTime())) {
    dateValue = null;
    for (const [pattern, groups] of [...commonDateTimePattern, ...commonDatePattern]) {
      const matched = new RegExp(pattern).exec(String(value));
      if (matched) {
        const year = matched[groups.year] || 0;
        const month = matched[groups.month] || 0;
        const day = matched[groups.day] || 0;
        const hour = matched[groups.hour] || 0;
        const minute = matched[groups.minute] || 0;
        const second = matched[groups.second] || 0;
        dateValue = new Date(
          Number(year || 0),
          Number(month || 0) - 1,
          Number(day || 0),
          Number(hour || 0),
          Number(minute || 0),
          Number(second || 0)
        );
        break;
      }
    }
  }
  if (!dateValue) {
    throw new TypeError(`Invalid date (${String(value)})`);
  }
  return dateValue;
}

export function parseDateBestEffortOrFallback<T>(
  value: string | number | Date,
  fallback: T
): Date | T {
  try {
    return parseDateBestEffort(value);
  } catch (e) {
    return fallback;
  }
}

export function formatDateBestEffort(
  value: NumberValue | StringValue | string | number | Date,
  format: string
): string {
  let dateValue: Date;
  try {
    dateValue = parseDateBestEffort(value);
  } catch (e) {
    console.error(e);
    return `Invalid date (${String(value)})`;
  }
  try {
    return dateFormat(dateValue, format);
  } catch (e) {
    return `Invalid date (${String(value)})`;
  }
}

/**
 *
 * @param year number
 * @returns array of months
 */
export function generateMonths(year: number) {
  return range(1, 13).map((num) => {
    return year + '-' + num.toLocaleString('en', { minimumIntegerDigits: 2 });
  });
}

export const createDuration = (duration: Duration): Duration => {
  let { years = 0, months = 0, days = 0, hours = 0, minutes = 0, seconds = 0 } = duration;
  if (seconds >= 60) {
    minutes += Math.floor(seconds / 60);
    seconds = seconds % 60;
  }
  if (minutes >= 60) {
    hours += Math.floor(minutes / 60);
    minutes = minutes % 60;
  }
  if (hours >= 24) {
    days += Math.floor(hours / 24);
    hours = hours % 24;
  }
  if (days >= 30) {
    months += Math.floor(days / 30);
    days = days % 30;
  }
  if (months >= 12) {
    years += Math.floor(months / 12);
    months = months % 12;
  }
  return {
    years,
    months,
    days,
    hours,
    minutes,
    seconds,
  };
};

/**
 * @warning Not really accurate
 */
export const years = (unixTimeStamp: number) => unixTimeStamp / (365 * 24 * 60 * 60 * 1_000);

/**
 * @warning Not really accurate
 */
export const months = (unixTimeStamp: number) => unixTimeStamp / (30 * 24 * 60 * 60 * 1_000);
export const weeks = (unixTimeStamp: number) => unixTimeStamp / (7 * 24 * 60 * 60 * 1_000);
export const days = (unixTimeStamp: number) => unixTimeStamp / (24 * 60 * 60 * 1_000);
export const hours = (unixTimeStamp: number) => unixTimeStamp / (60 * 60 * 1_000);
export const minutes = (unixTimeStamp: number) => unixTimeStamp / (60 * 1_000);
export const seconds = (unixTimeStamp: number) => unixTimeStamp / 1_000;
