import { stringify } from 'qs';

import { coreFetcher } from '../../client';
import { DataElementEntityColumn } from '../data-source/data-source.entity';
import {
  deleteOneRequestFactory,
  getOneRequestFactory,
  insertOneRequestFactory,
  restoreOneRequestFactory,
  updateOneRequestFactory,
} from '../request';
import { EntityUuid, User } from '../types';

import {
  PermissionRole,
  QueryDiscussion,
  QueryEntity,
  QueryHistory,
  QueryOverview,
  QueryRelationShip,
} from './query.entity';

const namespace = '/query';

export type TestSQLResult = DataElementEntityColumn[];

export const testSQLRequest = async (
  connectionStringId: EntityUuid,
  data: { sql: string; type: QueryEntity['query_type'] }
) => {
  const response = await coreFetcher.post<TestSQLResult>(
    `${namespace}/test/${connectionStringId}`,
    data
  );
  return response.data;
};

export type GetManyOptions = {
  page?: number;
  documentUuid?: EntityUuid;
};

export interface GetManyResponse {
  items: (QueryEntity & Partial<QueryRelationShip>)[];
  current_page: number;
  last_page: number;
}

const getManyRequest = async (options: GetManyOptions) => {
  return (await coreFetcher.get<GetManyResponse>(`${namespace}?${stringify(options)}`)).data;
};
const getParametersRequest = async (queryId: string) => {
  return (
    await coreFetcher.get<{ uuid: string; parameters: string[] | null }>(
      `${namespace}/${queryId}/parameters`
    )
  ).data;
};

export type GetQueryDiscussionOptions = {
  uuid: string;
};

const getQueryDiscussion = async ({ uuid }: GetQueryDiscussionOptions) => {
  return (await coreFetcher.get<QueryDiscussion[]>(`${namespace}/${uuid}/discussion`)).data;
};

export type CreateQueryDiscussionOptions = {
  query_uuid: string;
  discussion_text: string;
};
const createQueryDiscussion = async ({ query_uuid, ...data }: CreateQueryDiscussionOptions) => {
  await coreFetcher.post(`${namespace}/${query_uuid}/discussion`, data);
};

const getOneRequest = getOneRequestFactory<QueryEntity>(namespace);
const insertOneRequest = insertOneRequestFactory<QueryEntity>(namespace);
const updateOneRequest = updateOneRequestFactory<QueryEntity>(namespace);
const deleteOneRequest = deleteOneRequestFactory(namespace);
const restoreOneRequest = restoreOneRequestFactory(namespace);

async function getOverviewRequest(queryId: EntityUuid) {
  const { data } = await coreFetcher.get<QueryOverview>(`${namespace}/${queryId}/overview`);
  return data;
}

async function getHistoryRequest(queryId: EntityUuid) {
  const { data } = await coreFetcher.get<QueryHistory[]>(`${namespace}/${queryId}/history`);
  return data;
}

export interface AddUsersPayload {
  queryUuid: EntityUuid;
  users: {
    email: string;
    role: PermissionRole;
  }[];
}

async function addUsersRequest({ queryUuid, users }: AddUsersPayload) {
  const { data } = await coreFetcher.post<QueryOverview>(`${namespace}/${queryUuid}/permission`, {
    users,
  });

  return data;
}

export interface RemoveUserPayload {
  queryUuid: EntityUuid;
  permissionUuid: EntityUuid;
}

async function removeUserRequest({ queryUuid, permissionUuid }: RemoveUserPayload) {
  const { data } = await coreFetcher.delete<QueryOverview>(
    `${namespace}/${queryUuid}/permission/${permissionUuid}`
  );

  return data;
}

export type ReviewStatus = 'error' | 'warning' | 'success';
export interface AddReviewPayload {
  queryUuid: EntityUuid;
  content: string;
  status: ReviewStatus;
}

async function addReviewRequest({
  queryUuid,
  content: review_text,
  status: review_status,
}: AddReviewPayload) {
  const { data } = await coreFetcher.post<QueryOverview>(`${namespace}/${queryUuid}/review`, {
    review_text,
    review_status,
  });

  return data;
}

export interface GetReviewPayload {
  queryUuid: EntityUuid;
}

export interface Review {
  review_id: number;
  review_uuid: string;
  review_text: string;
  review_status: ReviewStatus;
  query_id: string;
  user_id: number;
  created_at: string;
  change_log_id?: any;
  user?: User;
}

export interface Summary {
  user: User;
  user_id: number;
  _month: string;
  review_status: string;
}

export interface ReviewsResult {
  items: Review[];
  summary: Summary[];
}

async function getReviewsRequest({ queryUuid }: GetReviewPayload) {
  const { data } = await coreFetcher.get<ReviewsResult>(`${namespace}/${queryUuid}/review`);

  return data;
}

export default {
  restoreOneRequest,
  testSQLRequest,
  getParametersRequest,
  getManyRequest,
  getOneRequest,
  getOverviewRequest,
  getHistoryRequest,
  insertOneRequest,
  updateOneRequest,
  deleteOneRequest,
  addUsersRequest,
  removeUserRequest,
  getQueryDiscussion,
  createQueryDiscussion,
  addReviewRequest,
  getReviewsRequest,
};
