import { applyUpdater, Updater } from './chore';
import { createSearchPattern } from './regex';

export function arrayGet<T, K extends keyof T>(items: T[], field: K): T[K][] {
  return items.map((item) => item[field]);
}

export function replaceItem<T>(
  items: T[],
  condition: (s: T, index: number) => boolean,
  value: T | ((s: T) => T)
): T[] {
  return items.map((item, i) => {
    if (!condition(item, i)) {
      return item;
    }
    if (value instanceof Function) {
      return value(item);
    }
    return value;
  });
}

export function replaceIndex<T>(items: T[], index: number, updater: Updater<T>): T[] {
  return items.map((item, i) => (i === index ? applyUpdater(updater, item) : item));
}

export function removeIndex<T>(items: T[], index: number): T[] {
  const array = [...items];
  if (index > -1) {
    array.splice(index, 1);
  }
  return array;
}

export interface SearchFunction {
  <T>(items: T[], searchFields: (keyof T)[], keyword: string, separator?: string): T[];
  <T>(items: T[], getSearchString: (item: T) => string, keyword: string, separator?: string): T[];
}

/**
 * Return items matched with keyword
 * @param items items to search in
 * @param searchFields properties of item to search
 * @param keyword search keyword
 * @param separator
 */
export const search: SearchFunction = <T>(
  items: T[],
  searchFields: (keyof T)[] | ((item: T) => string),
  keyword: string,
  separator = '|||'
): T[] => {
  const pattern = createSearchPattern(keyword);
  return items.filter((item) => {
    let searchText: string;
    if (searchFields instanceof Function) {
      searchText = searchFields(item);
    } else {
      searchText = searchFields.map((field) => String(item[field])).join(separator);
    }
    return pattern.test(searchText);
  });
};

/**
 * Keep array sequence and reverse sequence of one or some properties
 * @param items
 * @param property property to reverse
 */
export function reverserProperty<T>(items: T[], property: (keyof T)[] | keyof T): T[] {
  const reverseFields = Array.isArray(property) ? property : [property];
  const reverseValues = reverseFields.map((field) => ({
    field,
    items: [...items].reverse().map((item) => item[field]),
  }));
  return items.map((item, index) => {
    const tmpItem: T = { ...item };
    reverseValues.forEach(({ field, items }) => {
      tmpItem[field] = items[index];
    });
    return tmpItem;
  });
}

export type SortOption<T extends keyof any = string> = [T, 'asc' | 'desc' | null | undefined];

export function toggleItem<T>(array: T[], item: T) {
  if (array.includes(item)) {
    return array.filter((value) => value !== item);
  }
  return [...array, item];
}

export const insertItem = <T>(array: T[], item: T, index?: number | null): T[] => [
  ...array.slice(0, index ?? array.length),
  item,
  ...array.slice(index ?? array.length),
];

export interface ItemsGroup<T> {
  group: string;
  items: T[];
}

export function group<T>(
  items: T[],
  groupBy: keyof T,
  sortOption?: (a: ItemsGroup<T>, b: ItemsGroup<T>) => number
): ItemsGroup<T>[] {
  const groups: Record<string, T[]> = {};
  items.forEach((item) => {
    const groupKey = String(item[groupBy]);
    if (!groups[groupKey]) {
      groups[groupKey] = [];
    }
    groups[groupKey].push(item);
  });
  const dataGroup = Object.entries(groups).map(([group, items]) => ({
    group,
    items,
  }));

  if (sortOption) return dataGroup.sort(sortOption);
  return dataGroup;
}

export function getPropertiesSameKeyValue<T extends Record<string, any>>(array?: Array<T>): any {
  if (!array || !array.length) {
    return {};
  }
  return (Object.keys(array[0]) as Array<keyof T>).reduce((acc, key) => {
    if (typeof array[0][key] == 'object') {
      const childArray = array.map((column) => column[key] || {}) as Array<T>;
      const value = getPropertiesSameKeyValue(childArray);
      if (value) {
        return { ...acc, [key]: value };
      }
    } else if (
      array.every((item) => {
        return item[key] == array[0][key];
      })
    ) {
      return { ...acc, [key]: array[0][key] };
    }
    return acc;
  }, {});
}

export function chunk<T = unknown>(arr: T[], size: number): T[][] {
  const result: T[][] = [];
  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size));
  }
  return result;
}

export function makeSortAlphabeticallyFn<T>(
  order: 'asc' | 'desc',
  normalizeData = String as (value: T) => string
) {
  return function compare(name1: T, name2: T) {
    const value1 = normalizeData(name1).toLowerCase();
    const value2 = normalizeData(name2).toLowerCase();

    const ascResult = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

    if (order === 'asc') {
      return ascResult;
    }
    return -ascResult;
  };
}

export function inArrayOrFallback<T>(
  array: T[] | ReadonlyArray<T>,
  value: T | unknown
): T | undefined;
export function inArrayOrFallback<T>(
  array: T[] | ReadonlyArray<T>,
  value: T | unknown,
  fallback: T
): T;
export function inArrayOrFallback<T>(
  array: T[] | ReadonlyArray<T>,
  value: T | unknown,
  fallback?: T
) {
  return array.includes(value as T) ? value : fallback ?? array.at(0);
}

export function wrap<T>(item: T | T[]): T[] {
  if (Array.isArray(item)) {
    return item;
  }
  return [item];
}

export function arrayMove<T>(array: T[], from: number, to: number): T[] {
  const newArray = array.slice();
  newArray.splice(to < 0 ? newArray.length + to : to, 0, newArray.splice(from, 1)[0]);
  return newArray;
}
