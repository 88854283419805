import { useMemo, useRef, useSyncExternalStore } from 'react';
import { StoreApi } from 'zustand';

export function createGetSelection<T, S>(
  getState: () => T,
  selector: (state: T) => S,
  isEqual: (a: S, b: S) => boolean = Object.is
) {
  let memoizedValue: { selection: S; state: T } | null = null;

  return (): S => {
    const nextState = getState();
    if (!memoizedValue) {
      // The first time the hook is called, there is no memoized result.
      memoizedValue = {
        selection: selector(nextState),
        state: nextState,
      };
      return memoizedValue.selection;
    }
    if (memoizedValue.state === nextState) {
      // The state hasn't changed, so we can return the previous selection.
      return memoizedValue.selection;
    }

    // The snapshot has changed, so we need to compute a new selection.
    const nextSelection = selector(nextState);

    if (isEqual(memoizedValue.selection, nextSelection)) {
      return memoizedValue?.selection;
    }
    memoizedValue = {
      state: nextState,
      selection: nextSelection,
    };
    return nextSelection;
  };
}

// Same as useSyncExternalStore, but supports selector and isEqual arguments.
export function useSyncStoreWithSelector<T, S>(
  store: StoreApi<T>,
  selector: (state: T) => S,
  isEqual: (a: S, b: S) => boolean = Object.is
): S {
  const optionsRef = useRef({ selector, isEqual });
  optionsRef.current = { selector, isEqual };

  const getSelection = useMemo(
    () => createGetSelection(() => store.getState(), selector, isEqual),
    [isEqual, selector, store]
  );

  return useSyncExternalStore<S>(store.subscribe, getSelection, getSelection);
}
