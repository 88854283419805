// TODO: implement, remove all comment after done

import {
  alpha,
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme as MuiTheme,
} from '@mui/material';
import { CSSInterpolation } from '@mui/system';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    rounded: true;
    'round-outlined': true;
  }
  interface ButtonPropsColorOverrides {
    // gray: true;
  }
  // interface ButtonClasses {
  //   underline: string
  //   pill: string
  //   invertPill: string
  // }
}

interface MuiButtonComponentType {
  styleOverrides?: ComponentsOverrides<MuiTheme>['MuiButton'];
  defaultProps?: ComponentsProps['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
}

export const MuiButtonComponent: MuiButtonComponentType = {
  variants: [],
  defaultProps: {
    disableElevation: true,
    // variant: 'contained',
  },
  styleOverrides: {
    root: ({ ownerState: { color, variant, size }, theme }): CSSInterpolation => {
      const palette = color && color != 'inherit' ? theme.palette[color] : theme.palette.primary;

      return [
        variant === 'rounded' && {
          color: 'white',
          textTransform: 'none' as const,
          backgroundColor: palette.main,
          borderRadius: Number(theme.shape.borderRadius) * 5,

          '&:hover': {
            backgroundColor: palette.dark,
          },

          '&:active': {
            backgroundColor: alpha(palette.main, theme.palette.action.activatedOpacity),
          },

          '&.Mui-disabled': {
            backgroundColor: alpha(palette.main, theme.palette.action.disabledOpacity),
            color: 'white',
          },

          // '@media (hover: none)': {
          //   backgroundColor: 'transparent',
          // },
        },
        variant === 'round-outlined' && {
          color: palette.main,
          textTransform: 'none' as const,
          borderRadius: Number(theme.shape.borderRadius) * 5,
          border: `1px solid ${palette.main}`,
          backgroundColor: 'transparent',

          '&:hover': {
            backgroundColor: alpha(palette.dark, theme.palette.action.hoverOpacity),
          },

          // '@media (hover: none)': {
          //   backgroundColor: 'transparent',
          // },
        },
        color === 'inherit' && {
          borderColor: 'currentColor',
          backgroundColor: 'inherit',
          '&:hover': {
            backgroundColor: 'inherit',
          },
        },
        size === 'small' && {
          ...theme.typography.inputValue,
          padding: theme.spacing(0.5, 3.5),
        },
      ];
    },
  },
};
