import { GridLayoutValue, PixelLayoutValue } from '~components/element-board';

import { DashboardEntity } from '../dashboard';
import { ElementEntity } from '../element';
import { EntityUuid } from '../types';

export const PAGE_DEFAULT_BACKGROUND_COLOR = '#F8F8F8';

export interface PageSettingEntity {
  width: number;
  height: number;
  bgColor?: string;
  textColor?: string;
  fontFamily?: string;
  refreshInterval?: number;
  boxShadow?: boolean;
  // enableGridLine?: boolean;
  // enableSnap?: boolean;
}

export type PageRenderMode = 'pixel' | 'grid';

export interface PageEntity {
  page_id: EntityUuid;
  page_code: string;
  page_hide_in_view: boolean;
  document_uuid: EntityUuid;
  page_number: number;
  page_name: string;
  page_render_mode: PageRenderMode;
  page_grid_layout?: GridLayoutValue;
  page_pixel_layout?: PixelLayoutValue;
  page_position: number;
  page_layout_dirty: boolean;
  page_setting: PageSettingEntity & {
    extendDocumentSetting?: boolean;
  };
  updated_at: string;
}

export interface PageRelationships {
  dashboard?: DashboardEntity;
  elements: ElementEntity[];
  query_params: Record<string, string>;
}

export const defaultPageSettingsEntity = (): Partial<PageSettingEntity> => ({
  // enableGridLine: false,
  // enableSnap: true,
});
