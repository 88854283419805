import { styled, Typography } from '@mui/material';

import { withProps } from '~utils/styled';

export const ErrorTypography = styled(
  withProps(Typography, { variant: 'caption', color: 'error' })
)(() => ({
  display: 'block',
  overflow: 'auto',
  maxHeight: 60,
  whiteSpace: 'pre-wrap',
}));
