import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { Box, Fade, Grid, Stack, Typography } from '@mui/material';

import Image from '~components/image';
import { linearGradient } from '~utils/color';

import Logo from '../../../assets/logo.svg';

import Dots from './components/dots';

export interface AuthenticationLayoutProps {
  background?: 'default' | 'paper';
}

const images = [
  {
    title: 'Turn Your Data Into a Story',
    subTitle:
      'Visualize your data in rich, interactive dashboards that are easy to share with colleagues',
    url: 'https://scaleflex.cloudimg.io/v7/Dataflake/Landing+Pages+Illustrations/Data-1.png?vh=0207d0',
  },
  {
    title: 'Complete Self Service Data Tool',
    subTitle:
      'Easily create real-time dashboards for your team and integrate them into your own site in minutes',
    url: 'https://scaleflex.cloudimg.io/v7/Dataflake/Landing+Pages+Illustrations/IT-2.png?vh=00744d',
  },
  {
    title: 'Advanced Visualization Options',
    subTitle: 'Get access to a wide variety of design options and chart settings',
    url: 'https://scaleflex.cloudimg.io/v7/Dataflake/Landing+Pages+Illustrations/Marketing-3.png?vh=432a26',
  },
];

export function AuthenticationLayout({}: AuthenticationLayoutProps) {
  const { t } = useTranslation();
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index) => (index + 1) % 3);
    }, 3000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        height: '100vh',
      }}
    >
      <Grid
        item
        xs
        sx={{
          background: (theme) => linearGradient(theme.palette.gradient.primary),
          display: { xs: 'none', md: 'flex' },
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'start',
        }}
      >
        <Box mt={5} ml={7.5}>
          <img src={Logo} height={64} alt="Logo" />
        </Box>
        <Box sx={{ m: 'auto' }}>
          {images.map(
            (image, i) =>
              index === i && (
                <Fade key={i} in={true}>
                  <Stack gap={4} alignItems="center">
                    <Image
                      src={t(`AUTHENTICATION_LAYOUT.IMAGE_${i}_url`, image.url)}
                      alt={t(`AUTHENTICATION_LAYOUT.IMAGE_${i}_title`, image.title)}
                      maxWidth={250}
                      showSkeleton
                      SkeletonProps={{ width: 250, height: 250 }}
                    />
                    <Stack>
                      <Typography variant="h5" textAlign="center" color="white" fontWeight={600}>
                        {t(`AUTHENTICATION_LAYOUT.IMAGE_${i}_title`, image.title)}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        textAlign="center"
                        color="white"
                        fontWeight={400}
                      >
                        {t(`AUTHENTICATION_LAYOUT.IMAGE_${i}_subtitle`, image.subTitle)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Fade>
              )
          )}
          <Box mt={10} mb={5}>
            <Dots count={3} index={index} onDotClick={(e, i) => setIndex(i)} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs alignSelf="center">
        <Outlet />
      </Grid>
    </Grid>
  );
}
