import { useReducer } from 'react';

export type RefsMap = Record<string, HTMLElement | SVGElement | null | undefined>;

export type RefsMapAction = Record<string, HTMLElement | SVGElement | null>;

export const refsReducer = (prevState: RefsMap, action: RefsMapAction): RefsMap => {
  return {
    ...prevState,
    ...action,
  };
};

export function useNodeRefs(defaultRefs: RefsMap = {}) {
  return useReducer(refsReducer, defaultRefs);
}
