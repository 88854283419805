import {
  GridLayoutValue,
  PixelBoxNode,
  PixelLayoutValue,
  PixelNode,
} from '~components/element-board';
import { DashboardEntity } from '~services/v1/dashboard';
import { PAGE_DEFAULT_BACKGROUND_COLOR } from '~services/v1/page';
import { memoizeSelector } from '~utils/zustand';

import { DashboardState } from '../store/dashboard-store';

import {
  selectDashboardSetting,
  selectFooterProperties,
  selectHeaderProperties,
} from './dashboard.selector';

export const selectActivePage = (state: DashboardState) => state.activePage;
export const selectActivePageName = (state: DashboardState) => state.activePage?.page_name;
export const selectActivePageCode = (state: DashboardState) => state.activePage?.page_code;
export const selectActivePageRenderMode = (state: DashboardState) =>
  state.activePage?.page_render_mode;
export const selectActivePageUpdateTime = (state: DashboardState) => state.activePage?.updated_at;
export const selectIsActivePageReady = (state: DashboardState) => !!state.activePage;

export const selectRawPageSetting = (state: DashboardState) => state?.activePage?.page_setting;

export const selectPageSetting = (state: DashboardState) => {
  const pageSetting = selectRawPageSetting(state);
  const dashboardSetting = selectDashboardSetting(state);
  const header = selectHeaderProperties(state);
  const footer = selectFooterProperties(state);
  let setting: DashboardEntity['document_setting'] | undefined;
  if (pageSetting?.extendDocumentSetting && dashboardSetting) {
    setting = dashboardSetting;
  } else if (pageSetting) {
    setting = pageSetting;
  }

  const height =
    (setting?.height ?? 1000) +
    (header.show ? header.height : 0) +
    (footer.show ? footer.height : 0);

  return {
    ...setting,
    width: setting?.width ?? 1000,
    height,
    boxShadow: setting?.boxShadow ?? true,
    bgColor: setting?.bgColor || PAGE_DEFAULT_BACKGROUND_COLOR,
  };
};

export const selectReloadInterval = memoizeSelector(
  selectPageSetting,
  (setting) => setting?.refreshInterval
);

export const selectActivePageGridLayout = (state: DashboardState): GridLayoutValue =>
  state.activePage?.page_grid_layout ?? {
    rows: [],
  };

export const _selectActivePagePixelLayout = (state: DashboardState): PixelLayoutValue =>
  state.activePage?.page_pixel_layout ?? [];

export const selectActivePagePixelLayout = memoizeSelector(
  _selectActivePagePixelLayout,
  (value): PixelLayoutValue =>
    value.map(
      (item): PixelNode =>
        item.type === 'box'
          ? {
              ...item,
              top: Math.round(item.top),
              left: Math.round(item.left),
              width: Math.round(item.width),
              height: Math.round(item.height),
            }
          : item
    )
);

export const selectPageNodes = (nodeIds: string[]) =>
  memoizeSelector(selectActivePagePixelLayout, (pixelLayout) =>
    pixelLayout.filter(
      (node): node is PixelBoxNode => node.type === 'box' && nodeIds.includes(node.id)
    )
  );

// export const selectLayoutElementIds = createSelector(
//   selectActivePageRenderMode,
//   selectActivePageGridLayout,
//   selectActivePagePixelLayout,
//   (renderMode, gridLayout, pixelLayout) =>
//     renderMode === 'grid'
//       ? getAllElementIdsFromGridLayout(gridLayout)
//       : getAllElementIdsFromPixelLayout(pixelLayout)
// );
