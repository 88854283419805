import { useMemo } from 'react';

import { useStorageStore } from '~providers/storage-provider/storage-store';
import { COMPANY_UUID_KEY } from '~services/client';

import { useSessionQuery } from './use-session-query';

export function useCurrentCompanyUuid() {
  return useStorageStore((store) => store.values[COMPANY_UUID_KEY]);
}
export function useCurrentCompany() {
  const { data } = useSessionQuery();
  const currentCompanyUuid = useCurrentCompanyUuid();
  return useMemo(
    () => data && !!currentCompanyUuid && data.companies[currentCompanyUuid],
    [currentCompanyUuid, data]
  );
}
