// import EditorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker';
// Enable this to make sql intellisense works
// import 'monaco-sql-languages/out/esm/pgsql/pgsql.contribution';
// import SqlWorker from 'monaco-sql-languages/out/esm/sql/sql.worker?worker';
import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import * as monaco from 'monaco-editor';

// self.MonacoEnvironment = {
//   getWorker(_, label) {
//     if (label === 'sql') {
//       return new SqlWorker();
//     }
//     // if (label === 'css' || label === 'scss' || label === 'less') {
//     //   return new cssWorker();
//     // }
//     // if (label === 'html' || label === 'handlebars' || label === 'razor') {
//     //   return new htmlWorker();
//     // }
//     // if (label === 'typescript' || label === 'javascript') {
//     //   return new tsWorker();
//     // }
//     return new EditorWorker();
//   },
// };

export interface CodeEditorProps {
  options?: monaco.editor.IStandaloneEditorConstructionOptions;
  height?: number | string;
  language: string;
  value?: string;
  onChange?: (value: string) => void;
}
export function CodeEditor({
  options = DEFAULT_EDITOR_OPTIONS,
  height,
  language,
  value,
  onChange,
}: CodeEditorProps) {
  const [editor, setEditor] = useState<monaco.editor.IStandaloneCodeEditor | null>(null);

  const valueRef = useRef(value);
  const editorContainerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!editorContainerRef.current) {
      return;
    }
    const editorRef = monaco.editor.create(editorContainerRef.current, {
      ...options,
      language,
      value: valueRef.current,
    });

    const observer = new ResizeObserver(() => {
      editorRef.layout();
    });
    observer.observe(editorContainerRef.current);

    setEditor(editorRef);

    editorRef.onDidDispose(() => {
      observer.disconnect();
    });
    return () => {
      editorRef.dispose();
    };
  }, [language, options]);

  useEffect(() => {
    if (editor && !!value && value !== editor.getValue()) {
      editor.setValue(value || '');
    }
  }, [editor, value]);

  useEffect(() => {
    const model = editor?.getModel?.();
    if (model) {
      const listener = model.onDidChangeContent(() => {
        onChange?.(model.getValue());
      });
      return () => listener.dispose();
    }
  }, [editor, onChange]);

  return <Box ref={editorContainerRef} height={height} />;
}

// eslint-disable-next-line react-refresh/only-export-components
export const DEFAULT_EDITOR_OPTIONS: CodeEditorProps['options'] = {
  scrollBeyondLastLine: false,
  mouseWheelZoom: true,
};

// eslint-disable-next-line react-refresh/only-export-components
export const COMPACT_EDITOR_OPTIONS: CodeEditorProps['options'] = {
  ...DEFAULT_EDITOR_OPTIONS,
  lineNumbers: 'off',
  glyphMargin: false,
  folding: false,
  lineDecorationsWidth: 0,
  lineNumbersMinChars: 0,
  minimap: { enabled: false },
  renderLineHighlight: 'none',
};
