import { styled } from '@mui/material';

export const ColorInput = styled('input')(({ theme }) => ({
  maxWidth: '80px',
  display: 'block',
  marginLeft: `${theme.spacing(0.5)}`,
  '&.error': {
    color: `${theme.palette.error.main}`,
    borderColor: `${theme.palette.error.main}`,
    borderRadius: '5px',
    '&:focus': {
      outline: 'none',
    },
  },
}));

export const ColorContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '230px',
  paddingTop: `${theme.spacing(0.5)}`,
  paddingBottom: `${theme.spacing(0.5)}`,
  '& > .item': {
    padding: '1px',
    width: 'calc(100% / 9)',
    display: 'flex',
    justifyContent: 'center',
  },
}));
