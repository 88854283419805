import React, { Dispatch, DOMAttributes, forwardRef, PropsWithChildren, useCallback } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useMergeRefs } from '@floating-ui/react';

import { useHighlightSearchElement } from '~components/element-board/hooks/use-highlight-search-element';

import { BaseNode } from '../../../../element-board.types';
import { RefsMapAction } from '../../../../hooks/use-node-refs';
import { GridRow, RowNode } from '../../grid-board';
import { GridBoardProps } from '../../grid-board.type';
import { useMovable } from '../../hooks/use-movable';

import { useContextMenuHandler } from './hooks/use-context-menu-handler';

import { NodeContainer } from './node-mover.styled';

interface CellMoverProps
  extends Omit<DOMAttributes<HTMLDivElement>, 'onContextMenu' | 'onChange'>,
    PickAndTransformOptional<
      GridBoardProps,
      'onContextMenu' | 'onDeleteElement' | 'onChange' | 'onChangeSelectedNodeIds'
    > {
  draggable?: boolean;
  selected?: boolean;
  selectedNodes: BaseNode[];
  node: RowNode;
  row: GridRow;
  setNodesRef: Dispatch<RefsMapAction>;
}

export const NodeMover = forwardRef<HTMLDivElement, PropsWithChildren<CellMoverProps>>(
  function NodeMover(
    {
      draggable,
      // active,
      children,
      onContextMenu,
      onDeleteElement,
      onChange,
      onChangeSelectedNodeIds,
      selectedNodes,
      node,
      row,
      setNodesRef,
      ...props
    },
    forwardRef
  ) {
    const nodeRef = React.useRef<HTMLElement | null>(null);

    const { attributes, setNodeRef, listeners, isDragging, transform } = useMovable(
      {
        row,
        node,
      },
      {
        disabled: !draggable,
      }
    );
    const setRef = useMergeRefs([
      setNodeRef,
      forwardRef,
      useCallback(
        (el: HTMLElement) =>
          setNodesRef({
            [node.id]: el,
          }),
        [node.id, setNodesRef]
      ),
      nodeRef,
    ]);

    useHighlightSearchElement({
      node,
      element: nodeRef.current,
      onChangeSelectedNodeIds,
    });

    // all the functions or values you can expose here
    // useImperativeHandle(ref, () => ({
    //   focus: () => {
    //     textAreaRef.current.focus();
    //   }
    // }));

    const style = {
      width: `${node.width}%`,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleX: isDragging ? 0.6 : 1,
          scaleY: isDragging ? 0.6 : 1,
        }
      ),
      // transition,
    };

    const contextMenuHandler = useContextMenuHandler({
      node: node,
      onContextMenu,
      onDeleteElement,
      onChange,
      onChangeSelectedNodeIds,
      selectedNodes,
    });

    return (
      <NodeContainer
        ref={setRef}
        style={style}
        // active={active}
        {...props}
        {...listeners}
        {...attributes}
        onContextMenu={contextMenuHandler}
      >
        {children}
      </NodeContainer>
    );
  }
);
