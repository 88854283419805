import { styled } from '@mui/material';

export const Tabs = styled('div')(({ theme }) => ({
  borderRadius: `${theme.shape.borderRadius}px`,
  border: `thin solid ${theme.palette.divider}`,
  display: 'flex',
  alignItems: 'stretch',
  userSelect: 'none',
  overflow: 'auto',
}));

export const Tab = styled('div')(({ theme }) => ({
  flex: 1,
  textAlign: 'center',
  borderRight: `thin solid ${theme.palette.divider}`,
  padding: theme.spacing(0.5, 1),
  cursor: 'pointer',
  '&.active': {
    backgroundColor: `${theme.palette.primary.main}`,
    color: `${theme.palette.primary.contrastText}`,
  },
  '&:last-of-type': {
    borderRightColor: 'transparent',
  },
}));
