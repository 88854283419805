import React, { ForwardedRef, forwardRef, ReactNode, useState } from 'react';
import { Box, ListItemButtonBaseProps, ListItemIcon, Paper, Tooltip } from '@mui/material';

import { useScrollToSelectedListItem } from '~hooks/use-scroll-to-selected-list-item';
import { group, ItemsGroup, search } from '~utils/array';

import { SearchInput, SearchInputProps } from './components/search-input';

import { StyledList, StyledListItem, StyledListSubheader } from './df-list-picker.styled';

export interface ListPickerOption extends ListItemButtonBaseProps {
  label: ReactNode;
  value: string;
  group?: string;
  tooltip?: ReactNode;
  icon?: ReactNode;
  endIcon?: ReactNode;
}

export interface DFListPickerProps {
  disableSearch?: boolean;
  options?: ListPickerOption[];
  value?: string;
  onChange?: (value: string) => unknown;
  maxHeight?: number;
  disabledAll?: boolean;
  scrollToSelectedItem?: boolean;
  sortGroup?: (a: ItemsGroup<ListPickerOption>, b: ItemsGroup<ListPickerOption>) => number;
  searchProp?: SearchInputProps;
}

function renderListItems(
  items: ListPickerOption[],
  selectedValue?: string,
  onSelect?: (value: string) => unknown,
  disabledAll?: boolean,
  ref?: (ref: HTMLDivElement | null, isSelected: boolean) => void
) {
  return items.map(
    ({ value: optionValue, label: optionLabel, tooltip, icon, endIcon, ...props }) => {
      const isSelected = optionValue === selectedValue;
      const listItem = (
        <StyledListItem
          {...props}
          ref={(value) => ref?.(value, isSelected)}
          key={optionValue}
          selected={isSelected}
          onClick={() => onSelect && onSelect(optionValue)}
          disabled={disabledAll || props.disabled}
        >
          {icon && <ListItemIcon sx={{ minWidth: 0 }}>{icon}</ListItemIcon>}
          {optionLabel}
          {endIcon && <ListItemIcon sx={{ minWidth: 0, ml: 'auto' }}>{endIcon}</ListItemIcon>}
        </StyledListItem>
      );
      if (tooltip) {
        return (
          <Tooltip key={optionValue} title={tooltip} placement="left">
            {listItem}
          </Tooltip>
        );
      } else {
        return listItem;
      }
    }
  );
}

export const DFListPicker = forwardRef(function DFListPicker(
  {
    options,
    onChange,
    value,
    disableSearch,
    maxHeight = 300,
    disabledAll = false,
    scrollToSelectedItem = true,
    sortGroup,
    searchProp,
  }: DFListPickerProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const [searchValue, setSearchValue] = useState('');
  const { wrapperRef, setSelectedItemRef: _setSelectedItemRef } = useScrollToSelectedListItem<
    HTMLUListElement,
    HTMLDivElement
  >(scrollToSelectedItem);
  const filteredOptions = search(options || [], ['label', 'value'], searchValue);
  const groups =
    options?.some((item) => !!item.group) && group(filteredOptions, 'group', sortGroup);

  const setSelectedItemRef = (ref: HTMLDivElement | null, isSelected: boolean) => {
    if (isSelected) {
      _setSelectedItemRef(ref);
    }
  };

  return (
    <Paper ref={ref} sx={{ overflow: 'hidden' }}>
      {!disableSearch && (
        <Box p={1} sx={(theme) => ({ background: theme.palette.background.grey })}>
          <SearchInput
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            {...searchProp}
          />
        </Box>
      )}
      <StyledList sx={{ maxHeight, overflow: 'auto', p: 0 }} ref={wrapperRef}>
        {groups
          ? groups.map(({ group, items }) => (
              <React.Fragment key={group}>
                <StyledListSubheader>{group}</StyledListSubheader>
                {renderListItems(items, value, onChange, disabledAll, setSelectedItemRef)}
              </React.Fragment>
            ))
          : renderListItems(filteredOptions, value, onChange, disabledAll, setSelectedItemRef)}
      </StyledList>
    </Paper>
  );
});
