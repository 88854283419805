import { RectPosition } from '~utils/geometry';

import { Rectangle } from './rectangle-draw.styled';

interface RectangleDrawProps {
  position: RectPosition;
}

export function RectangleDraw({ position }: RectangleDrawProps) {
  return (
    <Rectangle
      style={{
        ...position,
      }}
    />
  );
}
