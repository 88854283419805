import { createStore } from 'zustand';

import { DashboardEntity } from '~services/v1/dashboard';
import { DataSourceEntity } from '~services/v1/data-source';
import { ElementEntity } from '~services/v1/element';
import { PageEntity } from '~services/v1/page';
import { ShareEntity } from '~services/v1/share';

export interface DashboardState {
  error: boolean;
  editable: boolean;
  dashboard: DashboardEntity | null;
  requiredParameters: string[];
  dataSources: Record<string, DataSourceEntity | null | undefined>;
  elements: ElementEntity[];
  elementsParams: Record<string, Record<string, string>>;
  pages: PageEntity[];
  activePage: PageEntity | null;
  activeShare: ShareEntity | null;
  dispatch: <T>(action: DashboardAction<T>) => T;
}

export const initialPageState = (): Pick<
  DashboardState,
  'activePage' | 'elements' | 'elementsParams'
> => ({
  activePage: null,
  elements: [],
  elementsParams: {},
});

export const initialDashboardState = (): Omit<DashboardState, 'dispatch'> => ({
  ...initialPageState(),
  error: false,
  editable: false,
  dashboard: null,
  requiredParameters: [],
  dataSources: {},
  pages: [],
  activeShare: null,
});
export function createDashboardStore() {
  return createStore<DashboardState>(
    (_, _2, store): DashboardState => ({
      ...initialDashboardState(),
      dispatch: (action) => action(store),
    })
  );
}

export type DashboardStore = ReturnType<typeof createDashboardStore>;

export type DashboardAction<T> = (store: DashboardStore) => T;

export function dispatchDashboardAction<T>(store: DashboardStore, action: DashboardAction<T>): T {
  return action(store);
}
