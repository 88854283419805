import { useRef, useState } from 'react';
import { CaretLeftIcon, CaretRightIcon } from '@dataflake/icons';
import { BoxProps, Stack, Typography } from '@mui/material';
import { addDays } from 'date-fns';
import { differenceInDays } from 'date-fns/differenceInDays';
import { DateObj, useDayzed } from 'dayzed';
import { max, min } from 'lodash-es';

import {
  DfCalendarDayButton,
  DfCalendarDayGrid,
  DfCalendarHeader,
  DfCalendarNextPrevButton,
  DfCalendarRoot,
  DfCalendarWeekLabel,
} from './df-calendar.styled';

export interface CalendarValueProps {
  range?: boolean;

  selectedDate?: Date;
  onChangeSelectedDate?(date: Date): void;

  selectedDateRange?: [Date, Date];
  onChangeSelectedDateRange?(dateRange: [Date, Date | null]): void;
}

export interface DfCalendarProps extends Omit<BoxProps, 'children'>, CalendarValueProps {
  maxDate?: Date;
  minDate?: Date;
}

const monthNamesShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const weekdayNamesShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

function calculateDateRange({
  range,
  tmpStartRange,
  selectedDateRange,
  selectedDate,
}: CalendarValueProps & {
  tmpStartRange: Date | null;
}) {
  if (range) {
    if (!selectedDateRange) {
      return;
    }
    if (tmpStartRange) {
      return [tmpStartRange];
    }
    const [startDate, endDate] = selectedDateRange;

    const days = Math.abs(differenceInDays(startDate, endDate));

    return Array.from({ length: days + 1 }, (_, i) => {
      return addDays(startDate, i);
    });
  }
  return selectedDate;
}

export function DfCalendar({
  range,

  selectedDate,
  onChangeSelectedDate,

  selectedDateRange,
  onChangeSelectedDateRange,

  minDate,
  maxDate,

  ...boxProps
}: DfCalendarProps) {
  const [tmpStartRange, setTmpStartRange] = useState<Date | null>(null);
  const tmpStartRangeRef = useRef(tmpStartRange);
  tmpStartRangeRef.current = tmpStartRange;

  const selected = calculateDateRange({
    range,

    tmpStartRange,
    selectedDate,
    onChangeSelectedDate,

    selectedDateRange,
    onChangeSelectedDateRange,
  });

  const dayzedData = useDayzed({
    selected: selected,
    minDate,
    maxDate,
    onDateSelected(selectedDate: DateObj) {
      if (range) {
        if (!tmpStartRangeRef.current) {
          setTmpStartRange(selectedDate.date);
        } else {
          const dateA = tmpStartRangeRef.current.getTime();
          const dateB = selectedDate.date.getTime();
          onChangeSelectedDateRange &&
            onChangeSelectedDateRange([
              new Date(min([dateA, dateB]) as number),
              new Date(max([dateA, dateB]) as number),
            ]);
          setTmpStartRange(null);
        }
      } else {
        onChangeSelectedDate?.(selectedDate.date);
      }
    },
  });

  const { calendars, getBackProps, getForwardProps, getDateProps } = dayzedData;

  return (
    <DfCalendarRoot {...boxProps}>
      {calendars.map((calendar) => (
        <Stack gap={1} key={`${calendar.month}${calendar.year}`}>
          <DfCalendarHeader>
            <DfCalendarNextPrevButton {...getBackProps({ calendars })}>
              <CaretLeftIcon />
            </DfCalendarNextPrevButton>
            <Typography>
              {monthNamesShort[calendar.month]} {calendar.year}
            </Typography>
            <DfCalendarNextPrevButton {...getForwardProps({ calendars })}>
              <CaretRightIcon />
            </DfCalendarNextPrevButton>
          </DfCalendarHeader>
          <DfCalendarDayGrid>
            {weekdayNamesShort.map((weekday) => (
              <DfCalendarWeekLabel
                variant="caption"
                key={`${calendar.month}${calendar.year}${weekday}`}
              >
                {weekday}
              </DfCalendarWeekLabel>
            ))}
            {calendar.weeks.map((week, weekIndex) =>
              week.map((dateObj, index) => {
                const key = `${calendar.month}${calendar.year}${weekIndex}${index}`;
                if (!dateObj) {
                  return <div key={key} />;
                }
                const { date, selected, selectable, today } = dateObj;
                return (
                  <DfCalendarDayButton
                    key={key}
                    disabled={!selectable}
                    selected={selected}
                    today={today}
                    {...getDateProps({ dateObj })}
                  >
                    {selectable ? date.getDate() : 'X'}
                  </DfCalendarDayButton>
                );
              })
            )}
          </DfCalendarDayGrid>
        </Stack>
      ))}
    </DfCalendarRoot>
  );
}
