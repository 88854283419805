import { getItemsBoundaryBox } from '~utils/geometry';

import { getPixelNodesByIds, isPixelBoxItem } from '../../pixel-board.utils';

import { PixelBoardState } from './pixel-board.store';

export const selectSelectedBoxNodes = (state: PixelBoardState) =>
  getPixelNodesByIds(state.selectedNodeIds, state.value).filter(isPixelBoxItem);

export const selectUnselectedBoxNodes = (state: PixelBoardState) =>
  state.value.filter((node) => !state.selectedNodeIds.includes(node.id)).filter(isPixelBoxItem);

export const selectBoundaryRect = (state: PixelBoardState) =>
  !!state.selectedNodeIds.length &&
  getItemsBoundaryBox(selectSelectedBoxNodes(state).filter(isPixelBoxItem));
