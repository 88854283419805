import { XCircleIcon } from '@dataflake/icons';
import { ChipProps as MUIChipProps } from '@mui/material';

import * as Styled from './chip.styled';

interface ChipProps extends MUIChipProps {}

export function Chip(props: ChipProps) {
  return (
    <Styled.Chip
      size="small"
      {...props}
      deleteIcon={<XCircleIcon onMouseDown={(event) => event.stopPropagation()} />}
    />
  );
}
