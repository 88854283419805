import React from 'react';

export type BorderStyle =
  | 'dashed'
  | 'dotted'
  | 'double'
  | 'groove'
  | 'hidden'
  | 'inset'
  | 'none'
  | 'outset'
  | 'ridge'
  | 'solid';

export type ShapeType = 'rectangle' | 'pentagon' | 'ellipse' | 'triangle' | 'star';

export interface PolygonConfig {
  width: number;
  height: number;
  padding: number;
  strokeWidth: number;
}

export function pentagonPoints({ width, height, padding, strokeWidth }: PolygonConfig) {
  const halfStoke = strokeWidth / 2;
  return [
    [width / 2 + padding, padding + halfStoke],
    [width + padding - halfStoke, height / 3 + padding],
    [width + padding - width / 5, height + padding - halfStoke],
    [padding + halfStoke + width / 5, height + padding - halfStoke],
    [padding + halfStoke, height / 3 + padding],
  ];
}

export function trianglePoints({ width, height, padding, strokeWidth }: PolygonConfig) {
  const halfStoke = strokeWidth / 2;
  return [
    [width / 2 + padding, padding + halfStoke],
    [width + padding - halfStoke, height + padding - halfStoke],
    [padding + halfStoke, height + padding - halfStoke],
  ];
}

// export function starPoints({ width, height, padding, strokeWidth }: PolygonConfig) {
//   const halfStoke = strokeWidth / 2;
//   return [
//     [width / 5 + padding, height + padding + halfStoke],
//     [width / 2 + padding, padding],
//     [width / 1.25 + padding, height + padding],
//     [padding, height / 2.85 + padding],
//     [width + padding, height / 2.85 + padding],
//   ];
// }

export function getBorderStyle(style: React.CSSProperties['borderStyle'], strokeWidth: number) {
  switch (style) {
    case 'dashed':
      return `${strokeWidth * 5},${strokeWidth}`;
    case 'dotted':
      return `${strokeWidth},${strokeWidth}`;
  }
  return undefined;
}
