import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

import { useSessionQuery } from '~hooks/auth';
import { useRouteHasMobileView } from '~hooks/route/use-route-has-mobile-view';

import { ExternalNavigate } from '../../components/external-navigate';
import { useIsPublicRoute } from '../../hooks/route/use-is-public-route';

export interface AuthGuardProviderProps {
  children?: ReactNode;
}

export function RouterGuardProvider({ children }: AuthGuardProviderProps) {
  const isPublicRoute = useIsPublicRoute();
  const routeHasMobileView = useRouteHasMobileView();
  const { isLoading, isError, isRefetchError } = useSessionQuery();

  if (
    !routeHasMobileView &&
    import.meta.env.VITE_MOBILE_LANDING_URL &&
    screen?.width &&
    screen?.width < 600
  ) {
    return <ExternalNavigate to={import.meta.env.VITE_MOBILE_LANDING_URL} />;
  }

  if (isPublicRoute) {
    return <>{children}</>;
  }

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!isPublicRoute && (isError || isRefetchError)) {
    return (
      <Navigate
        to={`/login?ref=${encodeURIComponent(
          window.location.href.slice(window.location.origin.length)
        )}`}
      />
    );
  }
  return <>{children}</>;
}
