import { Paper, styled } from '@mui/material';

export const DotsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: `${theme.spacing(1.5)}`,
  justifyContent: 'center',
  alignItems: 'center',
}));

export const DotOuter = styled('div', { shouldForwardProp: (prop) => prop != 'active' })<{
  active?: boolean;
}>(({ theme, active }) => ({
  '--outer-scale-value': "${(props) => (props.active ? '1.5' : '1')}",
  '--inner-scale-value': "${(props) => (props.active ? '1' : '0')}",
  width: 16,
  height: 16,
  background: `${active ? theme.palette.primary.light : theme.palette.primary.dark}`,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transform: 'scale(var(--outer-scale-value))',
  transition: 'all 400ms ease-in-out',
}));

export const DotInner = styled(Paper)({
  width: '5px',
  height: '5px',
  background: '#fff',
  borderRadius: '50%',
  transform: 'scale(var(--inner-scale-value))',
  transition: 'all 400ms ease-in-out',
});
