import { Box, styled } from '@mui/material';

export const StyledDroppable = styled(Box)<{ isOver: boolean }>(
  () => ({
    position: 'absolute',
    // backgroundColor: 'red',
    borderRadius: 4,
  }),
  ({ isOver, theme }) =>
    isOver && {
      backgroundColor: theme.palette.primary.main,
    }
);
