import type { DataType } from 'csstype';
import { Globals } from 'csstype';
import deepmerge, { Options } from 'deepmerge';

import { CHART_COLOR_PALETTE, isDarkColor, isTransparent } from '~utils/color';
import { arrayReplaceStrategy } from '~utils/deepmerge';

import { ElementType } from '../element';

export const CHART_LINE_COLOR = '#E5E7EB';

export interface TextProperties {
  fontFamily: string;
  fontSize: number;
  fontWeight: string | number;
  fontStyle: string;
}

export interface DashboardThemeText {
  general: TextProperties;
  filterTitle: TextProperties;
  chartTitle: TextProperties;
  cardTitle: TextProperties;
  filterInput: TextProperties;
  legend: TextProperties;
  dataLabels: TextProperties;
  tableHeader: TextProperties;
  cardData: TextProperties;
  tooltip: TextProperties;
  xAxis: TextProperties;
  yAxis: TextProperties;
}

export interface DashboardThemeColor {
  palette: string[];
  scaleColors: string[]; // Use for geomap chart, maximum 10 items
  elementBackground: string;
  pageBackground: string;
  tableHeadingBackground: string;
  chartLine: string;
  text: Record<keyof DashboardThemeText, string>;
}

export interface DashboardThemeShape {
  showShadow: boolean;
  borderWidth: number | string;
  borderRadius: number | string;
  /**
   * @deprecated TODO: Move this to color
   */
  borderColor: string;
  borderStyle: Globals | DataType.LineStyle | string;
}

export interface DashboardThemeConfig {
  shape: DashboardThemeShape;
  color: DashboardThemeColor;
  text: DashboardThemeText;
}

export const defaultBaseTextProperties = {
  fontFamily: 'Roboto',
  // please careful, you are changing many places
  fontSize: 13,
  fontWeight: 'normal',
  fontStyle: 'normal',
};

export const defaultTextColor = (backgroundColor?: string): DashboardThemeColor['text'] => {
  const isDark = !!backgroundColor && isDarkColor(backgroundColor);
  return {
    general: isDark ? '#fff' : '#000',
    filterTitle: isDark ? '#fff' : '#000',
    chartTitle: isDark ? '#fff' : '#000',
    cardTitle: isDark ? '#fff' : '#000',
    filterInput: isDark ? '#fff' : '#000',
    legend: isDark ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
    cardData: isDark ? '#fff' : '#000',
    dataLabels: isDark ? '#fff' : '#000',
    tableHeader: isDark ? '#fff' : '#000',
    tooltip: '#fff',
    xAxis: isDark ? '#fff' : '#000',
    yAxis: isDark ? '#fff' : '#000',
  };
};

const defaultShape: DashboardThemeShape = {
  showShadow: false,
  borderRadius: 8,
  borderStyle: 'none',
  borderWidth: 1,
  borderColor: '#111010',
};

const mergeOptions: Options = {
  arrayMerge: arrayReplaceStrategy,
};

export const generateDefaultElementTheme = (
  elementType?: ElementType,
  options?: DeepPartial<DashboardThemeConfig>
): DashboardThemeConfig => {
  const pageBackgroundColor = options?.color?.pageBackground ?? 'rgba(255,255,255,1)';
  const fallbackElementBackground = elementType === 'shape' ? '#fff' : 'rgba(255, 255, 255, 0)';
  const elementBackground = options?.color?.elementBackground ?? fallbackElementBackground;

  const displayedElementBackground = isTransparent(elementBackground)
    ? pageBackgroundColor
    : elementBackground;

  const palette = options?.color?.palette || CHART_COLOR_PALETTE;

  const defaultTheme: DashboardThemeConfig = {
    shape: defaultShape,
    color: {
      palette,
      scaleColors: palette.slice(0, 5),
      elementBackground: elementBackground,
      pageBackground: pageBackgroundColor,
      tableHeadingBackground: '#D9E1E7',
      chartLine: CHART_LINE_COLOR,
      text: defaultTextColor(displayedElementBackground),
    },
    text: {
      general: defaultBaseTextProperties,
      filterTitle: {
        ...defaultBaseTextProperties,
        fontWeight: 'bold',
      },
      chartTitle: defaultBaseTextProperties,
      cardTitle: {
        ...defaultBaseTextProperties,
        fontSize: 17.5,
        fontWeight: 'bold',
      },
      filterInput: {
        ...defaultBaseTextProperties,
        fontSize: 13,
        fontWeight: 'bold',
      },
      legend: defaultBaseTextProperties,
      tooltip: {
        ...defaultBaseTextProperties,
        fontWeight: 'bold',
      },
      cardData: {
        ...defaultBaseTextProperties,
        fontSize: 21,
      },
      dataLabels: defaultBaseTextProperties,
      tableHeader: {
        ...defaultBaseTextProperties,
        fontWeight: 'bold',
      },
      xAxis: defaultBaseTextProperties,
      yAxis: defaultBaseTextProperties,
    },
  };

  return deepmerge(defaultTheme, options ?? {}, mergeOptions);
};

export function extendTheme(
  theme: DashboardThemeConfig,
  options: DeepPartial<DashboardThemeConfig>
): DashboardThemeConfig {
  return deepmerge(theme, options ?? {}, mergeOptions);
}

export const themeTextConfigKeys = Object.keys(
  generateDefaultElementTheme().text
) as (keyof DashboardThemeText)[];
