import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleIcon, InfoIcon, MinusCircleIcon } from '@dataflake/icons';
import { ButtonProps, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';

import { useModalContext } from '~libs/modal-hook';
import { DFDialog, DFDialogProps } from '~ui/df-dialog';

import { AcceptButton, RejectButton } from './df-confirm-dialog.styled';

const iconMaps: Record<
  DialogVariant,
  {
    icon: React.ReactNode;
    color: DialogVariant;
  }
> = {
  error: { icon: <InfoIcon fontSize="large" />, color: 'error' },
  success: { icon: <CheckCircleIcon fontSize="large" />, color: 'success' },
  warning: { icon: <MinusCircleIcon fontSize="large" />, color: 'warning' },
};

export type DialogVariant = 'error' | 'warning' | 'success';

export interface DFConfirmDialogProps extends Omit<DFDialogProps, 'open' | 'onClose' | 'title'> {
  force?: boolean;
  variant?: DialogVariant;
  title: React.ReactNode;
  rejectButtonProps?: ButtonProps;
  acceptButtonProps?: ButtonProps;
  children: React.ReactNode;
  onResolve?: () => unknown | Promise<unknown>;
  onReject?: () => void;
}

export function DFConfirmDialog({
  force = true,
  variant,
  title,
  children,
  rejectButtonProps,
  acceptButtonProps,
  onResolve,
  onReject,
  size = 'xs',
  ...rest
}: DFConfirmDialogProps) {
  const { t } = useTranslation();

  const modal = useModalContext();

  const handleClose = () => {
    if (!force) {
      modal.remove();
    }
  };

  const handleReject = () => {
    onReject?.();
    modal.remove();
  };

  const handleResolve = async () => {
    await onResolve?.();
    modal.remove();
  };

  return (
    <DFDialog open={modal.visible} onClose={handleClose} size={size} {...rest}>
      <DialogTitle>
        {
          <Stack
            sx={{
              gap: 3,
              ...(variant && { color: (theme) => theme.palette[iconMaps[variant].color].main }),
            }}
          >
            {variant && iconMaps[variant].icon}
            {title}
          </Stack>
        }
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions sx={{ pt: 3 }}>
        <RejectButton onClick={handleReject} {...rejectButtonProps}>
          {rejectButtonProps?.children || t('COMMON.CANCEL')}
        </RejectButton>
        <AcceptButton
          onClick={handleResolve}
          color={variant === 'error' ? 'error' : 'primary'}
          {...acceptButtonProps}
        >
          {acceptButtonProps?.children || t('COMMON.OK')}
        </AcceptButton>
      </DialogActions>
    </DFDialog>
  );
}
