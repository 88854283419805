import React, { ForwardedRef, forwardRef, useState } from 'react';
import { MagnifyingGlassIcon } from '@dataflake/icons';
import { Box, Input, InputAdornment, Paper } from '@mui/material';

import { DFCheckbox } from '~ui/df-input/components/df-checkbox';
import { group, search } from '~utils/array';

import { ListPickerMultipleOption, renderListItems } from './df-list-picker-multiple.utils';

import {
  StyledList,
  StyledListItem,
  StyledListSubheader,
} from '../df-list-picker/df-list-picker.styled';

export interface DFListPickerMultipleProps {
  disableSearch?: boolean;
  options?: ListPickerMultipleOption[];
  value?: string[];
  maxHeight?: number;
  showSelectAll?: boolean;
  onChange?: (value: string[]) => void;
}

export const DFListPickerMultiple = forwardRef(function DFListPickerMultiple(
  {
    options,
    onChange,
    value,
    disableSearch,
    showSelectAll,
    maxHeight = 300,
  }: DFListPickerMultipleProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const [searchValue, setSearchValue] = useState('');
  const filteredOptions = search(options || [], ['label', 'value'], searchValue);
  const groups = options?.some((item) => !!item.group) && group(filteredOptions, 'group');
  const isSelectedAll = value?.length && options?.length ? value.length === options.length : false;

  const handleSelectAll = () => {
    if (isSelectedAll) {
      onChange?.([]);
    } else if (options?.length) {
      onChange?.(options.map((item) => item.value));
    }
  };

  return (
    <Paper ref={ref}>
      {!disableSearch && (
        <Box p={1} sx={(theme) => ({ background: theme.palette.background.grey })}>
          <Input
            fullWidth
            startAdornment={
              <InputAdornment position="start">
                <MagnifyingGlassIcon sx={{ fontSize: 12 }} />
              </InputAdornment>
            }
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Box>
      )}
      <StyledList sx={{ maxHeight, overflow: 'auto', p: 0 }}>
        {showSelectAll && (
          <StyledListItem onClick={handleSelectAll}>
            <DFCheckbox checked={isSelectedAll} sx={{ py: 0.5 }} />
            All
          </StyledListItem>
        )}
        {groups
          ? groups.map(({ group, items }) => (
              <React.Fragment key={group}>
                <StyledListSubheader>{group}</StyledListSubheader>
                {renderListItems(items, value, onChange)}
              </React.Fragment>
            ))
          : renderListItems(filteredOptions, value, onChange)}
      </StyledList>
    </Paper>
  );
});
