import { FetchError } from '~services/fetcher-factory';
import {
  ElementEntity,
  NewElementData,
  // ElementPlacement,
  // ElementPositionProperties,
} from '~services/v1/element';

// import { RectPosition } from '~utils/geometry';
import {
  selectActiveDashboard,
  // selectFooterProperties,
  // selectHeaderProperties,
} from '../selectors/dashboard.selector';
import { selectActivePage /*selectPageSetting*/ } from '../selectors/page.selector';
import { DashboardState } from '../store/dashboard-store';

// export function calculateElementPlacement(
//   elementStylePosition: RectPosition,
//   state: DashboardAllState
// ): ElementPlacement {
//   const { top, height } = elementStylePosition;
//   const header = selectHeaderProperties(state);
//   const footer = selectFooterProperties(state);
//   const pageHeight = selectPageSetting(state)?.height;
//
//   if (header.show && top < header.height) {
//     return 'header';
//   }
//
//   if (footer.show && top + height > pageHeight - footer.height) {
//     return 'footer';
//   }
//
//   return 'body';
// }

// export function updateElementPlacement<
//   T extends AnyElementEntity = AnyElementEntity
// >(
//   properties: Partial<T & ElementPositionProperties>,
//   state: DashboardAllState
// ): Partial<T & ElementPositionProperties> {
//   const newProperties = {
//     ...properties,
//     page_id: selectActivePage(state)?.page_id,
//     document_uuid: selectActiveDashboard(state)?.document_uuid,
//   };
//
//   if (!properties.element_style_position) {
//     return newProperties;
//   }
//
//   // const header = selectHeaderProperties(state);
//   // const { top } = properties.element_style_position;
//   const elementStylePosition = {
//     ...newProperties.element_style_position,
//     // top: top - (header.show && top > header.height ? header.height : 0),
//   };
//
//   return {
//     ...newProperties,
//     element_placement: calculateElementPlacement(properties.element_style_position, state),
//     element_style_position: elementStylePosition,
//   };
// }

export function addElementDefaultProperties<
  T extends ElementEntity,
  E extends NewElementData<T> | T | Partial<T>,
>(properties: E, state: DashboardState): E {
  const newProperties = {
    ...properties,
    page_id: selectActivePage(state)?.page_id,
    document_uuid: selectActiveDashboard(state)?.document_uuid,
  };

  // if (!properties.element_style_position) {
  //   return newProperties;
  // }
  //
  // // const header = selectHeaderProperties(state);
  // // const { top } = properties.element_style_position;
  // const elementStylePosition = {
  //   ...newProperties.element_style_position,
  //   // top: top - (header.show && top > header.height ? header.height : 0),
  // };

  return {
    ...newProperties,
    // element_placement: calculateElementPlacement(properties.element_style_position, state),
    // element_style_position: elementStylePosition,
  };
}

export function isShareAuthenticationError(error: unknown) {
  return (
    error && error instanceof FetchError && (error.body as any)?.redirect === 'share-authentication'
  );
}
