import { createQueryHook } from '~utils/react-query';

import requests from './query.service';

export const useQueriesQuery = createQueryHook(['query', 'getMany'], requests.getManyRequest);
export const useQueryOverviewQuery = createQueryHook(
  ['document', 'getOverviewQuery'],
  requests.getOverviewRequest
);
export const useQueryQuery = createQueryHook(['query', 'getOne'], requests.getOneRequest);
export const useQueryParametersQuery = createQueryHook(
  ['query', 'getParameters'],
  requests.getParametersRequest
);
export const useQueryDiscussionQuery = createQueryHook(
  ['query', 'discussions'],
  requests.getQueryDiscussion
);
export const useQueryHistoryQuery = createQueryHook(
  ['query', 'getHistoryQuery'],
  requests.getHistoryRequest
);
export const useReviewsQuery = createQueryHook(['query', 'getReviews'], requests.getReviewsRequest);
