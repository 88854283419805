/* eslint-disable max-len */
import { createIcon } from './factory';

export const BringFrontIcon = createIcon(
  'BringFront',
  'M462.097,271.969h-30.94V141.224c0-33.02-26.864-59.883-59.883-59.883H239.532v-30.94\n' +
    'c0-27.516-22.386-49.903-49.903-49.903H49.903C22.386,0.499,0,22.885,0,50.402v139.727c0,27.516,22.386,49.903,49.903,49.903\n' +
    'H81.84v130.745c0,33.02,26.864,59.883,59.883,59.883h130.745v30.94c0,27.516,22.386,49.903,49.903,49.903h139.727\n' +
    'c27.516,0,49.903-22.386,49.903-49.903V321.871C512,294.355,489.614,271.969,462.097,271.969z M81.84,141.224v58.885H49.903\n' +
    'c-5.503,0-9.98-4.477-9.98-9.981V50.402c0-5.503,4.477-9.98,9.98-9.98H189.63c5.503,0,9.981,4.477,9.981,9.98v30.94h-57.887\n' +
    'C108.704,81.341,81.84,108.205,81.84,141.224z M141.723,390.737c-11.007,0-19.961-8.954-19.961-19.961V141.224\n' +
    'c0-11.007,8.954-19.961,19.961-19.961h229.552c11.007,0,19.961,8.954,19.961,19.961v229.552c0,11.007-8.955,19.961-19.961,19.961\n' +
    'H141.723z M472.078,461.598c0,5.503-4.477,9.981-9.981,9.981H322.37c-5.503,0-9.98-4.477-9.98-9.981v-30.94h58.885\n' +
    'c33.02,0,59.883-26.864,59.883-59.883v-58.885h30.94c5.503,0,9.981,4.477,9.981,9.98V461.598z',
  '0 0 512 512'
);
