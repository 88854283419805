import { QueryKey, UseQueryOptions } from '@tanstack/react-query';

import { useCurrentProjectUuid } from '~hooks/auth/use-current-project';
import { createMutationHook, createQueryHook } from '~utils/react-query';

import { FetchError } from '../../fetcher-factory';

import { DashboardEntity } from './dashboard.entity';
import requests from './dashboard.service';

export const _useDocumentsQuery = createQueryHook(['document', 'getMany'], requests.getManyRequest);

export function useDocumentsQuery(
  options?: Omit<
    UseQueryOptions<DashboardEntity[], FetchError, DashboardEntity[], QueryKey>,
    'queryKey'
  >
) {
  const activeProjectUuid = useCurrentProjectUuid();
  return _useDocumentsQuery(undefined, {
    enabled: !!activeProjectUuid,
    staleTime: 0,
    refetchOnWindowFocus: false,
    retry: false,
    ...options,
  });
}

export const useDashboardTemplatesQuery = createQueryHook(
  ['dashboard-template'],
  requests.getDashboardTemplatesRequest
);

export const useDashboardSharesQuery = createQueryHook(
  ['dashboard-shares'],
  requests.getDashboardSharesRequest
);

export const useDashboardQueriesQuery = createQueryHook(
  ['dashboard-queries'],
  requests.getDashboardQueriesRequest
);
export const useDashboardQueriesElementsCountQuery = createQueryHook(
  ['dashboard-queries-elements-count'],
  requests.getDashboardQueriesElementsCountRequest
);
export const useDashboardConnectionStringsQuery = createQueryHook(
  ['dashboard-connection-strings'],
  requests.getDashboardConnectionStringsRequest
);
export const useDashboardConnectionStringsElementsCountQuery = createQueryHook(
  ['dashboard-connection-strings-elements-count'],
  requests.getDashboardConnectionStringsElementsCountRequest
);

export const useDashboardSearchItemsQuery = createQueryHook(
  ['dashboard-search-items'],
  requests.getDashboardSearchItemsRequest
);

export const useDashboardPagesRelatedQueryQuery = createQueryHook(
  ['dashboard-charts-related-query'],
  requests.getDashboardPagesRelatedQueryRequest
);

export const useCopyDashboardMutation = createMutationHook(requests.duplicateDocumentRequest);
