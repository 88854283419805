import { createRoot } from 'react-dom/client';
import { NoSsr } from '@mui/material';

import './i18n';

import App from './app';
import { Rollbar } from './rollbar';

import './index.scss';

const el = document.getElementById('root');

if (!el) {
  throw new Error('HTML template is missing #root');
}

const root = createRoot(el);

root.render(
  // <React.StrictMode>
  <NoSsr>
    <Rollbar>
      <App />
    </Rollbar>
  </NoSsr>
  // </React.StrictMode>
);
