import { Stack, styled, TextField, Typography } from '@mui/material';

export const Label = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  fontSize: `${theme.typography.inputValue.fontSize}px`,
  paddingLeft: theme.spacing(1),
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

export const DispatchBrief = styled(Stack, { shouldForwardProp: (prop) => prop !== 'active' })<{
  active: boolean;
}>(({ active, theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0.5),
  backgroundColor: active ? theme.palette.action.selected : 'none',
  '& .MuiTypography-root': {
    fontWeight: active ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular,
    color: theme.palette.text.secondary,
  },
}));

export const Input = styled(TextField)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  ['.MuiInput-input']: {
    fontSize: `${theme.typography.inputValue.fontSize}px`,
    color: theme.palette.text.secondary,
  },
}));

export const AddWrapper = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  padding: theme.spacing(1),
  ['.MuiButton-root']: {
    textTransform: 'capitalize',
    backgroundColor: 'white',
    marginTop: 1,
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
