import { Box as MUIBox, styled, Typography } from '@mui/material';

export const SubLabel = styled(Typography, {
  shouldForwardProp: (propName) => propName !== 'active',
})<{ active?: boolean }>(({ theme, active }) => ({
  color: !active ? theme.palette.primary.light : theme.palette.common.white,
  fontWeight: active ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular,
}));

export const Box = styled(MUIBox)(({}) => ({
  width: '100%',
  cursor: 'pointer',
}));
