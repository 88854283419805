import { useDraggable } from '@dnd-kit/core';

import { Coordinate, ResizeDirection } from '~utils/geometry';

import { BoxResizeData } from '../../../../pixel-board.types';

import { StyledResizeHandler } from './resize-handler-point.styled';

export interface ResizeHandlerPointProps {
  direction: ResizeDirection;
  transform?: Coordinate;
}

export function ResizeHandlerPoint({ direction, transform }: ResizeHandlerPointProps) {
  const data: BoxResizeData = {
    context: 'pixel-board',
    action: 'resize',
    position: direction,
    nodeType: 'box',
  };

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: `resize-multiple-${direction}`,
    data: data,
  });

  return (
    <StyledResizeHandler
      {...attributes}
      {...listeners}
      style={{ transform: `translate(${transform?.x ?? 0}px, ${transform?.y ?? 0}px)` }}
      ref={setNodeRef}
      direction={direction}
      role="button"
      tabIndex={0}
      data-direction={direction}
    />
  );
}
