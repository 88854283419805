import { UseQueryResult } from '@tanstack/react-query';
import { createStore } from 'zustand';

import { DataSourceEntity, DataSourceProgressEntity } from '~services/v1/data-source';

export interface ElementDataStore {
  progressQueryResult: UseQueryResult<DataSourceProgressEntity> | null;
  invalidate: undefined | (() => Promise<void>);
  dataSourceQueryResult: UseQueryResult<DataSourceEntity>;
}

export function createElementDataProviderStore(defaultValue: ElementDataStore) {
  return createStore<ElementDataStore>(() => ({
    ...defaultValue,
  }));
}
