import { Dispatch, SetStateAction, useEffect } from 'react';

import { ACTIVE_ELEMENT_PARAM_KEY } from '~components/universal-search';
import { useSearchParams } from '~libs/router';

import { BaseNode } from '../element-board.types';

interface UseHighlightSearchElementProps {
  node: BaseNode;
  element: HTMLElement | null;
  /**
   * @description: must be memo
   */
  onChangeSelectedNodeIds?: Dispatch<SetStateAction<string[]>>;
}

export function useHighlightSearchElement({
  node,
  element,
  onChangeSelectedNodeIds,
}: UseHighlightSearchElementProps) {
  const searchParams = useSearchParams([ACTIVE_ELEMENT_PARAM_KEY]);

  const activeElementId = searchParams[ACTIVE_ELEMENT_PARAM_KEY];

  useEffect(() => {
    if (activeElementId && node.data.elementId === activeElementId) {
      onChangeSelectedNodeIds?.([node.id]);

      element?.scrollIntoView({
        block: 'center',
        inline: 'center',
      });
    }
  }, [activeElementId, element, node.data.elementId, node.id, onChangeSelectedNodeIds]);
}
