import { Avatar as MUIAvatar, AvatarProps, Box } from '@mui/material';

import { stringToHslColor } from './df-avatar.utils';

import { StyledBadge } from './df-avatar.styled';

const SIZES = {
  small: 20,
  medium: 24,
  large: 32,
};

export interface AvatarStatus {
  status?: 'active' | 'inactive';
}

export interface DFAvatarProps extends AvatarProps, AvatarStatus {
  name?: string;
  size?: keyof typeof SIZES;
}

export function DFAvatar({
  name,
  size = 'medium',
  sx,
  status = 'inactive',
  ...props
}: DFAvatarProps) {
  const background = name && stringToHslColor(name);

  return (
    <Box>
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
        status={status}
      >
        <MUIAvatar {...props} sx={{ width: SIZES[size], height: SIZES[size], background, ...sx }}>
          {!!name && Array.from(name)[0]?.toUpperCase()}
        </MUIAvatar>
      </StyledBadge>
    </Box>
  );
}
