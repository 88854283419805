import React from 'react';
import { CaretDownIcon, CaretUpIcon } from '@dataflake/icons';
import { InputAdornment, Stack } from '@mui/material';

import { useNumberInputState } from '~hooks/use-number-input-state';

import { DFOutlinedInput, DFOutlinedInputProps } from '../df-outlined-input';

import { StyledButton } from './df-outlined-number-input.styled';

interface DFOutlinedNumberInputProps extends Omit<DFOutlinedInputProps, 'onChange'> {
  highlight?: boolean;
  minValue?: number;
  maxValue?: number;
  onChange?: (value: number) => void;
  onClickArrow?: React.MouseEventHandler<HTMLElement>;
}

export function DFOutlinedNumberInput({
  highlight,
  disabled,
  minValue = Number.NEGATIVE_INFINITY,
  maxValue = Number.POSITIVE_INFINITY,
  value,
  onChange,
  onClickArrow,
  sx,
  endAdornment,
  ...props
}: DFOutlinedNumberInputProps) {
  const [hover, setHover] = React.useState(false);

  const [inputValue, onChangeInputValue] = useNumberInputState(
    Number(value),
    (value) => onChange?.(value),
    (value) => Number(value),
    String
  );

  const handleIncrease = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClickArrow?.(e);
    onChangeInputValue?.(String((Number(inputValue) || 0) + 1));
  };
  const handleDecrease = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClickArrow?.(e);
    onChangeInputValue?.(String((Number(inputValue) || 0) - 1));
  };

  const handleChange = (value: string) => {
    if (Number(value) >= minValue && Number(value) <= maxValue) {
      onChangeInputValue?.(value);
    }
  };

  return (
    <DFOutlinedInput
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      type="number"
      value={inputValue}
      onChange={(e) => handleChange(e.target.value)}
      inputProps={{ min: minValue, max: maxValue }}
      disabled={disabled}
      endAdornment={
        <InputAdornment position="end" sx={{ ml: 0 }}>
          <Stack direction="row" gap={0.5} justifyContent="center" alignItems="center">
            {endAdornment}
            <Stack visibility={hover ? 'visible' : 'hidden'}>
              <StyledButton
                disabled={disabled || Number(inputValue) >= maxValue}
                onClick={handleIncrease}
              >
                <CaretUpIcon sx={{ width: '100%' }} />
              </StyledButton>
              <StyledButton
                disabled={disabled || Number(inputValue) <= minValue}
                onClick={handleDecrease}
              >
                <CaretDownIcon sx={{ width: '100%' }} />
              </StyledButton>
            </Stack>
          </Stack>
        </InputAdornment>
      }
      onBlur={(e) => handleChange(e.target.value || '0')}
      sx={{
        paddingRight: 1,
        ...(highlight && {
          backgroundColor: (theme) => theme.palette.primary.lighter,
        }),
        ...sx,
      }}
      {...props}
    />
  );
}
