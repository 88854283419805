import { Box, styled } from '@mui/material';

export const ColorPaletteContainer = styled(Box, {
  shouldForwardProp(propName: PropertyKey) {
    return propName !== 'itemsPerRow' && propName !== 'itemSize';
  },
})<{ itemSize: number; itemsPerRow: number }>(({ theme, itemsPerRow, itemSize }) => ({
  display: 'inline-block',
  width: itemSize * itemsPerRow + (itemsPerRow + 1) * parseInt(theme.spacing(0.5)),
}));
