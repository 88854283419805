/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { forwardRef } from 'react';
import { Box, InputAdornment, Popper, Stack } from '@mui/material';

import { DefaultSelectIcon } from '~ui/df-input/components/df-list-picker-input/df-list-picker-input.styled';
import { DFOutlinedInputProps } from '~ui/df-input/components/df-outlined-input';
import { DFPickerInputControl } from '~ui/df-input/components/df-picker-input-control';
import {
  DFListPickerMultiple,
  DFListPickerMultipleProps,
  ListPickerMultipleOption,
} from '~ui/df-picker/components/df-list-picker-multiple';

import { Chip } from './components/chip';

import { ChipList, OutlinedInput } from './df-list-picker-multiple-input.styled';

export interface DFListPickerMultipleInputProps
  extends Omit<DFOutlinedInputProps, 'value' | 'onChange'>,
    Omit<DFListPickerMultipleProps, 'options'> {
  options?: (ListPickerMultipleOption | string)[];
  maxChip?: number;
}

export const DFListPickerMultipleInput = forwardRef(function DFListPickerMultipleInput(
  {
    value,
    onChange,
    disableSearch,
    options = [],
    maxHeight,
    maxChip = 2,
    showSelectAll,
    startAdornment,
    ...props
  }: DFListPickerMultipleInputProps,
  ref
) {
  const [internalState, setInternalState] = React.useState<string[]>([]);
  const selectedValues = value ? value : internalState;
  const setChange = value ? onChange : setInternalState;

  const slicedValues = selectedValues.slice(0, maxChip);
  const truncated = selectedValues.length > maxChip;

  const optionsMap = React.useMemo(() => {
    return options.reduce(
      (acc, i) => {
        if (typeof i === 'string') {
          return {
            ...acc,
            [i]: {
              value: i,
              label: i,
            },
          };
        }

        return {
          ...acc,
          [i.value]: i,
        };
      },
      {} as Record<string, ListPickerMultipleOption>
    );
  }, [options]);

  function handleChange(newValue: string[]) {
    setChange && setChange(newValue);
  }

  function handleDelete(removeValue: string) {
    return () => {
      const newState = selectedValues.filter((value) => value !== removeValue);
      setChange && setChange(newState);
    };
  }

  return (
    <DFPickerInputControl ref={ref}>
      {({ open, toggle, popperAnchorEl, inputWidth, setInputRef }) => (
        <>
          <OutlinedInput
            endAdornment={
              <InputAdornment position="end">{<DefaultSelectIcon up={open} />}</InputAdornment>
            }
            startAdornment={
              <InputAdornment position="start">
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  {startAdornment}
                  <ChipList>
                    {slicedValues.map((value) => (
                      <Chip
                        key={value}
                        label={optionsMap[value]?.label}
                        onDelete={handleDelete(value)}
                      />
                    ))}
                    {truncated && <Chip label={`${selectedValues.length - maxChip}+`} />}
                  </ChipList>
                </Stack>
              </InputAdornment>
            }
            {...props}
            inputProps={{
              style: {
                minWidth: 0,
                paddingLeft: 0,
                paddingRight: 0,
              },
            }}
            ref={setInputRef}
            onClick={(e) => toggle(e.currentTarget)}
            fullWidth
            readOnly
          />
          <Popper
            open={open}
            anchorEl={popperAnchorEl}
            sx={{ zIndex: (theme) => theme.zIndex.modal }}
          >
            <Box minWidth={inputWidth}>
              <DFListPickerMultiple
                disableSearch={disableSearch}
                options={Object.values(optionsMap)}
                maxHeight={maxHeight}
                value={selectedValues}
                onChange={handleChange}
                showSelectAll={showSelectAll}
              />
            </Box>
          </Popper>
        </>
      )}
    </DFPickerInputControl>
  );
});
