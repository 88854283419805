import { initReactI18next } from 'react-i18next';
import { formatDistance } from 'date-fns/formatDistance';
import { de } from 'date-fns/locale/de';
import { enUS } from 'date-fns/locale/en-US';
import { fr } from 'date-fns/locale/fr';
import { Locale } from 'date-fns/locale/types';
import i18n from 'i18next';
import { TOptions } from 'i18next/typescript/options';
import HttpApi, { HttpBackendOptions } from 'i18next-http-backend';

import { localStorage } from '../storage';
import { parseDateBestEffort } from '../utils/datetime';

import getDefaultTranslation, { keyToDefaultValue } from './default-translation';

export const LANGUAGE_KEY = 'i18n_language';

const DATE_FNS_LOCALES: Record<string, Locale> = {
  en: enUS,
  enUS,
  de,
  fr,
};

const backend: HttpBackendOptions = {
  loadPath: `${import.meta.env.VITE_I18N_IT_BASE_URL}/api/export/grid/f2/${import.meta.env.VITE_I18N_GRID_UUID}?version=2`,
  parse(rawData, langs) {
    const data: { grid_data: { lang: string; translations: Record<string, string> }[] } =
      JSON.parse(rawData);
    const firstLang = langs ? (typeof langs === 'string' ? langs : langs[0]) : 'en';
    const translationData = data.grid_data.find((d) => d.lang === firstLang);
    return translationData?.translations ?? {};
  },
  addPath: `https://neo.wordplex.io/api/import/request-translations?grid_uuid=${import.meta.env.VITE_I18N_GRID_UUID}&version=2`,
  customHeaders: {
    'Content-Type': 'application/json',
  },
  parsePayload(namespace: string, key: string, fallbackValue?: string): { [p: string]: any } {
    return {
      grid_uuid: import.meta.env.VITE_I18N_GRID_UUID,
      translations_requests: [
        {
          key: key,
          lang: 'en',
          default:
            fallbackValue !== key
              ? fallbackValue
              : getDefaultTranslation(key) || keyToDefaultValue(key),
        },
      ],
    };
  },
  // uuid: import.meta.env.VITE_I18N_GRID_UUID,
  // langPath: `${import.meta.env.VITE_I18N_IT_BASE_URL}/api/export/grid/{{uuid}}/langs`,
  // loadPath: `${import.meta.env.VITE_I18N_IT_BASE_URL}/api/export/grid/f2/{{uuid}}`,
  // savePath: 'https://neo.wordplex.io/api/import/request-translations?grid_uuid={{uuid}}',
};

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    fallbackLng: 'en',
    lng: String(localStorage.getItem(LANGUAGE_KEY) || 'en'),
    ns: ['translations'],
    defaultNS: 'translations',
    debug: import.meta.env.ENV !== 'production',
    load: 'languageOnly',
    keySeparator: false,
    nsSeparator: false,
    saveMissing: true,
    returnNull: false,
    overloadTranslationOptionHandler([key]): TOptions {
      return {
        defaultValue: getDefaultTranslation(key) || keyToDefaultValue(key),
      };
    },
    react: {
      useSuspense: false,
    },
    backend: {
      ...backend,
    },
    interpolation: {
      format(value: unknown, format, lang) {
        switch (format) {
          case 'uppercase':
            return String(value).toUpperCase();
          case 'distance_from_now':
            const locale = DATE_FNS_LOCALES[lang || 'en'] || enUS;
            let fromDate: Date;
            try {
              fromDate = parseDateBestEffort(String(value));
            } catch (e) {
              console.error(e);
              return '';
            }
            return formatDistance(fromDate, new Date(), {
              locale,
              addSuffix: true,
            });
        }
        return String(value);
      },
    },
  });
