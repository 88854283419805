import { ComponentType, useState } from 'react';
import { StoreApi, useStore } from 'zustand';

import { createModalStore, ModalContext, ModalStore } from '../modal-context';

export function useModal<T>(Component: ComponentType<T>, props?: T) {
  const [modalStoreApi] = useState(() => createModalStore<T>(props));
  const storeValue = useStore(modalStoreApi);
  const placeholder = (
    <ModalContext.Provider value={modalStoreApi as StoreApi<ModalStore<unknown>>}>
      {storeValue.props && <Component {...storeValue.props} />}
    </ModalContext.Provider>
  );

  return {
    ...storeValue,
    placeholder,
  };
}
