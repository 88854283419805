import {
  Button,
  DialogActions as MUIDialogActions,
  DialogContent as MUIDialogContent,
  Stack as MUIStack,
  styled,
  TableContainer as MUITableContainer,
} from '@mui/material';

import { withProps } from '~utils/styled';

export const SectionAction = styled(withProps(Button, { variant: 'rounded' }))(({ theme }) => ({
  fontSize: `${theme.typography.body2.fontSize}`,
  padding: `${theme.spacing(0.5, 3.5)}`,
  textTransform: 'capitalize',
}));

export const DialogActions = styled(MUIDialogActions)(({ theme }) => ({
  width: '30%',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2),
}));

export const Stack = styled(MUIStack)({
  height: '100%',
  overflow: 'hidden',
});

export const DialogContent = styled(MUIDialogContent)({
  height: '100%',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
});

export const TableContainer = styled(MUITableContainer)({
  maxHeight: '100%',
  display: 'flex',
  overflow: 'auto',
});
