import { useCallback, useEffect } from 'react';
import deepmerge, { Options } from 'deepmerge';
import { shallow } from 'zustand/shallow';

import { useOverrideElementTheme } from '~hooks/use-override-element-theme';
import {
  extendTheme,
  generateDefaultElementTheme,
} from '~services/v1/dashboard-theme/dashboard-theme.entity';
import { normalizeElementTheme } from '~services/v1/dashboard-theme/dashboard-theme.utils';
import {
  AxisChartConfig,
  BarChartElementEntity,
  defaultColumnStyleConfig,
  ElementEntity,
  ElementEntityStyle,
  ElementTheme,
  getXScale,
  HorizontalBarChartConfig,
  HorizontalBarChartElementEntity,
  LineChartElementEntity,
  MixedChartYAxis,
  TableChartElementEntity,
  TableColumn,
} from '~services/v1/element';
import { EntityUuid } from '~services/v1/types';
import { jsonEqual } from '~utils/compare';
import { arrayReplaceStrategy } from '~utils/deepmerge';
import { createZustandHooks } from '~utils/zustand';

import { useDashboardSelector } from '../dashboard-provider';
import { selectPageSetting } from '../dashboard-provider/selectors';
import { useElementDataState } from '../element-data-provider';

import { ElementContext } from './element-provider';
import { ChartSeries } from './element-provider.store';

const mergeOptions: Options = {
  arrayMerge: arrayReplaceStrategy,
};

export const [useElementStore, useElementState] = createZustandHooks(ElementContext);
export const useSaveChartSeries = (series: ChartSeries) => {
  const setChartSeries = useElementState((store) => store.setChartSeries);
  useEffect(() => {
    setChartSeries({
      type: series.type,
      series: series.series,
    } as ChartSeries);
  }, [setChartSeries, series.type, series.series]);
};

export function useElementContext<E extends ElementEntity, T = E>(
  selector?: (el: E) => T,
  equalityFn?: (value1: T, value2: T) => boolean
) {
  return useElementState((store) => {
    if (!store.element) {
      throw new Error(
        'Element context is not existed, This component need an ElementProvider to work, wrap it with ElementProvider'
      );
    }
    let element = store.element as E;
    if (
      element.element_type === 'chart' &&
      (element.element_config.chartType === 'bar' ||
        element.element_config.chartType === 'horizontalBar' ||
        element.element_config.chartType === 'line')
    ) {
      element = {
        ...element,
        element_config: {
          ...element.element_config,
          stacked100: store.reverseStack100
            ? !element.element_config.stacked100
            : element.element_config.stacked100,
        },
      };
    }
    if (!selector) {
      return element as unknown as T;
    }
    return selector(element);
  }, equalityFn || shallow);
}

export function useUpdateElement<E extends ElementEntity>() {
  return useElementState((state) => state.updateElement) as (properties: Partial<E>) => unknown;
}

export function useUpdateElementConfig<E extends ElementEntity>() {
  const updateElement = useElementState((state) => state.updateElement);
  return useCallback(
    (elementConfig: Partial<E['element_config']>) =>
      updateElement({
        element_config: elementConfig,
      }),
    [updateElement]
  );
}

export function useUpdateYAxisConfigMixedChart() {
  const updateElement = useElementState((state) => state.updateElement);
  const element = useElementState((state) => state.element);

  return useCallback(
    (yAxisConfig: Partial<MixedChartYAxis>, yAxisIndexes: number[]) => {
      if (
        element?.element_type === 'chart' &&
        element.element_config.chartType === 'mixed' &&
        element.element_config.yAxis
      ) {
        updateElement({
          element_config: {
            yAxis: element.element_config.yAxis.map((item, idx) =>
              yAxisIndexes.includes(idx) ? { ...item, ...yAxisConfig } : item
            ),
          },
        });
      }
    },
    [element?.element_config, element?.element_type, updateElement]
  );
}

export const ELEMENT_THEME_STORAGE_KEY = 'element-theme';

function shouldPersistTheme(theme: ElementTheme) {
  return theme.color?.text?.general || theme.color?.elementBackground || theme.shape;
}

export function useUpdateElementTheme() {
  const updateElement = useElementState((state) => state.updateElement);

  return useCallback(
    (theme: ElementTheme) => {
      updateElement({
        element_theme: theme,
      });
      if (shouldPersistTheme(theme)) {
        const elementTheme = localStorage.getItem(ELEMENT_THEME_STORAGE_KEY);

        localStorage.setItem(
          ELEMENT_THEME_STORAGE_KEY,
          JSON.stringify(
            deepmerge(elementTheme ? JSON.parse(elementTheme) : {}, theme, mergeOptions)
          )
        );
      }
    },
    [updateElement]
  );
}

export function useUpdateDimensionAxisChart() {
  const dataSource = useElementDataState((state) => state.dataSourceQueryResult.data);

  const updateElementConfig = useUpdateElementConfig<
    BarChartElementEntity | LineChartElementEntity
  >();

  return useCallback(
    (dimension?: string) => {
      const newConfig: Partial<AxisChartConfig> = {
        dimension: dimension || '',
      };
      const matchedColumn = dataSource?.columns?.find((col) => col.name === dimension);
      if (matchedColumn) {
        newConfig.xScale = getXScale(matchedColumn.type);
      }
      updateElementConfig(newConfig);
    },
    [dataSource?.columns, updateElementConfig]
  );
}

export function useUpdateDimensionHorizontalBarChart() {
  const dataSource = useElementDataState((state) => state.dataSourceQueryResult.data);

  const updateElementConfig = useUpdateElementConfig<HorizontalBarChartElementEntity>();

  return useCallback(
    (dimension?: string) => {
      const newConfig: Partial<HorizontalBarChartConfig> = {
        dimension: dimension || '',
      };
      const matchedColumn = dataSource?.columns?.find((col) => col.name === dimension);
      if (matchedColumn) {
        newConfig.indexScale = getXScale(matchedColumn.type);
      }
      updateElementConfig(newConfig);
    },
    [dataSource?.columns, updateElementConfig]
  );
}

export const selectId = (el: ElementEntity) => el.element_id;

export const selectElementDescription = (el: ElementEntity) => el.element_description;

export function selectElementConfig<E extends ElementEntity = ElementEntity>(
  el: E
): E['element_config'] {
  return el.element_config;
}

export function selectElementStyle(el: ElementEntity) {
  return Object.fromEntries(
    Object.entries(el).filter(([key]) => key.startsWith('element_style') || key === 'element_id')
  ) as ElementEntityStyle & { element_id: EntityUuid };
}

export function useElementTheme<T>(
  selector: (theme: ElementTheme) => T,
  compareFn?: (t1: T, t2: T) => boolean
): T;
export function useElementTheme(): ElementTheme;
export function useElementTheme<T extends ElementTheme>(
  selector?: (theme: ElementTheme) => T,
  compareFn?: (t1: T, t2: T) => boolean
): T {
  const overrideElementTheme = useOverrideElementTheme();
  // TODO => Have to think about conflicts of background color and text color of dashboard and element
  const pageBackground = useDashboardSelector(selectPageSetting)?.bgColor;

  return useElementContext((el) => {
    let elementTheme = deepmerge(
      {
        color: {
          pageBackground,
        },
      },
      el.element_theme ?? {},
      overrideElementTheme
    ) as ElementTheme;

    elementTheme = normalizeElementTheme(elementTheme);
    const defaultElementTheme = generateDefaultElementTheme(el.element_type, elementTheme);

    const theme = extendTheme(defaultElementTheme, elementTheme);

    if (selector) {
      return selector(theme);
    }
    return theme as T;
  }, compareFn ?? jsonEqual);
}

export function useTableColumns() {
  const elementTheme = useElementTheme();

  return useElementContext((el: TableChartElementEntity) => {
    return el.element_config.columns?.map((col) => {
      const defaultColumnStyle = defaultColumnStyleConfig({
        dense: el.element_config.dense,
      });

      return {
        ...col,
        style: {
          ...defaultColumnStyle,
          ...col.style,
          color: col.style?.color || elementTheme.color?.text?.general || defaultColumnStyle.color,
        },
      } as TableColumn;
    });
  }, jsonEqual);
}
