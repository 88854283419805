import React, { forwardRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from '@dataflake/icons';
import { BoxProps, Grid, Typography } from '@mui/material';

import { useLocalStorageState } from '~hooks/use-local-storage-state';
import { chunk } from '~utils/array';
import { colorsAreEqual } from '~utils/color';

import { ColorItem } from '../color-item';

import { CustomColorPicker } from './comonents/custom-color-picker';

import { ColorPaletteContainer } from './df-color-palette.styled';

const defaultColors = [
  '#172B4D',
  '#0055CC',
  '#206A83',
  '#216E4E',
  '#E56910',
  '#AE2E24',
  '#5E4DB2',
  '#758195',
  '#1D7AFC',
  '#2898BD',
  '#22A06B',
  '#FEA362',
  '#C9372C',
  '#8270DB',
  '#FFFFFF',
  '#CCE0FF',
  '#C6EDFB',
  '#BAF3DB',
  '#F8E6A0',
  '#FFD5D2',
  '#DFD8FD',
];

const ITEMS_PER_ROW = 7;

const PALETTES = {
  textColor: defaultColors,
  fillColor: chunk(defaultColors, ITEMS_PER_ROW)
    .reverse()
    .flat()
    .map((color) =>
      color === '#FFFFFF' ? 'rgba(0,0,0,0)' : color === '#CCE0FF' ? '#FFFFFF' : color
    ),
  borderColor: defaultColors,
};

export type DfColorPalette = keyof typeof PALETTES;

export interface DFColorPaletteProps extends Omit<BoxProps, 'onChange' | 'ref'> {
  palette?: DfColorPalette;
  itemSize?: number;
  disabled?: boolean;
  value?: string;
  onChange?: (value: string) => void;
}

export const DFColorPalette = forwardRef<HTMLDivElement, DFColorPaletteProps>(
  function DFColorPalette(
    { palette = 'textColor', itemSize = 21, value, onChange, disabled, ...props },
    ref
  ) {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<SVGElement | HTMLElement | null>(null);
    const [customColors, setCustomColors] = useLocalStorageState<string[]>('custom-colors', []);

    const onChangeValue = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        if (onChange) {
          onChange(e.currentTarget.value);
        }
      },
      [onChange]
    );

    function handleCustomColorClose() {
      setAnchorEl(null);
    }

    async function handleCustomColorChange(color: string) {
      await setCustomColors((colors: string[]) => {
        const newColors = colors.filter(
          (oldColor) => oldColor !== color && !PALETTES[palette].includes(color)
        );
        return [...newColors, color];
      });
      onChange && onChange(color);
      handleCustomColorClose();
    }

    return (
      <ColorPaletteContainer
        sx={{ p: 1 }}
        itemSize={itemSize}
        itemsPerRow={ITEMS_PER_ROW}
        {...props}
        ref={ref}
      >
        <Typography variant="inputLabel" fontWeight="bold">
          {t('COMMON.DEFAULTS')}
        </Typography>
        <Grid container columns={ITEMS_PER_ROW} spacing={0.5}>
          {PALETTES[palette].map((color) => (
            <Grid item xs={1} key={color}>
              <ColorItem
                disabled={disabled}
                size={itemSize}
                color={color}
                value={color}
                checked={colorsAreEqual(value || '', color)}
                onClick={onChangeValue}
              />
            </Grid>
          ))}
        </Grid>
        <Typography variant="inputLabel" fontWeight="bold">
          {t('COMMON.USER_CUSTOMS')}
        </Typography>
        <Grid container columns={ITEMS_PER_ROW} spacing={1}>
          {customColors.map((color) => (
            <Grid item xs={1} key={color} sx={{ width: '100px' }}>
              <ColorItem
                disabled={disabled}
                size={itemSize}
                color={color}
                value={color}
                checked={colorsAreEqual(value || '', color)}
                onClick={onChangeValue}
              />
            </Grid>
          ))}
          <Grid item xs={1} sx={{ width: '100px' }}>
            <PlusIcon
              sx={{
                cursor: 'pointer',
                ...(disabled && {
                  color: (theme) => theme.palette.text.disabled,
                  cursor: 'auto',
                }),
              }}
              onClick={(e) => {
                if (!disabled) {
                  e.stopPropagation();
                  setAnchorEl(e.currentTarget);
                }
              }}
            />
          </Grid>
        </Grid>
        <CustomColorPicker
          anchorEl={anchorEl}
          onChange={handleCustomColorChange}
          onClose={handleCustomColorClose}
        />
      </ColorPaletteContainer>
    );
  }
);
