import { localStorage } from '../storage';

import { Fetcher } from './fetcher-factory';

export const SHARE_UUID_KEY = 'share_uuid';
export const SHARE_PASSWORD_KEY = 'share_key';
export const BOT_ACCESS_KEY = 'bot_access_key';
export const SESSION_UUID_KEY = 'session_uuid';
export const COMPANY_UUID_KEY = 'company_uuid';
export const PROJECT_UUID_KEY = 'project_uuid';
export const DASHBOARD_UUID_KEY = 'document_uuid';
export const PAGE_UUID_KEY = 'page_uuid';

export const API_VERSION_HEADER_KEY = 'X-Version';
export const SESSION_UUID_HEADER_KEY = 'X-Session-Token';
export const COMPANY_UUID_HEADER_KEY = 'X-Company-Token';
export const PROJECT_UUID_HEADER_KEY = 'X-Project-Token';

export const baseURL =
  import.meta.env.VITE_API_BASE_URL || `${window.location.origin}${import.meta.env.VITE_API_PATH}`;

export const authFetcher = new Fetcher({
  baseURL,
  fetcherOptions: (defaultOptions) => ({
    ...defaultOptions,
    headers: {
      ...defaultOptions.headers,
      [API_VERSION_HEADER_KEY]: '2018_R2',
      [SESSION_UUID_HEADER_KEY]: localStorage.getItem(SESSION_UUID_KEY) ?? '',
      [COMPANY_UUID_HEADER_KEY]: localStorage.getItem(COMPANY_UUID_KEY) ?? '',
      [PROJECT_UUID_HEADER_KEY]: localStorage.getItem(PROJECT_UUID_KEY) ?? '',
    },
  }),
});

export const coreFetcher = new Fetcher({
  baseURL: `${baseURL}/v1`,
  fetcherOptions: (defaultOptions) => ({
    ...defaultOptions,
    headers: {
      ...defaultOptions.headers,
      [API_VERSION_HEADER_KEY]: '2018_R2',
      [SESSION_UUID_HEADER_KEY]: localStorage.getItem(SESSION_UUID_KEY) ?? '',
      [COMPANY_UUID_HEADER_KEY]: localStorage.getItem(COMPANY_UUID_KEY) ?? '',
      [PROJECT_UUID_HEADER_KEY]: localStorage.getItem(PROJECT_UUID_KEY) ?? '',
      'X-Document-Token': localStorage.getItem(DASHBOARD_UUID_KEY) ?? '',
      'X-Page-Token': localStorage.getItem(PAGE_UUID_KEY) ?? '',
      'X-Share-Token': localStorage.getItem(SHARE_UUID_KEY) ?? '',
      'X-Share-Key': localStorage.getItem(SHARE_PASSWORD_KEY) ?? '',
      'X-Bot-Key': localStorage.getItem(BOT_ACCESS_KEY) ?? '',
    },
  }),
});

export const alertFetcher = new Fetcher({
  baseURL: import.meta.env.VITE_ALERT_API_BASE_URL,
  fetcherOptions: (defaultOptions) => ({
    ...defaultOptions,
    headers: {
      ...defaultOptions.headers,
      'X-Tenant-Name': `df.${localStorage.getItem(COMPANY_UUID_KEY)}`,
    },
  }),
});

export const incidentTestClient = new Fetcher({
  baseURL: import.meta.env.VITE_ALERT_API_BASE_URL,
  fetcherOptions: (defaultOptions) => defaultOptions,
});
