import { LegacyCompactMode } from '~utils/unit';

import { GenericChartConfig, GenericChartElementEntity } from './chart-element';

export type DataBoxFunction = 'first' | 'sum' | 'min' | 'max' | 'avg' | 'last';

export type CardDisplayType = 'number' | 'string' | 'auto';

export interface DataCardSpecialConfig {
  column?: string;
  displayType?: CardDisplayType | null;
  function: DataBoxFunction;
  compactNumber?: LegacyCompactMode;
  decimalPrecision?: number;
}

export const DECIMAL_PRECISION_DEFAULT = 2;

export const defaultDataCardConfig = (): DataCardSpecialConfig => ({
  function: 'first',
  compactNumber: 'simplify',
  decimalPrecision: DECIMAL_PRECISION_DEFAULT,
});

export const CARD_DISPLAY_OPTIONS: CardDisplayType[] = ['number', 'string', 'auto'];

export interface DataCardConfig extends GenericChartConfig<'card'>, DataCardSpecialConfig {
  chartType: 'card';
}

export interface DataCardElementEntity extends GenericChartElementEntity<DataCardConfig> {}
