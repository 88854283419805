import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';

import { DataCenterInput } from '~components/input/components/data-center-input';
import { EmailInput } from '~components/input/components/email-input';
import { PasswordInput } from '~components/input/components/password-input';
import { GoogleIcon } from '~icons/legacy/google-icon';
import { useSearch } from '~libs/router';

import ForgotPassword from './components/forgot-password';
import useLoginForm from './login-page.form';

export interface LoginProps {}

export function LoginPage({}: LoginProps) {
  const { onSubmit, inputs, isPending, getValues } = useLoginForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const search = useSearch();
  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={{
        px: {
          xs: 3,
          sm: 7,
          lg: 15,
        },
      }}
    >
      <Typography align="center" variant="h5" fontWeight="bold" mb={2}>
        {t('COMMON.LOG_IN')}
      </Typography>
      <Stack gap={3} mb={3}>
        <DataCenterInput getValues={getValues} size="small" fullWidth />
        <EmailInput {...inputs.email} />
        <Stack gap={1} alignItems="end">
          <PasswordInput {...inputs.password} />
          <ForgotPassword />
        </Stack>
      </Stack>
      <Stack spacing={2} mb={4}>
        <Button disabled={isPending} color="primary" type="submit" variant="rounded">
          {t('COMMON.LOG_IN')}
        </Button>
        {import.meta.env.VITE_ENABLE_GOOGLE_AUTH && (
          <>
            <Box sx={{ width: '50%', alignSelf: 'center' }}>
              <Divider>
                <Typography color="text.secondary">{t('COMMON.OR')}</Typography>
              </Divider>
            </Box>
            <Stack gap={4}>
              <Button
                href="/api/v1/auth/redirect/google"
                type="button"
                startIcon={<GoogleIcon />}
                variant="round-outlined"
              >
                {t('COMMON.GOOGLE')}
              </Button>
            </Stack>
          </>
        )}
      </Stack>
      <Stack gap={2}>
        <Typography align="center" color="text.secondary">
          {t('COMMON.DONT_HAVE_AN_ACCOUNT')}
        </Typography>
        <Button
          type="button"
          variant="round-outlined"
          style={{
            color: theme.palette.primary.main,
          }}
          onClick={() => navigate(`/register${search}`)}
        >
          {t('COMMON.REGISTER')}
        </Button>
      </Stack>
    </Box>
  );
}
