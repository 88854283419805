import React, { ReactNode } from 'react';
import { FormHelperText, Stack, StackProps } from '@mui/material';

import { DFFormLabel } from '~ui/df-form/components/df-form-label';

export interface DFFormItemProps {
  label?: ReactNode;
  input: ReactNode;
  error?: string;
  switchInput?: boolean;
  stackProps?: StackProps;
}

export const DFFormItem = React.forwardRef<HTMLDivElement, DFFormItemProps>(function DFFormItem(
  { label, input, error, switchInput, stackProps },
  ref
) {
  return (
    <Stack
      direction={switchInput ? 'row' : undefined}
      justifyContent={switchInput ? 'space-between' : undefined}
      alignItems={switchInput ? 'center' : undefined}
      ref={ref}
      {...stackProps}
    >
      {!!label && <DFFormLabel>{label}</DFFormLabel>}
      {input}
      {error && <FormHelperText error>{error}</FormHelperText>}
    </Stack>
  );
});
