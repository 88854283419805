import {
  Dialog as MUIDialog,
  dialogClasses,
  DialogContent as MUIDialogContent,
  IconButton,
  InputBase as MUIInputBase,
  paperClasses,
  styled,
} from '@mui/material';

export const Dialog = styled(MUIDialog)(() => ({
  [`.${dialogClasses.container}`]: {
    paddingTop: '15vh',
    alignItems: 'flex-start',
  },
  [`.${paperClasses.root}`]: {
    overflowY: 'unset',
    boxShadow: 'unset',
    borderRadius: 'unset',
    backgroundColor: 'transparent',
  },
}));

export const DialogContent = styled(MUIDialogContent)(({ theme }) => ({
  padding: theme.spacing(0),
}));

export const InputBase = styled(MUIInputBase)(({ theme }) => ({
  height: 34,
  padding: '8px 12px',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
}));

export const SearchWrapper = styled('div')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
}));

export const ButtonClose = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[500],
  position: 'absolute',
  top: theme.spacing(-4),
  right: theme.spacing(-2),
}));
