import { AxisChartSpecialConfig, defaultAxisChartConfig } from './axis-chart-element';
import { GenericChartConfig, GenericChartElementEntity } from './chart-element';

export type BarChartSpecialConfig = AxisChartSpecialConfig;

export const defaultBarChartConfig = () => ({ ...defaultAxisChartConfig(), showStacked: true });

export interface BarChartConfig extends GenericChartConfig<'bar'>, BarChartSpecialConfig {}

export interface BarChartElementEntity extends GenericChartElementEntity<BarChartConfig> {}
