import React from 'react';

export function useScrollToSelectedListItem<T extends HTMLElement, U extends HTMLElement>(
  active = true
) {
  const wrapperRef = React.useRef<T | null>(null);
  const [selectedItemRef, setSelectedItemRef] = React.useState<U | null>(null);

  React.useEffect(() => {
    if (active && selectedItemRef && wrapperRef.current) {
      const activeItemHeight = selectedItemRef.clientHeight;
      const offset = wrapperRef.current.clientHeight / 2 - activeItemHeight / 2;
      wrapperRef.current.scrollTop = selectedItemRef.offsetTop - offset;
    }
  }, [active, selectedItemRef]);

  return { wrapperRef, selectedItemRef, setSelectedItemRef };
}
