import React, { useRef, useState } from 'react';
import { Box, ClickAwayListener, Fade, Paper, PaperProps, PopperProps } from '@mui/material';

import { ActivatorWrapper, Arrow, Popper } from './df-context-popup.styled';

export type DFContextPopupProps = Omit<PopperProps, 'open' | 'children'> & {
  activator: React.ReactNode | ((active: boolean) => React.ReactNode);
  placement?: PopperProps['placement'];
  onClose?: () => void;
  children?: React.ReactNode | ((props: { onClose: () => void }) => React.ReactNode);
  PaperProps?: PaperProps;
  arrow?: boolean;
  flip?: boolean;
  spacing?: number;
  disableCloseOnClick?: boolean;
  disabled?: boolean;
  activatorAnchorEl?: HTMLElement | null;
  activeOnHover?: boolean;
  disableCloseOnClickAway?: boolean;
  setActivatorAnchorEl?: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
};

export const DFContextPopup = React.forwardRef<HTMLDivElement, DFContextPopupProps>(
  function DFContextPopup(
    {
      activator,
      placement,
      onClose,
      children,
      PaperProps,
      arrow = true,
      flip = false,
      spacing = 16,
      disableCloseOnClick,
      modifiers: extendedModifiers = [],
      activatorAnchorEl,
      setActivatorAnchorEl,
      disabled,
      activeOnHover,
      disableCloseOnClickAway,
      ...rest
    },
    ref
  ) {
    const anchorRef = useRef<HTMLDivElement>(null);
    const [internalAnchorEl, setInternalAnchorEl] = useState<HTMLElement | null>(null);
    const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
    const [hover, setHover] = React.useState(false);

    const anchorEl = activatorAnchorEl ?? internalAnchorEl;
    const setAnchorEl = setActivatorAnchorEl ?? setInternalAnchorEl;

    function handleClose() {
      setAnchorEl(null);
      onClose && onClose();
    }

    function handleCloseOnClick() {
      return !disableCloseOnClick && handleClose();
    }

    const modifiers = React.useMemo(() => {
      return [
        {
          name: 'arrow',
          enabled: arrow,
          options: {
            element: arrowRef,
          },
        },
        {
          name: 'flip',
          enabled: flip,
        },
        {
          name: 'offset',
          options: {
            offset: [0, spacing],
          },
        },
        ...extendedModifiers,
      ];
    }, [arrow, arrowRef, extendedModifiers, flip, spacing]);

    function handleMouseOver() {
      setHover(true);
    }

    function handleMouseLeave() {
      setHover(false);
    }

    React.useEffect(() => {
      let timeout: NodeJS.Timeout;

      if (activeOnHover) {
        if (hover) {
          setAnchorEl(anchorRef.current);
        } else {
          timeout = setTimeout(() => {
            setAnchorEl(null);
          }, 100);
        }
      }

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }, [activeOnHover, hover, setAnchorEl]);

    return (
      <ClickAwayListener onClickAway={!disableCloseOnClickAway ? handleClose : () => {}}>
        <Box>
          <ActivatorWrapper
            ref={anchorRef}
            onClick={(e) => {
              setAnchorEl((old) => (!old ? e.currentTarget : null));
            }}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            sx={{ color: (theme) => (!!anchorEl ? theme.palette.primary.main : undefined) }}
          >
            {typeof activator === 'function' ? activator(!!anchorEl) : activator}
          </ActivatorWrapper>
          <Popper
            ref={ref}
            placement={placement}
            {...rest}
            anchorEl={anchorEl}
            open={disabled ? false : !!anchorEl}
            modifiers={modifiers}
            popperOptions={{
              strategy: 'absolute',
            }}
          >
            <Fade in={!!open} unmountOnExit>
              <Paper
                {...PaperProps}
                onClick={handleCloseOnClick}
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMouseLeave}
              >
                {arrow && <Arrow ref={setArrowRef} className="MuiPopper-arrow" />}
                {children && typeof children === 'function'
                  ? children({ onClose: handleClose })
                  : children}
              </Paper>
            </Fade>
          </Popper>
        </Box>
      </ClickAwayListener>
    );
  }
);
