import React, { ComponentType, forwardRef } from 'react';
import { Theme } from '@mui/material';

export const border = (
  theme: Theme,
  position: 'l' | 'r' | 'b' | 't' | 'y' | 'x' | 'a' = 'a',
  width: React.CSSProperties['borderWidth'] = 'thin',
  style: React.CSSProperties['borderStyle'] = 'solid'
) => ({
  borderLeft: ['l', 'x', 'a'].includes(position)
    ? `${width} ${style} ${theme.palette.divider}`
    : undefined,
  borderRight: ['r', 'x', 'a'].includes(position)
    ? `${width} ${style} ${theme.palette.divider}`
    : undefined,
  borderTop: ['t', 'y', 'a'].includes(position)
    ? `${width} ${style} ${theme.palette.divider}`
    : undefined,
  borderBottom: ['b', 'y', 'a'].includes(position)
    ? `${width} ${style} ${theme.palette.divider}`
    : undefined,
});

export const card = (theme: Theme, variant: 'outlined' | 'elevated' | 'flat') => ({
  borderRadius: theme.shape.borderRadius,
  border: variant === 'outlined' ? `thin solid ${theme.palette.divider}` : undefined,
  boxShadow: variant === 'elevated' ? theme.shadows[1] : undefined,
});

export const fixedScrollBar = (theme: Theme) => ({
  '::-webkit-scrollbar': {
    width: theme.spacing(1),
    height: theme.spacing(1),
  },
  '::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    boxShadow: 'inset 0 0 4px grey',
  },
  '::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: theme.spacing(0.5),
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
});

export function withProps<P>(Component: ComponentType<P>, defaultProps: Partial<P>) {
  // eslint-disable-next-line react/display-name
  return forwardRef((props: P, ref) => <Component {...defaultProps} {...props} ref={ref} />);
}

export const hideScrollBar = {
  MsOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

export interface TransformOptions {
  translate?: {
    x?: number | string;
    y?: number | string;
  };
  skew?: {
    x?: number | string;
    y?: number | string;
  };
  scale?: {
    x?: number | string;
    y?: number | string;
  };
}

export const createTransformString = ({ skew, scale, translate }: TransformOptions) => {
  const normalize = (value: number | string, unit = '') =>
    typeof value === 'number' ? `${value}${unit}` : value;
  const scaleX = normalize(scale?.x ?? 1);
  const scaleY = normalize(scale?.y ?? 1);
  const skewX = normalize(skew?.x ?? 0, 'deg');
  const skewY = normalize(skew?.y ?? 0, 'deg');
  const translateX = normalize(translate?.x ?? 0, 'px');
  const translateY = normalize(translate?.y ?? 0, 'px');
  return [
    `scaleX(${scaleX})`,
    `scaleY(${scaleY})`,
    `skewX(${skewX})`,
    `skewY(${skewY})`,
    `translateX(${translateX})`,
    `translateY(${translateY})`,
  ].join(' ');
};
