import { authFetcher, COMPANY_UUID_HEADER_KEY } from '../../client';

export enum SharedUserScope {
  COMPANY = 'company',
  PROJECT = 'project',
}
export enum UserRole {
  READ = 'read',
  WRITE = 'write',
  MANAGER = 'manager',
  ADMIN = 'admin', // admin role on Cloudimage
  ADMINISTRATOR = 'administrator', // admin role on Hub
  OWNER = 'owner',
}

export type SharedUserRequestParams = {
  scope_type: SharedUserScope;
  level: UserRole;
  email_address: string;
  data?: {
    permissions?: string[];
  };
  scope_projects: string[] | null;
  company_uuid: string;
};

export const resendInvitationRequest = (invitationUuid: string) =>
  authFetcher.put(`/share/invitation/${invitationUuid}/resend`, null);
export const getInvitationRequest = (invitationUuid: string) =>
  authFetcher.get<{ email: string | null }>(`/invite/${invitationUuid}`);

export const cancelInvitationRequest = (invitationUuid: string) =>
  authFetcher.delete(`/share/invitation/${invitationUuid}`);

export const shareUserRequest = ({
  company_uuid: companyUuid,
  ...share
}: SharedUserRequestParams) =>
  authFetcher.post('/share/user', { share }, (options) => ({
    ...options,
    headers: {
      ...options.headers,
      [COMPANY_UUID_HEADER_KEY]: companyUuid,
    },
  }));

/**
 * Update shared user (Update permission of user)
 * @param shareUuid
 */
export const editSharedUserRequest = ({
  shareUuid,
  share,
}: {
  shareUuid: string;
  share: SharedUserRequestParams;
}) => authFetcher.put(`/share/user/${shareUuid}`, { share });

/**
 * Delete shared user (Remove user from project)
 * @param shareUuid
 */
export const deleteSharedUserRequest = (shareUuid: string) =>
  authFetcher.delete(`/share/user/${shareUuid}`);
