import React, { forwardRef } from 'react';
import { Paper, Stack } from '@mui/material';
import { differenceInDays } from 'date-fns';
import { formatDate } from 'date-fns/format';

import { DfDatetimeRangePicker } from '../../../../../df-picker/components/df-datetime-range-picker';
import { getDateRange } from '../../utils';
import { PeriodSelect, PeriodSelectProps } from '../period-select';

export interface DatePeriodPickerProps extends PeriodSelectProps {
  showTimeSelect?: boolean;
  showTimeSelectIfDaysLessThan?: number;
}

export const DatePeriodPicker = forwardRef<HTMLDivElement, DatePeriodPickerProps>(
  function DatePeriodPicker(
    {
      value,
      onChange = () => {},
      showTimeSelect,
      showTimeSelectIfDaysLessThan,
      availablePeriods,
      onHidePeriodsToggle,
    },
    ref
  ) {
    const dateRange = getDateRange(value);

    const isShowTimeSelect = React.useMemo(() => {
      if (!showTimeSelect) {
        return false;
      }
      if (!showTimeSelectIfDaysLessThan) {
        return true;
      }
      const dateDiff = Math.abs(differenceInDays(dateRange[0], dateRange[1]));
      return dateDiff < showTimeSelectIfDaysLessThan;
    }, [dateRange, showTimeSelect, showTimeSelectIfDaysLessThan]);

    const handleOnChangeDatePicker = React.useCallback(
      (newRange: [Date, Date]) => {
        onChange({
          type: 'fixed',
          value: [
            formatDate(newRange[0], isShowTimeSelect ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd'),
            formatDate(newRange[1], isShowTimeSelect ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd'),
          ],
        });
      },
      [isShowTimeSelect, onChange]
    );
    return (
      <Paper ref={ref}>
        <Stack p={1} maxWidth={450} alignItems="center">
          <PeriodSelect
            value={value}
            onChange={onChange}
            availablePeriods={availablePeriods}
            onHidePeriodsToggle={onHidePeriodsToggle}
          />
          <DfDatetimeRangePicker
            showTimeSelect={isShowTimeSelect}
            value={dateRange}
            onChange={handleOnChangeDatePicker}
          />
        </Stack>
      </Paper>
    );
  }
);
