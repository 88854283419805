import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme as MuiTheme,
} from '@mui/material';

interface MuiTooltipComponentType {
  styleOverrides?: ComponentsOverrides<MuiTheme>['MuiTooltip'];
  defaultProps?: ComponentsProps['MuiTooltip'];
  variants?: ComponentsVariants['MuiTooltip'];
}

export const MuiTooltipComponent: MuiTooltipComponentType = {
  // variants: [],
  defaultProps: {
    disableInteractive: true,
  },
  // styleOverrides: {},
};
