import { fillNullWithDefault } from '~utils/object';

import { coreFetcher } from '../../client';
import { DataElementEntityColumn } from '../data-source';
import { MonitorEntity } from '../monitor';
import { applyMiddleware, requestsFactory, ResponseMiddleWare } from '../request';
import { SignalEntity } from '../signal';
import { EntityUuid } from '../types';

import {
  ChartElementEntity,
  defaultAxisChartConfig,
  defaultBaseElementEntity,
  defaultColumnConfigInit,
  defaultDataCardConfig,
  defaultGeneralConfig,
  defaultMixedChartConfig,
  defaultPieChartConfig,
  defaultSankeyDiagramConfig,
  defaultTableChartConfig,
  defaultTextElementConfig,
  ElementEntity,
  NewElementData,
  PredefinedDateRange,
  TableChartConfig,
} from './element.entity';

export const PREDEFINED_DATE_RANGE: ReadonlyArray<{ value: PredefinedDateRange; name: string }> = [
  {
    value: 'last-year',
    name: 'Last year',
  },
  {
    value: 'last-month',
    name: 'Last month',
  },
  {
    value: 'last-week',
    name: 'Last week',
  },
  {
    value: 'this-year',
    name: 'This year',
  },
  {
    value: 'this-month',
    name: 'This month',
  },
  {
    value: 'this-week',
    name: 'This week',
  },
  {
    value: 'yesterday',
    name: 'Yesterday',
  },
  {
    value: 'today',
    name: 'Today',
  },
];

const namespace = '/element';

export const fillElementNullConfig = (item: ElementEntity): ElementEntity => {
  item = fillNullWithDefault<ElementEntity>(item, defaultBaseElementEntity());
  switch (item.element_type) {
    case 'chart':
      let elementConfig = item.element_config;
      switch (item.element_config.chartType) {
        case 'pie':
          elementConfig = fillNullWithDefault(elementConfig, {
            ...defaultGeneralConfig(),
            ...defaultPieChartConfig(),
          });
          break;
        case 'bar':
        case 'line':
        case 'horizontalBar':
          elementConfig = fillNullWithDefault(elementConfig, {
            ...defaultGeneralConfig(),
            ...defaultAxisChartConfig(),
          });
          break;
        case 'mixed':
          elementConfig = fillNullWithDefault(elementConfig, {
            ...defaultGeneralConfig(),
            ...defaultMixedChartConfig(),
          });
          break;
        case 'table':
          elementConfig = {
            ...fillNullWithDefault(elementConfig, {
              ...defaultGeneralConfig(),
              ...defaultTableChartConfig(),
            }),
            columns: (elementConfig as TableChartConfig).columns?.map((col) =>
              fillNullWithDefault(col, defaultColumnConfigInit(col.displayType))
            ),
          } as TableChartConfig;
          break;
        case 'card':
          elementConfig = fillNullWithDefault(elementConfig, {
            ...defaultGeneralConfig(),
            ...defaultDataCardConfig(),
          });
          break;
        case 'sankey':
          elementConfig = fillNullWithDefault(elementConfig, {
            ...defaultGeneralConfig(),
            ...defaultSankeyDiagramConfig(),
          });
          break;
      }
      return {
        ...item,
        element_config: elementConfig,
      } as ChartElementEntity;
    case 'text':
      return {
        ...item,
        element_config: { ...item.element_config, ...defaultTextElementConfig() },
      };
  }
  return item;
};

const singleElementMiddleware: ResponseMiddleWare<ElementEntity> = (item) =>
  fillElementNullConfig(item);
const multipleElementMiddleware: ResponseMiddleWare<ElementEntity[]> = (items) =>
  items.map((item) => fillElementNullConfig(item));

const requests = requestsFactory<ElementEntity>(namespace, {
  middlewares: {
    getOneRequest: [singleElementMiddleware],
    getManyRequest: [multipleElementMiddleware],
    insertOneRequest: [singleElementMiddleware],
    updateOneRequest: [singleElementMiddleware],
    updateManyRequest: [multipleElementMiddleware],
    restoreOneRequest: [singleElementMiddleware],
    restoreManyRequest: [multipleElementMiddleware],
  },
});

async function insertOneRequest({ image, ...data }: NewElementData) {
  const formData = new FormData();
  if (image) {
    formData.append('image', image);
  }
  formData.append('data', JSON.stringify(data));
  const response = await coreFetcher.post<ElementEntity>(`${namespace}`, formData);
  return applyMiddleware(response.data, [singleElementMiddleware]);
}

async function updateOneRequest(
  id: string,
  { image, ...data }: Partial<ElementEntity & { image?: File }>
) {
  const formData = new FormData();
  if (image) {
    formData.append('image', image);
  }
  formData.append('data', JSON.stringify(data));
  formData.append('_method', 'patch');
  const response = await coreFetcher.post<ElementEntity>(`${namespace}/${id}`, formData);
  return applyMiddleware(response.data, [singleElementMiddleware]);
}

async function updateConfigRequest<
  T extends ElementEntity = ElementEntity,
  C extends T['element_config'] = T['element_config'],
>(id: EntityUuid, config: Partial<C>, replace = false): Promise<T> {
  let url = `${namespace}/${id}/config`;
  if (replace) {
    url += '?replace=1';
  }
  const { data: element } = await coreFetcher.patch<ElementEntity>(url, config);
  return applyMiddleware(element, [singleElementMiddleware]) as Promise<T>;
}

//
// async function updateLabelsRequest<T extends AnyElementEntity = AnyElementEntity>(
//   id: EntityUuid,
//   labels: Record<string, string|null>,
// ): Promise<T> {
//   const url = `${namespace}/${id}/labels`;
//   const { data: element } = await client.patch<AnyElementEntity>(url, {
//     labels,
//   });
//   return applyMiddleware(element, [singleElementMiddleware]) as Promise<T>;
// }

// async function updateColorsRequest<T extends AnyElementEntity = AnyElementEntity>(
//   id: EntityUuid,
//   colors: Record<string, string|null>,
// ): Promise<T> {
//   const url = `${namespace}/${id}/colors`;
//   const { data: element } = await client.patch<T>(url, {
//     colors,
//   });
//   return element;
// }

async function getLoggingData(
  id: EntityUuid,
  filter?: string,
  bindParams?: any,
  offset = 0,
  limit = 100
): Promise<any[]> {
  let url = `${namespace}/${id}/logging-data?offset=${offset}&limit=${limit}`;
  if (filter) {
    url += `&filter=${encodeURI(filter)}`;
  }
  if (bindParams) {
    const bindParamsString = encodeURI(JSON.stringify(bindParams));
    url += `&bindParams=${bindParamsString}`;
  }
  const { data: rows } = await coreFetcher.get<any[]>(url);
  return rows;
}

async function getHistogramData(id: EntityUuid, filter?: string, bindParams?: any): Promise<any[]> {
  let url = `${namespace}/${id}/histogram-data?a=a`;
  if (filter) {
    url += `&filter=${encodeURI(filter)}`;
  }
  if (bindParams) {
    const bindParamsString = encodeURI(JSON.stringify(bindParams));
    url += `&bindParams=${bindParamsString}`;
  }
  const { data: rows } = await coreFetcher.get<any[]>(url);
  return rows;
}

async function getLogSeverities(id: EntityUuid): Promise<string[]> {
  const { data: rows } = await coreFetcher.get<string[]>(`${namespace}/${id}/logging-severity`);
  return rows;
}

async function getLoggingStructure(id: EntityUuid): Promise<DataElementEntityColumn[]> {
  const { data: columns } = await coreFetcher.get<DataElementEntityColumn[]>(
    `${namespace}/${id}/logging-structure`
  );
  return columns;
}

async function copyElements(
  ids: EntityUuid[],
  config: {
    pageId: EntityUuid;
    adjustX?: number;
    adjustY?: number;
  }
): Promise<ElementEntity[]> {
  const { data: elements } = await coreFetcher.post<ElementEntity[]>(`${namespace}/copy`, {
    ids,
    ...config,
  });
  return applyMiddleware(elements, [multipleElementMiddleware]);
}

export const getElementParamDefaultValues = (element: ElementEntity) => {
  const params: Record<string, string> = {};
  (element?.element_query_params || [])
    .filter((param) => ['element', 'fixed'].includes(param.valueFrom))
    .forEach((param) => {
      params[param.name] = param.default;
    }, []);
  return params;
};

export interface GetMonitorsSignalsOptions {
  elementUuid: EntityUuid;
}

export const getElementMonitorsRequest = async ({ elementUuid }: GetMonitorsSignalsOptions) => {
  const response = await coreFetcher.get<Array<MonitorEntity>>(
    `${namespace}/${elementUuid}/monitor`
  );
  return response.data.reverse();
};

export const getElementSignalsRequest = async ({ elementUuid }: GetMonitorsSignalsOptions) => {
  const response = await coreFetcher.get<Array<SignalEntity>>(`${namespace}/${elementUuid}/signal`);
  return response.data.reverse();
};

export default {
  ...requests,
  insertOneRequest,
  updateOneRequest,
  updateConfigRequest,
  getLoggingData,
  getLoggingStructure,
  getHistogramData,
  getLogSeverities,
  copyElements,
  getElementMonitorsRequest,
  getElementSignalsRequest,
};
