import { ThemeOptions } from '@mui/material';

import { MuiButtonComponent } from './mui-button.component';
import { MuiChipComponent } from './mui-chip.component';
import { MuiDialogActionsComponent } from './mui-dialog-actions.component';
import { MuiDialogTitleComponent } from './mui-dialog-title.component';
import { MuiInputBaseComponent } from './mui-input-base.component';
import { MuiLinkComponent } from './mui-link.component';
import { MuiSvgIconComponent } from './mui-svg-icon.component';
import { MuiTabsComponent } from './mui-tabs.component';
import { MuiTooltipComponent } from './mui-tooltip.component';

const components: ThemeOptions['components'] = {
  MuiButton: MuiButtonComponent,
  MuiLink: MuiLinkComponent,
  MuiTabs: MuiTabsComponent,
  MuiChip: MuiChipComponent,
  MuiDialogTitle: MuiDialogTitleComponent,
  MuiDialogActions: MuiDialogActionsComponent,
  MuiSvgIcon: MuiSvgIconComponent,
  MuiTooltip: MuiTooltipComponent,
  MuiInputBase: MuiInputBaseComponent,
};

export default components;
