import { useMemo } from 'react';

import { calculateRadius, Coordinate } from '~utils/geometry';

import { Line } from './line-draw.styled';

interface LineDrawProps {
  from: Coordinate;
  to: Coordinate;
}

export function LineDraw({ from, to }: LineDrawProps) {
  const length = useMemo(
    () => Math.sqrt((from.x - to.x) ** 2 + (from.y - to.y) ** 2),
    [from.x, from.y, to.x, to.y]
  );

  const radius = useMemo(() => calculateRadius(from, to), [from, to]);

  return (
    <Line
      style={{
        width: length,
        top: from.y,
        left: from.x,
        transform: `rotate(${radius.toFixed(5)}deg)`,
      }}
    />
  );
}
