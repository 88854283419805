import React, { Suspense } from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import { AuthenticationLayout } from '~components/layouts';
import { ThemeProvider } from '~providers/theme-provider';

import LoadingPanel from '../components/loading-panel';

/**
 * Avoiding lazy login and register page to make it load faster
 */
import LoginPage from './login-page';
import RegisterPage from './register-page';

const ElementViewPage = React.lazy(() => import('./element-view-page'));
const ComingSoonPage = React.lazy(() => import('./coming-soon-page'));
const AutoLoginPage = React.lazy(() => import('./auto-login-page'));
const CatalogsPage = React.lazy(() => import('./catalog-page'));
const DashBoardPage = React.lazy(() => import('./dashboard-page'));
const SelectDatacenterPage = React.lazy(() => import('./select-datacenter-page'));
const DashboardLoginPage = React.lazy(() => import('./dashboard-login-page'));
const DashboardEditorPage = React.lazy(() => import('./dashboard-editor-page'));
const DashboardViewPage = React.lazy(() => import('./dashboard-view-page'));
const CompanyPage = React.lazy(() => import('./company-page'));
const CompanyDetailPage = React.lazy(() => import('./company-detail-page'));
const ContactPage = React.lazy(() => import('./contact-page'));
const ProfilePage = React.lazy(() => import('./profile-page'));
const RetrievePasswordPage = React.lazy(() => import('./retrieve-password-page'));
const QueryPage = React.lazy(() => import('./query-page'));
const SignalPage = React.lazy(() => import('./signal'));
const MonitorPage = React.lazy(() => import('./monitor-page'));
const IncidentPage = React.lazy(() => import('./incident-page'));
const OnboardingPage = React.lazy(() => import('./onboarding-page'));
const QuestionPage = React.lazy(() => import('./question-page'));
const ConnectPage = React.lazy(() => import('./connect-page'));
const DefaultLayout = React.lazy(() =>
  import('~components/layouts/default-layout').then(({ DefaultLayout }) => ({
    default: DefaultLayout,
  }))
);
const DataExplorerPage = React.lazy(() => import('./data-explorer-page'));
const DataExplorerWidgetPage = React.lazy(() => import('./data-explorer-widget-page'));
const DataExplorerLoginPage = React.lazy(() => import('./data-explorer-login-page'));
const ErrorNotFoundPage = React.lazy(() => import('./error-not-found-page'));

const routes: RouteObject[] = [
  {
    element: <ThemeProvider name="experiment" />,
    children: [
      {
        path: '/explorer',
        element: <DataExplorerPage />,
      },
      {
        path: '/explorer/:userUuid',
        element: <DataExplorerPage />,
      },
      {
        path: '/explorer/e/:userUuid',
        element: <DataExplorerWidgetPage />,
      },
      {
        path: '/explorer/authentication/:userUuid',
        element: <DataExplorerLoginPage />,
      },
      {
        path: '/404',
        element: <ErrorNotFoundPage />,
      },
    ],
  },
  {
    element: (
      <ThemeProvider name="experiment">
        <AuthenticationLayout />
      </ThemeProvider>
    ),
    children: [
      {
        path: '/explorer',
        element: <DataExplorerPage />,
      },
      {
        path: '/login',
        element: <LoginPage />,
      },
      {
        path: '/datacenter-select',
        element: <SelectDatacenterPage />,
      },
      {
        path: '/autologin',
        element: <AutoLoginPage />,
      },
      {
        path: '/register',
        element: <RegisterPage />,
      },
      {
        path: '/s/register',
        element: <RegisterPage />,
      },
      {
        path: '/onboarding',
        element: <OnboardingPage />,
      },
      {
        path: '/s/:shareId/authentication',
        element: <DashboardLoginPage />,
      },
      {
        path: '/share/:shareId/authentication',
        element: <DashboardLoginPage />,
      },
      {
        path: '/s/password/:token',
        element: <RetrievePasswordPage />,
      },
    ],
  },
  {
    element: <ThemeProvider />,
    children: [
      {
        path: '/element-share/:shareId',
        element: <ElementViewPage />,
      },
      {
        path: '/es/:shareId',
        element: <ElementViewPage />,
      },
      {
        path: '/share/:shareId',
        element: <DashboardViewPage />,
      },
      {
        path: '/s/:shareId',
        element: <DashboardViewPage />,
      },
      {
        path: '/:shareId/share',
        element: <DashboardViewPage />,
      },
      {
        path: '/:documentId/view',
        element: <DashboardViewPage />,
      },
      {
        path: '/:documentId/edit',
        element: <DashboardEditorPage />,
      },
      {
        element: <DefaultLayout />,
        children: [
          {
            path: '/',
            element: <DashBoardPage />,
          },
          {
            path: '/questions',
            element: <QuestionPage />,
          },
          {
            path: '/charts',
            element: <ComingSoonPage />,
          },
          {
            path: '/alerts',
            element: <ComingSoonPage />,
          },
          {
            path: '/quality',
            element: <ComingSoonPage />,
          },
          {
            path: '/stats',
            element: <ComingSoonPage />,
          },
          {
            path: '/query',
            element: <QueryPage />,
          },
          {
            path: '/signal',
            element: <SignalPage />,
          },
          {
            path: '/monitor',
            element: <MonitorPage />,
          },
          {
            path: '/catalog',
            element: <CatalogsPage />,
          },
          {
            path: '/incident',
            element: <IncidentPage />,
          },
          {
            path: '/contact',
            element: <ContactPage />,
          },
          {
            path: '/company',
            element: <CompanyPage />,
          },
          {
            path: '/company/:companyUuid',
            children: [
              {
                index: true,
                element: <Navigate to="./user" replace />,
              },
              {
                path: '/company/:companyUuid/user',
                element: <CompanyDetailPage />,
              },
              {
                path: '/company/:companyUuid/project',
                element: <CompanyDetailPage />,
              },
            ],
          },
          {
            path: '/profile',
            element: <ProfilePage />,
          },
          {
            path: '/setting',
            element: <ComingSoonPage />,
          },
          {
            path: '/connect/:providerName/:userProviderId',
            element: <ConnectPage />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
];

function Routes() {
  const routesElement = useRoutes(routes);
  return <Suspense fallback={<LoadingPanel variant="linear" />}>{routesElement}</Suspense>;
}

export default Routes;
