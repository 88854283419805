import { styled } from '@mui/material';

import { SearchTextToken } from '~utils/string';

export const TextTokenItem = styled('span', {
  shouldForwardProp: (prop) => prop !== 'type',
})<{ type: SearchTextToken['type'] }>(
  ({ theme, type }) =>
    type === 'keyword' && {
      backgroundColor: theme.palette.warning.light,
    }
);
