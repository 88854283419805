import { Box, styled, typographyClasses } from '@mui/material';

export const ConnectionStringWrapper = styled(Box, {
  shouldForwardProp: (propName) => propName != 'fontSize',
})<{
  fontSize?: number;
}>(({ fontSize = 11 }) => ({
  [`.${typographyClasses.root}`]: {
    fontSize: fontSize,
  },
}));
